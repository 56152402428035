import {
  ensureItemLayoutTypeIsSupported,
  isFullWidthByLayouts,
  isSiteWidthByLayouts,
  isMeshItemLayout,
  layoutSize,
  ensureItemLayoutIsMeshItemLayout,
  ensureContainerLayoutIsMeshContainerLayout,
} from '#packages/layoutUtils';
import { isMeshLayoutEnabled as isMeshLayoutEnabled_inner } from '#packages/layoutOneDockMigration';
import { moveByAndPushTransition as moveByAndPushTransitionInner } from './transitions/moveByAndPushTransition';
import {
  moveByTransition as moveByTransitionInner,
  moveByTransitionEndInterceptor,
  moveByTransitionEndAfterHook,
} from './transitions/moveByTransition';
import { resizeToAndPushTransition as resizeToAndPushTransitionInner } from './transitions/resizeToAndPushTransition';
import { createLayoutMeshReparentApi } from './layoutMeshReparentApi/createLayoutMeshReparentApi';
import { createLayoutMeshMoveByApi } from './layoutMeshMoveByApi/createLayoutMeshMoveByApi';
import { createLayoutMeshMoveToApi } from './createLayoutMeshMoveToApi';
import { createLayoutMeshGroupApi } from './createLayoutMeshGroupApi';
import { createLayoutMeshArrangementApi } from './createLayoutMeshArrangementApi';
import { recalculateContainerMeshGrid } from './recalculateContainerMeshGrid';
import {
  calculateComponentLayoutHeight,
  calculateComponentLayoutWidth,
} from './calculateComponentLayoutWidthAndHeight';
import { isNumber } from './utils';
import _ from 'lodash';

import type { EditorAPI } from '#packages/editorAPI';
import type { HistoryAddOptions } from '#packages/history';
import type {
  CompRef,
  SingleLayoutData,
  Rect,
  RecursivePartial,
} from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutResizeToOptions, LayoutRotateToOptions } from '../type';
import type { HistoryApi } from '../createHistoryApi';

/**
 * @deprecated use isMeshLayoutEnabled from `@/layoutOneDockMigration` instead
 * @example
 * import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
 */
export const isMeshLayoutEnabled = isMeshLayoutEnabled_inner;

export function createLayoutMeshApi({
  editorAPI,
  historyApi,
  layoutGetApi,
}: {
  editorAPI: EditorAPI;
  historyApi: HistoryApi;
  layoutGetApi: LayoutGetApi;
}) {
  function resolveCompRefWithVariant(compRef: CompRef): CompRef {
    return compRef.type === 'MOBILE'
      ? editorAPI.components.variants.getPointer(compRef, [
          editorAPI.variants.mobile.get(),
        ])
      : compRef;
  }

  function get(compRef: CompRef): SingleLayoutData {
    if (!isMeshLayoutEnabled_inner()) {
      throw new Error('Mesh layout is not enabled');
    }

    if (Array.isArray(compRef)) {
      throw new Error(
        'get() does not support array of compRefs.\nEnsure you are passing a single compRef',
      );
    }

    return editorAPI.components.responsiveLayout.get(
      resolveCompRefWithVariant(compRef),
    );
  }

  function measureRect(compRef: CompRef): Rect {
    return editorAPI.components.responsiveLayout.runtime.measure.getRelativeToContainerBoundingBox(
      compRef,
    );
  }

  function update(
    compRef: CompRef,
    layoutsPartial: RecursivePartial<SingleLayoutData>,
  ) {
    if (layoutsPartial.itemLayout) {
      ensureItemLayoutTypeIsSupported(layoutsPartial.itemLayout);
    }

    editorAPI.dsActions.components.responsiveLayout.update(
      resolveCompRefWithVariant(compRef),
      layoutsPartial,
    );
  }

  /**
   * Upadate container children grid after
   * - adding/removing component to the container or
   * = updating component position or height
   */
  function updateContainerGrid(
    containerRef: CompRef,
    {
      compRectsMap,
      convertChildrenToMeshItemLayout,
    }: {
      compRectsMap?: Map<string, Rect>;
      convertChildrenToMeshItemLayout?: boolean;
    } = {},
  ) {
    if (!editorAPI.dsRead.transactions.isRunning()) {
      throw new Error(
        'updateContainerGrid should be called only inside a transaction',
      );
    }

    const getRect = (compRef: CompRef) =>
      compRectsMap?.get(compRef.id) ?? measureRect(compRef);
    const containerRect = getRect(containerRef);
    const containerChildrenRefs = editorAPI.components
      .getChildren(containerRef)
      .filter((compRef) =>
        convertChildrenToMeshItemLayout
          ? compRectsMap.has(compRef.id)
          : isMeshItemLayout(get(compRef)?.itemLayout),
      )
      .map((compRef) => resolveCompRefWithVariant(compRef));

    if (containerChildrenRefs.length === 0) {
      return;
    }

    const containerLayouts = get(containerRef);
    const containerChildrenRectsMap = new Map(
      containerChildrenRefs.map((compRef) => [compRef.id, getRect(compRef)]),
    );
    const meshGridLayouts = recalculateContainerMeshGrid(
      editorAPI,
      containerRef,
      {
        containerRect,
        containerChildrenRefs,
        containerChildrenRectsMap,
      },
    );

    update(containerRef, {
      containerLayout: {
        ...containerLayouts.containerLayout,
        ...meshGridLayouts.containerLayout,
      },
    });

    containerChildrenRefs.forEach((compRef) => {
      const layouts = get(compRef);

      // preserve previous layoutItem properties only for MeshItemLayout
      const itemLayoutPrev = isMeshItemLayout(layouts.itemLayout)
        ? layouts.itemLayout
        : {};
      const itemRect = containerChildrenRectsMap.get(compRef.id);
      const itemLayoutRectOverrides =
        !isFullWidthByLayouts(layouts) && !isSiteWidthByLayouts(layouts)
          ? { left: layoutSize.px(itemRect.x) }
          : {};
      const itemLayoutGridOverrides = meshGridLayouts.itemLayoutByCompId.get(
        compRef.id,
      );

      update(compRef, {
        itemLayout: {
          type: 'MeshItemLayout',
          ...itemLayoutPrev,
          ...itemLayoutRectOverrides,
          ...itemLayoutGridOverrides,
        },
      });
    });
  }

  const layoutMeshCoreApi = {
    resolveCompRefWithVariant,
    measureRect,
    get,
    update,
    updateContainerGrid,
  };
  const layoutMeshReparentApi = createLayoutMeshReparentApi({
    editorAPI,
    layoutMeshCoreApi,
  });
  const layoutMeshMoveByApi = createLayoutMeshMoveByApi({
    editorAPI,
    historyApi,
    layoutMeshCoreApi,
    layoutMeshReparentApi,
  });
  const layoutMeshMoveToApi = createLayoutMeshMoveToApi({
    editorAPI,
    historyApi,
    layoutMeshCoreApi,
  });
  const layoutMeshGroupApi = createLayoutMeshGroupApi({
    editorAPI,
    layoutGetApi,
    layoutMeshCoreApi,
  });
  const layoutMeshArrangementApi = createLayoutMeshArrangementApi({
    editorAPI,
    historyApi,
    layoutMeshCoreApi,
  });

  function moveByAndPushTransition(compRef: CompRef) {
    return moveByAndPushTransitionInner(
      {
        editorAPI,
        historyApi,
        layoutMeshCoreApi,
      },
      compRef,
    );
  }

  function moveByTransition(compRefs: CompRef[]) {
    return moveByTransitionInner(
      {
        editorAPI,
        historyApi,
        layoutGetApi,
        layoutMeshCoreApi,
        layoutMeshMoveByApi,
      },
      compRefs,
    );
  }

  function getConstrainedRect<TRect extends Partial<Rect>>(
    compRef: CompRef,
    compRect: TRect,
  ): TRect {
    return _.pick(
      editorAPI.components.layout.getConstrainedLayout(compRef, compRect),
      Object.keys(compRect),
    ) as TRect;
  }

  function resizeTo_Inner(
    compRef: CompRef,
    compSize: { width?: number; height?: number },
  ) {
    if (!isNumber(compSize.width) && !isNumber(compSize.height)) {
      throw new Error(
        'resizeTo should be called with at least one of width or height',
      );
    }

    const layouts = get(compRef);

    ensureItemLayoutIsMeshItemLayout(layouts.itemLayout);

    let { width, height, minHeight } = layouts.componentLayout;
    let { width: meshWidth, height: meshHeight } = layouts.itemLayout.meshData;

    if (isNumber(compSize.width)) {
      ({ width } = calculateComponentLayoutWidth(layouts, compSize.width));
      meshWidth = compSize.width;
    }

    if (isNumber(compSize.height)) {
      ({ height, minHeight } = calculateComponentLayoutHeight(
        layouts,
        compSize.height,
      ));
      meshHeight = compSize.height;
    }

    const newLayout: RecursivePartial<SingleLayoutData> = {
      itemLayout: {
        ...layouts.itemLayout,
        meshData: {
          ...layouts.itemLayout.meshData,
          width: meshWidth,
          height: meshHeight,
        },
      },
      componentLayout: {
        ...layouts.componentLayout,
        width,
        height,
        minHeight,
      },
    };

    const isContainer = editorAPI.components.is.container(compRef);

    if (isContainer) {
      ensureContainerLayoutIsMeshContainerLayout(layouts.containerLayout);

      newLayout.containerLayout = {
        ...layouts.containerLayout,
        meshData: {
          ...layouts.containerLayout.meshData,
          contentHeight: meshHeight, // TODO: layout constraints issue
        },
      };
    }

    // to avoid resize and push behaviour we need to update both namespaces
    // meshData and componentLayout
    update(compRef, newLayout);
  }

  async function resizeTo(
    compRef: CompRef,
    compSizeRaw: { width?: number; height?: number },
    options: LayoutResizeToOptions & {
      dontApplyConstrains?: boolean;
    } = {},
  ): Promise<void> {
    const compSize = !options.dontApplyConstrains
      ? getConstrainedRect(compRef, compSizeRaw)
      : compSizeRaw;

    resizeTo_Inner(compRef, compSize);

    historyApi.debouncedAdd('component - update layout size', options);
  }

  async function rotateTo(
    compRef: CompRef,
    rotationInDegrees: number,
    options: LayoutRotateToOptions = {},
  ): Promise<void> {
    editorAPI.components.transformations.update(compRef, {
      type: 'TransformData',
      rotate: rotationInDegrees,
    });

    historyApi.debouncedAdd('component - update layout rotation', options);
  }

  function resizeToAndPushTransition(compRef: CompRef) {
    return resizeToAndPushTransitionInner(
      {
        editorAPI,
        historyApi,
        layoutMeshCoreApi,
      },
      compRef,
    );
  }

  /**
   * Update position, size and rotation of a component in a single transaction
   */
  async function batchUpdate(
    compRef: CompRef,
    layoutUpdate: Partial<Rect> & { rotationInDegrees?: number },
    options: HistoryAddOptions & { dontApplyConstrains?: boolean } = {},
  ) {
    await editorAPI.transactions.run(async () => {
      const { rotationInDegrees, ...rectRaw } = layoutUpdate;
      const rect = !options.dontApplyConstrains
        ? getConstrainedRect(compRef, rectRaw)
        : rectRaw;

      if (isNumber(rotationInDegrees)) {
        await rotateTo(compRef, rotationInDegrees, {
          dontAddToUndoRedoStack: true,
        });
      }

      if (isNumber(rect.width) || isNumber(rect.height)) {
        await resizeTo(
          compRef,
          {
            width: rect.width,
            height: rect.height,
          },
          {
            dontAddToUndoRedoStack: true,
            dontApplyConstrains: true,
          },
        );
      }

      if (isNumber(rect.x) || isNumber(rect.y)) {
        await layoutMeshMoveToApi.moveTo(
          compRef,
          { x: rect.x, y: rect.y },
          {
            dontAddToUndoRedoStack: true,
          },
        );
      }
    });

    historyApi.debouncedAdd(
      `component - update layout (${Object.keys(layoutUpdate).join(', ')})`,
      options,
    );
  }

  return {
    __core: layoutMeshCoreApi,
    hooks: {
      moveByTransitionEndInterceptor,
      moveByTransitionEndAfter: moveByTransitionEndAfterHook,
    },
    get,
    measureRect,
    moveBy: layoutMeshMoveByApi.moveBy,
    moveByAndPushTransition,
    moveByTransition,
    moveTo: layoutMeshMoveToApi.moveTo,
    moveToMany: layoutMeshMoveToApi.moveToMany,
    resizeTo,
    resizeToAndPushTransition,
    rotateTo,
    batchUpdate,
    groupTransaction: layoutMeshGroupApi.groupTransaction,
    ungroupTransaction: layoutMeshGroupApi.ungroupTransaction,
    updateContainerGrid,
    reparentAndUpdateContainersGrids:
      layoutMeshReparentApi.reparentAndUpdateContainersGrids,
    arrangement: layoutMeshArrangementApi,
    __updateMeshLayout: update,
    /**
     * @deprecated use isMeshLayoutEnabled from `@/layoutOneDockMigration` instead
     */
    isEnabled: isMeshLayoutEnabled,
  };
}

export { moveByTransitionEndInterceptor };
export type LayoutMeshApi = ReturnType<typeof createLayoutMeshApi>;
