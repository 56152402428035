//@ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

import type { CompRef } from 'types/documentServices';

import type { EditorAPI } from '#packages/editorAPI';

const BLOCK_COMP_WIDTH = 700;
const MOBILE_BLOCK_COMP_WIDTH = 300;
const MINIMUM_SECTION_HEIGHT = 30;
const SECTIONS_MERGE_MAX_DISTANCE = 30;
const MERGE_SECTION_THRESHOLD = -3;
const SMALL_COMPS_SECTION_THRESHOLD = 30;

function isBlockComp(CompWithLayout, isMobile) {
  const blockCompWidth = isMobile ? MOBILE_BLOCK_COMP_WIDTH : BLOCK_COMP_WIDTH;
  return CompWithLayout.layout.width >= blockCompWidth;
}

function isVerticallyContained(overlappedLayout, overlappingLayout, threshold) {
  threshold = threshold || 1;

  const overlappingYWithinOverlapped =
    overlappingLayout.y >= overlappedLayout.y &&
    overlappingLayout.y <= overlappedLayout.bottomY;
  const overlappingBottomYWithinOverlapped =
    overlappingLayout.bottomY >= overlappedLayout.y &&
    overlappingLayout.bottomY <= overlappedLayout.bottomY;
  const overlappingExceedsOverlappedInBothDirections =
    overlappingLayout.y <= overlappedLayout.y &&
    overlappingLayout.bottomY >= overlappedLayout.bottomY;

  return (
    (overlappingYWithinOverlapped &&
      overlappedLayout.bottomY - overlappingLayout.y >=
        overlappingLayout.height * threshold) ||
    (overlappingBottomYWithinOverlapped &&
      overlappingLayout.bottomY - overlappedLayout.y >=
        overlappingLayout.height * threshold) ||
    (overlappingExceedsOverlappedInBothDirections &&
      overlappedLayout.height >= overlappingLayout.height * threshold)
  );
}

function getSortedComponentsWithLayout(
  editorAPI: EditorAPI,
  components: CompRef[],
) {
  return _(components)
    .reject((comp) => editorAPI.components.layout.isPinned(comp))
    .map(function (comp) {
      let layout = editorAPI.components.layout.get(comp);
      if (layout.bounding) {
        layout = layout.bounding;
      }
      layout = {
        ...layout,
        bottomY: layout.y + layout.height,
      };
      return {
        layout,
        comp,
      };
    })
    .sortBy('layout.y')
    .value();
}

function getBlockComps(sortedComps, isMobile) {
  return sortedComps.filter((compWithLayout) =>
    isBlockComp(compWithLayout, isMobile),
  );
}

function mergeOverlappingCompsToSections(sections, sortedComps, threshold) {
  sections.forEach((section) => {
    const sectionLayout = getSectionLayout(section);
    const overlappingComps = sortedComps.filter((comp) =>
      isVerticallyContained(sectionLayout, comp.layout, threshold),
    );

    sortedComps = _.difference(sortedComps, overlappingComps);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    section.comps = _.union(section.comps, _.map(overlappingComps, 'comp'));
  });
  return sections;
}

function filterCompsThatAreContainedInSections(sortedComps, sections) {
  const compsInSectionsMap = _(sections).flatMap('comps').keyBy('id').value();

  return _.reject(
    sortedComps,
    (compWithLayout) => !!compsInSectionsMap[compWithLayout.comp.id],
  );
}

function getCompClustersSections(sortedChildren, threshold) {
  const sections = [];

  for (let i = 0; i < sortedChildren.length; i++) {
    const currComp = sortedChildren[i];
    const currSection = {
      position: {
        top: currComp.layout.y,
        bottom: currComp.layout.bottomY,
      },
      comps: [],
    };

    currSection.position.top = currComp.layout.y;
    currSection.comps.push(currComp.comp);

    let endSection = false;
    while (!endSection) {
      const nextComp = sortedChildren[i + 1];
      if (
        !nextComp ||
        nextComp.layout.y > currSection.position.bottom + threshold
      ) {
        endSection = true;
      } else {
        currSection.comps.push(nextComp.comp);
        currSection.position.bottom = Math.max(
          currSection.position.bottom,
          nextComp.layout.bottomY,
        );
        i++;
      }
    }

    sections.push(currSection);
  }

  return sections;
}

function getSectionLayout(section) {
  return {
    y: section.position.top,
    bottomY: section.position.bottom,
    height: getSectionHeight(section),
  };
}

function getSectionHeight(section) {
  return section.position.bottom - section.position.top;
}

function mergeSections(sections, mergedSectionIdx, intoSectionIdx) {
  const mergedSection = sections[mergedSectionIdx];
  const intoSection = sections[intoSectionIdx];
  intoSection.comps = intoSection.comps.concat(mergedSection.comps);

  intoSection.position.top = Math.min(
    intoSection.position.top,
    mergedSection.position.top,
  );
  intoSection.position.bottom = Math.max(
    intoSection.position.bottom,
    mergedSection.position.bottom,
  );

  sections.splice(mergedSectionIdx, 1);
}

function mergeShortSections(sections) {
  let currSection, nextSection, previousSection, isMerged;

  for (let i = 0; i < sections.length; i++) {
    isMerged = false;
    currSection = sections[i];

    if (getSectionHeight(currSection) < MINIMUM_SECTION_HEIGHT) {
      previousSection = sections[i - 1];
      nextSection = sections[i + 1];

      const distanceFromPrevious = previousSection
        ? currSection.position.top - previousSection.position.bottom
        : Number.POSITIVE_INFINITY;
      const distanceFromNext = nextSection
        ? nextSection.position.top - currSection.position.bottom
        : Number.POSITIVE_INFINITY;

      if (
        distanceFromPrevious < distanceFromNext &&
        distanceFromPrevious <= SECTIONS_MERGE_MAX_DISTANCE
      ) {
        mergeSections(sections, i, i - 1);
        isMerged = true;
      } else if (
        distanceFromNext < distanceFromPrevious &&
        distanceFromNext <= SECTIONS_MERGE_MAX_DISTANCE
      ) {
        mergeSections(sections, i, i + 1);
        isMerged = true;
      }

      if (isMerged) {
        i--;
      }
    }
  }

  return sections;
}

function mergeOverlappingSections(sections) {
  let i = 0;
  while (i < sections.length) {
    const isOverlapping =
      sections[i + 1] &&
      sections[i + 1].position.top <=
        sections[i].position.bottom + MERGE_SECTION_THRESHOLD;
    if (isOverlapping) {
      mergeSections(sections, i + 1, i);
    } else {
      i++;
    }
  }

  return sections;
}

function sectionsAboveTheirContainer(section) {
  return section.position.bottom < 0;
}

function markRemovableSections(editorAPI: EditorAPI, sections) {
  // TODO: Remove when mobile hide is supported in app studio
  const isAppStudioMobileHide =
    util.appStudioUtils.isAppStudio() && editorAPI.isMobileEditor();
  return sections.map((section) => {
    const hasNonRemovableComponent =
      isAppStudioMobileHide ||
      section.comps.some((comp) => !editorAPI.components.is.removable(comp));
    section.isRemovable = !hasNonRemovableComponent;

    return section;
  });
}

function analyzeContainerSections(editorAPI: EditorAPI, containerRef) {
  const componentsOnPage = editorAPI.components.getChildren(containerRef);
  const sortedComponents = getSortedComponentsWithLayout(
    editorAPI,
    componentsOnPage,
  );
  const blockComps = getBlockComps(
    sortedComponents,
    editorAPI.isMobileEditor(),
  );
  let smallComps = _.difference(sortedComponents, blockComps);

  let blockCompsSections = getCompClustersSections(
    blockComps,
    MERGE_SECTION_THRESHOLD,
  );
  blockCompsSections = mergeOverlappingCompsToSections(
    blockCompsSections,
    smallComps,
    0.5,
  );

  smallComps = filterCompsThatAreContainedInSections(
    smallComps,
    blockCompsSections,
  );
  const smallCompsSections = getCompClustersSections(
    smallComps,
    SMALL_COMPS_SECTION_THRESHOLD,
  );

  const sections = _(blockCompsSections)
    .union(smallCompsSections)
    .sortBy('position.top')
    .thru(mergeShortSections)
    .thru(mergeOverlappingSections)
    .reject(sectionsAboveTheirContainer)
    .thru(_.partial(markRemovableSections, editorAPI))
    .value();

  return sections;
}

export {
  analyzeContainerSections as analyzeContainer,
  getSortedComponentsWithLayout,
  BLOCK_COMP_WIDTH,
  MOBILE_BLOCK_COMP_WIDTH,
};
