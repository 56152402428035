// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import * as platformEvents from 'platformEvents';
import * as userPreferences from '../userPreferences/userPreferences';
import * as stateManagement from '#packages/stateManagement';
const { backgroundUtils, guidUtils } = util;

/**
 * SE-7281 bug , we have duplicated mobile data,  fix the current bg id's if duplication exists
 * @param mobileData
 */
const fixDuplicationOfMobileData =
  (mobileData) =>
  (dispatch, getState, { dsRead }) => {
    const primaryPageId = dsRead.pages.getPrimaryPageId();
    const backgroundData = dsRead.pages.background.get(primaryPageId, 'mobile');
    const oldId = mobileData.ref?.id || backgroundData.ref?.id || '';
    const mobileDuplication =
      userPreferences.selectors.getSessionUserPreferences(
        `bg_panel_mobile_data_duplications_${oldId}`,
      )(getState());
    if (mobileDuplication?.count) {
      mobileData.ref.id = `${guidUtils.getUniqueId(
        primaryPageId,
        '_',
      )}_mobile_bg`;
      if (mobileData.ref.mediaRef) {
        mobileData.ref.mediaRef.id = undefined;
      }

      dispatch(
        userPreferences.actions.setSessionUserPreferences(
          `bg_panel_mobile_data_duplications_${oldId}`,
          { count: mobileDuplication.count - 1 },
        ),
      );
      dispatch(
        userPreferences.actions.setSessionUserPreferences(
          `bg_panel_mobile_data_duplications_${mobileData.ref.id}`,
          { count: 0 },
        ),
      );
    }
  };

/**
 * @param mobileData
 */
function patchMobileScrollType(mobileData) {
  if (mobileData.ref.scrollType === 'fixed') {
    return;
  }
  mobileData.ref.scrollType = 'scroll';
}

/**
 * mobile data manipulation
 * @param desktopData
 * @param mobileData
 * @param pageId
 */
const updateMobileData =
  (desktopData, mobileData, pageId) =>
  (dispatch, getState, { editorAPI }) => {
    let mediaData;
    //assume mobileData is immutable
    mobileData = _.cloneDeep(mobileData);

    backgroundUtils.clearOverlayData(mobileData);
    //first level override all but id and ref
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      mobileData.ref,
      _.omit(desktopData.ref, ['id', 'mediaRef', 'metaData']),
    );
    patchMobileScrollType(mobileData);

    //second level(mediaRef)
    if (desktopData.ref.mediaRef) {
      if (desktopData.ref.mediaRef.type === 'WixVideo') {
        //mobile should discard video and set the  poster image as background
        mediaData = desktopData.ref.mediaRef.posterImageRef;
      } else {
        mediaData = desktopData.ref.mediaRef;
      }
      mobileData.ref.mediaRef = _.omit(mediaData, 'id');
      //createNewIdIfHomePage(editorAPI, mobileData, pageId);
    } else {
      mobileData.ref.mediaRef = null;
    }

    editorAPI.pages.background.update(pageId, mobileData, 'mobile');
  };

/**
 * @param device
 * @param currentData
 * @param pageId
 */
const updatePageBackground =
  (device, currentData, pageId) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const newData = _.cloneDeep(currentData);
    if (device === 'mobile') {
      //force mobile split when updating mobile background data
      const firstChange = newData.isPreset;
      newData.isPreset = false;
      patchMobileScrollType(newData);
      dispatch(fixDuplicationOfMobileData(newData));
      if (firstChange) {
        dispatch(
          stateManagement.notifications.actions.showUserActionNotification({
            message: 'BG_Panel_Mobile_Background_Change_Info_Text',
            title: 'BG_Panel_Mobile_Background_Change_Info_Text',
            type: 'info',
          }),
        );
      }
    } else {
      // If desktop, and mobile.isPreset, change mobile data while changing desktop data.
      const mobileData = dsRead.pages.background.get(pageId, 'mobile');

      if (!mobileData.ref) {
        mobileData.ref = {};
      }

      if (mobileData?.isPreset) {
        dispatch(fixDuplicationOfMobileData(mobileData));
        dispatch(updateMobileData(newData, mobileData, pageId));
      }
    }
    editorAPI.pages.background.update(pageId, newData, device);

    const pageRef = editorAPI.dsRead.pages.getReference(pageId);
    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.pageBackgroundChanged({ pageRef }),
    );
  };

export { updatePageBackground };
