import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';

export function startUpdatePositionInTransition(
  { editorAPI }: { editorAPI: EditorAPI },
  compRefs: CompRef[],
  compRectRelativeToScreenInitialByCompId: Map<string, Rect>,
) {
  const { dsActions } = editorAPI;
  let isDetached = false;
  let deltaXLatest = 0;
  let deltaYLatest = 0;

  function ensureDetached() {
    if (!isDetached) {
      dsActions.components.layout.runtime.detachComponents(compRefs);
      isDetached = true;
    }
  }

  function update({ deltaX, deltaY }: { deltaX: number; deltaY: number }) {
    if (deltaX === deltaXLatest && deltaY === deltaYLatest) {
      return;
    }

    ensureDetached();

    const scrollPosition = editorAPI.scroll.get();
    compRefs.forEach((compRef) => {
      const compRectInitial = compRectRelativeToScreenInitialByCompId.get(
        compRef.id,
      );

      const left = compRectInitial.x + deltaX;
      const top = compRectInitial.y + deltaY;

      dsActions.components.layout.runtime.updateDetachedLayout(compRef, {
        absoluteLeft: left - scrollPosition.scrollLeft,
        absoluteTop: top - scrollPosition.scrollTop,
        left,
        top,
      });
    });

    deltaXLatest = deltaX;
    deltaYLatest = deltaY;
  }

  function get() {
    return { deltaX: deltaXLatest, deltaY: deltaYLatest };
  }

  function end() {
    if (isDetached) {
      editorAPI.dsActions.components.layout.runtime.reattachComponents();
    }

    isDetached = false;
  }

  return {
    update,
    get,
    end,
  };
}
