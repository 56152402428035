import _ from 'lodash';
import * as gridLinesActionTypes from './gridLinesActionTypes';
import constants from '#packages/constants';
import type { GridLinesType } from '#packages/constants';
import type { Reducer } from 'types/redux';
import type { GridLine } from 'types/core';

const { SET_GRID_LINES_OVERRIDES } = gridLinesActionTypes;

export interface GridLinesState {
  overrides: {
    [type in GridLinesType]: GridLine[];
  };
}

export const gridLinesInitialState: GridLinesState = {
  overrides: {
    [constants.GRID_LINES_TYPES.PAGE]: null,
  },
};

const reducer: Reducer<GridLinesState> = (
  gridLines = gridLinesInitialState,
  action,
) => {
  switch (action.type) {
    case SET_GRID_LINES_OVERRIDES:
      return _.defaultsDeep(
        {
          overrides: { [action.gridLinesType]: action.overrides },
        },
        gridLines,
      );

    default:
      return gridLines;
  }
};

export default reducer;
