import _ from 'lodash';
import experiment from 'experiment';
import {
  isRefComponent,
  getRefComponentRootChild,
} from '#packages/documentServices';
import type * as DS from '@wix/document-services-types';
import * as componentsSelectors from '../components/componentsSelectors';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { isReferredComponent } = componentsSelectors;

const getPlatformConnectionShowFocusBox = (
  dsRead: AnyFixMe,
  connection: AnyFixMe,
) => {
  const { controllerRef, role } = connection;
  const stageDataByRole = dsRead.platform.controllers.getStageDataByRole(
    controllerRef,
    role,
  );
  return stageDataByRole?.showFocusBox;
};

const isPlatformShowFocusBox = (compRef: AnyFixMe, dsRead: AnyFixMe) => {
  if (experiment.isOpen('se_FocusBoxForTPAWithViewStates')) {
    const isTpa = dsRead.tpa.isTpaByCompType(
      dsRead.components.getType(compRef),
    );
    if (isTpa && dsRead.platform.controllers.getStageData(compRef)?.viewStates)
      return true;
  }

  const connections = dsRead.platform.controllers.connections.get(compRef);
  if (!_.isEmpty(connections)) {
    return connections
      .map((connection: DS.Connection) =>
        getPlatformConnectionShowFocusBox(dsRead, connection),
      )
      .find(_.isBoolean);
  }
};

const getConnectedComponentClosedBehavior = (
  compRef: CompRef,
  editorAPI: EditorAPI,
) => {
  const connectedComponent = isRefComponent(editorAPI.dsRead, compRef)
    ? getRefComponentRootChild(editorAPI.dsRead, compRef)
    : compRef;
  const data =
    editorAPI.dsRead.platform.controllers.getConnectedComponentStageData(
      connectedComponent,
    ) as Record<string, any>;
  const parentRef = editorAPI.components.findAncestor(
    compRef,
    (ancestor) =>
      editorAPI.components.getType(ancestor) ===
      'platform.components.AppWidget',
  );
  const compData = editorAPI.components.data.get(parentRef);
  const appDefId = compData?.applicationId || compData?.appDefinitionId;
  const manifestLoaded = editorAPI.dsRead.platform.hasAppManifest(appDefId);

  return appDefId &&
    !manifestLoaded &&
    data === null &&
    !isRefComponent(editorAPI.dsRead, compRef)
    ? { selectable: false }
    : data?.behavior?.closed;
};

const isSelectableDueToManifest = (compRef: CompRef, editorAPI: EditorAPI) => {
  const selectableDueToManifest = getConnectedComponentClosedBehavior(
    compRef,
    editorAPI,
  )?.selectable;
  return _.isBoolean(selectableDueToManifest) ? selectableDueToManifest : true;
};

const isHiddenFromHierarchyDueToManifest = (
  compRef: CompRef,
  editorAPI: EditorAPI,
) => {
  const hiddenDueToManifest = getConnectedComponentClosedBehavior(
    compRef,
    editorAPI,
  )?.hideFromHierarchy;
  return _.isBoolean(hiddenDueToManifest) ? hiddenDueToManifest : false;
};

const getComponentOverridesByType = (store: AnyFixMe, type: AnyFixMe) =>
  store.componentMetaData?.overrides?.[type];

const isReferredComponentHidden = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean => {
  return (
    isReferredComponent(compRef) &&
    isHiddenFromHierarchyDueToManifest(compRef, editorAPI)
  );
};

export {
  isPlatformShowFocusBox,
  isSelectableDueToManifest,
  isReferredComponentHidden,
  isHiddenFromHierarchyDueToManifest,
  getConnectedComponentClosedBehavior,
  getComponentOverridesByType,
};
