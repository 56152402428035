import constants from '#packages/constants';
import imageLayoutRelativeToScreenPlugin from './plugins/image';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';

export interface LayoutRelativeToScreenPluginDef {
  updatedLayout: CompLayout;
  keepProportions: boolean;
}

type LayoutRelativeToScreenPlugin = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  layout: CompLayout,
  keepProportions: boolean,
) => LayoutRelativeToScreenPluginDef;

const plugins: { [compType: string]: LayoutRelativeToScreenPlugin } = {
  [constants.COMP_TYPES.PHOTO]: imageLayoutRelativeToScreenPlugin,
};

export default plugins;
