import constants from '#packages/constants';

enum ERROR_STATUS {
  GATEWAY_TIMEOUT = 504,
  INTERNAL_SERVER_ERROR = 500,
  TOO_MANY_REQUESTS = 429,
  CONTENT_TOO_LARGE = 413,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  UNAITHORIZED = 401,
  BAD_REQUEST = 400,
}

const getMetaSiteInstance = (dsRead: AnyFixMe) =>
  dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  ).instance;

const isMultiselect = (components: AnyFixMe) => components.length > 1;

const createBiComponentType = (components: AnyFixMe) =>
  isMultiselect(components) ? 'multiselect' : components[0]?.componentType;

const createBiComponents = (components: AnyFixMe) =>
  isMultiselect(components)
    ? components.map((item: AnyFixMe) => item.componentType).join(',')
    : components[0].componentType;

const createBiErrorMassage = (e: AnyFixMe) => {
  if (e instanceof Error) {
    return e.toString();
  }

  if (typeof e === 'string') {
    return e;
  }

  let errorMessage = '';

  if (e.status) {
    errorMessage += e.status;
  }

  if (e.statusText) {
    errorMessage += ` ${e.statusText}`;
  }

  return errorMessage;
};

const createNotificationErrorMassage = (error: AnyFixMe) => {
  let message = '';
  let helpId = '';

  switch (error?.status) {
    case ERROR_STATUS.BAD_REQUEST:
      message = 'Notifications_MyDesigns_SaveError_404_Text';
      helpId = '5f23538c-d00c-4e8a-862c-6b86b6692c85';
      break;
    case ERROR_STATUS.UNAITHORIZED:
      message = 'Notifications_MyDesigns_SaveError_401_Text';
      helpId = 'c80bedbb-41b0-483d-9912-401775f60ff6';
      break;
    case ERROR_STATUS.FORBIDDEN:
      message = 'Notifications_MyDesigns_SaveError_403_Text';
      helpId = '88dae806-c442-4d7c-8a81-d9034051604c';
      break;
    case ERROR_STATUS.NOT_FOUND:
      message = 'Notifications_MyDesigns_SaveError_Not_Found_Text';
      helpId = 'dc7b614c-677e-4001-990e-71a69465c4f8';
      break;
    case ERROR_STATUS.CONTENT_TOO_LARGE:
      message = 'Notifications_MyDesigns_SaveError_Too_Big_Text';
      break;
    case ERROR_STATUS.TOO_MANY_REQUESTS:
      message = 'Notifications_MyDesigns_SaveError_429_Text';
      helpId = '385cdec4-5940-4e84-9ce0-94a7dc56965f';
      break;
    case ERROR_STATUS.INTERNAL_SERVER_ERROR:
      message = 'Notifications_MyDesigns_SaveError_500_Text';
      helpId = '0a28fd61-98a3-4728-bb84-9effc6819766';
      break;
    case ERROR_STATUS.GATEWAY_TIMEOUT:
      message = 'Notifications_MyDesigns_SaveError_504_Text';
      helpId = 'bfadf1d8-822b-4e03-bec9-079619f743ee';
      break;
    default:
      message = 'Notifications_MyDesigns_SaveError_Fail_Fetch_Text';
  }

  return {
    message,
    helpId,
  };
};

const mergeCollections = (prevItems: AnyFixMe[], nextItems: AnyFixMe[]) => {
  const prevItemsValues = prevItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item,
    }),
    {},
  );

  return nextItems.map((item: AnyFixMe) => {
    const prevItem = prevItemsValues[item.id];

    return prevItem ? { ...prevItem, ...item } : item;
  });
};

export {
  createBiComponentType,
  createBiComponents,
  getMetaSiteInstance,
  mergeCollections,
  createBiErrorMassage,
  createNotificationErrorMassage,
};
