// @ts-nocheck
import _ from 'lodash';
import * as actionTypes from './dynamicPagesActionTypes';
import * as selectors from './dynamicPagesSelectors';
import * as util from '#packages/util';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type { SitemapEntry } from 'types/documentServices';
import constants from '#packages/constants';

const setInnerRoutes = (innerRoutes) => ({
  type: actionTypes.SET_INNER_ROUTES,
  innerRoutes,
});
const setPreventFetch = (shouldPrevent) => ({
  type: actionTypes.SET_PREVENT_FETCH,
  shouldPrevent,
});
const resetInnerRoutes = () => ({ type: actionTypes.RESET_INNER_ROUTES });
const setRouterData = (routerData) => ({
  type: actionTypes.SET_ROUTER_DATA,
  routerData,
});
const resetRouterData = () => ({ type: actionTypes.RESET_ROUTER_DATA });
const setSelectedInnerRoute = (selectedInnerRoute) => ({
  type: actionTypes.SET_SELECTED_INNER_ROUTE,
  selectedInnerRoute,
});
const setCurrentRouteInfo = (currentRouteInfo) => ({
  type: actionTypes.SET_CURRENT_ROUTE_INFO,
  currentRouteInfo,
});
const resetSelectedInnerRoute = () => ({
  type: actionTypes.RESET_SELECTED_INNER_ROUTE,
});
const setInnerRouteFetchProcess = () => ({
  type: actionTypes.SET_INNER_ROUTES_FETCH_PROCESS,
});
const resetInnerRouteFetchProcess = () => ({
  type: actionTypes.RESET_INNER_ROUTES_FETCH_PROCESS,
});
const setInnerRoutesCounts = ({ innerRoutesCounts }) => ({
  type: actionTypes.SET_INNER_ROUTES_COUNT,
  innerRoutesCounts,
});
const clearInnerRoutesCounts = () => ({
  type: actionTypes.CLEAR_INNER_ROUTES_COUNT,
});
const setCachedInnerRoutes = (routerData, pageId, innerRoutes) => ({
  type: actionTypes.SET_CACHED_INNER_ROUTES,
  routerData,
  pageId,
  innerRoutes,
});

const getNavigationData = (newRoute, routerId, dsRead) => {
  const linkItem = dsRead.data.createItem('DynamicPageLink');
  return Object.assign(linkItem, { innerRoute: newRoute || '', routerId });
};

const findInnerRouteIndex = (innerRoute, innerRoutes) => {
  const index = innerRoutes.findIndex((route) =>
    isEqualIgnoreCase(innerRoute, route.url),
  );
  if (index === -1) {
    return null;
  }
  return index;
};

const isEqualIgnoreCase = (str1, str2) =>
  str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0;

const navigateToRoute =
  (newRoute, routerId, dynamicPageIdOverride?) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const navData = getNavigationData(newRoute, routerId, dsRead);

    const currentInnerRoute = dsRead.routers.getCurrentInnerRoute();
    const isAlreadyOnThatRoute =
      currentInnerRoute?.routerId === navData.routerId &&
      (navData.innerRoute === currentInnerRoute.innerRoute ||
        (navData.innerRoute === '/' && currentInnerRoute.innerRoute === ''));

    if (!isAlreadyOnThatRoute) {
      dsActions.pages.navigateTo({ ...navData, dynamicPageIdOverride });
    }
  };

const resetAllDynamicPageData = () => (dispatch) => {
  dispatch(resetInnerRoutes());
  dispatch(resetRouterData());
  dispatch(resetSelectedInnerRoute());
};

const isCollectionPage = (innerRouters) =>
  innerRouters.length === 1 && innerRouters[0].url === '/';

const routerDataChanged = (getState, dsRead) => {
  const pageId = dsRead.pages.getPrimaryPageId();
  const oldRouterData = selectors.getRouterData(getState());
  const newRouterData = dsRead.routers.getRouterDataForPageIfExist(pageId);

  return !_.isEqual(oldRouterData, newRouterData);
};

const updateDynamicPagesState =
  () =>
  (dispatch, getState, { editorAPI, dsRead }) => {
    const oldCurrentRouteInfo = selectors.getCurrentRouteInfo(getState());
    const oldInnerRoutes = selectors.getInnerRoutes(getState());

    const currentRouteInfo = dsRead.routers.getCurrentInnerRoute();
    if (!_.isEqual(oldCurrentRouteInfo, currentRouteInfo)) {
      dispatch(setCurrentRouteInfo(currentRouteInfo));
    }

    if (!currentRouteInfo.isDynamic) {
      dispatch(resetAllDynamicPageData());
      return;
    }

    const oldRouterId = selectors.getRouterId(getState());
    const alreadyInFetchProcess =
      selectors.isInInnerRoutesFetchProcess(getState());
    const routerHasChanged = currentRouteInfo.routerId !== oldRouterId;
    const innerRouteHasChanged =
      oldCurrentRouteInfo?.innerRoute &&
      currentRouteInfo.innerRoute !== oldCurrentRouteInfo.innerRoute;
    if (!routerHasChanged && innerRouteHasChanged) {
      editorAPI.scroll.registerNewScrollPosition(editorAPI.scroll.get());
    }
    const isFetchEnabled = !selectors.shouldPreventFetch(getState());
    const wasOnCollectionPage = oldInnerRoutes
      ? isCollectionPage(oldInnerRoutes)
      : false;
    const navigatedFromCollectionToItemPage =
      !routerHasChanged &&
      wasOnCollectionPage &&
      currentRouteInfo.innerRoute !== '';
    if (
      isFetchEnabled &&
      ((!alreadyInFetchProcess && routerHasChanged) ||
        currentRouteInfo.innerRoute === undefined ||
        navigatedFromCollectionToItemPage ||
        routerDataChanged(getState, dsRead))
    ) {
      return dispatch(fetchInnerRoutes());
    }

    const oldSelectedInnerRoute = selectors.getSelectedInnerRoute(getState());
    if (currentRouteInfo.innerRoute !== oldSelectedInnerRoute) {
      dispatch(setSelectedInnerRoute(currentRouteInfo.innerRoute));
    }
  };

const setSelectedInnerRouteAndNavigate =
  (newInnerRoute, routerId, dynamicPageIdOverride?) => (dispatch, getState) => {
    routerId = routerId ? routerId : selectors.getRouterId(getState());
    dispatch(navigateToRoute(newInnerRoute, routerId, dynamicPageIdOverride));
    dispatch(setSelectedInnerRoute(newInnerRoute));
  };

const preFetchAndCacheInnerRoutes =
  (routersData?) =>
  (dispatch, getState, { editorAPI, dsRead }) => {
    routersData = routersData || editorAPI.routers.get.all();
    const requests = [];
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.PRE_FETCH_ROUTERS_INNER_ROUTES,
    );
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    Object.entries(routersData || {}).forEach(([routerId, routerData]) => {
      Object.values(routerData.pages || {})
        .filter((pageId) => {
          const pageRef = editorAPI.pages.getReference(pageId);
          return !platformAPI.pageReplace.isReplacer(pageRef);
        })
        .forEach((pageId) => {
          requests.push(
            new Promise((resolve) => {
              dsRead.routers.getRouterInnerRoutes(
                routerId,
                pageId,
                (innerRoutes) => {
                  if (innerRoutes) {
                    dispatch(
                      setCachedInnerRoutes(
                        { ...routerData, routerId },
                        pageId,
                        innerRoutes,
                      ),
                    );
                  } else {
                    // fetching failed, ignore it at prefetch phase and handle it on page routes request
                  }
                  resolve();
                },
              );
            }),
          );
        });
    });

    return Promise.all(requests).then(() => {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.PRE_FETCH_ROUTERS_INNER_ROUTES,
      );
    });
  };

const getInnerRoutesFromCacheOrFetch = (
  { dsRead, dispatch, getState },
  routerData,
  pageId,
  forceFetch,
) => {
  const cachedRoutes = selectors.getCachedInnerRoutes(
    getState(),
    routerData.routerId,
    pageId,
  );
  if (cachedRoutes && !forceFetch) {
    return Promise.resolve(cachedRoutes);
  }

  dispatch(setInnerRouteFetchProcess());
  return new Promise((resolve) => {
    dsRead.routers.getRouterInnerRoutes(
      routerData.routerId,
      pageId,
      (fetchedRoutes) => {
        fetchedRoutes = fetchedRoutes || [];
        dispatch(resetInnerRouteFetchProcess());
        dispatch(setCachedInnerRoutes(routerData, pageId, fetchedRoutes));
        resolve(fetchedRoutes);
      },
    );
  });
};

const fetchInnerRoutes =
  (primaryPageId?, routerData?, selectedRoute?, forceFetch?) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    let dynamicPageIdOverride;
    let initPrimaryPageId = primaryPageId || dsRead.pages.getPrimaryPageId();
    const initRouterData =
      routerData ||
      dsRead.routers.getRouterDataForPageIfExist(initPrimaryPageId);
    if (!initRouterData) {
      dispatch(resetAllDynamicPageData());
      return;
    }

    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    const pageRef = editorAPI.pages.getReference(initPrimaryPageId);
    const isReplacerPage = platformAPI.pageReplace.isReplacer(pageRef);
    const { variants, replacers } =
      platformAPI.pageReplace.getPageVariations(pageRef);
    if (
      variants.length ||
      replacers.length ||
      platformAPI.pageReplace.getOriginalPageRef(pageRef)
    ) {
      dynamicPageIdOverride = initPrimaryPageId;
    }

    if (isReplacerPage) {
      const originalPageRef =
        platformAPI.pageReplace.getOriginalPageRef(pageRef);
      initPrimaryPageId = originalPageRef.id;
    }

    return getInnerRoutesFromCacheOrFetch(
      { dsRead, dispatch, getState },
      initRouterData,
      initPrimaryPageId,
      forceFetch,
    ).then((innerRoutes: SitemapEntry[]) => {
      const appManifest = dsRead.platform.getAppManifest(
        initRouterData.appDefinitionId,
      );
      const sortByKey =
        appManifest?.pages?.sortInnerRoutes ||
        constants.DYNAMIC_PAGES.SORT_INNER_ROUTES.TITLE;
      if (sortByKey != constants.DYNAMIC_PAGES.SORT_INNER_ROUTES.NO_SORT) {
        innerRoutes = _.sortBy(innerRoutes, [sortByKey]);
      }
      const newPrimaryPageId = dsRead.pages.getPrimaryPageId();
      const navigationWasDone = newPrimaryPageId !== initPrimaryPageId;

      const newRouterData =
        dsRead.routers.getRouterDataForPageIfExist(newPrimaryPageId);
      if (
        !isReplacerPage &&
        (navigationWasDone || !_.isEqual(initRouterData, newRouterData))
      ) {
        dispatch(resetAllDynamicPageData());
      } else {
        dispatch(
          setFetchedRouteState(
            innerRoutes,
            newRouterData,
            selectedRoute,
            dynamicPageIdOverride,
          ),
        );
      }
      return innerRoutes;
    });
  };

const setFetchedRouteState =
  (
    innerRoutes: SitemapEntry[],
    routerData,
    selectedRoute,
    dynamicPageIdOverride: string | undefined,
  ) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    //category (collection) page case
    if (isCollectionPage(innerRoutes)) {
      dispatch(setInnerRoutes(innerRoutes));
      dispatch(setRouterData(routerData));
      dispatch(setSelectedInnerRoute(null));
      dispatch(navigateToRoute(innerRoutes[0].url, routerData.routerId));
      return;
    }

    const currentRouteInfo = dsRead.routers.getCurrentInnerRoute();
    const currInnerRoute = currentRouteInfo?.innerRoute;
    const selectedRouteIndex = selectedRoute
      ? findInnerRouteIndex(selectedRoute, innerRoutes, false)
      : null;
    const currentRoute = currInnerRoute
      ? findInnerRouteIndex(currInnerRoute, innerRoutes) || 0
      : 0;
    const index = _.isNumber(selectedRouteIndex)
      ? selectedRouteIndex
      : currentRoute;
    const newCurrentInnerRoute = innerRoutes[index]
      ? innerRoutes[index].url
      : null;

    dispatch(setInnerRoutes(innerRoutes));
    dispatch(setRouterData(routerData));
    if (
      currInnerRoute &&
      isEqualIgnoreCase(currInnerRoute, newCurrentInnerRoute)
    ) {
      dispatch(setSelectedInnerRoute(newCurrentInnerRoute));
    } else if (newCurrentInnerRoute) {
      dispatch(
        setSelectedInnerRouteAndNavigate(
          newCurrentInnerRoute,
          routerData.routerId,
          dynamicPageIdOverride,
        ),
      );
    } else if (!innerRoutes.length && currInnerRoute) {
      const pageId = dsRead.pages.getPrimaryPageId();
      dispatch(setSelectedInnerRoute(undefined));
      dsActions.pages.navigateTo(pageId);
    } else {
      dispatch(setSelectedInnerRoute(currInnerRoute));
    }
  };

const fetchInnerRoutesCounts =
  (pageIds: string[]) =>
  (dispatch, getState, { dsRead }) => {
    const routerIds = pageIds
      .map(dsRead.routers.getRouterDataForPageIfExist)
      .filter(Boolean)
      .map(({ routerId }) => routerId);

    const requests = _(routerIds)
      .uniq()
      .map(
        (routerId) =>
          new Promise((resolve) => {
            dsRead.routers.getRouterInnerRoutesCount(
              routerId,
              undefined,
              resolve,
            );
          }),
      )
      .value();

    Promise.all(requests).then((responses) => {
      const countByPage = _(responses)
        .flatten()
        .filter((r) => r?.pageName)
        .reduce((response, partialResponse) => {
          return {
            ...response,
            [partialResponse.pageName]: partialResponse.count,
          };
        }, {});

      dispatch(setInnerRoutesCounts({ innerRoutesCounts: countByPage }));
    });
  };

export {
  setCurrentRouteInfo,
  setInnerRoutes,
  resetInnerRoutes,
  setRouterData,
  setPreventFetch,
  resetRouterData,
  setSelectedInnerRoute,
  resetSelectedInnerRoute,
  setInnerRouteFetchProcess,
  resetInnerRouteFetchProcess,
  fetchInnerRoutes,
  resetAllDynamicPageData,
  updateDynamicPagesState,
  setSelectedInnerRouteAndNavigate,
  setInnerRoutesCounts,
  fetchInnerRoutesCounts,
  clearInnerRoutesCounts,
  preFetchAndCacheInnerRoutes,
};
