// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as util from '#packages/util';
import coreUtilsLib from 'coreUtilsLib';
import * as selectors from './mobileWizardSelectors';
import * as siteSegments from '../../siteSegments/siteSegments';
import * as panels from '../../panels/panels';
import * as coreBi from '#packages/coreBi';
import * as bi from '../../bi/bi';
import * as actionTypes from './mobileWizardActionTypes';

const panelsActions = panels.actions;

const setIsMobileWizardEnabled = (enabled) => ({
  type: actionTypes.SET_MOBILE_WIZARD_IS_ENABLED,
  enabled,
});
const setMobileWizardOpeningState = (openingState) => ({
  type: actionTypes.SET_MOBILE_WIZARD_OPENING_STATE,
  openingState,
});

const setTinyMenuAvailableSkins = (availableSkins) => ({
  type: actionTypes.SET_TINY_MENU_AVAILABLE_SKINS,
  availableSkins,
});
const setHeaderAnimationProgress = (animationInProgress) => ({
  type: actionTypes.SET_HEADER_ANIMATION_PROGRESS,
  animationInProgress,
});

const setCurrentStep = (currentStep) => ({
  type: actionTypes.SET_CURRENT_STEP,
  currentStep,
});
const setStepsToShow = (stepsToShow) => ({
  type: actionTypes.SET_STEPS_TO_SHOW,
  stepsToShow,
});
const setUserSelections = (stepName, stepValue) => ({
  type: actionTypes.SET_USER_SELECTIONS,
  stepName,
  stepValue,
});
const setLastSaved = (stepName, value) => ({
  type: actionTypes.SET_LAST_SAVED,
  stepName,
  value,
});

const setAvailableQabPresets = (availablePresets) => {
  availablePresets.push({ id: 'NONE', structure: {} });

  return {
    type: actionTypes.SET_QAB_AVAILABLE_PRESETS,
    availablePresets,
  };
};

const { headerScrollTypes } = util;

const MOBILE = 'MOBILE';
const CSS_ANIMATION_DURATION = 1700;
const { OPENING_STATE } = constants.MOBILE.MOBILE_WIZARD;

const TINY_MENU_ID = 'TINY_MENU';
const TINY_MENU_POINTER = { id: TINY_MENU_ID, type: MOBILE };
const TINY_MENU_COMP_TYPE = 'wysiwyg.viewer.components.mobile.TinyMenu';
const OLD_TINY_MENU_SKINS_TO_DEPRECATE = [
  'wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin',
  'wysiwyg.viewer.skins.mobile.TinyMenuSkin',
];
const TINY_MENU_DEPRECATION_ALTERNATIVE_SKIN =
  'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin';
const TINY_MENU_OPEN_PREVIEW_STATE = 'open';

const HEADER_ID = 'SITE_HEADER';
const HEADER_POINTER = { id: HEADER_ID, type: MOBILE };
const FAKE_SCROLL_DURATION = 1.5; // seconds
let currentAnimationSequenceId;

const QAB_POINTER = { id: 'QUICK_ACTION_BAR', type: MOBILE };
const QAB_COMP_TYPE = 'wysiwyg.viewer.components.QuickActionBar';
const QAB_OPEN_PREVIEW_STATE = 'open';
const QAB_PRESETS_IDS_TO_USE_IN_THE_WIZARD = [
  'QuickActionBar_Preset6',
  'QuickActionBar_Preset4',
];
const QAB_DEFAULT_VALUE_TO_SELECT = 'QuickActionBar_Preset4';
const QAB_PRESETS_TO_BE_OPEN = ['QuickActionBar_Preset4'];

const BACK_TP_TOP_BUTTON_TYPE = 'wysiwyg.viewer.components.BackToTopButton';
const BACK_TO_TOP_BUTTON_POINTER = { id: 'BACK_TO_TOP_BUTTON', type: MOBILE };
const BACK_TO_TOP_BUTTON_VERTICAL_MARGIN_WITH_QAB = 87;
const BACK_TO_TOP_BUTTON_DEFAULT_VERTICAL_MARGIN = 20;

const getTinyMenuAvailableSkins =
  () =>
  (dispatch, getState, { dsRead }) => {
    let availableSkins = selectors.tinyMenu.getAvailableSkins(getState());
    if (!availableSkins.length) {
      availableSkins =
        dsRead.theme.skins.getComponentSkins(TINY_MENU_COMP_TYPE);
    }
    availableSkins = _.difference(
      availableSkins,
      OLD_TINY_MENU_SKINS_TO_DEPRECATE,
    );
    dispatch(setTinyMenuAvailableSkins(availableSkins));
  };

const getTinyMenuLastSavedSkin =
  () =>
  (dispatch, getState, { dsRead }) => {
    const selectedTinyMenuSkin = dsRead.components.skin.get(TINY_MENU_POINTER);
    dispatch(setLastSaved('tinyMenu', selectedTinyMenuSkin));
  };

const selectDefaultQabForTheFirstTime = () => (dispatch, getState) => {
  const state = getState();

  const availablePresets = selectors.qab.getAvailablePresets(state);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const defaultPresetToSelect = _.find(availablePresets, {
    id: QAB_DEFAULT_VALUE_TO_SELECT,
  });

  if (defaultPresetToSelect) {
    dispatch(setSelectedQabPreset(defaultPresetToSelect, false));
    dispatch(setLastSaved('qab', defaultPresetToSelect.id));
  }
};

const relayoutBackToTopButton = (editorAPI) => {
  const backToTopButton = editorAPI.components.get.byType(
    BACK_TP_TOP_BUTTON_TYPE,
  )[0];
  if (backToTopButton) {
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    const bttDock = editorAPI.components.layout.getDock(backToTopButton);
    const qabEnabled = editorAPI.mobile.qab.isEnabled();
    const expectedVerticalMargin = qabEnabled
      ? BACK_TO_TOP_BUTTON_VERTICAL_MARGIN_WITH_QAB
      : BACK_TO_TOP_BUTTON_DEFAULT_VERTICAL_MARGIN;
    if (bttDock?.bottom?.px !== expectedVerticalMargin) {
      // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
      editorAPI.components.layout.updateDock(backToTopButton, {
        bottom: { px: expectedVerticalMargin },
      });
    }
  }
};

const initQabStep =
  () =>
  (dispatch, getState, { editorAPI }) => {
    const state = getState();
    dispatch(getAvailableQabPresets());
    dispatch(getQabSelectedValue());

    const selectedQab = selectors.qab.getSelectedQab(state);

    if (selectedQab) {
      const selectedQabHasPreviewState =
        QAB_PRESETS_TO_BE_OPEN.includes(selectedQab);
      if (selectedQabHasPreviewState) {
        dispatch(setPreviewState(QAB_POINTER.id, QAB_OPEN_PREVIEW_STATE));
      }
    } else {
      dispatch(selectDefaultQabForTheFirstTime());
      relayoutBackToTopButton(editorAPI);
    }
  };

const getAvailableQabPresets =
  () =>
  (dispatch, _getState, { editorAPI }) => {
    const allQabPresets =
      editorAPI.addPanel.getSectionsByComponentType(QAB_COMP_TYPE)?.[0]?.props
        ?.items ?? [];

    const filteredQabPresets = allQabPresets.filter((preset) =>
      QAB_PRESETS_IDS_TO_USE_IN_THE_WIZARD.includes(preset?.id),
    );

    dispatch(setAvailableQabPresets(filteredQabPresets));
  };

const getQabSelectedValue =
  () =>
  (dispatch, getState, { editorAPI }) => {
    if (!editorAPI.mobile.qab.isEnabled()) {
      dispatch(setLastSaved('qab', 'NONE'));
    }
  };

const setSelectedQabPreset =
  (preset, selectionMadeByUser = true) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const state = getState();
    dispatch(setPreviewState(QAB_POINTER.id, undefined));

    if (selectionMadeByUser) {
      dispatch(setUserSelections('qab', preset.id));
      dispatch(sendSelectedValueBiEvent(QAB_POINTER.id, preset.id));
    }

    if (preset.id === 'NONE') {
      editorAPI.mobile.actionBar.version.revert();
      editorAPI.waitForChangesApplied(() => {
        relayoutBackToTopButton(editorAPI);
      });
      return;
    }

    if (!editorAPI.mobile.qab.isEnabled()) {
      editorAPI.mobile.actionBar.version.upgrade();
    }

    editorAPI.waitForChangesApplied(() => {
      const availablePresets = selectors.qab.getAvailablePresets(state);
      const selectedPreset = availablePresets.find(
        ({ id }) => id === preset.id,
      );
      const compDef = _.cloneDeep(selectedPreset.structure);
      const layoutToMaintain = _.pick(
        dsRead.components.layout.get(QAB_POINTER),
        ['x', 'y', 'width', 'height'],
      );

      editorAPI.components.layout.update(QAB_POINTER, layoutToMaintain, true);
      editorAPI.components.style.update(QAB_POINTER, compDef.style);
      editorAPI.components.properties.update(QAB_POINTER, compDef.props);

      if (QAB_PRESETS_TO_BE_OPEN.includes(preset.id)) {
        dispatch(setPreviewState(QAB_POINTER.id, 'open'));
      }
      relayoutBackToTopButton(editorAPI);

      dispatch(sendAddQABBiEvent);
    });
  };

const initHeaderStep = () => (dispatch) => {
  dispatch(getLastSavedHeaderType());
};

const clearHeaderStep =
  () =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    dsActions.site.animateScroll(0, 0, 0);
    if (currentAnimationSequenceId) {
      editorAPI.components.behaviors.stopPreviewAnimation(
        [currentAnimationSequenceId],
        0,
      );
    }
  };

const getLastSavedHeaderType =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const fixedPosition =
      editorAPI.components.layout.get_fixedPosition(HEADER_POINTER);
    const compBehaviors = dsRead.components.behaviors.get(HEADER_POINTER) || [];

    const headerType =
      headerScrollTypes.getSelectedHeaderType(
        fixedPosition,
        compBehaviors,
        'MOBILE',
      ) || headerScrollTypes.getDefaultHeaderType();
    const scrollType = headerType?.value?.scrollType;
    dispatch(setLastSaved('header', scrollType));

    dispatch(
      scrollToTop(() => {
        const animationName = headerType?.value?.animationName;
        dispatch(animateHeaderScrollAnimation(animationName));
      }),
    );
  };

const _getSelectedHeaderOption = (headerScrollTypesModel, scrollType) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(
    headerScrollTypesModel,
    (option) => option?.value?.scrollType === scrollType,
  );

// TODO: add easing to solve animation sync with scroll animation
const _createFakeAnimation = (animationName, headerHeight) => ({
  action: '',
  duration: FAKE_SCROLL_DURATION,
  name: animationName,
  params: {
    compMeasures: {
      height: headerHeight,
    },
    yoyo: true,
    repeat: 1,
  },
});

const animateHeaderScrollAnimation =
  (animationName) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    const resetHeaderAnimationProgress = () => {
      dispatch(setHeaderAnimationProgress(false));
    };

    if (currentAnimationSequenceId) {
      editorAPI.components.behaviors.stopPreviewAnimation(
        [currentAnimationSequenceId],
        0,
      );
    }

    const headerHeight =
      editorAPI.components.layout.get_size(HEADER_POINTER).height;

    dsActions.site.animateScroll(0, 0, 0);
    if (animationName) {
      const fakeAnimation = _createFakeAnimation(animationName, headerHeight);
      currentAnimationSequenceId =
        editorAPI.components.behaviors.previewAnimation(
          HEADER_POINTER,
          fakeAnimation,
        );
    }

    dsActions.site.animateScroll(
      0,
      headerHeight * 2,
      FAKE_SCROLL_DURATION,
      () => {
        dsActions.site.animateScroll(
          0,
          0,
          FAKE_SCROLL_DURATION,
          resetHeaderAnimationProgress,
        );
      },
    );
  };

const sendSelectedValueBiEvent = (currentStep, value) => (dispatch) => {
  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard.MOBILE_WIZARD_CHOOSE_OPTION,
      {
        current_step: currentStep,
        selected_value: value,
      },
    ),
  );
};

const sendAddQABBiEvent = (dispatch, getState, { editorAPI }) => {
  const quickActionBarRef = editorAPI.mobile.qab.get();
  const componentType = editorAPI.components.getType(quickActionBarRef);

  dispatch(
    bi.actions.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
      origin: 'mobile_wizard_onboarding',
      preset_data_skin:
        quickActionBarRef.skin || quickActionBarRef?.style?.skin || '',
      editor_working_mode: 'mobile',
      component_type: componentType,
      component_id: quickActionBarRef.id,
    }),
  );
};

const setSelectedHeaderType =
  (scrollType) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const state = getState();
    const selectedHeaderType = selectors.header.getSelectedHeaderType(state);
    const isSelectingTheSameOptionTwice = _.isEqual(
      selectedHeaderType,
      scrollType,
    );

    const animationProgress = selectors.header.getAnimationProgress(state);

    if (animationProgress && isSelectingTheSameOptionTwice) {
      return;
    }

    dispatch(setHeaderAnimationProgress(true));

    const selectedHeaderOption = _getSelectedHeaderOption(
      headerScrollTypes.headerScrollTypesModel,
      scrollType,
    );
    const compBehaviors = dsRead.components.behaviors.get(HEADER_POINTER) || [];
    const animationName = selectedHeaderOption?.value?.animationName;

    dispatch(setUserSelections('header', scrollType));
    dispatch(
      siteSegments.actions.setHeaderScrollBehavior(
        selectedHeaderOption.value,
        HEADER_POINTER,
        compBehaviors,
        MOBILE,
      ),
    );
    dispatch(sendSelectedValueBiEvent(HEADER_ID, scrollType));

    // must wait for header layout fixedPosition change, before animating the header
    dsActions.waitForChangesApplied(() => {
      dispatch(animateHeaderScrollAnimation(animationName));
    });
  };

const moveStepBy = (number) => (dispatch, getState) => {
  const state = getState();
  const stepsToShow = selectors.getStepsToShow(state);
  const currentStep = selectors.getCurrentStep(state);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
  const currentIndex = _.indexOf(stepsToShow, currentStep);
  const nextStep =
    stepsToShow[currentIndex + number] || stepsToShow[currentIndex];
  const currentStepSelectedValue = selectors.getCurrentStepSelectedValue(state);
  dispatch(setCurrentStep(nextStep));

  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard.MOBILE_WIZARD_NAVIGATE,
      {
        current_step: currentStep,
        selected_value: currentStepSelectedValue,
        next_or_previous: number > 0 ? 'NEXT' : 'PREVIOUS',
      },
    ),
  );
};

const setNextStep = () => moveStepBy(+1);
const setPreviousStep = () => moveStepBy(-1);

const wixLogoClick = () => (dispatch) => {
  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard
        .MOBILE_WIZARD_TOP_BAR_WIX_LOGO_CLICK,
    ),
  );
};

const setStepFromProgressBar = (nextStep) => (dispatch, getState) => {
  const state = getState();
  const currentStep = selectors.getCurrentStep(state);
  const currentStepSelectedValue = selectors.getCurrentStepSelectedValue(state);

  dispatch(setCurrentStep(nextStep));
  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard
        .MOBILE_WIZARD_PROGRESS_BAR_NAVIGATE,
      {
        current_step: currentStep,
        next_step: nextStep,
        selected_value: currentStepSelectedValue,
      },
    ),
  );
};

const skipWizard = () => (dispatch) => {
  dispatch(sendFinishWizardBiEvent('SKIP'));
  dispatch(disableMobileWizard());
};

const setPreviewState =
  (compId, previewState) =>
  (dispatch, getState, { dsActions }) => {
    dsActions.documentMode.setComponentPreviewState(compId, previewState);
  };

const buildWizardSteps =
  () =>
  (dispatch, getState, { editorAPI, dsActions, dsRead }) => {
    let stepsToShow = [
      'MOBILE_WIZARD_FIRST_STEP',
      'TINY_MENU',
      'SITE_HEADER',
      'QUICK_ACTION_BAR',
      'BACK_TO_TOP_BUTTON',
      'MOBILE_WIZARD_LAST_STEP',
    ];

    if (dsRead.mobile.menuContainer.isExist()) {
      stepsToShow = _.without(stepsToShow, 'TINY_MENU');
    }

    dispatch(setStepsToShow(stepsToShow));
    dispatch(setCurrentStep(stepsToShow[0]));

    dispatch(panelsActions.closeAllPanels());

    editorAPI.panelManager.closeAllPanels();
    editorAPI.selection.deselectComponents();
    dsActions.renderPlugins.allowSiteOverflow(true);
  };

const clearAfterMobileWizardCloses =
  () =>
  (dispatch, getState, { dsActions }) => {
    dsActions.renderPlugins.allowSiteOverflow(false);
  };

const sendFinishWizardBiEvent = (finishMethod) => (dispatch, getState) => {
  const state = getState();
  const currentStep = selectors.getCurrentStep(state);
  const userSelections = selectors.getUserSelections(state);

  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard.MOBILE_WIZARD_FINISH,
      {
        current_step: currentStep,
        tiny_menu_option: userSelections.tinyMenu,
        site_header_option: userSelections.header,
        quick_action_bar_option: userSelections.qab,
        back_to_top_button_option: userSelections.backToTop,
        finish_method: finishMethod,
      },
    ),
  );
};

const finishWizard = () => (dispatch) => {
  dispatch(sendFinishWizardBiEvent('FINISH'));
  dispatch(disableMobileWizard());
};

const sendVideoCloseBiEvent = (_eventParams) => (dispatch) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const eventParams = _.assign(
    {
      origin: 'go_to_mobile_editor',
      panel_name: 'mobile_wizard_mobileEditorFirstTimeVideoPanel',
    },
    _eventParams,
  );
  dispatch(
    bi.actions.event(
      coreBi.events.newUserIntroVideoPanel.INTRO_VIDEO_CLOSE,
      eventParams,
    ),
  );
};

const navigateToNoneLandingPageIfNeeded = (editorAPI, callback) => {
  if (!editorAPI.isCurrentPageLandingPage()) {
    callback();
    return;
  }

  const currentPageId = editorAPI.pages.getCurrentPageId();
  const noneLandingPages = _.reject(
    editorAPI.pages.getPagesData(),
    'isLandingPage',
  );
  const homePageId = editorAPI.homePage.get();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const isHomePageNoneLandingPage = !!_.find(noneLandingPages, {
    id: homePageId,
  });
  const firstNoneLandingPageFound = _.head(noneLandingPages)?.id;

  const pageToNavigateTo = isHomePageNoneLandingPage
    ? homePageId
    : firstNoneLandingPageFound;
  editorAPI.pages.navigateTo(pageToNavigateTo, callback);
  editorAPI.store.dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard
        .MOBILE_WIZARD_NAVIGATE_TO_NONE_LANDING_PAGE,
      {
        current_page_id: currentPageId,
        next_page_id: pageToNavigateTo,
      },
    ),
  );
};

const enableMobileWizard =
  () =>
  (dispatch, getState, { editorAPI }) => {
    const isMobileWizardEnabled =
      selectors.getIsMobileWizardEnabled(getState());
    const isDesktopEditor = editorAPI.isDesktopEditor();
    const isInPreviewMode = editorAPI.preview.isInPreviewMode();

    if (isDesktopEditor || isMobileWizardEnabled || isInPreviewMode) {
      return;
    }

    const showMobileWizard = () => {
      util.keyboardShortcuts.disable();
      dispatch(setIsMobileWizardEnabled(true));

      editorAPI.toolsControl.toggleHideTools(true, false);
      window.setTimeout(() => {
        dispatch(setMobileWizardOpeningState(OPENING_STATE.IN_PROGRESS));
      }, 100); // delay to render mobileWizard before transitioning it in..

      dispatch(
        bi.actions.event(
          coreBi.events.mobileEditor.mobileWizard.MOBILE_WIZARD_START,
        ),
      );
    };

    navigateToNoneLandingPageIfNeeded(editorAPI, showMobileWizard);
  };

const disableMobileWizard =
  () =>
  (dispatch, getState, { editorAPI }) => {
    const isMobileWizardEnabled =
      selectors.getIsMobileWizardEnabled(getState());
    if (!isMobileWizardEnabled) {
      return;
    }
    dispatch(setMobileWizardOpeningState(OPENING_STATE.FINISH));

    // timeouts here are to create smooth clean in/out animations
    window.setTimeout(() => {
      dispatch(setIsMobileWizardEnabled(false));
      editorAPI.toolsControl.toggleHideTools(false, false);
      util.keyboardShortcuts.enable();
    }, CSS_ANIMATION_DURATION);

    window.setTimeout(() => {
      dispatch(setMobileWizardOpeningState(OPENING_STATE.BEFORE));
    }, 2 * CSS_ANIMATION_DURATION);
  };

const scrollToTop =
  (callback = _.noop) =>
  (dispatch, getState, { dsActions, dsRead }) => {
    const targetY = 0;
    const currentY = dsRead.site.getScroll().y;
    const isMobileEditor = true;
    const animationDuration = coreUtilsLib.scrollUtils.calcScrollDuration(
      currentY,
      targetY,
      isMobileEditor,
    );

    dsActions.site.animateScroll(0, targetY, animationDuration, callback);
  };

const toggleBackToTopButton = (isToggleOn, dsActions, editorAPI) => {
  const shouldOverrideDock = isToggleOn && editorAPI.mobile.qab.isEnabled();
  const dockedOverrides = shouldOverrideDock
    ? { bottom: { px: BACK_TO_TOP_BUTTON_VERTICAL_MARGIN_WITH_QAB } }
    : {};
  dsActions.mobile.mobileOnlyComponents.toggleBackToTopButton(
    isToggleOn,
    dockedOverrides,
  );
};

const getBackToTopFromViewer = (editorAPI) =>
  !!editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponentExistOnStructure(
    BACK_TO_TOP_BUTTON_POINTER.id,
  );
const _stringToBoolean = (boolAsString) => boolAsString === 'true';

const initBackToTopStep =
  () =>
  (dispatch, getState, { dsActions, dsRead, editorAPI }) => {
    const state = getState();
    const backToTopAsString =
      selectors.backToTop.getSelectedBackToTop(state) ||
      selectors.getLastSaved(state, 'backToTop');

    let backToTopAsBoolean;

    if (backToTopAsString) {
      backToTopAsBoolean = _stringToBoolean(backToTopAsString);
    } else {
      backToTopAsBoolean = getBackToTopFromViewer(editorAPI);
      dispatch(setLastSaved('backToTop', _.toString(backToTopAsBoolean)));
    }

    const shouldRemoveLegacyBtt =
      backToTopAsBoolean &&
      dsRead.mobile.mobileOnlyComponents.isLegacyBackToTopButton(
        BACK_TO_TOP_BUTTON_POINTER,
      );
    if (shouldRemoveLegacyBtt) {
      dsActions.mobile.mobileOnlyComponents.toggleBackToTopButton(false);
    }
    toggleBackToTopButton(backToTopAsBoolean, dsActions, editorAPI);
  };

const setSelectedBackToTop =
  (bool) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    const boolAsString = _.toString(bool);
    dispatch(setUserSelections('backToTop', boolAsString));

    toggleBackToTopButton(bool, dsActions, editorAPI);
    dispatch(
      sendSelectedValueBiEvent(BACK_TO_TOP_BUTTON_POINTER.id, boolAsString),
    );
  };

const overrideDeprecatedTinyMenuSkinsIfNeeded =
  (changeSkinMethod) =>
  (dispatch, getState, { dsRead }) => {
    const templateTinyMenuSkin = dsRead.components.skin.get(TINY_MENU_POINTER);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isOldTemplateToDeprecate = _.includes(
      OLD_TINY_MENU_SKINS_TO_DEPRECATE,
      templateTinyMenuSkin,
    );

    if (isOldTemplateToDeprecate) {
      dispatch(
        setSelectedTinyMenu(
          changeSkinMethod,
          TINY_MENU_DEPRECATION_ALTERNATIVE_SKIN,
          false,
        ),
      );
      dispatch(
        setLastSaved('tinyMenu', TINY_MENU_DEPRECATION_ALTERNATIVE_SKIN),
      );
    }
  };

const initTinyMenuStep = () => (dispatch, getState) => {
  dispatch(getTinyMenuAvailableSkins());
  dispatch(getTinyMenuLastSavedSkin());

  dispatch(
    scrollToTop(() => {
      if (selectors.getCurrentStep(getState()) === TINY_MENU_ID) {
        dispatch(
          setPreviewState(TINY_MENU_POINTER.id, TINY_MENU_OPEN_PREVIEW_STATE),
        );
      }
    }),
  );
};

const setSelectedTinyMenu =
  (changeSkinMethod, skinName, selectionMadeByUser = true) =>
  (dispatch) => {
    changeSkinMethod(skinName);
    if (selectionMadeByUser) {
      dispatch(setUserSelections('tinyMenu', skinName));
      dispatch(sendSelectedValueBiEvent(TINY_MENU_POINTER.id, skinName));
    }
  };

const firstStepSkipClicked = () => (dispatch) => {
  dispatch(disableMobileWizard());
  dispatch(
    bi.actions.event(
      coreBi.events.mobileEditor.mobileWizard.MOBILE_WIZARD_FIRST_STEP_SKIP,
    ),
  );
};

export {
  enableMobileWizard,
  disableMobileWizard,
  firstStepSkipClicked,
  scrollToTop,
  setCurrentStep,
  setNextStep,
  setPreviousStep,
  skipWizard,
  setPreviewState,
  buildWizardSteps,
  clearAfterMobileWizardCloses,
  setUserSelections,
  finishWizard,
  sendVideoCloseBiEvent,
  setStepFromProgressBar,
  wixLogoClick,
  initTinyMenuStep,
  setSelectedTinyMenu,
  overrideDeprecatedTinyMenuSkinsIfNeeded,
  initHeaderStep,
  clearHeaderStep,
  setSelectedHeaderType,
  initQabStep,
  setSelectedQabPreset,
  setAvailableQabPresets,
  initBackToTopStep,
  setSelectedBackToTop,
};
