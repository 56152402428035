// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { isMeshLayoutEnabled } from '#packages/layout';

import type { BeforePlugin } from 'types/editorPlugins';

function getTpaParentRef(editorAPI, popupRef, path) {
  let result = popupRef;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(path, function (indexInChildren) {
    const children =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(result);
    result = children[indexInChildren];
  });
  return result;
}

function addTpas(editorAPI, addedPopupRef, tpaComps) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(tpaComps, function (tpaDef) {
    const tpaParentRef = getTpaParentRef(
      editorAPI,
      addedPopupRef,
      tpaDef.pathToParent,
    );
    const tpaRef = editorAPI.dsActions.tpa.widget.add(tpaDef.appDefinitionId, {
      widgetId: tpaDef.widgetId,
      pageId: addedPopupRef.id,
      parentContainerRef: tpaParentRef,
      layout: tpaDef.layout,
      style: tpaDef.style,
    });

    _.forOwn(tpaDef.data, function (tpaDataValue, tpaDataKey) {
      editorAPI.dsActions.tpa.data.set(tpaRef, tpaDataKey, tpaDataValue);
    });
  });
}

function finalizePopupAddition(
  editorAPI,
  pageRef,
  focusedPageId,
  onCompAddCallback,
) {
  editorAPI.history.add('adding new popup', {
    undoShouldClosePopup: true,
    actionType: constants.DS_ACTIONS.ADD_PAGE,
    nextPage: pageRef.id,
    currentPage: focusedPageId,
  });

  editorAPI.dsActions.waitForChangesApplied(function () {
    const popupContainerRef = editorAPI.pages.popupPages.getPopupContainer(
      pageRef.id,
    );
    editorAPI.selection.selectComponentByCompRef(popupContainerRef);
    onCompAddCallback(popupContainerRef);
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  if (!compDef.data.isPopup) {
    return null;
  }

  compDef.layout.x = 0;
  compDef.layout.y = 0;
  compDef.data.pageUriSEO = null;

  const pageDef = _.omit(compDef, 'tpaComps');
  const popupPageRef = editorAPI.pages.popupPages.add(
    translate(pageDef.data.title),
    pageDef,
  );

  const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();
  editorAPI.pages.navigateTo(popupPageRef.id);

  if (isMeshLayoutEnabled()) {
    const onChangesApplied = function () {
      if (compDef.tpaComps) {
        addTpas(editorAPI, popupPageRef, compDef.tpaComps);
      }

      finalizePopupAddition(
        editorAPI,
        popupPageRef,
        focusedPageId,
        onCompAddCallback,
      );
    };

    return {
      compRef: popupPageRef,
      onChangesApplied,
    };
  }

  if (compDef.tpaComps) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      addTpas(editorAPI, popupPageRef, compDef.tpaComps);
      finalizePopupAddition(
        editorAPI,
        popupPageRef,
        focusedPageId,
        onCompAddCallback,
      );
    });
  } else {
    finalizePopupAddition(
      editorAPI,
      popupPageRef,
      focusedPageId,
      onCompAddCallback,
    );
  }

  return {
    compRef: popupPageRef,
  };
};

export default plugin;
