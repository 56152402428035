// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import santaCoreUtils from '@wix/santa-core-utils';
import actionTypes from '../platformActionTypes';
import * as panelsActions from '../../panels/panelsActions';
import * as componentsSelectors from '../../components/componentsSelectors';
import type { CompRef } from 'types/documentServices';

const { isReferredComponent } = componentsSelectors;
const { addElementsUtils } = util.platform;
const { remoteStructureFetcher } = santaCoreUtils;

const setMasterStructure = (masterStructure) => ({
  type: actionTypes.SET_MASTER_STRUCTURE,
  masterStructure,
});

const fetchMasterStructure =
  (controllerRef) =>
  (dispatch, getState, { dsRead }) => {
    dispatch(setMasterStructure(null));

    const widgetId =
      dsRead.platform.controllers.settings.get(controllerRef)?.type;
    const { applicationId: appDefinitionId } =
      dsRead.components.data.get(controllerRef);
    const appData = dsRead.platform.getAppDataByAppDefId(appDefinitionId);

    return remoteStructureFetcher
      .getWidgetStructureByAppData(appData, widgetId)
      .then((appWidgetStructure) => {
        dispatch(setMasterStructure(appWidgetStructure));
      });
  };

const addComponent =
  (controllerRef, componentRole, masterStructure) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    addElementsUtils.addComponent(
      dsRead,
      dsActions,
      controllerRef,
      componentRole,
      masterStructure,
    );
  };

const addGhostComponent =
  (controllerRef, compPointer) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    addElementsUtils.addGhostComponent(
      dsRead,
      dsActions,
      controllerRef,
      compPointer,
    );
  };

const addAllComponents =
  (controllerRef, masterStructure) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const componentsRole = addElementsUtils.getAddableComponents(
      dsRead,
      controllerRef,
      masterStructure,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(componentsRole, (role) => {
      addElementsUtils.addComponent(
        dsRead,
        dsActions,
        controllerRef,
        role,
        masterStructure,
      );
    });
  };

const addAllGhostComponents =
  (controllerRef) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const addableComponents = addElementsUtils.getGhostRefComponents(
      dsRead,
      controllerRef,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(addableComponents, (addableComponent) => {
      addElementsUtils.addGhostComponent(
        dsRead,
        dsActions,
        controllerRef,
        addableComponent.compPointer,
      );
    });
  };

const openAddElementsPanel =
  (widgetRef: CompRef) =>
  (dispatch, getState, { editorAPI }: DispatchMapperArgs) => {
    const panelName = isReferredComponent(widgetRef)
      ? 'compPanels.panels.Widget.closeAddElementsPanel'
      : 'compPanels.panels.Widget.openAddElementsPanel';
    editorAPI.store.dispatch(
      panelsActions.openComponentPanel(panelName, {
        widgetRef,
      }),
    );
  };

const resetWidget =
  (controllerRef) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const appDefinitionId =
      dsRead.components.data.get(controllerRef)?.applicationId;
    const appData = dsRead.tpa.app.getDataByAppDefId(appDefinitionId);

    if (appData?.applicationId) {
      dispatch(
        panelsActions.closePanelByName(
          'compPanels.panels.Widget.openAddElementsPanel',
        ),
      );
      dsActions.platform.notifyApplication(appData.applicationId, {
        eventType: constants.EVENTS.ADD_ELEMENTS_PANEL.RESET_WIDGET,
        eventPayload: { controllerRef },
      });
    }
  };

export {
  openAddElementsPanel,
  fetchMasterStructure,
  addComponent,
  addGhostComponent,
  addAllComponents,
  addAllGhostComponents,
  setMasterStructure,
  resetWidget,
};
