import * as util from '#packages/util';
import { HttpClient } from '@wix/http-client';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getFocalPointFromFaces } from '@wix/editor-content-injector';

const getFocalPoint = async (uri: string) => {
  const image = await getImageInfo(uri);
  if (image.file_input && image.file_input.face) {
    return getFocalPointFromFaces(image);
  }
  return { x: 50, y: 50 };
};

const getImageInfo = async (uri: string) => {
  const httpClient = new HttpClient();
  const image = await httpClient
    .get(
      `${util.serviceTopology.mediaUploadServerUrl}/site/media/files/${uri}/info`,
    )
    .then((res) => res.data)
    .catch((error) =>
      ErrorReporter.captureException(error, {
        tags: { package: 'focalPoint' },
      }),
    );
  return image;
};

export default {
  getFocalPoint,
  getImageInfo,
};
