import _ from 'lodash';
import { array } from '#packages/util';
import { areComponentsSiblings } from '#packages/documentServices';
import { createLayoutPatcher } from './realLayout';

import type { EditorAPI } from '#packages/editorAPI';

/**
 * @deprecated
 * Tom Halpern (13.02.2023)
 * > Looks like we still have 14 users excluded. Meaning they are getting Bolt.
 * > Im adding this to my “kill bolt” doc and will update u once the day has come
 * > Should happen by end of Q i hopr
 */
export function createLayoutGetApi_Bolt({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const { setLayoutReadFromDOM, clearLayoutCache, patchLayoutFn } =
    createLayoutPatcher(editorAPI);

  const { asArray } = array;

  const get = patchLayoutFn((compRefOrRefs) => {
    //do not change implementation, see ./realLayout.ts file.
    const compRefs = asArray(compRefOrRefs);
    if (compRefs.length > 1) {
      if (!areComponentsSiblings(editorAPI.dsRead, compRefs)) {
        throw new Error('Cannot get layout for non-siblings components');
      }
      return editorAPI.dsRead.components.layout.getSnugLayout(compRefs);
    }
    const [compRef] = compRefs;
    return editorAPI.dsRead.components.layout.get(compRef);
  });

  const getRelativeToStructure = patchLayoutFn((compRefs) => {
    //do not change implementation, see ./realLayout.ts file.
    const compsArr = asArray(compRefs);
    if (compsArr.length > 1) {
      return editorAPI.dsRead.components.layout.getSnugLayoutRelativeToStructure(
        compsArr,
      );
    }
    return editorAPI.dsRead.components.layout.getRelativeToStructure(
      _.head(compsArr),
    );
  });

  return {
    cache: {
      setLayoutReadFromDOM,
      clearLayoutCache,
    },
    get,
    getRelativeToStructure,
  };
}
