import React from 'react';
import { WorkspaceModesApiKey, EditorRestrictionsApiKey } from '#packages/apis';
import { allowed, type UxResourceName } from './editorRestrictionsApi';
import { TextButton, Text, Tooltip } from '@wix/wix-base-ui';
import { hoc } from '#packages/util';

import type { InferComponentProps } from '#packages/apilib';
import type { Dispatch, StateMapperArgs, ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';

interface WithRestrictionsProps {
  disabled?: boolean;
}

type WithRestrictions = <T>(
  Component: React.ComponentType<T>,
) => React.ForwardRefExoticComponent<
  React.PropsWithoutRef<T & WithRestrictionsProps> & React.RefAttributes<any>
>;

interface WithRestrictionsWrapperOwnProps {
  uxResourceName: UxResourceName;
  withTooltip?: boolean;
  Component: React.ComponentType<any & WithRestrictionsProps>;
}

type WithRestrictionsWrapperPureProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  WithRestrictionsWrapperOwnProps
>;

const HELP_ID_PERMISSIONS = '12bf99d1-3702-4c92-a009-ea67afdad2e3';

const tooltipContent = (
  openHelpCenter: (id: string, biParams?: object) => void,
) => {
  return (
    <div>
      <div>
        <Text
          skin="standard"
          weight="thin"
          size="small"
          enableEllipsis={false}
          children={'content_permission_generic_disabled_tooltip_text'}
        />
      </div>
      <div>
        <TextButton
          skin="standard"
          size="small"
          onClick={() => openHelpCenter(HELP_ID_PERMISSIONS)}
          children={
            'content_permission_generic_disabled_tooltip_learn_more_link'
          }
        />
      </div>
    </div>
  );
};

/**
 * Restrictions wrapper that controls component's visibility and interactivity
 * Important! It is component's responsibility to implement disabled state
 */
const withRestrictionsWrapper = ({
  uxResourceName,
  Component,
  openHelpCenter,
  withTooltip,
  ...props
}: WithRestrictionsWrapperPureProps): JSX.Element => {
  const isVisible = allowed(`${uxResourceName}.visible`);
  if (!isVisible) {
    return null;
  }

  const disabled = !allowed(`${uxResourceName}.interactive`);
  if (disabled) {
    const renderComponent = () => <Component {...props} disabled={true} />;

    if (withTooltip) {
      return (
        <Tooltip content={tooltipContent(openHelpCenter)} interactive={true}>
          {renderComponent()}
        </Tooltip>
      );
    }

    return renderComponent();
  }
  return <Component {...props} />;
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
  };
};

const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  return {
    workspaceMode: editorAPI.host.getAPI(WorkspaceModesApiKey).getMode(),
    restrictionsVersion: editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .getRestrictionsVersion(),
  };
};

const WithRestrictionsConnected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(withRestrictionsWrapper);

export const withRestrictions = (
  uxResourceName: UxResourceName,
  options?: { withTooltip?: boolean },
): WithRestrictions => {
  return <T,>(Component: React.ComponentType<T>) => {
    const withRestrictionsConnected = React.forwardRef<any, T>((props, ref) => {
      return (
        <WithRestrictionsConnected
          {...props}
          withTooltip={options?.withTooltip ?? true}
          uxResourceName={uxResourceName}
          Component={Component}
          ref={ref}
        />
      );
    });
    withRestrictionsConnected.displayName = 'withRestrictionsConnected';
    return withRestrictionsConnected;
  };
};

export const WithRestrictionsRender: React.ComponentType<{
  name: string;
  children: React.FC<WithRestrictionsProps>;
}> = ({ name, children }): JSX.Element => {
  return (
    <WithRestrictionsConnected uxResourceName={name} Component={children} />
  );
};
