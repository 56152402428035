// @ts-nocheck
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import * as util from '#packages/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as bi from '../bi/bi';
import * as mobileSelectors from '../mobile/mobileSelectors';
import * as panels from '../panels/panels';
import { selectors as selectionSelectors } from '../selection/selection';
import * as componentsSelectors from './componentsSelectors';
import {
  createCompPanelApiProviderValue,
  CompPanelApiProvider,
} from './compPanelApiContext';
import type { FrameProps } from '#packages/util';
import type {
  CompLayout,
  CompStructure,
  CompRef,
  Docking,
} from 'types/documentServices';
import type { EditorState } from '../store/editorState';
import type { ThunkAction } from 'types/redux';

const { reportBiEventOnUiChange } = bi.hoc;
const { isMobileEditor, isDesktopEditor } = mobileSelectors;
const { getBehaviors, getProperties, getStyleId, getSkin, getData } =
  componentsSelectors;
const { event, error } = bi.actions;
const { openHelpCenter } = panels.actions;
const parseNewDataToSet = (prevData, key, val) => {
  let newData;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(key)) {
    newData = val;
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  } else if (_.isArray(key) && _.isObject(val)) {
    const newValues = _.pick(val, key);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    newData = _.assign({}, prevData, newValues);
  } else if (_.isObject(key)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    newData = _.assign({}, prevData, key);
  } else {
    newData = _.defaults({ [key]: val }, prevData);
  }

  return newData;
};

const getMultiCompProperties = (
  getter,
  property,
  multiSelectedComponents = [],
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    multiSelectedComponents,
    (acc, compRef) => {
      acc.push({
        compRef,
        [property]: getter(compRef),
      });
      return acc;
    },
    [],
  );
};

export interface CompPanelProps {
  frameProps: FrameProps;
  updateProperties: (
    key: string,
    val: any,
    dontAddToUndoRedoStack?: boolean,
  ) => void;
  updateData: (key: string, val: any, dontAddToUndoRedoStack?: boolean) => void;
  updateDesign: (
    key: string,
    val: any,
    dontAddToUndoRedoStack?: boolean,
  ) => void;
  updateLayout: (
    newLayout: CompLayout,
    dontAddToUndoRedoStack?: boolean,
  ) => void;
  updateStyle: (newStyle: CompStructure['style']) => void;
  updateDock: (newDock: string) => void;
  setDock: (newDock: string) => void;
  updateBehavior: (behavior: any, actionName: string, viewMode: string) => void;
  removeBehavior: (behavior: any, actionName: string, viewMode: string) => void;
  hasLabel: () => boolean;
  translate: (key: string) => string;
  compData: CompStructure['data'];
  compStyle: CompStructure['style'];
  compProperties: CompStructure['props'];
  compLayout: CompStructure['layout'];
  selectedComponents: [CompRef];
}

const parseMultiCompDataToSet = (prevData, key, val) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (key && !_.isUndefined(val)) {
    const path = _.toPath(key);
    const newData = _.cloneDeep(prevData);
    if (_.isObject(val)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(val, (v, k) => {
        _.set(newData, path.concat(k), v);
      });
      return newData;
    }
    return _.set(newData, key, val);
  }
  return parseNewDataToSet(prevData, key, val);
};

const compPanel = (Component) =>
  // eslint-disable-next-line react/prefer-es6-class
  createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'compPanel'),
    propTypes: {
      compData: PropTypes.object,
      compDesign: PropTypes.object,
      compLayout: PropTypes.object.isRequired,
      compProperties: PropTypes.object,
      selectedComponentType: PropTypes.string.isRequired,
      openHelpCenter: PropTypes.func.isRequired,
      updateProperties: PropTypes.func.isRequired,
      updateData: PropTypes.func.isRequired,
      panelName: PropTypes.string.isRequired,
      updateDesign: PropTypes.func.isRequired,
      updateLayout: PropTypes.func.isRequired,
      updateDock: PropTypes.func.isRequired,
      setDock: PropTypes.func.isRequired,
      updateStyle: PropTypes.func.isRequired,
      updateBehavior: PropTypes.func.isRequired,
      removeBehavior: PropTypes.func.isRequired,
      compBehaviors: PropTypes.arrayOf(PropTypes.object),
      replaceComponentPanel: PropTypes.func.isRequired,
      isDeveloperModeEnabled: PropTypes.bool.isRequired,
      selectedComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
    },
    openHelpCenter(helpId, isFromLearnMore) {
      if (!helpId) {
        return;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
      const isLearnMoreSpecified = !_.isNil(isFromLearnMore);
      const biHelpParams = {
        component: this.props.selectedComponentType,
        learn_more: isLearnMoreSpecified ? !!isFromLearnMore : null,
        panel_name: this.props.panelName,
        origin: constants.BI.HELP.ORIGIN.PANEL,
      };
      this.props.openHelpCenter(helpId, null, biHelpParams);
    },

    updateProperties(key, val, dontAddToUndoRedoStack) {
      const newData = parseNewDataToSet(this.props.compProperties, key, val);
      newData.id = newData?.id
        ? newData.id
        : this.props.compProperties && this.props.compProperties.id;
      this.props.updateProperties(
        this.props.selectedComponents,
        newData,
        dontAddToUndoRedoStack,
      );
    },

    updateData(key, val, dontAddToUndoRedoStack) {
      const newData = parseNewDataToSet(this.props.compData, key, val);
      newData.id = newData?.id
        ? newData.id
        : this.props.compData && this.props.compData.id;
      this.props.updateData(
        this.props.selectedComponents,
        newData,
        dontAddToUndoRedoStack,
      );
    },

    updateCompDesign(
      key,
      val,
      dontAddToUndoRedoStack,
      compRef,
      compDesign,
      isMultiCompDesign,
    ) {
      const newData = isMultiCompDesign
        ? parseMultiCompDataToSet(compDesign, key, val)
        : parseNewDataToSet(compDesign, key, val);
      newData.id = newData?.id ? newData.id : compDesign?.id;
      this.props.updateDesign(compRef, newData, false, dontAddToUndoRedoStack);
    },

    updateDesign(key, val, dontAddToUndoRedoStack) {
      if (this.props.multiSelectedComponents) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        return _.forEach(
          this.props.multiCompDesign,
          ({ compRef, compDesign }) =>
            this.updateCompDesign(
              key,
              val,
              dontAddToUndoRedoStack,
              compRef,
              compDesign,
              true,
            ),
        );
      }
      this.updateCompDesign(
        key,
        val,
        dontAddToUndoRedoStack,
        this.props.selectedComponents,
        this.props.compDesign,
      );
    },

    updateLayout(newLayout, dontAddToUndoRedoStack) {
      const fullLayoutToUpdate = _.merge({}, this.props.compLayout, newLayout);
      this.props.updateLayout(
        this.props.selectedComponents,
        fullLayoutToUpdate,
        dontAddToUndoRedoStack,
      );
      this.props.afterUpdateLayout?.({
        selectedComponents: this.props.selectedComponents,
        layout: fullLayoutToUpdate,
        dontAddToUndoRedoStack,
      });
    },

    updateDock(newDock) {
      this.props.updateDock(this.props.selectedComponents, newDock);
    },

    setDock(dockToSet) {
      this.props.setDock(this.props.selectedComponents, dockToSet);
    },

    updateStyle(newStyle, key, value) {
      if (this.props.multiSelectedComponents && key) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(this.props.multiCompStyle, ({ compRef, compStyle }) => {
          const style = _.cloneDeep(compStyle);
          _.set(style, `style.properties.${key}`, value);
          this.props.updateStyle(compRef, style);
        });
        return;
      }
      this.props.updateStyle(this.props.selectedComponents, newStyle);
    },

    updateBehavior(behavior, actionName, viewMode) {
      this.props.updateBehavior(
        this.props.selectedComponents,
        behavior,
        actionName,
        viewMode,
      );
    },

    removeBehavior(behaviorName, actionName, viewMode) {
      this.props.removeBehavior(
        this.props.selectedComponents,
        behaviorName,
        actionName,
        viewMode,
      );
    },

    hasLabel() {
      return !!this.props.compData.label;
    },

    render() {
      const updatedProps = _.defaults(
        {
          frameProps: util.panelUtils.getFrameProps(this.props),
          updateProperties: this.updateProperties,
          updateData: this.updateData,
          updateDesign: this.updateDesign,
          updateStyle: this.updateStyle,
          updateLayout: this.updateLayout,
          updateDock: this.updateDock,
          setDock: this.setDock,
          updateBehavior: this.updateBehavior,
          removeBehavior: this.removeBehavior,
          hasLabel: this.hasLabel,
          translate,
        },
        this.props,
      );

      return React.createElement(
        CompPanelApiProvider,
        {
          value: this.props.compPanelApiProviderValue,
        },
        React.createElement(Component, updatedProps),
      );
    },
  });

interface CompPanelOwnProps {
  selectedComponentType: string;
  selectedComponents: CompRef[];
  selectedComponent: CompRef[];
}

const getSelectedCompsRefs = (
  state: EditorState,
  ownProps: CompPanelOwnProps,
) =>
  ownProps.selectedComponents ||
  ownProps.selectedComponent ||
  selectionSelectors.getSelectedCompsRefs(state);

const mapStateToProps = (
  { editorAPI, state, dsRead }: StateMapperArgs,
  ownProps: CompPanelOwnProps,
) => {
  const selectedComponents = getSelectedCompsRefs(state, ownProps);
  if (selectedComponents.length === 0) {
    return {};
  }

  const compStyleId = getStyleId(selectedComponents, dsRead);
  const compStyle = editorAPI.components.style.get(_.head(selectedComponents));
  const selectedComponentType =
    ownProps.selectedComponentType ||
    componentsSelectors.getCompTypeSuffix(selectedComponents, dsRead);
  const multiCompDesign = getMultiCompProperties(
    editorAPI.components.design.get,
    'compDesign',
    ownProps.multiSelectedComponents,
  );
  const multiCompStyle = getMultiCompProperties(
    editorAPI.components.style.get,
    'compStyle',
    ownProps.multiSelectedComponents,
  );
  return {
    selectedComponents,
    compData: getData(selectedComponents, dsRead),
    compProperties: getProperties(selectedComponents, dsRead),
    compLayout:
      editorAPI.components.layout.getRelativeToStructure(selectedComponents),
    compDesign: editorAPI.components.design.get(selectedComponents),
    compStyleId,
    compStyle,
    compSkin: getSkin(selectedComponents, dsRead),
    selectedComponentType,
    compBehaviors: getBehaviors(selectedComponents, dsRead),
    isMobileEditor: isMobileEditor(state),
    isDesktopEditor: isDesktopEditor(state),
    isDeveloperModeEnabled: editorAPI.developerMode.isEnabled(),
    multiCompDesign,
    multiCompStyle,
  };
};

const validateDataItem =
  (...args) =>
  (dispatch, getState, { dsRead }) =>
    dsRead.data.isItemValid(...args);
const updateStyle =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.style.update(...args);

const updateProperties =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.properties.update(...args);
const updateData =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.data.update(...args);
const updateDesign =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.design.update(...args);
const updateLayout =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.layout.updateRelativeToStructure(...args);
/**
 * @deprecated
 */
const updateDock =
  (compRef: CompRef, docking: Docking): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    // TODO: migrate to new responsive layout API
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    return editorAPI.components.layout.updateDock(compRef, docking, {
      allowOneDockAdapter_DONT_USE: true,
    });
  };
/**
 * @deprecated
 */
const setDock =
  (compRef: CompRef, docking: Docking): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    // TODO: migrate to new responsive layout API
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    return editorAPI.components.layout.setDock(compRef, docking, {
      allowOneDockAdapter_DONT_USE: true,
    });
  };
const updateBehavior =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.behaviors.update(...args);
const removeBehavior =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.behaviors.remove(...args);
const getLinkValueAsString =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    util.link.getLinkValueAsString(editorAPI, ...args);

const replaceComponentPanel =
  (panelType, panelProps) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.replaceComponentPanel(panelType, panelProps);

const mapDispatchToProps = (dispatch, ownProps: CompPanelOwnProps) => ({
  compPanelApiProviderValue: dispatch((_dispatch, getState, { editorAPI }) =>
    createCompPanelApiProviderValue(editorAPI, {
      compRefs: getSelectedCompsRefs(getState(), ownProps),
    }),
  ),
  validateDataItem: (...args) => dispatch(validateDataItem(...args)),
  updateStyle: (...args) => dispatch(updateStyle(...args)),
  openHelpCenter: (helpId, props, biHelpParams) =>
    dispatch(openHelpCenter(helpId, props, biHelpParams)),
  updateProperties: (...args) => dispatch(updateProperties(...args)),
  updateData: (...args) => dispatch(updateData(...args)),
  updateDesign: (...args) => dispatch(updateDesign(...args)),
  updateLayout: (...args) => dispatch(updateLayout(...args)),
  updateDock: (...args) => dispatch(updateDock(...args)),
  setDock: (...args) => dispatch(setDock(...args)),
  updateBehavior: (...args) => dispatch(updateBehavior(...args)),
  removeBehavior: (...args) => dispatch(removeBehavior(...args)),
  getLinkValueAsString: (...args) => dispatch(getLinkValueAsString(...args)),
  bi: {
    event: (biEvent, biEventParams) => dispatch(event(biEvent, biEventParams)),
    error: (errorEvent, errorParams) =>
      dispatch(error(errorEvent, errorParams)),
  },

  replaceComponentPanel: (panelType, panelProps) =>
    dispatch(replaceComponentPanel(panelType, panelProps)),
});

export default (Component) =>
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(compPanel(reportBiEventOnUiChange(Component)));
