import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { LayoutMeshApi } from './createLayoutMeshApi';
import type { HistoryAddOptions, HistoryApi } from '../createHistoryApi';

export function createLayoutMeshArrangementApi({
  editorAPI,
  historyApi,
  layoutMeshCoreApi,
}: {
  editorAPI: EditorAPI;
  historyApi: HistoryApi;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
}) {
  async function moveToIndexTransaction(
    compRef: CompRef,
    compIndexTarget: number,
    options?: HistoryAddOptions,
  ) {
    await editorAPI.dsActions.transactions.run(async () => {
      editorAPI.dsActions.components.arrangement.moveToIndex(
        compRef,
        compIndexTarget,
      );

      if (editorAPI.sections.isSection(compRef)) {
        layoutMeshCoreApi.updateContainerGrid(
          editorAPI.components.getContainer(compRef),
        );
      }
    });

    historyApi.add('component - move to index', options);
  }

  return {
    moveToIndexTransaction,
  };
}
