// @ts-nocheck
import _ from 'lodash';
import * as savePublish from '../savePublish/savePublish';
import constants from '#packages/constants';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';

function initDealer(editorAPI) {
  loadDealerViewer().then(({ wixReactDealerViewer, wixDealerClientApi }) => {
    editorAPI.wixReactDealerViewer = wixReactDealerViewer;
    editorAPI.wixDealerClientApi = wixDealerClientApi;
    fetchDealerPostPublish(editorAPI);
  });

  loadDealerCss(editorAPI);
}

function sendDealerBI({
  editorAPI,
  isUsingDealer,
  description,
  stage,
  category = 'upgrade',
}) {
  _.invoke(
    editorAPI,
    ['bi', 'event'],
    coreBi.events.dealer.content_presented_by_dealer,
    {
      description,
      content_presented_by_dealer: isUsingDealer,
      stage,
      category,
    },
  );
}

const loadDealerViewer = () =>
  Promise.all([
    import('wixReactDealerViewer'),
    import('wixDealerClientApi'),
  ]).then(([wixReactDealerViewer, wixDealerClientApi]) => ({
    wixReactDealerViewer,
    wixDealerClientApi,
  }));

function fetchDealerPostPublish(editorAPI, ignoreCache) {
  if (isDealerReady(editorAPI)) {
    const metaSiteInstance =
      editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance;

    util.dealerUtils
      .loadDealerAsset(
        editorAPI.wixDealerClientApi,
        util.dealerUtils.bannerPositions.POST_PUBLISH,
        ignoreCache,
        editorAPI.site.getMetaSiteId(),
        metaSiteInstance,
      )
      .then((asset) => {
        const isAssetExists = asset.assetExist();
        const description = isAssetExists
          ? ''
          : 'banner data pre fetch asset does not exist error';

        sendDealerBI({
          editorAPI,
          isUsingDealer: isAssetExists,
          stage: 'prefetch',
          description,
        });

        editorAPI.store.dispatch(
          savePublish.actions.setIsDealerPostpublishLoaded(isAssetExists),
        );
      })
      .catch((e) => {
        sendDealerBI({
          editorAPI,
          isUsingDealer: false,
          stage: 'prefetch',
          description: `banner data pre fetch ${e?.message ?? 'unknown'} error`,
        });
      });
  }
}

function fetchDealerPostSave(editorAPI) {
  editorAPI.store.dispatch(
    savePublish.actions.setIsDealerPostsaveLoaded(false),
  );

  const isReady =
    isDealerReady(editorAPI) &&
    editorAPI.isDealerCssLoaded &&
    editorAPI.wixReactDealerViewer?.DealerViewer;

  if (!isReady) {
    return;
  }

  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  util.dealerUtils
    .loadDealerAsset(
      editorAPI.wixDealerClientApi,
      util.dealerUtils.bannerPositions.POST_SAVE,
      true, // force update dealer cache to serve fresh offer on each onSave click
      editorAPI.site.getMetaSiteId(),
      metaSiteInstance,
    )
    .then((asset: any) => {
      sendDealerBI({
        editorAPI,
        isUsingDealer: asset.assetExist(),
        stage: 'fetch',
        description: 'postSave banner asset loaded',
        category: 'postSave',
      });

      editorAPI.store.dispatch(
        savePublish.actions.setIsDealerPostsaveLoaded(asset.assetExist()),
      );
    })
    .catch((e) => {
      sendDealerBI({
        editorAPI,
        isUsingDealer: false,
        stage: 'fetch',
        description: `postSave asset fetch error: ${e?.message || '(empty)'}`,
        category: 'postSave',
      });
    });
}

const isDealerReady = (editorAPI) =>
  editorAPI.wixDealerClientApi?.DealerAssetsLoader &&
  _.invoke(editorAPI, ['dsRead', 'generalInfo', 'getMetaSiteId']) &&
  _.invoke(
    editorAPI,
    'dsRead.platform.getAppDataByApplicationId',
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

const loadDealerCss = (editorAPI) => {
  const link = window.document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = `${util.serviceTopology.scriptsLocationMap['react-dealer-viewer']}/dealer-react-viewer.min.css`;
  link.onload = () => {
    editorAPI.isDealerCssLoaded = true;
  };

  window.document.head.appendChild(link);
};

const initDealerAction =
  () =>
  (dispatch, getState, { editorAPI }) =>
    initDealer(editorAPI);

const fetchDealerPostPublishAction =
  (ignoreCache) =>
  (dispatch, getState, { editorAPI }) =>
    fetchDealerPostPublish(editorAPI, ignoreCache);

export {
  initDealerAction as initDealer,
  fetchDealerPostPublishAction as fetchDealerPostPublish,
  fetchDealerPostSave,
};
