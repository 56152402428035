// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'reactDOM';
import PropTypes from 'prop-types';
import $ from 'zepto';
import * as util from '#packages/util';
import * as utils from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';

const { measuring } = utils.hoc;
const { elementUtils } = util;

// eslint-disable-next-line react/prefer-es6-class
const ToolbarPositioning = createReactClass({
  displayName: 'toolbarPositioning',
  propTypes: {
    children: PropTypes.func.isRequired,
    isCustomPosition: PropTypes.bool,
    isHidden: PropTypes.bool,
    initialPos: PropTypes.object.isRequired,
    dragRightLimit: PropTypes.number.isRequired,
    lastCustomXPosition: PropTypes.number,
    className: PropTypes.string,
    reportMeasurements: PropTypes.func.isRequired,
    measurements: PropTypes.shape({
      shouldCompSlideRight: PropTypes.bool,
      dragLimits: PropTypes.shape({
        x: PropTypes.arrayOf(PropTypes.number),
        y: PropTypes.arrayOf(PropTypes.number),
      }),
      position: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
      }),
    }),
    stageLayout: PropTypes.object,
    editorContentLayout: PropTypes.object,
  },
  getDefaultProps() {
    return {
      dragRightLimit: 0,
      initialPos: { right: 0, top: 0 },
      lastCustomXPosition: null,
    };
  },
  componentDidMount() {
    this.measureComp();
  },
  componentDidUpdate() {
    this.measureComp();
  },
  fitPositionToScreen(dragLimits) {
    const domNode = ReactDOM.findDOMNode(this);
    const boundingClientRect = domNode.getBoundingClientRect();
    const currentTop = boundingClientRect.top;
    const currentLeft =
      this.props.lastCustomXPosition || boundingClientRect.left;

    const maxToolbarLeft = dragLimits.x[1] - boundingClientRect.width;

    const newLeft = util.math.ensureWithinLimits(
      currentLeft,
      dragLimits.x[0],
      maxToolbarLeft,
    );
    const newTop = util.math.ensureWithinLimits(
      currentTop,
      dragLimits.y[0],
      dragLimits.y[1],
    );

    return {
      left: newLeft,
      top: newTop,
    };
  },
  getEditorContentLayout() {
    /** For wix code tests it runs directly without topbar mapper, this default case need for wixcode tests */
    return this.props.editorContentLayout || {};
  },
  getDragLimits() {
    const panelElm = ReactDOM.findDOMNode(this);
    const panelHeight = elementUtils.getOuterHeight(panelElm);
    const panelWidth = elementUtils.getOuterWidth(panelElm);
    return util.panelUtils.getPanelDragLimits(
      panelHeight,
      panelWidth,
      this.props.stageLayout,
      this.getEditorContentLayout(),
      this.props.isMobileEditor,
    );
  },
  getComponentPosition(dragLimits) {
    return this.props.isCustomPosition
      ? this.fitPositionToScreen(dragLimits)
      : this.props.initialPos;
  },
  measureComp() {
    if (this.props.isHidden) {
      this.props.reportMeasurements(this.props.measurements);
    } else {
      const dragLimits = this.getDragLimits();

      this.props.reportMeasurements({
        shouldCompSlideRight: this.shouldSlideRight(),
        dragLimits,
        position: this.getComponentPosition(dragLimits),
      });
    }
  },
  shouldSlideRight() {
    const componentNode = $(ReactDOM.findDOMNode(this));
    const componentOffsetLeft = componentNode.offset().left;
    const componentMiddle = componentNode.width() / 2;
    const windowMiddle = $(window).width() / 2;

    return componentOffsetLeft + componentMiddle > windowMiddle;
  },
  getClassNames() {
    const { shouldCompSlideRight } = this.props.measurements;
    const classNamesMap = {
      'hide-tools': this.props.isHidden,
      'show-tools': !this.props.isHidden,
      'slide-right': shouldCompSlideRight,
      'slide-left': !shouldCompSlideRight,
    };

    return [
      ...Object.keys(classNamesMap).filter((key) => classNamesMap[key]),
      `${this.props.className}`,
    ].join(' ');
  },
  getMeasurements() {
    let positionStyle = {};
    let dragLimits = {};

    if (!_.isEmpty(this.props.measurements)) {
      positionStyle = this.props.measurements.position;
      dragLimits = this.props.measurements.dragLimits;
    }
    return { positionStyle, dragLimits };
  },
  render() {
    const { positionStyle, dragLimits } = this.getMeasurements();
    const className = this.getClassNames();
    return React.createElement(
      'div',
      {
        style: positionStyle,
        className,
      },
      this.props.children(dragLimits),
    );
  },
});

export default _.flow(measuring)(ToolbarPositioning) as AnyFixMe;
