// @ts-nocheck
import _ from 'lodash';
import textActionTypes from './textActionTypes';
import * as selection from '../selection/selection';
import * as components from '../components/components';
import coreUtilsLib from 'coreUtilsLib';
import {
  getSelectedComponent,
  getSelectedColor,
  getWidgetDesignSelectedComponents,
} from './textSelectors';
import constants from '#packages/constants';

const {
  OPEN_TEXT_EDITOR,
  CLOSE_TEXT_EDITOR,
  SET_SELECTED_COMPONENT,
  SET_SELECTED_COLOR,
  LOAD_FROM_WIDGET_DESIGN,
  CLEAR_WIDGET_DESIGN_STATE,
  SET_FONT_FAMILY_DROPDOWN_OPENED,
} = textActionTypes;

const undoStack = [];
const redoStack = [];
const openTextEditor = (
  editStyleOnly = false,
  isLinkPanelOpen = false,
  shouldOpenSettingsPanel = true,
  origin = null,
) => {
  return {
    type: OPEN_TEXT_EDITOR,
    editStyleOnly,
    isLinkPanelOpen,
    shouldOpenSettingsPanel,
    origin,
  };
};

const closeTextEditor = () => ({
  type: CLOSE_TEXT_EDITOR,
});

const setFontFamilyDropdownOpen = (isOpen) => ({
  type: SET_FONT_FAMILY_DROPDOWN_OPENED,
  isOpen,
});

const setSelectedComponent = (selectedComponent) => ({
  type: SET_SELECTED_COMPONENT,
  selectedComponent,
});

const setSelectedColor = (selectedColor) => ({
  type: SET_SELECTED_COLOR,
  selectedColor,
});

const loadFromWidgetDesign = (selectedComponents, panelProps) => ({
  type: LOAD_FROM_WIDGET_DESIGN,
  selectedComponents,
  panelProps,
});

const clearWidgetDesignState = () => ({
  type: CLEAR_WIDGET_DESIGN_STATE,
});

const getCommandData = (commandName, commandData) => ({
  data: { commandName, commandData },
});

const loadInvisibleTextManager =
  (textManager) => (dispatch, getState, services) => {
    Object.assign(services, {
      invisibleTextManager: textManager,
    });
  };

const unloadInvisibleTextManager = () => (dispatch, getState, services) => {
  Object.assign(services, {
    invisibleTextManager: null,
  });
};

const loadInvisibleLinksHelper =
  (linkHelper) => (dispatch, getState, services) => {
    Object.assign(services, {
      invisibleLinksHelper: linkHelper,
    });
  };

const unloadInvisibleLinksHelper = () => (dispatch, getState, services) => {
  Object.assign(services, {
    invisibleLinksHelper: null,
  });
};

const loadTextManager = (textManager) => (dispatch, getState, services) => {
  Object.assign(services, {
    textManager,
  });
};

const unloadTextManager = () => (dispatch, getState, services) => {
  Object.assign(services, {
    textManager: null,
  });
};

const statefulCommands = new Set(['bold', 'italic', 'underline']);

const ckUpdateData = (
  editorAPI,
  invisibleTextManager,
  invisibleLinksHelper,
  compRef,
  data,
) => {
  // get new Data and update
  data.text = invisibleTextManager.getData();
  data.linkList = invisibleLinksHelper.getAllLinks();
  editorAPI.components.data.update(compRef, data);
};

const getComponentsToEditData = (
  selectedComponent,
  remainingComponents,
  dsRead,
  logTitle,
) => {
  const textComponentToEditData = components.selectors.getCompsData(
    remainingComponents,
    dsRead,
  );

  coreUtilsLib.wSpy.log('textEditor', [
    logTitle,
    selectedComponent,
    true,
    remainingComponents,
  ]);

  return textComponentToEditData;
};

const execInvisibleCommandForRepeaterComp = (
  event,
  selectedComponent,
  invisibleTextManager,
  invisibleLinksHelper,
  textManager,
  dsRead,
  editorAPI,
  cmdOptions,
) => {
  const isTextComp =
    components.selectors.getCompType(selectedComponent, dsRead) ===
    constants.COMP_TYPES.TEXT;
  const repeatedComponents =
    dsRead.deprecatedOldBadPerformanceApis.components.getRepeatedComponents(
      selectedComponent,
    );
  if (isTextComp && repeatedComponents.length > 1) {
    const textComponentToEditData = getComponentsToEditData(
      selectedComponent,
      repeatedComponents,
      dsRead,
      'execInvisibleCommand',
    );

    textComponentToEditData.forEach((data, dataIndex) => {
      const { commandName, commandData } = event.data;

      // set CK with comp data
      invisibleTextManager.setData(data.text, {
        validateHtml: false,
        removeNestedStyles: true,
      });
      invisibleLinksHelper.setCurrentLinks(data.linkList);

      if (statefulCommands.has(commandName)) {
        const commandStates = invisibleTextManager.getCommandsStates();
        const isAlreadyInDesiredState =
          commandData && commandStates[commandName] === commandData.value;

        if (isAlreadyInDesiredState) {
          return;
        }
      }

      // select all text and exec command
      invisibleTextManager.execCommand('selectAll', {
        execWithoutFocus: cmdOptions?.execWithoutFocus,
      });
      invisibleTextManager.execCommand(commandName, commandData, {
        execWithoutHistory: true,
        execWithoutFocus: true,
      });
      ckUpdateData(
        editorAPI,
        invisibleTextManager,
        invisibleLinksHelper,
        repeatedComponents[dataIndex],
        data,
      );
    });
  }
};

const execInvisibleSeoTagChangeForRepeaterComp = (
  editorAPI,
  tag,
  selectedComponent,
  invisibleTextManager,
  invisibleLinksHelper,
  dsRead,
) => {
  const isTextComp =
    components.selectors.getCompType(selectedComponent, dsRead) ===
    constants.COMP_TYPES.TEXT;
  const repeatedComponents =
    dsRead.deprecatedOldBadPerformanceApis.components.getRepeatedComponents(
      selectedComponent,
    );
  if (isTextComp && repeatedComponents.length > 1) {
    const textComponentToEditData = getComponentsToEditData(
      selectedComponent,
      repeatedComponents,
      dsRead,
      'execInvisibleSeoTagChange',
    );

    textComponentToEditData.forEach((data, dataIndex) => {
      // set CK with comp data
      invisibleTextManager.setData(data.text, {
        validateHtml: false,
        removeNestedStyles: true,
      });
      invisibleLinksHelper.setCurrentLinks(data.linkList);

      invisibleTextManager.setSeoTag(tag);
      ckUpdateData(
        editorAPI,
        invisibleTextManager,
        invisibleLinksHelper,
        repeatedComponents[dataIndex],
        data,
      );
    });
  }
};

const execRepeaterSeoTagChange =
  (tag) =>
  (
    dispatch,
    getState,
    { invisibleTextManager, invisibleLinksHelper, dsRead, editorAPI },
  ) => {
    const selectedComponent = _.head(
      selection.selectors.getSelectedCompsRefs(getState()),
    );
    execInvisibleSeoTagChangeForRepeaterComp(
      editorAPI,
      tag,
      selectedComponent,
      invisibleTextManager,
      invisibleLinksHelper,
      dsRead,
    );
  };

const execInvisibleCommand =
  (event) =>
  (
    dispatch,
    getState,
    {
      invisibleTextManager,
      invisibleLinksHelper,
      textManager,
      dsRead,
      editorAPI,
    },
  ) => {
    const selectedComponent = _.head(
      selection.selectors.getSelectedCompsRefs(getState()),
    );
    if (event.isRepeated) {
      execInvisibleCommandForRepeaterComp(
        event,
        selectedComponent,
        invisibleTextManager,
        invisibleLinksHelper,
        textManager,
        dsRead,
        editorAPI,
      );
    } else {
      execInvisibleCommand_Single(
        event,
        selectedComponent,
        invisibleTextManager,
        invisibleLinksHelper,
        dsRead,
        editorAPI,
      );
    }
  };

const guardedCommandsList = [
  'justifyleft',
  'justifycenter',
  'justifyright',
  'justifyblock',
];

const execInvisibleCommand_Single = (
  event,
  compRef,
  invisibleTextManager,
  invisibleLinksHelper,
  dsRead,
  editorAPI,
) => {
  const isTextComp =
    components.selectors.getCompType(compRef, dsRead) ===
    constants.COMP_TYPES.TEXT;

  if (!isTextComp) return;

  const textData = editorAPI.components.data.get(compRef);

  const { commandName, commandData, cmdOptions } = event.data;

  // set CK with comp data
  invisibleTextManager.setData(textData.text, {
    validateHtml: false,
    removeNestedStyles: true,
  });
  invisibleLinksHelper.setCurrentLinks(textData.linkList);

  if (statefulCommands.has(commandName)) {
    const commandStates = invisibleTextManager.getCommandsStates();
    const isAlreadyInDesiredState =
      commandData && commandStates[commandName] === commandData.value;

    if (isAlreadyInDesiredState) {
      return;
    }
  }

  // don't execute command on text if it already has those styles applied
  if (
    guardedCommandsList.includes(commandName) &&
    invisibleTextManager.getCommandsStates()[commandName]
  )
    return;

  // select all text and exec command
  invisibleTextManager.execCommand('selectAll', {
    execWithoutFocus: cmdOptions?.execWithoutFocus,
  });
  invisibleTextManager.execCommand(commandName, commandData, {
    execWithoutHistory: true,
    execWithoutFocus: true,
  });
  ckUpdateData(
    editorAPI,
    invisibleTextManager,
    invisibleLinksHelper,
    compRef,
    textData,
  );
};

const getRemainingComponents = (getState) => {
  const widgetDesignSelectedComponents =
    getWidgetDesignSelectedComponents(getState());
  const selectedComponents =
    widgetDesignSelectedComponents?.length > 0
      ? widgetDesignSelectedComponents
      : selection.selectors.getSelectedCompsRefs(getState());
  const selectedTextComp = getSelectedComponent(getState());
  return _.reject(selectedComponents, ['id', selectedTextComp.id]);
};

const execMultiCompCommands =
  (commandName, params, cmdOptions, isMouseOut) =>
  (
    dispatch,
    getState,
    {
      invisibleTextManager,
      invisibleLinksHelper,
      textManager,
      editorAPI,
      dsRead,
    },
  ) => {
    undoStack.push({
      commandName,
      params: textManager.getCommandsStates()[commandName],
    });
    if (isMouseOut) {
      params = getSelectedColor(getState());
    }

    const remainingComponents = getRemainingComponents(getState);
    remainingComponents.forEach((compRef) => {
      const data = editorAPI.components.data.get(compRef);

      // set CK with comp data
      invisibleTextManager.setData(data.text, {
        validateHtml: false,
        removeNestedStyles: true,
      });
      invisibleLinksHelper.setCurrentLinks(data.linkList);

      const invisibleCommandsState = invisibleTextManager.getCommandsStates();
      const commandsState = textManager.getCommandsStates();
      const shouldExecuteCommand = _.isBoolean(
        invisibleCommandsState[commandName],
      )
        ? invisibleCommandsState[commandName] === commandsState[commandName]
        : true;

      if (shouldExecuteCommand) {
        invisibleTextManager.execCommand('selectAll', {
          execWithoutFocus: cmdOptions?.execWithoutFocus,
        });
        invisibleTextManager.execCommand(commandName, params, {
          execWithoutHistory: true,
          execWithoutFocus: cmdOptions?.execWithoutFocus,
        });
        ckUpdateData(
          editorAPI,
          invisibleTextManager,
          invisibleLinksHelper,
          compRef,
          data,
        );
        execInvisibleCommandForRepeaterComp(
          getCommandData(commandName, params),
          compRef,
          invisibleTextManager,
          invisibleLinksHelper,
          textManager,
          dsRead,
          editorAPI,
          cmdOptions,
        );
      }
    });
    textManager.execCommand(commandName, params, cmdOptions);
    if (!cmdOptions?.execWithoutFocus) {
      textManager.focus();
    }
  };

const execSeoTagChange =
  (tagName) =>
  (
    dispatch,
    getState,
    {
      invisibleTextManager,
      invisibleLinksHelper,
      textManager,
      editorAPI,
      dsRead,
    },
  ) => {
    const remainingComponents = getRemainingComponents(getState);
    remainingComponents.forEach((compRef) => {
      const data = editorAPI.components.data.get(compRef);
      invisibleTextManager.setData(data.text, {
        validateHtml: false,
        removeNestedStyles: true,
      });
      invisibleLinksHelper.setCurrentLinks(data.linkList);
      invisibleTextManager.setSeoTag(tagName);
      ckUpdateData(
        editorAPI,
        invisibleTextManager,
        invisibleLinksHelper,
        compRef,
        data,
      );
      execInvisibleSeoTagChangeForRepeaterComp(
        editorAPI,
        tagName,
        compRef,
        invisibleTextManager,
        invisibleLinksHelper,
        dsRead,
      );
    });

    textManager.setSeoTag(tagName);
  };

const execStylePropertyMultiCompCommands =
  (action, value, theme, textControls) =>
  (dispatch, getState, { editorAPI }) => {
    const componentRefs = getWidgetDesignSelectedComponents(getState());
    componentRefs.forEach((compRef) => {
      const compStyle = editorAPI.components.style.get(compRef);

      if (action.type === 'themed') {
        textControls.themedActions.applyThemedAction(
          compRef,
          action,
          value,
          compStyle,
          theme,
          editorAPI.components.style.update,
        );
        return;
      }

      textControls.nonThemedAction.applyNonThemedAction(
        compRef,
        action,
        value,
        compStyle,
        editorAPI.components.style.update,
      );
    });
  };

const handleMultiselectUndoRedo = ({
  primaryStack,
  secondaryStack,
  getState,
  editorAPI,
  invisibleTextManager,
  invisibleLinksHelper,
  textManager,
  dsRead,
}) => {
  const remainingComponents = getRemainingComponents(getState);
  if (!_.isEmpty(primaryStack)) {
    const { commandName, params } = primaryStack.pop();
    secondaryStack.push({ commandName, params });
    remainingComponents.forEach((compRef) => {
      const data = editorAPI.components.data.get(compRef);
      invisibleTextManager.setData(data.text, {
        validateHtml: false,
        removeNestedStyles: true,
      });
      invisibleTextManager.execCommand('selectAll');
      invisibleTextManager.execCommand(commandName, params, {
        execWithoutHistory: true,
      });
      data.text = invisibleTextManager.getData();
      editorAPI.components.data.update(compRef, data);
      execInvisibleCommandForRepeaterComp(
        getCommandData(commandName, params),
        compRef,
        invisibleTextManager,
        invisibleLinksHelper,
        textManager,
        dsRead,
        editorAPI,
      );
    });
  }
};

const multiselectUndo =
  () =>
  (
    dispatch,
    getState,
    {
      invisibleTextManager,
      editorAPI,
      invisibleLinksHelper,
      textManager,
      dsRead,
    },
  ) => {
    handleMultiselectUndoRedo({
      primaryStack: undoStack,
      secondaryStack: redoStack,
      getState,
      editorAPI,
      invisibleTextManager,
      invisibleLinksHelper,
      textManager,
      dsRead,
    });
  };

const multiselectRedo =
  () =>
  (
    dispatch,
    getState,
    {
      invisibleTextManager,
      editorAPI,
      invisibleLinksHelper,
      textManager,
      dsRead,
    },
  ) => {
    handleMultiselectUndoRedo({
      primaryStack: redoStack,
      secondaryStack: undoStack,
      getState,
      editorAPI,
      invisibleTextManager,
      invisibleLinksHelper,
      textManager,
      dsRead,
    });
  };

const invisibleTextEditor = {
  load: loadInvisibleTextManager,
  unload: unloadInvisibleTextManager,
  execCmd: execInvisibleCommand,
  changeSeoTag: execRepeaterSeoTagChange,
  loadLinksHelper: loadInvisibleLinksHelper,
  unloadLinksHelper: unloadInvisibleLinksHelper,
};

const textEditor = {
  load: loadTextManager,
  unload: unloadTextManager,
  loadFromWidgetDesign,
  clearWidgetDesignState,
};

const multiselect = {
  execCmd: execMultiCompCommands,
  changeSeoTag: execSeoTagChange,
  execStylePropertyCmd: execStylePropertyMultiCompCommands,
  setSelectedComponent,
  setSelectedColor,
  undo: multiselectUndo,
  redo: multiselectRedo,
};

export {
  invisibleTextEditor,
  textEditor,
  multiselect,
  openTextEditor,
  closeTextEditor,
  setFontFamilyDropdownOpen,
};
