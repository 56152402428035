import type { RCM_ORIGIN } from '#packages/constants';
import type { fedopsLogger } from '#packages/util';
import {
  CLOSE_CONTEXT_MENU,
  OPEN_CONTEXT_MENU,
} from './contextMenuActionTypes';

interface OpenContextMenuParams {
  editorPositionX: number;
  editorPositionY: number;
  viewerPositionX?: number;
  viewerPositionY?: number;
  origin?: RCM_ORIGIN;
  biOrigin?: string;
  removeSelectedGuide?: Function;
  removeAllGuidesInRuler?: Function;
  context?: object;
  openRCMInteraction?: ReturnType<typeof fedopsLogger.mapInteraction>;
}

const openContextMenu = ({
  editorPositionX,
  editorPositionY,
  viewerPositionX,
  viewerPositionY,
  origin,
  biOrigin,
  removeSelectedGuide,
  removeAllGuidesInRuler,
  context = {},
  openRCMInteraction,
}: OpenContextMenuParams) => ({
  type: OPEN_CONTEXT_MENU,
  editorPositionX,
  editorPositionY,
  viewerPositionX,
  viewerPositionY,
  origin,
  biOrigin,
  removeSelectedGuide,
  removeAllGuidesInRuler,
  context,
  openRCMInteraction,
});

const closeContextMenu = () => ({
  type: CLOSE_CONTEXT_MENU,
});

export { openContextMenu, closeContextMenu };
