import PropTypes from 'prop-types';
import type { EditorAPI } from '#packages/editorAPI';

export default {
  contextTypes: {
    editorAPI: PropTypes.object,
  },

  getEditorAPI(): EditorAPI {
    // @ts-expect-error
    return this.context.editorAPI;
  },
};
