// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

const invoke = (f) => f();

const { animationFrameRenderer } = util;

export default (requestAnimationFrame, cancelAnimationFrame, store) => {
  let subscribers = [];

  const subscribe = (subscriber) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/concat
    subscribers = _.concat(subscribers, subscriber);
    return () => {
      subscribers = _.without(subscribers, subscriber);
    };
  };

  const notifySubscribers = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(subscribers, invoke);
  };

  const notifySubscribersOnAnimationFrame = animationFrameRenderer(
    requestAnimationFrame,
    cancelAnimationFrame,
    notifySubscribers,
  );

  let cancelRender = _.noop;

  store.subscribe(() => {
    cancelRender = notifySubscribersOnAnimationFrame();
  });

  const flush = () => {
    cancelRender();
    notifySubscribers();
  };

  return _.defaults(
    {
      subscribe,
      flush,
    },
    store,
  );
};
