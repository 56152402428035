// @ts-nocheck
import { isMeshLayoutEnabled } from '#packages/layout';
import _ from 'lodash';
import type { BeforePlugin } from 'types/editorPlugins';

function getSvgShapeStructure(compDef) {
  return _.defaults(compDef, {
    data: {
      link: null,
      type: 'SvgShape',
    },
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  const compRef = originalAddComponentFunc(
    containerRef,
    getSvgShapeStructure(compDef),
  );

  if (isMeshLayoutEnabled()) {
    return {
      compRef,
      onChangesApplied: onCompAddCallback,
    };
  }

  if (onCompAddCallback) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      onCompAddCallback(compRef);
    });
  }
  return { compRef };
};

export default plugin;
