// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import * as platformEvents from 'platformEvents';
import coreUtilsLib from 'coreUtilsLib';
import * as bi from '../../bi/bi';
import * as highlightsActions from '../../highlights/highlightsActions';
import * as inlinePopupActions from '../../inlinePopup/inlinePopupActions';
import * as actionTypes from './hiddenItemsActionTypes';
import * as selectors from './hiddenItemsSelectors';
import * as selectionSelectors from '../../selection/selectionSelectors';
import * as panelsSelectors from '../../panels/panelsSelectors';
import * as pagesSelectors from '../../pages/pagesSelectors';
import * as componentLocatorActions from '../../componentLocator/componentLocatorActions';
import type { CompRef } from '@wix/document-services-types';
import { EditorAPI } from '#packages/editorAPI';
import experiment from 'experiment';

const isRedesignExperimentDisabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') !== 'B' &&
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') !== 'C';
let hiddenItemsUtils; // because of require.js circular dependencies issues. remove when moving to Webpack

const { event } = bi.actions;
const isMasterPage = (pageId) => pageId === 'masterPage';

const markHoveredComponent = (compId, pageId) => (dispatch, getState) => {
  const state = getState();
  const masterPageHiddenComponents =
    selectors.getMasterPageHiddenComponents(state);
  const currentPageHiddenComponents =
    selectors.getCurrentPageHiddenComponents(state);

  const hiddenComps = isMasterPage(pageId)
    ? masterPageHiddenComponents
    : currentPageHiddenComponents;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const hiddenComp = _.find(hiddenComps, { compId });

  dispatch({
    type: actionTypes.SET_HIDDEN_ITEMS_HOVERED_COMPONENT,
    hoveredComponent: {
      hiddenComp,
      pageId,
      isInteraction: false,
    },
  });
};

const refreshMobileHiddenComponents =
  () =>
  (dispatch, getState, { editorAPI }) => {
    if (!hiddenItemsUtils) {
      return;
    }
    const state = getState();
    const focusedPageId = pagesSelectors.getFocusedPageId(state);
    const hoveredComponent = selectors.getHoveredComponent(state);
    const masterPageHiddenComponents = hiddenItemsUtils.getHiddenCompsForPage(
      editorAPI,
      'masterPage',
    );
    const currentPageHiddenComponents = hiddenItemsUtils.getHiddenCompsForPage(
      editorAPI,
      focusedPageId,
    );
    const hiddenItemSorter = ['desktopLayout.y', 'desktopLayout.x', 'compId'];

    const hiddenComponentsOfMasterPageOrCurrentPage = isMasterPage(
      hoveredComponent.pageId,
    )
      ? masterPageHiddenComponents
      : currentPageHiddenComponents;
    if (
      !_.isEmpty(hoveredComponent) &&
      !hoveredComponent.isInteraction &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      !_.find(
        hiddenComponentsOfMasterPageOrCurrentPage,
        hoveredComponent.hiddenComp,
      )
    ) {
      hiddenComponentsOfMasterPageOrCurrentPage.push(
        hoveredComponent.hiddenComp,
      );
    }

    dispatch({
      type: actionTypes.SET_HIDDEN_ITEMS_MASTER_PAGE_COMPS,
      hiddenComponents: _.sortBy(masterPageHiddenComponents, hiddenItemSorter),
    });

    dispatch({
      type: actionTypes.SET_HIDDEN_ITEMS_CURRENT_PAGE_COMPS,
      hiddenComponents: _.sortBy(currentPageHiddenComponents, hiddenItemSorter),
    });
  };

const isMobileHiddenItemsPanelOpen = (state) =>
  panelsSelectors.isMobileHiddenItemsPanelOpen(state);

const refreshMobileHiddenComponentsIfPanelIsOpen =
  () => (dispatch, getState) => {
    if (isMobileHiddenItemsPanelOpen(getState())) {
      dispatch(refreshMobileHiddenComponents());
    }
  };

const hideHiddenItemsCounter =
  () =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.mobile.hideHiddenItemsCounter();
  };

const initHiddenItemsPanel =
  (_hiddenItemsUtils) =>
  (dispatch, getState, { editorAPI }) => {
    hiddenItemsUtils =
      typeof hiddenItemsUtils !== 'undefined'
        ? hiddenItemsUtils
        : _hiddenItemsUtils;
    dispatch(refreshMobileHiddenComponents(_hiddenItemsUtils));
    if (isRedesignExperimentDisabled) {
      dispatch(hideHiddenItemsCounter());
    }
    editorAPI.selection.deselectComponents();
  };

const clearHiddenItems = () => (dispatch, getState) => {
  const hoveredComponent = selectors.getHoveredComponent(getState());
  const compId = hoveredComponent?.hiddenComp?.compId;
  const pageId = hoveredComponent?.pageId;
  if (compId && pageId) {
    dispatch(stopPreviewReAddComponent());
  }

  dispatch(highlightsActions.clearHighlights());
  dispatch(clearHoveredComponent());
};

const SCROLL_MARGIN = 50;

const highlightDefs = (compRef, layout, dsRead) => {
  const repeatedItems =
    dsRead.deprecatedOldBadPerformanceApis.components.getRepeatedComponents(
      compRef,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const repeatedItemsDefs = _.map(repeatedItems, (itemRef) => ({
    compRef: itemRef,
    layout: dsRead.components.layout.getRelativeToScreen(itemRef),
    type: constants.UI.HIGHLIGHTS.TYPES.OVERLAY,
  }));

  return repeatedItemsDefs
    ? repeatedItemsDefs
    : [
        {
          compRef,
          layout,
          type: constants.UI.HIGHLIGHTS.TYPES.OVERLAY,
        },
      ];
};

const highlightComp =
  (compRef, layout) =>
  (dispatch, getState, { dsRead }) => {
    if (!isMobileHiddenItemsPanelOpen(getState())) {
      return;
    }

    const highlightsDefs = highlightDefs(compRef, layout, dsRead);
    dispatch(highlightsActions.setHighlights(highlightsDefs));
  };

const clearHoveredComponent = () => ({
  type: actionTypes.SET_HIDDEN_ITEMS_HOVERED_COMPONENT,
  hoveredComponent: {},
});

const addComponentToStageAndScrollToIt =
  (compId, pageId, scrollCallback = _.noop, showCallback = _.noop) =>
  async (dispatch, getState, { dsActions, editorAPI }) => {
    if (!isMobileHiddenItemsPanelOpen(getState())) {
      return;
    }

    const canStillPreviewComp = (compRef) =>
      compRef && editorAPI.components.is.exist(compRef);

    dsActions.mobile.hiddenComponents.show(compId, pageId);
    await editorAPI.waitForChangesAppliedAsync();
    await editorAPI.components.hooks.componentAddedToStage.fire({
      origin: 'unHide',
      compRef: editorAPI.components.get.byId(compId),
    });

    callbacksAndScrollToComp(
      compId,
      canStillPreviewComp,
      editorAPI,
      scrollCallback,
      showCallback,
    );
  };

const callbacksAndScrollToComp = (
  compId: string,
  canStillPreviewComp: (compRef: CompRef) => boolean,
  editorAPI: EditorAPI,
  scrollCallback = _.noop,
  showCallback = _.noop,
) => {
  const compRef = editorAPI.components.get.byId(compId);
  if (!canStillPreviewComp(compRef)) {
    return;
  }
  showCallback(compRef);
  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);
  const targetY = compLayout.y - SCROLL_MARGIN;
  const scrollDuration = coreUtilsLib.scrollUtils.calcScrollDuration(
    editorAPI.site.getScroll().y,
    targetY,
    true,
  );
  editorAPI.scroll.animateScrollTo({ y: targetY }, scrollDuration, () => {
    if (canStillPreviewComp(compRef)) {
      scrollCallback();
    }
  });
  editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
    platformEvents.factory.showMobileElement({ compRef }),
  );
};
const setIsAddingComponentInProgress = (bool) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_ADDING_COMPONENT_IN_PROGRESS,
    isAddingHiddenItemInProgress: bool,
  });
};

const reAddComponent =
  (compId, pageId, compType, sectionType) =>
  (dispatch, getState, { editorAPI }) => {
    dispatch(setIsAddingComponentInProgress(true));
    const actionType = isMasterPage(pageId)
      ? actionTypes.REMOVE_HIDDEN_ITEM_FROM_MASTER_PAGE_LIST
      : actionTypes.REMOVE_HIDDEN_ITEM_FROM_CURRENT_PAGE_LIST;

    window.setTimeout(() => {
      dispatch(highlightsActions.clearHighlights());
      dispatch(clearHoveredComponent());
      dispatch({ type: actionType, compId });

      dispatch(
        addComponentToStageAndScrollToIt(compId, pageId, () => {
          const compRef = editorAPI.components.get.byId(compId, pageId);
          if (editorAPI.components.is.exist(compRef)) {
            editorAPI.selection.selectComponentByCompRef(compRef);
          }

          const focusedContainer =
            selectionSelectors.getFocusedContainer(getState());
          if (editorAPI.components.is.inlinePopup(focusedContainer)) {
            dispatch(inlinePopupActions.open(focusedContainer));
          }

          dispatch(setIsAddingComponentInProgress(false));
        }),
      );

      editorAPI.history.add('unhide mobile component');
    }, 1000);

    dispatch(sendReAddBiEvent(compId, pageId, compType, sectionType));
  };

const stopPreviewReAddComponent =
  () =>
  (dispatch, getState, { dsActions }) => {
    const isAddingHiddenItemInProgress =
      selectors.getIsAddingHiddenItemInProgress(getState());
    if (isAddingHiddenItemInProgress) {
      return;
    }

    dispatch(highlightsActions.clearHighlights());
    dispatch(clearHoveredComponent());
    dsActions.history.applyLatestSnapshot();
  };

const previewReAddComponent =
  (compId, pageId) =>
  (dispatch, getState, { editorAPI }) => {
    const isAddingHiddenItemInProgress =
      selectors.getIsAddingHiddenItemInProgress(getState());
    if (isAddingHiddenItemInProgress) {
      return;
    }

    dispatch(markHoveredComponent(compId, pageId));

    editorAPI.selection.deselectComponents();
    dispatch(highlightsActions.clearHighlights());

    dispatch(
      addComponentToStageAndScrollToIt(
        compId,
        pageId,
        () => {},
        (compRef: CompRef) => {
          editorAPI.store.dispatch(
            componentLocatorActions.locateComponentAndHighlight(
              compRef,
              true,
              constants.UI.HIGHLIGHTS.TYPES.NORMAL_WITH_OVERLAY,
            ),
          );
        },
      ),
    );
  };

const sendReAddBiEvent =
  (compId, pageId, compType, sectionType) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const params = {
      current_page_name: pageId,
      component_type: compType,
      section: sectionType,
      component_id: compId,
    };
    if (editorAPI.isTpa(compType)) {
      const compRef = editorAPI.components.get.byId(
        compId,
        pageId,
        editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP,
      );
      const compData = editorAPI.components.data.get(compRef);
      const appData = dsRead.tpa.app.getData(compData.applicationId);
      params.app_id = appData.appDefinitionId;
      params.app_site_id = appData.instanceId;
      params.instance_id = compId;
    }
    dispatch(
      event(
        coreBi.events.mobileEditor.hiddenElements.HIDDEN_ELEMENTS_SHOW,
        params,
      ),
    );
  };

export {
  initHiddenItemsPanel,
  isMobileHiddenItemsPanelOpen,
  clearHiddenItems,
  clearHoveredComponent,
  callbacksAndScrollToComp,
  stopPreviewReAddComponent,
  previewReAddComponent,
  refreshMobileHiddenComponentsIfPanelIsOpen,
  reAddComponent,
  hideHiddenItemsCounter,
  sendReAddBiEvent,
  highlightComp,
};
