// @ts-nocheck
import mobileLayout from '../constants/mobileLayout';
import layout from '../constants/layout';
import mobileSettings from '../constants/mobileSettings';
import settings from '../constants/settings';
import design from '../constants/design';
import mobileDesign from '../constants/mobileDesign';
import gfpp from '../constants/gfpp';
import gfppMobile from '../constants/gfppMobile';
import customizeDesign from '../constants/customizeDesign';
import { translate } from '#packages/i18n';

function getLayoutHelpId(compType, inMobileEditor) {
  if (inMobileEditor && mobileLayout[compType]) {
    return mobileLayout[compType];
  }

  return layout[compType];
}

function getSettingsHelpId(compType, inMobileEditor) {
  if (inMobileEditor && mobileSettings[compType]) {
    return mobileSettings[compType];
  }

  return settings[compType];
}

function getDesignHelpId(compType, isMobile) {
  const helpId = isMobile ? mobileDesign[compType] : design[compType];

  return helpId || design.DEFAULT_DESIGN_HELP_ID;
}

function getCustomizeDesignHelpId(compType) {
  return customizeDesign[compType]
    ? customizeDesign[compType]
    : customizeDesign.DEFAULT_CUSTOMIZE_DESIGN_HELP_ID;
}

function getGfppHelpIds(compType) {
  if (!gfppMobile[compType]) {
    return {
      helpId: gfpp[compType],
    };
  }

  return {
    helpId: gfpp[compType],
    mobileHelpId: gfppMobile[compType],
  };
}

function getComponentHelpId({ panelType, componentType, isMobileEditor }) {
  const panelTypeWithoutSuffix = panelType?.replace('Panel', '');
  const helpIdTranslationKey = `${componentType?.replace('.', '_')}_help${
    isMobileEditor ? '_mobile_' : '_'
  }${panelTypeWithoutSuffix}`;

  const translatedHelpId = translate(helpIdTranslationKey);
  const hasHelpIdTranslation =
    translatedHelpId !== '' && translatedHelpId !== `!${helpIdTranslationKey}!`;

  return hasHelpIdTranslation ? translatedHelpId : undefined;
}

export {
  getLayoutHelpId,
  getSettingsHelpId,
  getDesignHelpId,
  getGfppHelpIds,
  getCustomizeDesignHelpId,
  getComponentHelpId,
};
