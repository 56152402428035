import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Point, Rect } from 'types/documentServices';

export function calulateXYInContainer_byXYRelativeToScreen(
  editorAPI: EditorAPI,
  {
    compXYRelativeToScreen,
    containerRef,
  }: {
    compXYRelativeToScreen: Point;
    containerRef: CompRef;
  },
) {
  const toRect =
    editorAPI.components.layout.getRelativeToScreen_rect(containerRef);
  const toMargins = editorAPI.components.layout.getCompMargin(containerRef);

  const compXYRelativeToContainer = {
    x: compXYRelativeToScreen.x - toRect.x - toMargins.left,
    y: compXYRelativeToScreen.y - toRect.y,
  };

  return compXYRelativeToContainer;
}

export function calulateXYInContainer_byXYInAnotherContainer(
  editorAPI: EditorAPI,
  {
    compXYInContainerFrom,
    containerFromRef,
    containerToRef,
  }: {
    compXYInContainerFrom: Point;
    containerFromRef: CompRef;
    containerToRef: CompRef;
  },
) {
  const fromRect =
    editorAPI.components.layout.getRelativeToScreen_rect(containerFromRef);
  const fromMargins =
    editorAPI.components.layout.getCompMargin(containerFromRef);

  const toRect =
    editorAPI.components.layout.getRelativeToScreen_rect(containerToRef);
  const toMargins = editorAPI.components.layout.getCompMargin(containerToRef);

  // eslint-disable-next-line prettier/prettier
  const deltaX = (fromRect.x + fromMargins.left) - (toRect.x + toMargins.left);
  const deltaY = fromRect.y - toRect.y;

  return {
    x: compXYInContainerFrom.x + deltaX,
    y: compXYInContainerFrom.y + deltaY,
  };
}

export function calulateRectInContainer_byRectInAnotherContainer(
  editorAPI: EditorAPI,
  {
    compRectInContainerFrom,
    containerFromRef,
    containerToRef,
  }: {
    compRectInContainerFrom: Rect;
    containerFromRef: CompRef;
    containerToRef: CompRef;
  },
) {
  const compXYInContainerTo = calulateXYInContainer_byXYInAnotherContainer(
    editorAPI,
    {
      compXYInContainerFrom: {
        x: compRectInContainerFrom.x,
        y: compRectInContainerFrom.y,
      },
      containerFromRef,
      containerToRef,
    },
  );

  return {
    ...compRectInContainerFrom,
    ...compXYInContainerTo,
  };
}
