import _ from 'lodash';
import * as util from '#packages/util';
import { layoutUtils } from '#packages/layoutUtils';
import * as domMeasurements from '../domMeasurements/domMeasurements';
import * as components from '../components/components';

import type {
  CompRef,
  CompLayout,
  Point,
  CompStructure,
} from 'types/documentServices';
import type { EditorState } from '../store/editorState';
import type { EditorAPI } from '#packages/editorAPI';

const { getStageLayout, getScrollMeasurements } = domMeasurements.selectors;

const shouldContainerGrowToFitContent = (
  editorAPI: EditorAPI,
  containerRef: CompRef,
  compDef?: CompStructure,
): boolean => {
  if (util.sections.isSectionsEnabled() && compDef) {
    const isSectionContainer = editorAPI.sections.isSection(containerRef);
    const isStretchedComp =
      editorAPI.addPanelInfra.addPanelUtils.isStretchedComp(editorAPI, compDef);
    const containerLayout = editorAPI.components.layout.get_size(containerRef);

    const lastFWEChild = util.sections.getLastFWEChildInContainer(
      editorAPI,
      containerRef,
    );
    const isNextComponentExceedsContainerHeight =
      containerLayout.height < compDef.layout.height;

    if (
      isStretchedComp &&
      isSectionContainer &&
      (isNextComponentExceedsContainerHeight || Boolean(lastFWEChild))
    ) {
      return true;
    }
  }

  return (
    !editorAPI.components.is.page(containerRef) &&
    _.isEqual(editorAPI.pages.getPrimaryContainer(), containerRef)
  );
};

const getContainerToFitContent = (
  editorAPI: EditorAPI,
  container: CompRef,
): CompRef => {
  let [parentContainer] = editorAPI.dsRead.components.getAncestors(container);
  parentContainer = components.selectors.isStateBox(
    parentContainer,
    editorAPI.dsRead,
  )
    ? editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        parentContainer,
      )
    : parentContainer;

  return components.selectors.isAppWidget(parentContainer, editorAPI.dsRead)
    ? parentContainer
    : container;
};

const getCompPastePositionToFitContainer = (
  state: EditorState,
  containerLayout: CompLayout,
  compLayout: Partial<CompLayout>,
): Point => {
  const { height: stageHeight } = getStageLayout(state);
  const { scrollTop } = getScrollMeasurements(state);

  return layoutUtils.getCompPastePositionToFitContainer(
    containerLayout,
    compLayout,
    stageHeight,
    scrollTop,
  );
};

export {
  shouldContainerGrowToFitContent,
  getContainerToFitContent,
  getCompPastePositionToFitContainer,
};
