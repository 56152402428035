import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { GetLayoutFn } from '../type';

const isDiff = (a: number, b: number) => Math.abs(a - b) > 0.5;

export function createLayoutGetApiDebugger({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const getLayoutComparator = (name: string) => ({
    label: `dsRead.components.layout.${name}`,
    get fn() {
      return (editorAPI.dsRead.components.layout as any)[name];
    },
  });

  const fnsToCompare = {
    get: [getLayoutComparator('get')],
    getRelativeToStructure: [getLayoutComparator('getRelativeToStructure')],
    getRelativeToScreen: [getLayoutComparator('getRelativeToScreen')],
    getRelativeToScreenConsideringScroll: [
      getLayoutComparator('getRelativeToScreenConsideringScroll'),
    ],
  };

  function checkDiffs(fnName: string, compRef: CompRef, layout: CompLayout) {
    const comparations = fnsToCompare[fnName as keyof typeof fnsToCompare]
      ?.map((compare) => {
        const layout2 = compare.fn(compRef);
        const layout2Diffs = Object.entries(layout)
          .map(([key, value]) => [key, value, layout2[key]] as const)
          .filter(([_key, value1, value2]) => isDiff(value1 as any, value2));

        if (layout2Diffs.length > 0) {
          return {
            compare,
            layout: layout2,
            diff: layout2Diffs,
          };
        }

        return null;
      })
      .filter((comparation) => !!comparation);

    if (comparations?.length) {
      // eslint-disable-next-line no-console
      console.error(
        `${fnName} - layouts are not equal`,
        editorAPI.dsRead.components.getType(compRef),
        ...comparations[0].diff,
        {
          compRef,
          layout1: layout,
          comparations,
        },
      );
    }
  }

  function withDebugger(fnName: string, fn: GetLayoutFn) {
    return (compRef: CompRef) => {
      const layout = fn(compRef);

      checkDiffs(fnName, compRef, layout);

      // eslint-disable-next-line no-console
      console.log(
        fnName,
        editorAPI.dsRead.components.getType(compRef),
        `X:${layout.x},Y:${layout.y} W:${layout.width},H${layout.height}`,
      );

      return layout;
    };
  }

  // eslint-disable-next-line prefer-const
  let isEnabled = false;

  return {
    isEnabled: () => isEnabled,
    withDebugger,
  };
}
