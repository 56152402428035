import { boxSlideShowUtils } from '#packages/util';

const getTotalSlidesLength = (dsRead: AnyFixMe, boxSlideShow: AnyFixMe) =>
  boxSlideShowUtils.getAllSlides(dsRead, boxSlideShow).length;

const {
  getCurrentSlideIndex,
  getAllSlides,
  findCurrentSlide,
  openApplyToAllPanel,
} = boxSlideShowUtils;

export {
  getCurrentSlideIndex,
  getTotalSlidesLength,
  getAllSlides,
  findCurrentSlide,
  openApplyToAllPanel,
};
