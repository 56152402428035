import _ from 'lodash';
import designPanelActionTypes from './designPanelActionTypes';
import constants from '#packages/constants';
import { interactionsActionTypes } from '../interactions/interactionsActionTypes';
import type { Reducer } from 'types/redux';

export interface DesignPanelState {
  overridden: unknown;
  showCustomizeDesignOnly: boolean;
}

export const designPanelInitialState: DesignPanelState = {
  overridden: {},
  showCustomizeDesignOnly: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/map
const validOverriddenIds = _.map(constants.OVERRIDDEN.DESIGN_PANEL, 'ID');
const getOverriddenItemById = (
  id: AnyFixMe, // TODO: Fix this the next time the file is edited.
) =>
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(constants.OVERRIDDEN.DESIGN_PANEL, ['ID', id]);

const getValidItemValuesById = (id: AnyFixMe) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  _.values(getOverriddenItemById(id));

const getValidatedOverridden = ({ overridden }: AnyFixMe) =>
  _(overridden)
    .pick(validOverriddenIds)
    .mapValues((item, id) => _.pick(item, getValidItemValuesById(id)))
    .value();

const reducer: Reducer<DesignPanelState> = (
  designPanel = designPanelInitialState,
  action,
) => {
  switch (action.type) {
    case designPanelActionTypes.OVERRIDE_DESIGN_PANEL:
      return _.defaultsDeep(
        { overridden: getValidatedOverridden(action) },
        designPanel,
      );
    case interactionsActionTypes.UPDATE_INTERACTION_SELECTED_COMPONENT:
      return _.defaultsDeep(
        { showCustomizeDesignOnly: !action.payload?.isOnlyOnVariant },
        designPanel,
      );
    case designPanelActionTypes.CUSTOMIZE_DESIGN_ONLY:
    case interactionsActionTypes.ENTER_INTERACTION:
      return _.defaultsDeep({ showCustomizeDesignOnly: true }, designPanel);
    case designPanelActionTypes.SHOW_COMPLETE_PANEL:
    case interactionsActionTypes.EXIT_INTERACTION:
      return _.defaultsDeep({ showCustomizeDesignOnly: false }, designPanel);
    default:
      return designPanel;
  }
};

export default reducer;
