import { isFullWidthByLayouts, layoutSize } from '#packages/layoutUtils';
import type { SingleLayoutData } from 'types/documentServices';

export function calculateComponentLayoutHeight(
  { componentLayout }: SingleLayoutData,
  heightUpdated: number,
) {
  if (componentLayout.height.type === 'auto') {
    return {
      height: componentLayout.height,
      minHeight: layoutSize.px(heightUpdated),
    };
  }

  return {
    height: layoutSize.px(heightUpdated),
  };
}

export function calculateComponentLayoutWidth(
  layouts: SingleLayoutData,
  widthUpdated: number,
) {
  if (isFullWidthByLayouts(layouts)) {
    throw new Error(
      'Cannot resize a full width component by width.\n' +
        'Use `resizeTo` with `height` instead',
    );
  }

  return {
    width: layoutSize.px(widthUpdated),
  };
}

export function calculateComponentLayoutWidthAndHeight(
  layouts: SingleLayoutData,
  widthUpdated: number,
  heightUpdated: number,
) {
  const { width } = calculateComponentLayoutWidth(layouts, widthUpdated);
  const { height, minHeight } = calculateComponentLayoutHeight(
    layouts,
    heightUpdated,
  );

  return {
    width,
    height,
    minHeight,
  };
}
