import { HttpClient } from '@wix/http-client';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getAppsByTag } from '@wix/ambassador-devcenter-appmarket-v1-tag-app/http';
import { queryMarketListing } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/http';
import { RelatedAppsActionTypes } from './relatedAppsActionTypes';
import { fedopsLogger } from '#packages/util';
import { SortType } from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';

import type { Dispatch } from 'redux';

const httpClient = new HttpClient();

const setRelatedAppsAction = (relatedApps: AnyFixMe) => ({
  type: RelatedAppsActionTypes.SetRelatedApps,
  payload: relatedApps,
});

const startRelatedAppsLoadingAction = () => ({
  type: RelatedAppsActionTypes.StartRelatedAppsLoading,
});

export interface RelatedApp {
  tagsOrder: Record<string, number>;
  appDefinitionId: string;
  name: string;
  teaser: string;
  appIcon: string;
}

export const fetchRelatedAppsByTagIds =
  (tagIds: string[]) => async (dispatch: Dispatch) => {
    dispatch(startRelatedAppsLoadingAction());
    const relatedAppsGroups = await Promise.all(
      tagIds.map(async (tagId) => {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.ADD_PANEL.RELATED_APPS_LOAD,
        );
        try {
          const tagApps = await httpClient.request(
            getAppsByTag({ tagId, sortType: SortType.MANUAL }),
          );
          const { data } = await httpClient.request(
            queryMarketListing({
              query: {
                filter: {
                  appId: tagApps.data.tagApps.map(({ appId }) => appId),
                  languageCode: window.editorModel.languageCode,
                  status: 'PUBLISHED',
                },
              },
            }),
          );
          const tagAppsIndexes = tagApps.data.tagApps.reduce(
            (acc, { appId }, index) => ({ ...acc, [appId]: index }),
            {} as Record<string, number>,
          );
          const relatedApps = data.marketListing
            .sort((a, b) => tagAppsIndexes[a.appId] - tagAppsIndexes[b.appId])
            .filter(
              ({ basicInfo, assetsMap }) =>
                basicInfo && assetsMap?.appIcon?.assets?.length > 0,
            )
            .map(({ appId, basicInfo, assetsMap }, index) => ({
              tagsOrder: {
                [tagId]: index,
              },
              appDefinitionId: appId,
              name: basicInfo.name,
              teaser: basicInfo.teaser,
              appIcon: assetsMap.appIcon.assets[0].url,
            }));
          fedopsLogger.interactionEnded(
            fedopsLogger.INTERACTIONS.ADD_PANEL.RELATED_APPS_LOAD,
          );
          return relatedApps;
        } catch (e) {
          console.error('Failed to fetch related apps, tagId: ', tagId);
          console.error(e);
          ErrorReporter.captureException(e, {
            tags: { fetchRelatedAppFailure: true },
            extra: {
              tagId,
            },
          });
          return null;
        }
      }),
    );

    const relatedAppsByAppDefId = relatedAppsGroups
      .flat()
      .filter(Boolean)
      .reduce(
        (acc, { appDefinitionId, name, tagsOrder, teaser, appIcon }) => {
          if (!acc[appDefinitionId]) {
            acc[appDefinitionId] = {
              tagsOrder,
              name,
              teaser,
              appIcon,
              appDefinitionId,
            };
          } else {
            acc[appDefinitionId] = {
              ...acc[appDefinitionId],
              tagsOrder: {
                ...acc[appDefinitionId].tagsOrder,
                ...tagsOrder,
              },
            };
          }
          return acc;
        },
        {} as Record<string, RelatedApp>,
      );

    const relatedApps = Object.values(relatedAppsByAppDefId);

    return dispatch(setRelatedAppsAction(relatedApps));
  };
