import { Hooks } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

export const moveByTransitionEndInterceptor = Hooks.createInterceptorHook<{
  compContainerToByCompId?: Map<string, CompRef>;
}>({
  isUpdateDataAllowed: true,
});

export const moveByTransitionEndAfterHook = Hooks.createHook<{
  compRefUpdatedMap?: Map<string, CompRef>;
}>();
