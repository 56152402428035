import React from 'react';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export function createCompPanelApiProviderValue(
  editorAPI: EditorAPI,
  { compRefs }: { compRefs: CompRef[] },
) {
  return {
    editorAPI,
    compRefs,
  };
}

export type CompPanelApiProviderValue = ReturnType<
  typeof createCompPanelApiProviderValue
>;

const CompPanelApiContext = React.createContext<CompPanelApiProviderValue>({
  editorAPI: null,
  compRefs: null,
});

export const { Provider: CompPanelApiProvider } = CompPanelApiContext;

function ensureSingleComopnentSelected(compRefs: CompRef[]) {
  if (compRefs.length !== 1) {
    throw new Error(
      'This api can only be used when a single component is selected',
    );
  }
}

export const useLayoutFullWidthApi = () => {
  const { editorAPI, compRefs } = React.useContext(CompPanelApiContext);

  ensureSingleComopnentSelected(compRefs);

  const compRef = compRefs[0];
  const layoutFullWidthApi = React.useMemo(
    () => {
      const { getFullWidth, stretchComponent, unStretchComponent } =
        editorAPI.components.layout;
      return {
        getFullWidth: () => getFullWidth(compRef),
        stretchComponent: (value?: Parameters<typeof stretchComponent>[1]) =>
          stretchComponent(compRef, value),
        unStretchComponent: (
          value?: Parameters<typeof unStretchComponent>[1],
        ) => unStretchComponent(compRef, value),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [compRef],
  );

  return layoutFullWidthApi;
};
