import constants from '#packages/constants';
import type { EditorState } from '#packages/stateManagement';
import * as util from '#packages/util';
import { a11yInitialState } from '../a11y/a11yReducer';
import { addPanelInitialState } from '../addPanel/addPanelReducer';
import { applicationStudioInitialState } from '../applicationStudio/applicationStudioReducer';
import { appsStoreInitialState } from '../appsStore/appsStoreReducer';
import { ascendInitialState } from '../ascend/ascendReducer';
import { attachCandidateInitialState } from '../attachCandidate/attachCandidateReducer';
import { blogInitialState } from '../blog/blogReducer';
import { branchesInitialState } from '../branches/branchesReducer';
import { componentMetaDataInitialState } from '../componentMetaData/componentMetaDataReducer';
import { componentsStoreInitialState } from '../componentsStore/componentsStoreReducer';
import { initialConcurrentUsersState } from '../concurrentUsers/concurrentUsersReducers';
import { contextMenuInitialState } from '../contextMenu/contextMenuReducer';
import { designPanelInitialState } from '../designPanel/designPanelReducer';
import { developerToolBarInitialState } from '../developerToolBar/developerToolBarReducer';
import { devModeInitialState } from '../devMode/devModeReducer';
import { domMeasurementsInitialState } from '../domMeasurements/domMeasurementsReducer';
import { domainSuggestionsInitialState } from '../domainSuggestions/domainSuggestionsReducer';
import { draggableSlotsInitialState } from '../draggable/draggableSlots/draggableSlotsReducer';
import { dynamicPagesInitialState } from '../dynamicPages/dynamicPagesReducer';
import { tpaDynamicPagesInitialState } from '../tpaDynamicPages/tpaDynamicPagesReducer';
import { initialEditorSearchState } from '../editorSearch/editorSearchReducer';
import { featuresOverriddenInitialState } from '../featuresOverridden/featuresOverriddenReducer';
import { googleMapInitialState } from '../googleMap/googleMapReducer';
import { gridLinesInitialState } from '../gridLines/gridLinesReducer';
import { helpHomeInitialState } from '../helpHome/helpHomeReducer';
import { hoverBoxInitialState } from '../hoverBox/hoverBoxReducer';
import { inlinePopupInitialState } from '../inlinePopup/inlinePopupReducer';
import { interactionsInitialState } from '../interactions/interactionsReducer';
import { introFunnelInitialState } from '../introFunnel/introFunnelReducer';
import { layersPanelInitialState } from '../layersPanel/layersPanelReducer';
import { leftBarInitialState } from '../leftBar/leftBarReducer';
import { leftTreeInitialState } from '../leftTree/leftTreeReducer';
import { linkPanelInitialState } from '../linkPanel/linkPanelReducer';
import { localModeInitialState } from '../localMode/localModeReducer';
import { mobileInitialState } from '../mobile/initialState';
import { multilingualInitialState } from '../multilingual/multilingualReducer';
import { newAddPanelInitialState } from '../newAddPanel/newAddPanelReducer';
import { newReleasesInitialState } from '../newReleases/newReleasesReducer';
import { notificationsInitialState } from '../notifications/notificationsReducer';
import { pagesInitialState } from '../pages/pagesReducer';
import { panelsInitialState } from '../panels/panelsReducer';
import { permanentBorderInitialState } from '../permanentBorder/permanentBorderReducer';
import { pinModeInitialState } from '../pinMode/pinModeReducer';
import { platformInitialState } from '../platform/platformReducer';
import { preloadingsInitialState } from '../preloadings/preloadingsReducer';
import { previewModeInitialState } from '../preview/previewModeReducer';
import { previewInitialState } from '../preview/previewReducer';
import { publishingStatusInitialState } from '../publishingStatus/publishingStatusReducer';
import { testSiteStatusInitialState } from '../testSiteStatus/testSiteStatusReducer';
import { relatedAppsInitialState } from '../relatedApps/relatedAppsReducer';
import { rightClickMenuInitialState } from '../rightClickMenu/rightClickMenuReducer';
import { rulersInitialState } from '../rulers/rulersReducer';
import { schoolModeInitialState } from '../schoolMode/schoolModeReducer';
import { savedComponentsInitialState } from '../savedComponents/savedComponentsReducer';
import { savePublishInitialState } from '../savePublish/savePublishReducer';
import { secondaryLeftAreaInitialState } from '../secondaryLeftArea/secondaryLeftAreaReducer';
import { sectionsInitialState } from '../sections/sections.reducer';
import { sectionsOnStageInitialState } from '../sectionsOnStage/sectionsOnStage.reducer';
import { siteDisplayNameInitialState } from '../siteDisplayName/siteDisplayNameReducer';
import { smartWriterInitialState } from '../smartWriter/smartWriterReducer';
import { stateBoxInitialState } from '../stateBox/stateBoxReducer';
import { textInitialState } from '../text/textReducer';
import { topBarInitialState } from '../topBar/topBarReducer';
import { unifiedChatInitialState } from '../unifiedChat/unifiedChatReducer';
import { userPreferencesInitialState } from '../userPreferences/userPreferencesReducer';
import { viewToolsInitialState } from '../viewTools/viewToolsReducer';
import { virtualNumbersInitialState } from '../virtualNumbers/virtualNumbersReducer';
import { welcomeScreenInitialState } from '../welcomeScreen/welcomeScreenReducer';
import { wixAdInitialState } from '../wixAd/wixAdReducer';
import { wixCodeIDEInitialState } from '../wixCodeIDE/wixCodeIDEReducer';
import { siteCreationInitialState } from '../siteCreation/siteCreationReducer';
import { selectionInitialState } from '../selection/selectionReducer';
import { mouseActionsInitialState } from '../mouseActions/mouseActionsReducer';
import { stageLoaderInitialState } from '../stageLoader/stageLoaderReducer';

const initialEditorState: EditorState & {
  // TODO: cleanup all next types (define and add to EditorState)
  wixCodeIDE: unknown;
  eventsBinding: unknown;
  developerToolBar: unknown;
  localMode: unknown;
  stateBox: unknown;
  googleMap: unknown;
  preloadings: unknown;
  mouseActions: unknown;
  inlinePopup: unknown;
  userProfile: unknown;
  layers: unknown;
  stage: unknown;
  permanentBorder: unknown;
  uploadedFonts: unknown;
  pageIdBeforeEnteringPreview: unknown;
  candidates: unknown;
  site: unknown;
  text: unknown;
  hasDatabaseCollections: unknown;
  mobile: unknown;
  applyModeFromClipboardSuggestion: unknown;
  leftTree: unknown;
  wixAd: unknown;
  secondaryLeftArea: unknown;
  topBarCustomization: unknown;
  unreadComments: unknown;
  tpa: unknown;
  unifiedChat: unknown;
  siteDisplayName: unknown;
  multilingual: unknown;
  platform: unknown;
  applicationStudio: unknown;
  viewerRendersCounter: unknown;
  appsStore: unknown;
  rightClickMenu: unknown;
  welcomeScreen: unknown;
  externalLinks: unknown;
  savedComponents: unknown;
  schoolMode: unknown;
  a11y: unknown;
  blog: unknown;
  componentMetaData: unknown;
  virtualNumbers: unknown;
} = {
  addPanel: addPanelInitialState,
  newAddPanel: newAddPanelInitialState,
  designPanel: designPanelInitialState,
  featuresOverridden: featuresOverriddenInitialState,
  linkPanel: linkPanelInitialState,
  snapComponent: {
    verticalEqualDistance: false,
    horizontalEqualDistance: false,
  },
  componentsStore: componentsStoreInitialState,
  gridLines: gridLinesInitialState,
  editorIsInit: false,
  previewReady: false,
  config: {
    topBarHeight: util.topBar.getHeightConst(),
    minTopBarWidth: 1160,
    previewHeight: null,
    previewWidth: null,
    languages: ['latin'],
    extraSiteHeight: 40,
  },
  editingAreaPosition: {
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    right: 0,
    bottom: 0,
  },
  wixCodeLoaded: false,
  wixCodeIDE: wixCodeIDEInitialState,
  eventsBinding: {
    overridden: {
      shouldShow: true,
    },
  },
  developerToolBar: developerToolBarInitialState,
  devModeContext: devModeInitialState,
  localMode: localModeInitialState,
  contextMenu: contextMenuInitialState,
  hoverBox: hoverBoxInitialState,
  stateBox: stateBoxInitialState,
  layersPanel: layersPanelInitialState,
  googleMap: googleMapInitialState,
  preloadings: preloadingsInitialState,
  mouseActions: mouseActionsInitialState,
  selection: selectionInitialState,
  inlinePopup: inlinePopupInitialState,
  userProfile: {},
  layers: {
    spotlight: null,
  },
  stage: {
    highlights: [],
  },
  stageLoader: stageLoaderInitialState,
  permanentBorder: permanentBorderInitialState,
  uploadedFonts: {
    list: [],
    queue: [],
    uploadedItems: {},
    timeOutTokens: [],
  },
  domMeasurements: domMeasurementsInitialState,
  preview: previewInitialState,
  previewMode: previewModeInitialState,
  pageIdBeforeEnteringPreview: null,
  isMobileEditor: false,
  snapData: null,
  lassoLayout: null,
  candidates: null,
  clipboard: {},
  site: {
    premiumPackage: undefined,
  },
  siteCreation: siteCreationInitialState,
  topBar: topBarInitialState,
  branches: branchesInitialState,
  text: textInitialState,
  viewTools: viewToolsInitialState,
  rulers: rulersInitialState,
  hasDatabaseCollections: null,
  helpProps: null,
  attachCandidate: attachCandidateInitialState,
  constraintArea: null,
  panelData: panelsInitialState,
  schoolMode: schoolModeInitialState,
  previewingStatus: constants.PROGRESS_STATUS.IDLE,
  exitPreviewStatus: constants.PROGRESS_STATUS.IDLE,
  savingStatus: constants.PROGRESS_STATUS.IDLE,
  publishingStatus: publishingStatusInitialState,
  testSiteStatus: testSiteStatusInitialState,
  savePublish: savePublishInitialState,
  sitePremiumState: null,
  portalComps: {},
  mobile: mobileInitialState,
  applyModeFromClipboardSuggestion: null,
  leftBar: leftBarInitialState,
  leftTree: leftTreeInitialState,
  wixAd: wixAdInitialState,
  domainSuggestions: domainSuggestionsInitialState,
  sectionedPanel: {
    openDockedSections: [],
    shouldShow: true,
  },
  secondaryLeftArea: secondaryLeftAreaInitialState,
  topBarPagesFilter: '',
  topBarCustomization: {
    tooltipOverrides: {
      UNDO: null,
      SAVE: null,
    },
  },
  pageSections: {
    sections: null,
    selectedSection: null,
  },
  siteScale: 1,
  pinMode: pinModeInitialState,
  hideTools: false,
  hideToolsAndMaintainStagePosition: false,
  shouldHideToolsBtnBlink: false,
  halfOpacityTools: false,
  unreadComments: 0,
  isOpenTextEditor: false,
  imageCrop: {
    isOn: false,
    cropArea: null,
    cropOriginArea: null,
    isEntering: false,
  },
  shouldOverlayUpdate: true,
  tabIndicationState: {
    indication: 'INACTIVE',
    activeMode: undefined,
  },
  floatingBubble: {
    value: null,
    targetLayout: null,
    bubbleProps: {},
    shouldNotHideOnMouseLeaveTarget: false,
  },
  notifications: notificationsInitialState,
  tpa: {
    appsIdsToRefreshOnDashboardClose: [],
    trackedAppsForUpgrade: {},
    settingsPanelExtraProps: {},
    shouldRefreshGeneralInfo: false,
  },
  scrollTo: null,
  animateScrollTo: null,
  eventCounters: {
    focus: 0,
    clearTextSelection: 0,
    forceOpenPagesQuickNavigation: 0,
    forceOpenPagesPanel: 0,
  },
  userPreferences: userPreferencesInitialState,
  introFunnel: introFunnelInitialState,
  dynamicPages: dynamicPagesInitialState,
  tpaDynamicPages: tpaDynamicPagesInitialState,
  pages: pagesInitialState,
  unifiedChat: unifiedChatInitialState,
  siteDisplayName: siteDisplayNameInitialState,
  multilingual: multilingualInitialState,
  platform: platformInitialState,
  applicationStudio: applicationStudioInitialState,
  __renderCounter: 0,
  viewerRendersCounter: 0,
  appsStore: appsStoreInitialState,
  rightClickMenu: rightClickMenuInitialState,
  welcomeScreen: welcomeScreenInitialState,
  externalLinks: {
    overridden: {},
  },
  savedComponents: savedComponentsInitialState,
  draggableSlots: draggableSlotsInitialState,
  relatedApps: relatedAppsInitialState,
  a11y: a11yInitialState,
  blog: blogInitialState,
  componentMetaData: componentMetaDataInitialState,
  interactions: interactionsInitialState,
  editorSearch: initialEditorSearchState,
  ascend: ascendInitialState,
  virtualNumbers: virtualNumbersInitialState,
  smartWriter: smartWriterInitialState,
  newReleases: newReleasesInitialState,
  helpHome: helpHomeInitialState,
  sections: sectionsInitialState,
  sectionsOnStage: sectionsOnStageInitialState,
  concurrentUsers: initialConcurrentUsersState,
};

export default initialEditorState;
