import { translate } from '#packages/i18n';

const TWEETER_TWEET_PUBLIC_URL = 'https://twitter.com/intent/tweet?url=';
const SHOUTOUT_APP_ID = '135c3d92-0fea-1f9d-2ba5-2a1dfb04297e';
const SHOUTOUT_TEMPLATE_ID_TO_OPEN = 'feedb3b8-0b1a-413f-bcab-73e805d0f554';

function openInNewTab(url: AnyFixMe) {
  window.open(url);
}

export default {
  openShoutout(metaSiteId: AnyFixMe) {
    const url = [
      '//www.wix.com/my-account/sites/',
      metaSiteId,
      '/app/',
      SHOUTOUT_APP_ID,
      '?referralInfo=ed_prem_promo',
      '&appState=%3Fdl%3Dmessage%26messageId%3D',
      SHOUTOUT_TEMPLATE_ID_TO_OPEN,
    ].join('');

    openInNewTab(url);
  },

  openTwitter(publicSiteUrl: AnyFixMe, isUserPremium: AnyFixMe) {
    function buildTwitterUrl(
      message: AnyFixMe,
      relatedTitle: AnyFixMe,
      hashtagsTitle: AnyFixMe,
    ) {
      message = decodeURIComponent(message);
      return `${
        TWEETER_TWEET_PUBLIC_URL + publicSiteUrl
      }&text=${message}&related=${relatedTitle}&hashtags=${hashtagsTitle}`;
    }

    const msg = isUserPremium
      ? translate('TWITTER_CHECK_OUT_MY_SITE_MSG_PREMIUM')
      : translate('TWITTER_CHECK_OUT_MY_SITE_MSG_NON_PREMIUM');

    const twitterURL = buildTwitterUrl(
      msg,
      translate('TWITTER_RELATED_WIX_ACCOUNTS'),
      translate('TWITTER_WIX_HASHTAGS'),
    );

    openInNewTab(twitterURL);
  },
};
