import type { EditorState } from '#packages/stateManagement';
import type { GridLinesType } from '#packages/constants';
import type { GridLine } from 'types/core';

const getGridLinesOverrides = (
  state: EditorState,
  { gridLinesType }: { gridLinesType: GridLinesType },
): GridLine[] => state.gridLines.overrides[gridLinesType];

export { getGridLinesOverrides };
