import type { EditorAPI } from '#packages/editorAPI';
import type { AfterPlugin } from 'types/editorPlugins';
import { updateComponentSource } from '../../componentSourceFeatureUtils';

const wRichTextAfterPlugin: AfterPlugin = (
  editorAPI: EditorAPI,
  { compRef, contentSource },
): void => {
  if (!contentSource) return;

  updateComponentSource(editorAPI, { compRef, source: contentSource });
};

export default wRichTextAfterPlugin;
