import * as actionTypes from './featuresOverriddenActionTypes';
import constants from '#packages/constants';

const disableConnectToData = () => ({
  type: actionTypes.DISABLE_FEATURE,
  featureName: constants.FEATURES.CONNECT_TO_DATA,
});
const disablePaste = () => ({
  type: actionTypes.DISABLE_FEATURE,
  featureName: constants.FEATURES.PASTE,
});
const enablePaste = () => ({
  type: actionTypes.ENABLE_FEATURE,
  featureName: constants.FEATURES.PASTE,
});

export { disableConnectToData, disablePaste, enablePaste };
