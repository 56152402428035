import type { EditorAPI } from '#packages/editorAPI';
import type { TpaInnerRouteObject } from '@wix/document-services-types';
import type { Dispatch } from 'types/redux';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import * as util from '#packages/util';
import * as actionTypes from './tpaDynamicPagesActionTypes';
import type {
  IgnoreSubPagesFeature,
  TpaInnerRouteData,
  TpaSubPagesInnerRoutes,
  SubPageData,
} from './tpaDynamicPagesReducer';
import * as selectors from './tpaDynamicPagesSelectors';
import type { EditorState } from '#packages/stateManagement';

type FetchedInnerRoutesCallback = (
  innerRoutes: TpaInnerRouteData[],
  relativeUrl?: string,
) => void;

const setCachedInnerRoutes = (
  appDefinitionId: string,
  pageId: string,
  subPage: string,
  innerRoutes: TpaInnerRouteData[],
) => ({
  type: actionTypes.SET_TPA_CACHED_INNER_ROUTES,
  appDefinitionId,
  pageId,
  subPage,
  innerRoutes,
});

const setCurrentInnerRoute = (tpaInnerRouteInfo: {
  appDefinitionId?: string;
  pageId?: string;
  selectedInnerRoute?: TpaInnerRouteData;
}) => ({
  type: actionTypes.SET_CURRENT_TPA_INNER_ROUTE,
  ...tpaInnerRouteInfo,
});

const setIsInFetchProcess = (inInnerRoutesFetchProcess: boolean) => ({
  type: actionTypes.SET_TPA_INNER_ROUTES_FETCH_PROCESS,
  inInnerRoutesFetchProcess,
});

const setCurrentPageSessionId = (tpaPageSessionId: string) => ({
  type: actionTypes.SET_TPA_PAGE_SESSION_ID,
  tpaPageSessionId,
});

const cacheFeaturesSubPagesToIgnore =
  (appDefinitionId: string, tpaSubPagesData: SubPageData[]) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const existingSubPagesToIgnore = selectors.getSubPagesToIgnore(
      getState(),
      appDefinitionId,
    );
    if (existingSubPagesToIgnore) {
      return existingSubPagesToIgnore;
    }
    const appManifest = editorAPI.platform.getAppManifest(appDefinitionId);
    const subPagesToIgnore = tpaSubPagesData.reduce(
      (acc, subPage) => {
        if (subPage.hideFromLinkPanel) {
          acc.linkPanel.push(subPage.key);
        }
        if (subPage.hideFromFloatingNavBar) {
          acc.dynamicPagesNavBar.push(subPage.key);
        }
        return acc;
      },
      {
        linkPanel: [],
        dynamicPagesNavBar: [],
        // @ts-expect-error
        ...(appManifest?.pages?.subPagesToHide ?? {}),
      },
    );

    dispatch({
      type: actionTypes.SET_FEATURES_SUB_PAGES_TO_IGNORE,
      appDefinitionId,
      subPagesToIgnore,
    });

    return subPagesToIgnore;
  };

const setDefaultInnerRouteIfNeeded =
  (innerRoutes: TpaInnerRouteData[], relativeUrl?: string) =>
  (dispatch: Dispatch, getState: () => EditorState) => {
    const { path, appDefinitionId, pageId } =
      selectors.getCurrentRouteInfo(getState());
    const allInnerRoutes = selectors.getAllSubPagesInnerRoutes(
      getState(),
      appDefinitionId,
      pageId,
    );
    if (path && allInnerRoutes.find((innerRoute) => innerRoute.path === path)) {
      return;
    }
    const innerRouteUrl = relativeUrl ?? innerRoutes?.[0]?.path;
    const shouldNavigateToInnerRoute = !relativeUrl;
    if (innerRouteUrl) {
      dispatch(setCurrentUrlToState(innerRouteUrl, shouldNavigateToInnerRoute));
    }
  };

const fetchCacheInnerRoutes =
  (
    appDefinitionId: string,
    pageId: string,
    tpaSubPages: string[],
    ignoredSubPagesInFeature: string[],
    callback?: FetchedInnerRoutesCallback,
    relativeUrl?: string,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    tpaSubPages.forEach((subPage) => {
      dispatch(setCachedInnerRoutes(appDefinitionId, pageId, subPage, null));
    });

    dispatch(setIsInFetchProcess(true));

    const innerRoutesForCallback: TpaSubPagesInnerRoutes = tpaSubPages.reduce(
      (obj, subPage) => {
        obj[subPage] = [];
        return obj;
      },
      {} as TpaSubPagesInnerRoutes,
    );

    const getInnerRoutesPromises = tpaSubPages.map(
      (subPage) =>
        new Promise<void>((resolve) => {
          editorAPI.tpa.getTpaInnerRoutes(
            appDefinitionId,
            subPage,
            (results: TpaInnerRouteObject[]) => {
              const innerRoutes = results?.map((innerRoute) => ({
                ...innerRoute,
                subPage,
              }));

              if (!ignoredSubPagesInFeature.includes(subPage)) {
                innerRoutesForCallback[subPage] = innerRoutes;
              }
              dispatch(
                setCachedInnerRoutes(
                  appDefinitionId,
                  pageId,
                  subPage,
                  innerRoutes,
                ),
              );
              resolve();
            },
          );
        }),
    );

    const getAllInnerRoutesPromise = Promise.all(getInnerRoutesPromises);
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    getAllInnerRoutesPromise.then(
      () =>
        callback?.(Object.values(innerRoutesForCallback).flat(1), relativeUrl),
    );

    return Promise.race([getAllInnerRoutesPromise, timeoutPromise]).then(() => {
      dispatch(setIsInFetchProcess(false));
    });
  };

const fetchCacheInnerRoutesIfTpaDynamicPage =
  (
    appDefinitionId: string,
    pageId: string,
    options: {
      feature?: IgnoreSubPagesFeature;
      callback?: FetchedInnerRoutesCallback;
      relativeUrl?: string;
    },
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ): void => {
    if (!appDefinitionId) {
      return;
    }

    const failedToFetchSubPages = selectors.getSubPagesFailedToFetch(
      getState(),
      appDefinitionId,
      pageId,
    );

    if (failedToFetchSubPages && failedToFetchSubPages.length === 0) {
      return;
    }
    let tpaSubpagesData: SubPageData[] = [];
    let tpaSubPagesKeys: string[];
    if (!failedToFetchSubPages) {
      tpaSubpagesData = editorAPI.tpa.page.getSubPages(pageId);
      tpaSubPagesKeys = tpaSubpagesData.map(({ key }: { key: string }) => key);
    }
    if (
      failedToFetchSubPages ||
      (tpaSubPagesKeys && tpaSubPagesKeys.length > 0)
    ) {
      const ignoredSubPagesInFeature =
        dispatch(
          cacheFeaturesSubPagesToIgnore(appDefinitionId, tpaSubpagesData),
        )?.[options.feature] ?? [];
      dispatch(
        fetchCacheInnerRoutes(
          appDefinitionId,
          pageId,
          failedToFetchSubPages ?? tpaSubPagesKeys,
          ignoredSubPagesInFeature,
          options.callback,
          options.relativeUrl,
        ),
      );
    }
  };

const adaptStateToCurrentPage =
  (feature: IgnoreSubPagesFeature, relativeUrl: string) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const currentTpaInfo = selectors.getCurrentRouteInfo(getState());
    const currentPageId = editorAPI.pages.getCurrentPageId();
    const appData = editorAPI.pages.data.get(currentPageId);
    const appDefinitionId =
      appData?.appDefinitionId ?? appData?.managingAppDefId;

    if (currentTpaInfo?.pageId !== currentPageId) {
      dispatch(
        setCurrentInnerRoute({ appDefinitionId, pageId: currentPageId }),
      );

      if (currentTpaInfo) {
        dispatch(setCurrentPageSessionId(util.guidUtils.getGUID()));
      }
    }

    const callback = (innerRoutes: TpaInnerRouteData[], relativeUrl?: string) =>
      dispatch(setDefaultInnerRouteIfNeeded(innerRoutes, relativeUrl));

    dispatch(
      fetchCacheInnerRoutesIfTpaDynamicPage(appDefinitionId, currentPageId, {
        feature,
        callback,
        relativeUrl,
      }),
    );

    dispatch(setCurrentUrlToState(relativeUrl));

    const lastUpdatedInfo = selectors.getCurrentRouteInfo(getState());
    if (!lastUpdatedInfo.path) {
      callback(
        selectors.getFocusedInnerRoutes(
          getState(),
          lastUpdatedInfo.appDefinitionId,
          lastUpdatedInfo.pageId,
          feature,
        ),
      );
    }
  };

const fetchPageInnerRoutesInState =
  (
    pageId: string,
    feature?: IgnoreSubPagesFeature,
    callback?: FetchedInnerRoutesCallback,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const appData = editorAPI.pages.data.get(pageId);
    const appDefinitionId =
      appData?.appDefinitionId ?? appData?.managingAppDefId;

    dispatch(
      fetchCacheInnerRoutesIfTpaDynamicPage(appDefinitionId, pageId, {
        feature,
        callback,
      }),
    );
  };

const clearInnerRoutesFromCache = (
  appDefinitionId: string,
  pageId: string,
) => ({
  type: actionTypes.RESET_TPA_CACHED_INNER_ROUTES,
  appDefinitionId,
  pageId,
});

const matchesGenericMemberUrl = (
  relativeUrl: string,
  innerRoutePath: string,
) => {
  const regexPattern = innerRoutePath.replace(/\/my\//, '/.*/');
  const regex = new RegExp(`^${regexPattern}$`);

  return regex.test(relativeUrl);
};

const findInnerRoute = (
  relativeUrl: string,
  innerRoutePath: string,
  appDefinitionId: string,
) =>
  appDefinitionId === MEMBERS_AREA_V2
    ? matchesGenericMemberUrl(relativeUrl, innerRoutePath)
    : innerRoutePath === relativeUrl;

const setCurrentUrlToState =
  (relativeUrl: string, shouldNavigateToRelativeUrl: boolean = false) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    if (!relativeUrl) {
      return;
    }
    const currentInfo = selectors.getCurrentRouteInfo(getState());
    if (currentInfo.path === relativeUrl) {
      return;
    }
    const { appDefinitionId, pageId } = currentInfo;

    const selectedInnerRoute = selectors
      .getAllSubPagesInnerRoutes(getState(), appDefinitionId, pageId)
      .find(({ path }) => findInnerRoute(relativeUrl, path, appDefinitionId));

    dispatch(setCurrentInnerRoute({ ...currentInfo, selectedInnerRoute }));

    if (shouldNavigateToRelativeUrl && selectedInnerRoute) {
      editorAPI.pages.navigateTo({
        innerRoute: `.${selectedInnerRoute.path}`,
        routerId: pageId,
        isTpaRoute: true,
        type: 'DynamicPageLink',
      });
    }
  };

export {
  adaptStateToCurrentPage,
  setCurrentUrlToState,
  clearInnerRoutesFromCache,
  fetchPageInnerRoutesInState,
};
