import {
  isFixedItemLayout,
  isFullWidthByLayouts,
  isMeshItemLayout,
} from '#packages/layoutUtils';
import type {
  Alignment as GridAlignment,
  Docking,
  FixedItemLayout,
  Dock,
  UnitSize,
  SingleLayoutData,
  LayoutSize,
} from 'types/documentServices';

function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function getDockingDockFromLayoutSize(lzyoutSize: LayoutSize): Dock {
  if (!lzyoutSize) {
    return undefined;
  }

  switch (lzyoutSize.type) {
    case 'px':
      return { px: lzyoutSize.value };
    case 'vw':
      return { vw: lzyoutSize.value };
    case 'percentage':
      return { pct: lzyoutSize.value };
  }

  throw new Error(`Unsupported layout size type: ${lzyoutSize.type}`);
}

export function getLayoutSizeFromDokingDock(
  dock: Dock & { pct?: number },
  isAbsolute: boolean = false,
): UnitSize | undefined {
  if (!dock) {
    return undefined;
  }

  if (isNumber(dock.px)) {
    return { type: 'px', value: isAbsolute ? Math.abs(dock.px) : dock.px };
  }

  if (isNumber(dock.vw)) {
    return { type: 'vw', value: dock.vw };
  }

  if (isNumber(dock.pct)) {
    return { type: 'percentage', value: dock.pct };
  }
}

const dockToUnitSize = getLayoutSizeFromDokingDock;
const unitSizeToDock = getDockingDockFromLayoutSize;

/**
 * Code is same as in the migration in DS
 * https://github.com/wix-private/document-management/blob/ea0921531ce881e8cc6ad64d7ea2d3a03a259789/document-manager-utils/src/meshMigration/plugins/common.ts#L156
 */
export const createFixedItemLayoutFromDocking = (
  docking: Docking,
): Omit<FixedItemLayout, 'id'> => {
  let alignSelf: GridAlignment;
  let justifySelf: GridAlignment;
  let top: UnitSize;
  let bottom: UnitSize;
  let left: UnitSize;
  let right: UnitSize;

  // eslint-disable-next-line prefer-const
  ({ alignSelf, top, bottom } = ((): {
    alignSelf: GridAlignment;
    top?: UnitSize;
    bottom?: UnitSize;
  } => {
    if (docking.vCenter) {
      return docking.vCenter.px! < 0
        ? {
            alignSelf: 'center',
            bottom: dockToUnitSize(docking.vCenter, true),
          }
        : {
            alignSelf: 'center',
            top: dockToUnitSize(docking.vCenter),
          };
    }

    if (docking.bottom && !docking.top) {
      return {
        alignSelf: 'end',
        bottom: dockToUnitSize(docking.bottom),
      };
    }

    return {
      alignSelf: 'start',
      top: dockToUnitSize(docking.top),
      bottom: dockToUnitSize(docking.bottom),
    };
  })());

  // eslint-disable-next-line prefer-const
  ({ justifySelf, left, right } = ((): {
    justifySelf: GridAlignment;
    left?: UnitSize;
    right?: UnitSize;
  } => {
    if (docking.hCenter) {
      return docking.hCenter.px! < 0
        ? {
            justifySelf: 'center',
            right: dockToUnitSize(docking.hCenter, true),
          }
        : {
            justifySelf: 'center',
            left: dockToUnitSize(docking.hCenter),
          };
    }

    if (docking.right && !docking.left) {
      return {
        justifySelf: 'end',
        right: dockToUnitSize(docking.right),
      };
    }

    return {
      justifySelf: 'start',
      left: dockToUnitSize(docking.left),
      right: dockToUnitSize(docking.right),
    };
  })());

  const margins: FixedItemLayout['margins'] = {
    left,
    right,
    top,
    bottom,
  };

  (Object.keys(margins) as Array<keyof FixedItemLayout['margins']>).forEach(
    (key) => {
      // cleanup undefined values
      if (margins[key] === undefined) {
        delete margins[key];
      }
    },
  );

  return {
    type: 'FixedItemLayout',
    alignSelf,
    justifySelf,
    margins,
  };
};

/**
 * Code is same as in the reverse migration in DS
 * https://github.com/wix-private/document-management/blob/ea0921531ce881e8cc6ad64d7ea2d3a03a259789/document-manager-utils/src/meshMigration/plugins/reverseMeshMigration.ts#L78
 */
export const createDockingFromFixedItemLayout = (
  itemLayout: FixedItemLayout,
): Docking => {
  let vCenter: Dock;
  let hCenter: Dock;
  let left: Dock;
  let right: Dock;
  let top: Dock;
  let bottom: Dock;

  // eslint-disable-next-line prefer-const
  ({ vCenter, top, bottom } = ((): Docking => {
    switch (itemLayout.alignSelf) {
      case 'end':
        return {
          bottom: unitSizeToDock(itemLayout.margins.bottom),
        };
      case 'center':
        return {
          vCenter: unitSizeToDock(
            itemLayout.margins.bottom ?? itemLayout.margins.top,
          ),
        };
      default:
        return {
          top: unitSizeToDock(itemLayout.margins.top),
          bottom: unitSizeToDock(itemLayout.margins.bottom),
        };
    }
  })());

  // eslint-disable-next-line prefer-const
  ({ hCenter, left, right } = ((): Docking => {
    switch (itemLayout.justifySelf) {
      case 'end':
        return {
          right: unitSizeToDock(itemLayout.margins.right),
        };
      case 'center':
        return {
          hCenter: unitSizeToDock(
            itemLayout.margins.right ?? itemLayout.margins.left,
          ),
        };
      default:
        return {
          left: unitSizeToDock(itemLayout.margins.left),
          right: unitSizeToDock(itemLayout.margins.right),
        };
    }
  })());

  const docking: Docking = {
    left,
    right,
    top,
    bottom,
    vCenter,
    hCenter,
  };

  (Object.keys(docking) as Array<keyof Docking>).forEach((key) => {
    // cleanup undefined values
    if (docking[key] === undefined) {
      delete docking[key];
    }
  });

  return docking;
};

function createDockingFromMeshItemLayout(layouts: SingleLayoutData) {
  if (isFullWidthByLayouts(layouts) && isMeshItemLayout(layouts.itemLayout)) {
    return {
      // TODO: left & right were removed from "MeshItemLayout" - https://github.com/wix-private/document-management/pull/24507
      // @ts-expect-error
      left: unitSizeToDock(layouts.itemLayout.left),
      // @ts-expect-error
      right: unitSizeToDock(layouts.itemLayout.right),
    };
  }
}

/**
 * Code is same as in the reverse migration in DS
 * https://github.com/wix-private/document-management/blob/ea0921531ce881e8cc6ad64d7ea2d3a03a259789/document-manager-utils/src/meshMigration/plugins/reverseMeshMigration.ts#L78
 */
export const createDockingFromResponsiveLayouts = (
  layouts: SingleLayoutData | undefined,
): Docking => {
  if (!layouts) {
    return undefined;
  }

  if (isFixedItemLayout(layouts.itemLayout)) {
    return createDockingFromFixedItemLayout(layouts.itemLayout);
  }

  if (isMeshItemLayout(layouts.itemLayout)) {
    return createDockingFromMeshItemLayout(layouts);
  }
};
