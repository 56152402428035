import type { PageRef } from 'types/documentServices';
import type { EditorState } from '#packages/stateManagement';

const getFocusedPage = (state: EditorState): PageRef => state.pages.focusedPage;

const getFocusedPageId = (state: EditorState): string =>
  getFocusedPage(state)?.id;

const getFocusedPageTitle = (state: EditorState): string =>
  state.pages.focusedPageTitle;

const getIsEditorLoadedWithMoreThanMaxStaticPages = (
  state: EditorState,
): boolean => state.pages.isEditorLoadedWithMoreThanMaxStaticPages;

const getEditingMenuItemId = (state: EditorState): string =>
  state.pages.editingId;

export {
  getFocusedPage,
  getFocusedPageId,
  getFocusedPageTitle,
  getIsEditorLoadedWithMoreThanMaxStaticPages,
  getEditingMenuItemId,
};
