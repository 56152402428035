import _ from 'lodash';
import constraintsUtil from './constraintsUtil';
import { components, selection } from '#packages/stateManagement';
import constants from '#packages/constants';
import { array as arrayUtils, sections as sectionsUtils } from '#packages/util';
import { utils } from '#packages/core';

import type { LayoutConstraint, LayoutContstraintBoundaries } from '../types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const MAXIMUM_Y_COMP_ON_PAGE = 500000;

enum SEGMENT_SELECTORS {
  SITE_STRUCTURE = 'getSiteStructure',
  HEADER = 'getHeader',
  FOOTER = 'getFooter',
}

const { MOBILE_DRAG_CONSTRAINT_MARGINS } = constants.SIZE_LIMITS;

function getRelativeLayoutOfSegment(
  editorAPI: EditorAPI,
  segmentSelector: SEGMENT_SELECTORS,
) {
  const segment = editorAPI.siteSegments[segmentSelector]();
  return editorAPI.components.layout.getRelativeToScreen(segment);
}

function getSegmentAreaConstraints(
  siteStructureLayout: AnyFixMe,
  segmentLayout: AnyFixMe,
) {
  return {
    left: siteStructureLayout.x,
    width: siteStructureLayout.width,
    height: siteStructureLayout.height - segmentLayout.height,
  };
}

function getHeaderSpecificAreaConstraint(
  editorAPI: EditorAPI,
  siteStructureLayout: AnyFixMe,
) {
  const headerLayout = getRelativeLayoutOfSegment(
    editorAPI,
    SEGMENT_SELECTORS.HEADER,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    getSegmentAreaConstraints(siteStructureLayout, headerLayout),
    {
      top: siteStructureLayout.y + headerLayout.height,
    },
  );
}

function getSiteRegionContainerSpecificAreaConstraint(
  editorAPI: EditorAPI,
  siteStructureLayout: AnyFixMe,
  containerRef: CompRef,
) {
  const headerLayout = getRelativeLayoutOfSegment(
    editorAPI,
    SEGMENT_SELECTORS.HEADER,
  );
  const headerArea = {
    left: headerLayout.x,
    width: headerLayout.width,
    height: headerLayout.height,
    top: headerLayout.y,
  };
  const siteRegionContainerLayout =
    editorAPI.components.layout.getRelativeToScreen(containerRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const siteRegionContainerConstraintArea = _.assign(
    getSegmentAreaConstraints(siteStructureLayout, siteRegionContainerLayout),
    {
      top:
        siteStructureLayout.y +
        siteRegionContainerLayout.height +
        siteRegionContainerLayout.y,
    },
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/concat
  return _.concat(headerArea, siteRegionContainerConstraintArea);
}

function getFooterSpecificAreaConstraint(
  editorAPI: EditorAPI,
  siteStructureLayout: AnyFixMe,
) {
  const footerLayout = getRelativeLayoutOfSegment(
    editorAPI,
    SEGMENT_SELECTORS.FOOTER,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    getSegmentAreaConstraints(siteStructureLayout, footerLayout),
    {
      bottom: siteStructureLayout.y + footerLayout.y,
    },
  );
}

function isSiteRegionContainer(editorAPI: EditorAPI, ref: CompRef) {
  return (
    components.selectors.getCompTypeSuffix(ref, editorAPI.dsRead) ===
    'SiteRegionContainer'
  );
}

const MENU_CONTAINER_TYPE = 'wysiwyg.viewer.components.MenuContainer';

function isMenuContainer(editorAPI: EditorAPI, ref: CompRef) {
  return editorAPI.components.getType(ref) === MENU_CONTAINER_TYPE;
}

function isMenuContainerFocused(editorAPI: EditorAPI) {
  const focusedContainer = selection.selectors.getFocusedContainer(
    editorAPI.store.getState(),
  );
  return isMenuContainer(editorAPI, focusedContainer);
}

function getSiteRegionContainerBoundaries(
  editorAPI: EditorAPI,
  containerRef: CompRef,
): LayoutContstraintBoundaries {
  const siteRegionContainerLayout =
    editorAPI.components.layout.getRelativeToScreen(containerRef);

  return {
    top: {
      compTopY: siteRegionContainerLayout.y,
    },
    bottom: {
      compBottomY:
        siteRegionContainerLayout.y + siteRegionContainerLayout.height,
    },
    left: {
      compLeftX: siteRegionContainerLayout.x,
    },
    right: {
      compRightX: siteRegionContainerLayout.x + siteRegionContainerLayout.width,
    },
  };
}

function getDefaultComponentBoundaries(
  editorAPI: EditorAPI,
): LayoutContstraintBoundaries {
  const siteStructureLayout = getRelativeLayoutOfSegment(
    editorAPI,
    SEGMENT_SELECTORS.SITE_STRUCTURE,
  );
  return {
    top: { compBottomY: MOBILE_DRAG_CONSTRAINT_MARGINS },
    bottom: {},
    left: {
      compRightX: siteStructureLayout.x + MOBILE_DRAG_CONSTRAINT_MARGINS,
    },
    right: {
      compLeftX:
        siteStructureLayout.x +
        siteStructureLayout.width -
        MOBILE_DRAG_CONSTRAINT_MARGINS,
    },
  };
}

const mobileDefaultConstraints: LayoutConstraint = {
  getBoundaries(editorAPI: EditorAPI, compRef: CompRef | CompRef[]) {
    const { mobileUtil } = utils;
    const containerRef = editorAPI.components.getContainer(compRef);

    if (isSiteRegionContainer(editorAPI, containerRef)) {
      return getSiteRegionContainerBoundaries(editorAPI, containerRef);
    }

    const boundaries = getDefaultComponentBoundaries(editorAPI);

    const isLandingPage = editorAPI.isCurrentPageLandingPage();
    const pageLayout = editorAPI.components.layout.getRelativeToScreen(
      editorAPI.pages.getCurrentPage(),
    );

    const isMobileOnlyComponent: boolean =
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        arrayUtils.asArray(compRef)[0],
      );

    const isPageComponent = !constraintsUtil.isShownOnAllPagesComponent(
      editorAPI,
      compRef,
    );

    if (isPageComponent) {
      if (
        !sectionsUtils.isSectionsEnabled() ||
        editorAPI.pages.popupPages.isPopupOpened()
      ) {
        Object.assign(boundaries.bottom, {
          compBottomY: MAXIMUM_Y_COMP_ON_PAGE,
        });

        return boundaries;
      }

      const footerLayout = getRelativeLayoutOfSegment(
        editorAPI,
        SEGMENT_SELECTORS.FOOTER,
      );
      const footerBottom = footerLayout.y + footerLayout.height;

      if (isMobileOnlyComponent) {
        return {
          ...boundaries,
          bottom: {
            compBottomY: isLandingPage ? pageLayout.height : footerBottom,
          },
        };
      }

      return _.merge(boundaries, {
        top: {
          compBottomY: pageLayout.y + MOBILE_DRAG_CONSTRAINT_MARGINS,
        },
        bottom: {
          compTopY:
            pageLayout.y + pageLayout.height - MOBILE_DRAG_CONSTRAINT_MARGINS,
        },
      });
    }

    const footerLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.FOOTER,
    );
    const footerBottom = footerLayout.y + footerLayout.height;

    if (!isMenuContainerFocused(editorAPI)) {
      Object.assign(boundaries.bottom, {
        compBottomY: footerBottom,
      });
    }

    if (isMobileOnlyComponent) {
      return boundaries;
    }

    if (mobileUtil.isContainedByHeaderOrFooter(editorAPI, compRef, true)) {
      const headerLayout = getRelativeLayoutOfSegment(
        editorAPI,
        SEGMENT_SELECTORS.HEADER,
      );
      Object.assign(boundaries.bottom, { compBottomY: headerLayout.height });
    }

    if (mobileUtil.isContainedByHeaderOrFooter(editorAPI, compRef, false)) {
      Object.assign(boundaries.top, { compTopY: footerLayout.y });
    }

    return boundaries;
  },

  getConstraintArea(editorAPI: EditorAPI, compRef: CompRef) {
    const parentSegment = editorAPI.components.getContainer(compRef);
    const siteStructureLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.SITE_STRUCTURE,
    );

    if (isSiteRegionContainer(editorAPI, parentSegment)) {
      return getSiteRegionContainerSpecificAreaConstraint(
        editorAPI,
        siteStructureLayout,
        parentSegment,
      );
    }

    if (!constraintsUtil.isShownOnAllPagesComponent(editorAPI, compRef)) {
      return null;
    }
    if (editorAPI.sections.isHeader(parentSegment)) {
      return getHeaderSpecificAreaConstraint(editorAPI, siteStructureLayout);
    }
    if (editorAPI.sections.isFooter(parentSegment)) {
      return getFooterSpecificAreaConstraint(editorAPI, siteStructureLayout);
    }
    return null;
  },
};

const menuConstraints = {
  getBoundaries(editorAPI: EditorAPI, _compPointer: CompRef) {
    const siteStructureLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.SITE_STRUCTURE,
    );
    const headerLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.HEADER,
    );
    return {
      top: {
        compTopY: siteStructureLayout.y,
      },
      bottom: {
        compBottomY: headerLayout
          ? Math.min(
              headerLayout.height,
              constants.SIZE_LIMITS.MOBILE_MENU_MAX_Y,
            )
          : constants.SIZE_LIMITS.MOBILE_MENU_MAX_Y,
      },
      left: {
        compLeftX: siteStructureLayout.x,
      },
      right: {
        compRightX: siteStructureLayout.x + siteStructureLayout.width,
      },
    };
  },

  getConstraintArea(editorAPI: EditorAPI, _compPointer: CompRef) {
    const siteStructureLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.SITE_STRUCTURE,
    );
    const headerLayout = getRelativeLayoutOfSegment(
      editorAPI,
      SEGMENT_SELECTORS.HEADER,
    );
    const constraintArea = getHeaderSpecificAreaConstraint(
      editorAPI,
      siteStructureLayout,
    );
    Object.assign(constraintArea, {
      top:
        siteStructureLayout.y +
        Math.min(constants.SIZE_LIMITS.MOBILE_MENU_MAX_Y, headerLayout.height),
    });
    return constraintArea;
  },
};

const constraintsForComponentType: Record<string, LayoutConstraint> = {
  'wysiwyg.viewer.components.HeaderContainer': {
    getBoundaries(editorAPI, compRef) {
      const children = editorAPI.dsRead.components.getChildren(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
        _.isArray(compRef) ? compRef[0] : compRef,
      );
      const boundries = getDefaultComponentBoundaries(editorAPI);
      return _.isEmpty(children)
        ? boundries
        : // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(boundries, {
            top: {
              compBottomY: constants.SIZE_LIMITS.MOBILE_HEADER_MIN_HEIGHT,
            },
          });
    },
    getConstraintArea() {
      return null;
    },
  },
  'wysiwyg.viewer.components.mobile.TinyMenu': menuConstraints,
  'wysiwyg.viewer.components.MenuToggle': menuConstraints,
  'wysiwyg.viewer.components.BackToTopButton': {
    getBoundaries() {
      return {};
    },
    getConstraintArea() {
      return null;
    },
  },
};

function getConstraints(editorAPI: EditorAPI, compPointer: CompRef) {
  const compType = editorAPI.components.getType(compPointer);
  return constraintsForComponentType[compType] || mobileDefaultConstraints;
}

const constraint: LayoutConstraint = {
  shouldConstrain(editorAPI) {
    return editorAPI.isMobileEditor();
  },
  getConstraintArea(editorAPI, compPointer) {
    const constraints = getConstraints(editorAPI, compPointer);
    return constraints.getConstraintArea(editorAPI, compPointer);
  },
  getBoundaries(editorAPI, compPointer) {
    const constraints = getConstraints(editorAPI, compPointer);
    return constraints.getBoundaries(editorAPI, compPointer);
  },
};

export default constraint;
