import { isResponsiveEditor } from '@wix/santa-editor-utils';
import * as util from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';

function createNotificationSoundPlayer(src: string) {
  const audio = new window.Audio(src);

  function handlePlayError(error: AnyFixMe) {
    ErrorReporter.captureException(
      new Error(
        `NotificationSoundPlayError\n  - Original error: ${error.message}`,
      ),
    );
  }

  // NOTE: workaround for the autoplay policies of browsers https://jira.wixpress.com/browse/ED-379
  document.body.addEventListener(
    'click',
    () => {
      audio.volume = 0;
      audio.play()?.catch(handlePlayError);
    },
    { once: true },
  );

  function play() {
    audio.currentTime = 0;
    audio.volume = 1;
    // NOTE: some envs does not return promise from the `play()`
    audio.play()?.catch(handlePlayError);
  }

  return {
    play,
  };
}

export function notificationSoundPlayerFactory() {
  if (isResponsiveEditor()) {
    return {
      play: () => {},
    };
  }

  const url = util.media.getMediaUrl('notifications/sounds/Note06.mp3');
  return createNotificationSoundPlayer(url);
}
