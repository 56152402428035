// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

export default {
  linguistLanguageUrl: `${util.serviceTopology.linguistUrl}/languages`,
  multilingualServerless: `/_serverless/multilingual-languages/languages`,
  siteTranslatablesProperties: `/_api/site-translator/v2/properties`,
  machineTranslationLimit: '/_serverless/google-limitation//v1/properties',
  apiMethods: _.mapKeys(['POST', 'PUT', 'GET']),
  dataLoadTypes: _.mapKeys([
    'LOAD_LANGUAGES',
    'CHECK_LANGUAGE_STATUS',
    'INITIALIZE_LANGUAGES',
    'ADD_LANGUAGE',
    'UPDATE_LANGUAGE_STATUS',
    'UPDATE_LANGUAGE',
  ]),
};
