import ascendConstants from './constants';
import { isOnEditorX } from '@wix/santa-editor-utils';
import type { EditorState } from '#packages/stateManagement';
import experiment from 'experiment';

const getAscendPlan = (state: EditorState): string => state.ascend.ascendPlan;
const getPremiumPlan = (state: EditorState): string => state.ascend.premiumPlan;
const getIsTopPremium = (state: EditorState): boolean =>
  state.ascend.isTopPremium;
const getRemoveAscendFeatures = (state: EditorState): boolean =>
  state.ascend.removeAscendFeatures;
const getFetchStatus = (
  state: EditorState,
): ValueOf<typeof ascendConstants.ASCEND_FETCH_STATUS> =>
  state.ascend.fetchStatus;
const getAscendProductId = (state: EditorState): string =>
  state.ascend.ascendProductId;
const isFetchSucceed = (state: EditorState): boolean =>
  state.ascend.fetchStatus === ascendConstants.ASCEND_FETCH_STATUS.SUCCESS;
const getAscendUrl = ({ msid, origin, slug }: AnyFixMe): string => {
  const domain = isOnEditorX() ? 'editorx' : 'wix';
  const isNewPackagePicker = experiment.isOpen(
    'specs.ascend.AccessPackagePicker',
  );
  const queryParam = `?metaSiteId=${msid}&pp_origin=${origin}&originAppSlug=${slug}`;
  return isNewPackagePicker
    ? `https://www.${domain}.com/ascend-package-picker${queryParam}`
    : `https://www.${domain}.com/ascend/plans${queryParam}`;
};

export default {
  getRemoveAscendFeatures,
  getAscendPlan,
  getIsTopPremium,
  getFetchStatus,
  getPremiumPlan,
  getAscendProductId,
  isFetchSucceed,
  getAscendUrl,
};
