import addComponentPlugins from './addComponentPlugins/addComponentPlugins';
import * as updateDataPlugins from './updateDataPlugins/updateDataPlugins';
import structureEnhancersPlugins from './structureEnhancersPlugins/structureEnhancersPlugins';
import { interactions, notifications } from '#packages/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompStructure, DSAction } from 'types/documentServices';
import constants from '#packages/constants';

const PASTE_BLACKLIST = new Set<string>(['wysiwyg.viewer.components.HoverBox']);
const PASTE_BLACKLIST_ERROR =
  "The duplicated Hover Box you are trying to add, can't be added at this time. You can add a new Hover Box from the Add Panel.";

function addComponentUnderInteractionCallback(
  editorAPI: EditorAPI,
  compRef: CompRef,
  interactionContainerRef: CompRef,
  state: AnyFixMe,
) {
  const variantsPointer = interactions.selectors.getVariantPointers(state);
  const compRefWithVariants = editorAPI.components.variants.getPointer(
    compRef,
    variantsPointer,
  );
  editorAPI.documentServices.components.transformations.update(compRef, {
    hidden: true,
  });
  editorAPI.documentServices.components.transformations.update(
    compRefWithVariants,
    { hidden: false },
  );
  interactions.actions.setDefaultTransition(editorAPI, compRef);
  ErrorReporter.breadcrumb(
    `Added ${editorAPI.components.getType(compRef)} to interaction`,
  );
}

export type AddComponentResult =
  | CompRef
  | null
  | {
      hooks: {
        waitForCompRef: Promise<CompRef>;
        waitForChangesApplied: Promise<CompRef>;
      };
    };

function proposeArrangementIndexForSection(
  editorAPI: EditorAPI,
  _compDef: CompStructure,
) {
  const meshLayoutApi = editorAPI.components.layout.__mesh;
  if (!meshLayoutApi.isEnabled()) {
    return;
  }

  const focusedSectionLikeRef = editorAPI.sections.getFocusedSectionLike();
  if (!focusedSectionLikeRef) {
    return;
  }

  if (editorAPI.sections.isHeader(focusedSectionLikeRef)) {
    return 0;
  }

  if (editorAPI.sections.isSection(focusedSectionLikeRef)) {
    return (
      editorAPI.components.arrangement.getCompIndex(focusedSectionLikeRef) + 1
    );
  }
}

function proposeArrangementIndex(editorAPI: EditorAPI, compDef: CompStructure) {
  if (editorAPI.sections.isSectionByCompType(compDef.componentType)) {
    return proposeArrangementIndexForSection(editorAPI, compDef);
  }

  // NOTE: we don't have arrangement index propositions by default
}

const addComponent = function (
  editorAPI: EditorAPI,
  {
    originalAddComponentFunc,
    containerRef,
    compDef,
    optionalId,
    onCompAddCallback,
    origin,
    optionalIndex,
    contentSource,
    appDefinitionId,
  }: {
    originalAddComponentFunc: DSAction['components']['add'];
    containerRef: CompRef;
    compDef: CompStructure;
    optionalId?: string;
    onCompAddCallback?: (compRef: CompRef) => void;
    origin?: string;
    optionalIndex?: number;
    contentSource?: string;
    appDefinitionId?: string;
  },
): AddComponentResult {
  const dsActions = editorAPI.dsActions;
  const meshLayoutApi = editorAPI.components.layout.__mesh;

  const compType = compDef.componentType;
  const compDataType = compDef.data?.type;

  // This is temporary fix until TB can work with the blacklisted components
  if (PASTE_BLACKLIST.has(compType) && process.env.NODE_ENV !== 'test') {
    editorAPI.store.dispatch(
      notifications.actions.showWarningNotification({
        message: PASTE_BLACKLIST_ERROR,
      }),
    );
    return null;
  }

  if (meshLayoutApi.isEnabled() && !compDef.layout) {
    throw new Error(
      `'compDef.layout' is required for adding a component under mesh layout`,
    );
  }

  if (updateDataPlugins[compDataType as keyof typeof updateDataPlugins]) {
    compDef.data = updateDataPlugins[
      compDataType as keyof typeof updateDataPlugins
    ](compDef.data);
  }

  if (
    util.sections.shouldUseSectionInsteadOfPageAsContainer(
      editorAPI,
      containerRef,
      compDef,
    )
  ) {
    if (editorAPI.pages.getCurrentPageId() === containerRef.id) {
      containerRef = util.sections.getSectionContainerToPaste(editorAPI, [
        compDef,
      ]);
    } else {
      const [firstSection] = editorAPI.sections.getPageSections(containerRef);
      // no need to do anything if there are no sections since enforcement would wrap all comps before navigation
      if (firstSection) {
        containerRef = firstSection;
      }
    }
  }

  structureEnhancersPlugins.forEach((structureEnhancersPlugin) => {
    structureEnhancersPlugin(editorAPI, compDef, appDefinitionId);
  });

  const addComponentTransaction = () => {
    const addComponentPluginBefore = addComponentPlugins.before[compType];
    const addComponentPluginAfter = addComponentPlugins.after[compType];

    const addComponentPluginBeforeResult = addComponentPluginBefore?.(
      editorAPI,
      originalAddComponentFunc,
      containerRef,
      compDef,
      optionalId,
      onCompAddCallback,
      origin,
    );

    const { compRef, onChangesApplied } = addComponentPluginBeforeResult
      ? addComponentPluginBeforeResult
      : {
          compRef: originalAddComponentFunc(
            containerRef,
            compDef,
            optionalId,
            optionalIndex ?? proposeArrangementIndex(editorAPI, compDef),
          ),
          onChangesApplied: onCompAddCallback,
        };

    return {
      compRef,
      onChangesApplied:
        compRef && (onChangesApplied || addComponentPluginAfter)
          ? () => {
              onChangesApplied?.(compRef);
              addComponentPluginAfter?.(editorAPI, {
                compRef,
                origin,
                contentSource,
              });
            }
          : null,
    };
  };

  // NOTE: prepare for wrapping add in async `dsActions.transactions.run`
  try {
    const result = addComponentTransaction();

    if (meshLayoutApi.isEnabled() && !result.compRef) {
      // eslint-disable-next-line no-console
      console.warn(
        `addUtils.add: Failed to add component of type ${compDef.type} to container ${containerRef.id}`,
      );
    }

    const transactionPromise = Promise.resolve(result);

    const waitForCompRef = transactionPromise.then(({ compRef }) => compRef);
    const waitForChangesApplied = transactionPromise.then(
      async ({ compRef, onChangesApplied }) => {
        await dsActions.waitForChangesAppliedAsync();

        if (typeof onChangesApplied === 'function') {
          onChangesApplied();
        }

        return compRef;
      },
    );

    return {
      hooks: {
        waitForCompRef,
        waitForChangesApplied,
      },
    };
  } catch (error: MaybeError) {
    const wrappedError = new Error(`Add component failed:\n${error.message}`, {
      cause: error,
    });
    return {
      hooks: {
        waitForCompRef: Promise.reject(wrappedError),
        waitForChangesApplied: Promise.reject(wrappedError),
      },
    };
  }
};

const overrideChildrenComponentsDataRecursively = (
  childrenComponents: CompStructure['components'],
) => {
  if (!childrenComponents || childrenComponents.length === 0) {
    return;
  }

  childrenComponents.forEach((childComponent) => {
    if (typeof childComponent !== 'string') {
      if (childComponent.componentType === constants.COMP_TYPES.PHOTO) {
        Object.assign(childComponent.props, { autoFill: true });
      }
      overrideChildrenComponentsDataRecursively(childComponent.components);
    }
  });
};

const componentsWithPlugins = Object.keys(addComponentPlugins);

export {
  addComponent,
  addComponentUnderInteractionCallback,
  overrideChildrenComponentsDataRecursively,
  componentsWithPlugins,
};
