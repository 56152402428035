import _ from 'lodash';
import constants from '#packages/constants';
import { sections } from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { AfterPlugin } from 'types/editorPlugins';

const shouldResetCompLayout = (editorAPI: EditorAPI, compRef: CompRef) => {
  if (!sections.isSectionsEnabled()) return true;

  const container = editorAPI.components.getContainer(compRef);
  const isHeader = editorAPI.utils.isSameRef(
    container,
    editorAPI.siteSegments.getHeader(),
  );
  const isFooter = editorAPI.utils.isSameRef(
    container,
    editorAPI.siteSegments.getFooter(),
  );

  if (isHeader || isFooter) {
    return false;
  }

  return true;
};

const vectorImageAfterPlugin: AfterPlugin = (editorAPI, { compRef }) => {
  const data = editorAPI.components.data.get(compRef);
  const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(data.svgId);
  const svgType = svgInfo?.svgType;

  if (shouldResetCompLayout(editorAPI, compRef)) {
    const newShapeAspectRatio =
      editorAPI.media.vectorImage.getSvgOriginalAspectRatio(compRef);
    editorAPI.components.layout.resetCompLayoutToAspectRatio(
      compRef,
      newShapeAspectRatio,
      true,
    );
  }

  // "shape" types have 8 resize nodes
  if (svgType === 'shape') {
    editorAPI.components.properties.update(compRef, { displayMode: 'stretch' });
  }

  // "shape" and "tint" types need to have a default color set in design data
  if (['shape', 'tint'].includes(svgType)) {
    const design = editorAPI.components.design.get(compRef);
    const overrideColors = design?.overrideColors;
    if (_.isEmpty(overrideColors)) {
      const { color1 } =
        constants.VECTOR_IMAGE_DEFAULTS.VECTOR_SHAPE_COLOR_DEFAULT;
      const newDesign = Object.assign({}, design, {
        overrideColors: { color1 },
      });
      editorAPI.components.design.update(compRef, newDesign, true, true);
    }
  }
};

export default vectorImageAfterPlugin;
