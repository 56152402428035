// @ts-nocheck
import _ from 'lodash';

import type { EditorAPI } from '#packages/editorAPI';

function getRouterByPage(editorAPI, pageData) {
  const pageRef =
    pageData?.id && editorAPI.dsRead.pages.getReference(pageData.id);
  return pageRef && editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
}

function isDynamicPage(editorAPI, pageData) {
  return _.isObject(getRouterByPage(editorAPI, pageData));
}

function getExtendedDynamicPageInfo(editorAPI: EditorAPI, pageId: string) {
  const pageData = editorAPI.pages.data.get(pageId);
  const isMultiSectionCompOnPage =
    editorAPI.pages.isPageContainsTPAMultiSectionComp(pageId);
  const hasRouterData = isDynamicPage(editorAPI, pageData);

  return {
    hasRouterData,
    isMultiSectionCompOnPage,
  };
}

export { isDynamicPage, getExtendedDynamicPageInfo };
