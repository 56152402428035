import { hasFixedItemLayout } from '#packages/layoutUtils';
import { responsiveLayoutGet } from '../responsiveLayout';
import type { DSRead, CompRef } from 'types/documentServices';

export function layoutIsShowOnFixedPosition(
  dsRead: DSRead,
  compRef: CompRef,
): boolean {
  const layouts = responsiveLayoutGet(dsRead, compRef);
  return layouts && hasFixedItemLayout(layouts);
}
