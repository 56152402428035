import _ from 'lodash';
import type { LayoutContstraintBoundaries } from './types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';

const LAYOUT_PROPERTIES = ['x', 'y', 'width', 'height'] as const;

type ChangedPropertiesSet = Set<(typeof LAYOUT_PROPERTIES)[number]>;

function applyLeftBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  const compX = newLayout.x;
  const compRightX = newLayout.x + newLayout.width;
  const leftConstraint = boundaries.left;

  if (leftConstraint) {
    if (_.isNumber(leftConstraint.compLeftX)) {
      if (changedProperties.has('x')) {
        if (compX < leftConstraint.compLeftX) {
          newLayout.x += leftConstraint.compLeftX - compX;
        }
      }

      if (changedProperties.has('width')) {
        if (compX < leftConstraint.compLeftX) {
          newLayout.width -= leftConstraint.compLeftX - compX;
        }
      }
    }

    if (_.isNumber(leftConstraint.compRightX)) {
      if (changedProperties.has('x')) {
        if (compRightX < leftConstraint.compRightX) {
          newLayout.x += leftConstraint.compRightX - compRightX;
        }
      }

      if (changedProperties.has('width')) {
        if (compRightX < leftConstraint.compRightX) {
          newLayout.width += leftConstraint.compRightX - compRightX;
        }
      }
    }
  }
}

function applyRightBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  const compX = newLayout.x;
  const compRightX = newLayout.x + newLayout.width;
  const rightConstraint = boundaries.right;

  if (rightConstraint) {
    if (_.isNumber(rightConstraint.compRightX)) {
      if (changedProperties.has('x')) {
        if (compRightX > rightConstraint.compRightX) {
          newLayout.x += rightConstraint.compRightX - compRightX;
        }
      }

      if (changedProperties.has('width')) {
        if (compRightX > rightConstraint.compRightX) {
          newLayout.width += rightConstraint.compRightX - compRightX;
        }
      }
    }

    if (_.isNumber(rightConstraint.compLeftX)) {
      if (changedProperties.has('x')) {
        if (compX > rightConstraint.compLeftX) {
          newLayout.x += rightConstraint.compLeftX - compX;
        }
      }

      if (changedProperties.has('width')) {
        if (compX > rightConstraint.compLeftX) {
          newLayout.width -= rightConstraint.compLeftX - compX;
        }
      }
    }
  }
}

function applyTopBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  const compTopY = newLayout.y;
  const compBottomY = newLayout.y + newLayout.height;
  const topConstraint = boundaries.top;

  if (topConstraint) {
    if (_.isNumber(topConstraint.compTopY)) {
      if (changedProperties.has('y')) {
        if (compTopY < topConstraint.compTopY) {
          newLayout.y += topConstraint.compTopY - compTopY;
        }
      }

      if (changedProperties.has('height')) {
        if (compTopY < topConstraint.compTopY) {
          newLayout.height -= topConstraint.compTopY - compTopY;
        }
      }
    }

    if (_.isNumber(topConstraint.compBottomY)) {
      if (changedProperties.has('y')) {
        if (compBottomY < topConstraint.compBottomY) {
          newLayout.y += topConstraint.compBottomY - compBottomY;
        }
      }

      if (changedProperties.has('height')) {
        if (compBottomY < topConstraint.compBottomY) {
          newLayout.height += topConstraint.compBottomY - compBottomY;
        }
      }
    }
  }
}

function applyBottomBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  const compTopY = newLayout.y;
  const compBottomY = newLayout.y + newLayout.height;
  const bottomConstraint = boundaries.bottom;

  if (bottomConstraint) {
    if (_.isNumber(bottomConstraint.compBottomY)) {
      if (changedProperties.has('y')) {
        if (compBottomY > bottomConstraint.compBottomY) {
          newLayout.y += bottomConstraint.compBottomY - compBottomY;
        }
      }

      if (changedProperties.has('height')) {
        if (compBottomY > bottomConstraint.compBottomY) {
          newLayout.height += bottomConstraint.compBottomY - compBottomY;
        }
      }
    }

    if (_.isNumber(bottomConstraint.compTopY)) {
      if (changedProperties.has('y')) {
        if (compTopY > bottomConstraint.compTopY) {
          newLayout.y += bottomConstraint.compTopY - compTopY;
        }
      }

      if (changedProperties.has('height')) {
        if (compTopY > bottomConstraint.compTopY) {
          newLayout.height -= bottomConstraint.compTopY - compTopY;
        }
      }
    }
  }
}

function applyHorizontalBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  applyLeftBoundaries(boundaries, newLayout, changedProperties);
  applyRightBoundaries(boundaries, newLayout, changedProperties);
}

function applyVerticalBoundaries(
  boundaries: LayoutContstraintBoundaries,
  newLayout: Partial<Rect>,
  changedProperties: ChangedPropertiesSet,
) {
  applyTopBoundaries(boundaries, newLayout, changedProperties);
  applyBottomBoundaries(boundaries, newLayout, changedProperties);
}

function getLayoutDiff(newLayout: Partial<Rect>, oldLayout: Partial<Rect>) {
  const changedKeys = LAYOUT_PROPERTIES.filter(
    (key) => newLayout[key] !== oldLayout[key],
  );

  return new Set(changedKeys);
}

function applyBoundariesConstraintOnLayout(
  boundaries: LayoutContstraintBoundaries,
  // TODO: no need for editorAPI here
  editorAPI: EditorAPI,
  compRef: CompRef,
  newLayout: Partial<Rect>,
  oldLayout: Partial<Rect>,
) {
  const changedProperties = getLayoutDiff(newLayout, oldLayout);

  if (boundaries.left || boundaries.right) {
    applyHorizontalBoundaries(boundaries, newLayout, changedProperties);
  }

  if (boundaries.top || boundaries.bottom) {
    applyVerticalBoundaries(boundaries, newLayout, changedProperties);
  }
}

export { applyBoundariesConstraintOnLayout };
