import type { CompRef, CompLayout } from '@wix/document-services-types';
import type { LayoutGetApi } from './layoutGetApi';
import { getSnugLayoutFromLayoutsArray } from '#packages/layoutUtils';
import { array } from '#packages/util';

/**
 * This is used by layout box to freeze its position
 * during scroll animation preview
 */
export const createStageLayoutApi = ({
  layoutGetApi,
}: {
  layoutGetApi: LayoutGetApi;
}) => {
  const overrides = new Map<string, CompLayout>();

  return {
    getRelativeToScreen: (compRef: CompRef | CompRef[]) => {
      const compRefs = array.asArray(compRef);

      const layouts = compRefs.map((comp) => {
        if (overrides.has(comp.id)) {
          return overrides.get(comp.id);
        }
        return layoutGetApi.getRelativeToScreen(comp);
      });

      if (layouts.length <= 1) {
        return layouts[0];
      }

      return getSnugLayoutFromLayoutsArray(layouts);
    },
    freeze: (compRefs: CompRef | CompRef[]) => {
      const compRefsArr = array.asArray(compRefs);

      for (const compRef of compRefsArr) {
        overrides.set(compRef.id, layoutGetApi.getRelativeToScreen(compRef));
      }
    },
    unfreeze: (compRefs: CompRef | CompRef[]) => {
      const compRefsArr = array.asArray(compRefs);

      for (const compRef of compRefsArr) {
        overrides.delete(compRef.id);
      }
    },
  };
};
