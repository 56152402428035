import type { CompLayout, CompRef, DSRead } from 'types/documentServices';

function withScale(value: number = 1, scale: number): number {
  return scale === 1 ? value : value / scale;
}

export function layoutGetSize(
  dsRead: DSRead,
  compRef: CompRef,
): Pick<CompLayout, 'width' | 'height'> {
  if (dsRead.utils.isSameRef(compRef, dsRead.siteSegments.getSiteStructure())) {
    const { height } =
      dsRead.components.responsiveLayout.runtime.measure.getRelativeToContainerBoundingBox(
        compRef,
      );

    return {
      width: dsRead.site.getWidth(),
      height,
    };
  }

  const measurements = dsRead.utils.mesh.getMeasurements(compRef);

  if (!measurements) return undefined;

  const { width, height } = measurements;

  return { width, height };
}

function isRendered(dsRead: DSRead, compRef: CompRef): boolean {
  return dsRead.components.isRenderedOnSite(compRef);
}

export function layoutGetSizeRelativeToScreen(
  dsRead: DSRead,
  compRef: CompRef,
  context?: { siteScale: number },
): Pick<CompLayout, 'width' | 'height'> {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  const { width, height } =
    dsRead.components.layout.measure.getBoundingClientRect(compRef);

  return {
    width: withScale(width, context?.siteScale),
    height: withScale(height, context?.siteScale),
  };
}
