// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';
import * as bi from '../bi/bi';
import * as coreBi from '#packages/coreBi';

const fixBlogSinglePost =
  () =>
  (dispatch, getState, { dsRead, dsActions }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const singlePostPageData = _.find(dsRead.pages.getPagesData(), {
      appPageId: '7326bfbb-4b10-4a8e-84c1-73f776051e10',
    });
    const translatedTitle = translate('Pages_Blog_Post_Title');
    if (singlePostPageData && singlePostPageData.title !== translatedTitle) {
      dsActions.pages.data.update(singlePostPageData.id, {
        title: translatedTitle,
      });
      dispatch(
        bi.actions.event(coreBi.events.dataFixers.blogSinglePostFix, {
          msid: dsRead.generalInfo.getMetaSiteId(),
        }),
      );
    }
  };

const runDataFixers = () => (dispatch) => {
  _.defer(() => {
    dispatch(fixBlogSinglePost());
  });
};

export { runDataFixers };
