import * as actionTypes from './savePublishActionTypes';
import * as userPreferences from '../userPreferences/userPreferences';

import type { ThunkAction } from 'types/redux';
import type {
  PublishOptions,
  SaveAndPublishOptions,
} from '#packages/savePublish';

const publish =
  (options: PublishOptions): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      void editorAPI.publishManager.publish(options);
    }
  };

const saveAndPublish =
  (options: SaveAndPublishOptions): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      void editorAPI.publishManager.saveAndPublish(options);
    }
  };

const overrideSiteWasSavedPanel = (overrides: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_SITE_WAS_SAVED_PANEL,
  overrides,
});

const overrideDomainPanel = (overrides: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_DOMAIN_PANEL,
  overrides,
});

const overrideSaveAndPublish = (overridingFunc: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_SAVE_AND_PUBLISH,
  overridingFunc,
});

const overrideSaveReminderPanel = (overrides: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_SAVE_REMINDER_PANEL,
  overrides,
});

const overrideSiteDefaultName = (overridingDefaultName: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_SITE_DEFAULT_NAME,
  overridingDefaultName,
});

const setIsDiffSaveInProgress = (
  isDiffSaveInProgress: boolean,
  isManualSave: boolean = false,
) => ({
  type: actionTypes.SET_IS_DIFF_SAVE_IN_PROGRESS,
  isDiffSaveInProgress,
  isManualSave,
});

const setIsManualSave = (isManualSave: boolean) => ({
  type: actionTypes.SET_IS_MANUAL_SAVE,
  isManualSave,
});

const setIsPublishInProgress = (isPublishInProgress: boolean) => ({
  type: actionTypes.SET_IS_PUBLISH_IN_PROGRESS,
  isPublishInProgress,
});

const setIsTestSiteInProgress = (isTestSiteInProgress: boolean) => ({
  type: actionTypes.SET_IS_TEST_SITE_IN_PROGRESS,
  isTestSiteInProgress,
});

const setWaitForSaveDoneCallback = (
  saveDoneCb: (saveSucces: boolean, e?: any) => void,
) => ({
  type: actionTypes.SET_SAVE_IN_PROGRESS_DONE_CB,
  saveDoneCb,
});

const clearSaveCallbacksOnSaveComplete = () => ({
  type: actionTypes.CLEAR_SAVE_IN_PROGRESS_DONE_CB,
});

const setIsLocked = (isLocked: boolean) => ({
  type: actionTypes.SET_IS_LOCKED,
  isLocked,
});

const setIsBuildInProgress = (isBuildInProgress: boolean) => ({
  type: actionTypes.SET_IS_BUILD_IN_PROGRESS,
  isBuildInProgress,
});

const setIsSaveInProgress = (
  isSaveInProgress: boolean,
  isManualSave: boolean = false,
) => ({
  type: actionTypes.SET_IS_SAVE_IN_PROGRESS,
  isSaveInProgress,
  isManualSave,
});

const setIsForceSaveInBackground = (isForceSaveInBackground: boolean) => ({
  type: actionTypes.SET_IS_FORCE_SAVE_IN_BACKGROUND,
  isForceSaveInBackground,
});

const setPublishPanelPromotionIndex = (publishPanelPromotionIndex: number) => ({
  type: actionTypes.SET_PUBLISH_PANEL_PROMOTION_INDEX,
  publishPanelPromotionIndex,
});

const setIsDealerPostpublishLoaded = (isDealerPostpublishLoaded: boolean) => ({
  type: actionTypes.SET_IS_DEALER_POSTPUBLISH_LOADED,
  isDealerPostpublishLoaded,
});

const setIsDealerPostsaveLoaded = (isDealerPostsaveLoaded: boolean) => ({
  type: actionTypes.SET_IS_DEALER_POSTSAVE_LOADED,
  isDealerPostsaveLoaded,
});

const setSiteQrCodeUrl = (qrCodeUrl: string) => ({
  type: actionTypes.SET_SITE_QR_CODE_URL,
  qrCodeUrl,
});

const overrideShouldCheckForConcurrentUsers = (
  shouldCheckForConcurrentUsers: AnyFixMe,
) => ({
  type: actionTypes.OVERRIDE_SHOULD_CHECK_FOR_CONCURRENT_USERS,
  shouldCheckForConcurrentUsers,
});

const setWasLatestRCPublished = (wasLatestRCPublished: boolean) => ({
  type: actionTypes.SET_WAS_LATEST_RC_PUBLISHED,
  wasLatestRCPublished,
});

const setSessionLoadedAsDraft = (sessionLoadedAsDraft: boolean) => ({
  type: actionTypes.SET_SESSION_LOADED_AS_DRAFT,
  sessionLoadedAsDraft,
});

const setPublishInCurrentSession = (isPublishedInCurrentSession: boolean) => ({
  type: actionTypes.SET_PUBLISH_IN_CURRENT_SESSION,
  isPublishedInCurrentSession,
});
const setIsPublishErrorIgnoreInCurrentSession =
  <T>(value: T): ThunkAction =>
  (dispatch) => {
    dispatch(
      userPreferences.actions.setSessionUserPreferences(
        actionTypes.USER_IGNORE_PUBLISH_ERROR,
        value,
      ),
    );

    return value;
  };

export {
  publish,
  saveAndPublish,
  overrideSiteWasSavedPanel,
  overrideDomainPanel,
  overrideSaveAndPublish,
  overrideSaveReminderPanel,
  overrideSiteDefaultName,
  setSiteQrCodeUrl,
  overrideShouldCheckForConcurrentUsers,
  setIsForceSaveInBackground,
  setIsDiffSaveInProgress,
  setIsManualSave,
  setIsBuildInProgress,
  setIsPublishInProgress,
  setIsTestSiteInProgress,
  setIsSaveInProgress,
  setPublishPanelPromotionIndex,
  setIsDealerPostpublishLoaded,
  setIsDealerPostsaveLoaded,
  setIsLocked,
  setWaitForSaveDoneCallback,
  clearSaveCallbacksOnSaveComplete,
  setWasLatestRCPublished,
  setSessionLoadedAsDraft,
  setPublishInCurrentSession,
  setIsPublishErrorIgnoreInCurrentSession,
};
