import type { PopupContainerProperties } from 'types/documentServices';
import type { LayoutFullWidthValue } from '#packages/layout';

export function getFullWidthValueFromPopupContainerProperties(
  compProperties: Partial<PopupContainerProperties>,
): LayoutFullWidthValue {
  return compProperties.alignmentType === 'fullWidth'
    ? {
        isFullWidth: true,
        siteMargin: {
          type: 'px',
          value: compProperties.horizontalOffset,
        },
      }
    : {
        isFullWidth: false,
      };
}
