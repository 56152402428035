import type { EditorState } from '#packages/stateManagement';

const getIsAddingInteractionsHiddenItemInProgress = (state: EditorState) =>
  state.mobile.hiddenItems.isAddingInteractionsHiddenItemInProgress;
const getHoveredComponent = (state: EditorState) =>
  state.mobile.hiddenItems.hoveredComponent;
const getPreviousInteractionsHiddenItems = (state: EditorState) =>
  state.mobile.hiddenItems.interactionsHiddenComponents;

export {
  getIsAddingInteractionsHiddenItemInProgress,
  getHoveredComponent,
  getPreviousInteractionsHiddenItems,
};
