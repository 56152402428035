import type { TpaInnerRouteObject } from '@wix/document-services-types';
import type { HideSubPagesSettings } from '@wix/platform-editor-sdk';
import * as Actions from './tpaDynamicPagesActionTypes';

export type IgnoreSubPagesFeature = keyof HideSubPagesSettings;
export interface TpaInnerRouteData extends TpaInnerRouteObject {
  subPage: string;
}

export interface TpaSubPagesInnerRoutes {
  [subPage: string]: TpaInnerRouteData[];
}

export interface InnerRoutesCounts {
  [appDefinitionId: string]: {
    [pageId: string]: {
      [subPage: string]: number | undefined;
    };
  };
}

export interface CachedInnerRoutes {
  [appDefinitionId: string]: {
    pages: {
      [pageId: string]: TpaSubPagesInnerRoutes;
    };
    subPagesToIgnore: HideSubPagesSettings;
  };
}

export interface SubPageData {
  key: string;
  hideFromFloatingNavBar?: boolean;
  hideFromLinkPanel?: boolean;
}

export interface CurrentTpaInnerRoutesInfo {
  appDefinitionId: string;
  pageId: string;
  subPage: string;
  innerRouteTitle: string;
  path: string;
}

export interface TpaDynamicPagesState {
  cachedInnerRoutes: CachedInnerRoutes;
  selectedInnerRoute: TpaInnerRouteData;
  inInnerRoutesFetchProcess: boolean;
  currentPageSessionId: string;
  currentTpaInfo: CurrentTpaInnerRoutesInfo;
  innerRoutesCounts: InnerRoutesCounts;
}

type ActionType = (typeof Actions)[keyof typeof Actions];

export interface TpaDynamicPageStateAction {
  type: ActionType;
  [param: string]: any;
}

export const tpaDynamicPagesInitialState: TpaDynamicPagesState = {
  selectedInnerRoute: null,
  inInnerRoutesFetchProcess: false,
  currentPageSessionId: '',
  currentTpaInfo: null,
  innerRoutesCounts: {},
  cachedInnerRoutes: {},
};

const setCachedInnerRoutes = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => {
  const { appDefinitionId, pageId, subPage, innerRoutes } = action;
  const cachedInnerRoutes = tpaDynamicPages.cachedInnerRoutes;
  const innerRoutesCounts = tpaDynamicPages.innerRoutesCounts;

  return {
    ...tpaDynamicPages,
    cachedInnerRoutes: {
      ...cachedInnerRoutes,
      [appDefinitionId]: {
        ...(cachedInnerRoutes[appDefinitionId] ?? {}),
        pages: {
          ...(cachedInnerRoutes[appDefinitionId]?.pages ?? {}),
          [pageId]: {
            ...(cachedInnerRoutes[appDefinitionId]?.pages?.[pageId] ?? {}),
            [subPage]: innerRoutes,
          },
        },
      },
    },
    innerRoutesCounts: {
      ...innerRoutesCounts,
      [appDefinitionId]: {
        ...(innerRoutesCounts[appDefinitionId] ?? {}),
        [pageId]: {
          ...(innerRoutesCounts[appDefinitionId]?.[pageId] ?? {}),
          [subPage]: innerRoutes?.length,
        },
      },
    },
  };
};

const setFeaturesSubPagesToIgnore = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => {
  const { appDefinitionId, subPagesToIgnore } = action;
  const cachedInnerRoutes = tpaDynamicPages.cachedInnerRoutes;

  return {
    ...tpaDynamicPages,
    cachedInnerRoutes: {
      ...cachedInnerRoutes,
      [appDefinitionId]: {
        ...(cachedInnerRoutes[appDefinitionId] ?? {}),
        subPagesToIgnore,
      },
    },
  };
};

const setCurrentInnerRoute = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => {
  const { appDefinitionId, pageId, selectedInnerRoute } = action;

  if (!selectedInnerRoute) {
    return {
      ...tpaDynamicPages,
      currentTpaInfo: {
        appDefinitionId,
        pageId,
        subPage: undefined,
        innerRouteTitle: undefined,
        path: undefined,
      },
      selectedInnerRoute: null,
    };
  }

  return {
    ...tpaDynamicPages,
    currentTpaInfo: {
      appDefinitionId,
      pageId,
      subPage: selectedInnerRoute.subPage,
      innerRouteTitle: selectedInnerRoute.name,
      path: selectedInnerRoute.path,
    },
    selectedInnerRoute,
  };
};

const setIsInFetchProcess = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => ({
  ...tpaDynamicPages,
  inInnerRoutesFetchProcess: action.inInnerRoutesFetchProcess,
});

const setCurrentPageSessionId = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => ({
  ...tpaDynamicPages,
  currentPageSessionId: action.tpaPageSessionId,
});

const clearInnerRoutesFromCache = (
  tpaDynamicPages: TpaDynamicPagesState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => {
  const { appDefinitionId, pageId } = action;
  if (!tpaDynamicPages.cachedInnerRoutes[appDefinitionId]) {
    return tpaDynamicPages;
  }

  const { [pageId]: omittedPageInnerRoutes, ...restPagesCachedInnerRoutes } =
    tpaDynamicPages.cachedInnerRoutes[appDefinitionId].pages;
  const {
    [pageId]: omittedPageInnerRoutesCount,
    ...restPagesCachedInnerRoutesCount
  } = tpaDynamicPages.cachedInnerRoutes[appDefinitionId].pages;
  return {
    ...tpaDynamicPages,
    cachedInnerRoutes: {
      ...tpaDynamicPages.cachedInnerRoutes,
      [appDefinitionId]: {
        ...tpaDynamicPages.cachedInnerRoutes[appDefinitionId],
        pages: restPagesCachedInnerRoutes,
      },
    },
    innerRoutesCounts: {
      ...tpaDynamicPages.innerRoutesCounts,
      [appDefinitionId]: restPagesCachedInnerRoutesCount,
    },
    currentTpaInfo: null,
  };
};

const reducer = (
  tpaDynamicPages: TpaDynamicPagesState = tpaDynamicPagesInitialState,
  action: TpaDynamicPageStateAction,
): TpaDynamicPagesState => {
  switch (action.type) {
    case Actions.SET_TPA_CACHED_INNER_ROUTES:
      return setCachedInnerRoutes(tpaDynamicPages, action);

    case Actions.SET_FEATURES_SUB_PAGES_TO_IGNORE:
      return setFeaturesSubPagesToIgnore(tpaDynamicPages, action);

    case Actions.SET_CURRENT_TPA_INNER_ROUTE:
      return setCurrentInnerRoute(tpaDynamicPages, action);

    case Actions.SET_TPA_INNER_ROUTES_FETCH_PROCESS:
      return setIsInFetchProcess(tpaDynamicPages, action);

    case Actions.SET_TPA_PAGE_SESSION_ID:
      return setCurrentPageSessionId(tpaDynamicPages, action);

    case Actions.RESET_TPA_CACHED_INNER_ROUTES:
      return clearInnerRoutesFromCache(tpaDynamicPages, action);

    default:
      return tpaDynamicPages;
  }
};

export default reducer;
