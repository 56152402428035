import type { RCM_ORIGIN } from '#packages/constants';
import _ from 'lodash';
import type { fedopsLogger } from '#packages/util';
import * as pinMode from '../pinMode/pinMode';
import {
  OPEN_CONTEXT_MENU,
  CLOSE_CONTEXT_MENU,
} from './contextMenuActionTypes';

const pinModeActionTypes = pinMode.actionTypes;

export type ContextMenuState = {
  editorMousePosition: {
    x: number;
    y: number;
  };
  viewerPosition: {
    x?: number;
    y?: number;
  };
  origin: RCM_ORIGIN;
  biOrigin?: string;
  context: object;
  removeSelectedGuide: Function;
  removeAllGuidesInRuler: Function;
  openRCMInteraction?: ReturnType<typeof fedopsLogger.mapInteraction>;
} | null;

export const contextMenuInitialState: ContextMenuState = null;

const contextMenuReducer = (
  state = contextMenuInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case OPEN_CONTEXT_MENU:
      return _.defaults(
        {
          editorMousePosition: {
            x: action.editorPositionX,
            y: action.editorPositionY,
          },
          viewerPosition: {
            x: action.viewerPositionX,
            y: action.viewerPositionY,
          },
          origin: action.origin,
          biOrigin: action.biOrigin,
          context: action.context,
          removeSelectedGuide: action.removeSelectedGuide,
          removeAllGuidesInRuler: action.removeAllGuidesInRuler,
          openRCMInteraction: action.openRCMInteraction,
        },
        state,
      );
    case pinModeActionTypes.OPEN_PIN_MODE:
    case CLOSE_CONTEXT_MENU:
      return null;
    default:
      return state;
  }
};

export default contextMenuReducer;
