import { dsOneDockAdapter } from '#packages/layoutOneDockMigration';
import { getSnugLayoutFromLayoutsArray } from '#packages/layoutUtils';
import { createLayoutGetApiCache } from './createLayoutGetApiCache';
import { createLayoutGetApiDebugger } from './createLayoutGetApiDebugger';
import { getLayoutContext, getRelativeToScreenInner } from '../domLayout';
import { ensureCompRefOrRefsHasValue } from '../validation';
import type { CompRef, CompLayout, Rect } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { GetLayoutFn, GetLayoutFnMulti } from '../type';

export function createLayoutGetApi_FromDOM({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const cache = createLayoutGetApiCache();
  const layoutDebugger = createLayoutGetApiDebugger({ editorAPI });

  function get(compRef: CompRef): Rect {
    const dsRead = editorAPI.dsRead;

    const compLayout = getRelativeToStructure(compRef);
    const compContainerRef = dsRead.components.getContainer(compRef);
    const compContainerLayout =
      compContainerRef && getRelativeToStructure(compContainerRef);

    const compPosition = compContainerLayout
      ? {
          x: compLayout.x - compContainerLayout.x,
          y: compLayout.y - compContainerLayout.y,
        }
      : {
          x: 0,
          y: 0,
        };

    return {
      ...compLayout,
      x: compPosition.x,
      y: compPosition.y,
    };
  }

  function getRelativeToStructureXDiff(_compRef: CompRef): number {
    const dsRead = editorAPI.dsRead;

    return dsRead.components.is.fullWidth(_compRef)
      ? 0
      : dsRead.site.getSiteX();
    // return dsRead.site.getSiteX();
  }

  function getRelativeToStructure(compRef: CompRef): Rect {
    const dsRead = editorAPI.dsRead;
    const layoutRelativeToScreen = getRelativeToScreen(compRef);

    const siteWidth = dsRead.site.getWidth();
    const compPosition = {
      x: layoutRelativeToScreen.x - getRelativeToStructureXDiff(compRef),
      y: layoutRelativeToScreen.y,
    };
    const compSize = {
      width: Math.min(layoutRelativeToScreen.width, siteWidth),
      height: layoutRelativeToScreen.height,
    };

    return {
      x: compPosition.x,
      y: compPosition.y,
      width: compSize.width,
      height: compSize.height,
    };
  }

  function getRelativeToScreen(compRef: CompRef): Rect {
    return getRelativeToScreenInner(getLayoutContext(editorAPI), compRef);
  }

  function getRelativeToScreenConsideringScroll(compRef: CompRef): Rect {
    const dsRead = editorAPI.dsRead;
    const layout = getRelativeToScreen(compRef);

    if (!dsRead.components.layout.isShowOnFixedPosition(compRef)) {
      const scroll = dsRead.site.getScroll();

      return {
        ...layout,
        x: layout.x - scroll.x,
        y: layout.y - scroll.y,
      };
    }

    return layout;
  }

  function oneOrMulti(fnName: string, fn: (compRef: CompRef) => CompLayout) {
    return (compRefOrRefs: CompRef | CompRef[]): CompLayout => {
      ensureCompRefOrRefsHasValue(fnName, compRefOrRefs);

      if (!Array.isArray(compRefOrRefs)) {
        return fn(compRefOrRefs);
      }

      if (compRefOrRefs.length === 1) {
        return fn(compRefOrRefs[0]);
      }

      return getSnugLayoutFromLayoutsArray(compRefOrRefs.map((x) => fn(x)));
    };
  }

  function define(getRectFn: (compRef: CompRef) => Rect): GetLayoutFnMulti {
    const fnExtended: GetLayoutFn = (compRef) => {
      const adapter = dsOneDockAdapter(editorAPI.dsRead);

      const layout_rect = getRectFn(compRef);

      const rotationInDegrees = adapter.layout.get_rotationInDegrees(compRef);
      const scale = adapter.layout.get_scale(compRef);
      const fixedPosition = adapter.layout.get_fixedPosition(compRef);
      const bounding = editorAPI.dsRead.utils.getBoundingLayout({
        ...layout_rect,
        rotationInDegrees,
      });
      const docked = adapter.layout.getDock(compRef);

      return {
        ...layout_rect,
        rotationInDegrees,
        fixedPosition,
        scale,
        bounding,
        docked,
      };
    };

    const fnName = getRectFn.name;
    const fnSingle = layoutDebugger.isEnabled()
      ? layoutDebugger.withDebugger(fnName, fnExtended)
      : fnExtended;
    const fnMulti = oneOrMulti(fnName, fnSingle);
    const fnMemoized = fnMulti; // cache.memoize(fnMulti);

    return fnMemoized;
  }

  return {
    clearCache: cache.clearCache,
    get: define(get),
    getRelativeToStructure: define(getRelativeToStructure),
    getRelativeToScreen: define(getRelativeToScreen),
    getRelativeToScreenConsideringScroll: define(
      getRelativeToScreenConsideringScroll,
    ),
  };
}
