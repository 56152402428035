import type { EditorState } from '#packages/stateManagement';

const getCurrentBranchName = (state: EditorState): string =>
  state.branches.currentBranchName;

const getWasBranchesListFetched = (state: EditorState): boolean =>
  state.branches.wasBranchesListFetched;

const getHasBranches = (state: EditorState): boolean =>
  !!state.branches.hasBranches;

export { getCurrentBranchName, getWasBranchesListFetched, getHasBranches };
