import _ from 'lodash';
import { array, sections } from '#packages/util';

import tinyMenuFixedPositionPlugin from './tinyMenuFixedPositionPlugin';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const plugins: Record<string, FixedPositionPlugin> = {
  'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuFixedPositionPlugin,
  'wysiwyg.viewer.components.MenuToggle': tinyMenuFixedPositionPlugin,
};

export type FixedPositionPlugin<R extends void | boolean = void> = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
  newValue: boolean,
) => R;

function registerPlugin(compType: string, callBack: FixedPositionPlugin) {
  plugins[compType] = callBack;
}

const setDefaultFixedPosition: FixedPositionPlugin = (
  editorAPI,
  compRef,
  newVal,
) => {
  const [ref] = array.asArray(compRef);

  // https://github.com/wix-private/document-management/blob/b30cc2ea84908fdde8bbb2ceecbafc08992c9dfb/document-services-implementation/src/structure/structure.ts#L187-L202
  editorAPI.components.layout.updateFixedPosition(ref, newVal);

  const isSiteSegment = editorAPI.components.is.siteSegment(ref);

  if (sections.isSectionsEnabled() && newVal === false && !isSiteSegment) {
    reparentComponentToSectionOnUnFix(editorAPI, ref);
  }
};

export function getSectionLikeAttachCandidateOnUnFix(
  editorAPI: EditorAPI,
  ref: CompRef,
) {
  // eslint-disable-next-line no-lone-blocks
  {
    const compLayout = editorAPI.components.layout.get_rect(ref);
    compLayout.y += editorAPI.scroll.get().scrollTop;
    const compMiddle = (compLayout.y + compLayout.height + compLayout.y) / 2;
    const sectionLike = editorAPI.sections.getSectionLikeAtY(compMiddle);

    return sectionLike;
  }
}

function reparentComponentToSectionOnUnFix(editorAPI: EditorAPI, ref: CompRef) {
  // eslint-disable-next-line no-lone-blocks
  {
    const sectionLike = getSectionLikeAttachCandidateOnUnFix(editorAPI, ref);
    if (sectionLike) {
      editorAPI.components.setContainer(ref, sectionLike);
      editorAPI.setAttachCandidate(
        { comp: sectionLike, useAnimation: true },
        false,
      );
    }
  }
}

const setFixedPosition: FixedPositionPlugin<boolean> = (
  editorAPI,
  compRef,
  newVal,
) => {
  const compType = editorAPI.components.getType(compRef);

  if (plugins[compType] && _.isFunction(plugins[compType])) {
    plugins[compType].call(null, editorAPI, compRef, newVal);
  } else {
    setDefaultFixedPosition(editorAPI, compRef, newVal);
  }

  return newVal;
};

export { registerPlugin, setFixedPosition };
