import { sectionsOnStageActionTypes } from './sectionsOnStage.actionTypes';

import type { Section } from '#packages/sectionsOnStage';

export const sectionsOnStageInitialState: {
  hoveredSectionIndex: number;
  sections: Section[];
} = {
  hoveredSectionIndex: -1,
  sections: [],
};

export type SectionsOnStageState = typeof sectionsOnStageInitialState;

export const sectionsOnStageReducer = (
  sectionsOnStage = sectionsOnStageInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case sectionsOnStageActionTypes.SET_HOVERED_SECTION_INDEX:
      return {
        ...sectionsOnStage,
        hoveredSectionIndex: action.hoveredSectionIndex,
      };
    case sectionsOnStageActionTypes.SET_SECTIONS:
      return { ...sectionsOnStage, sections: action.sections };
    default:
      return sectionsOnStage;
  }
};
