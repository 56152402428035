import type { AfterPlugin } from 'types/editorPlugins';
import { updateAllChildrenSourceData } from '../../componentSourceFeatureUtils';

const fillTextContentAfterPlugin: AfterPlugin = (
  editorAPI,
  { compRef, contentSource },
) => {
  if (!contentSource) return;

  updateAllChildrenSourceData(editorAPI, {
    parentRef: compRef,
    source: contentSource,
    changedOverride: false,
  });
};

export default fillTextContentAfterPlugin;
