import { EditorAPIKey } from '#packages/apis';

import { createLayoutContentAreaApi } from './layoutContentAreaApi/createLayoutContentAreaApi';
import { createLayoutContentAreaOutOfGridApi } from './layoutContentAreaApi/createLayoutContentAreaOutOfGridApi';
import { createLayoutGetApi } from './layoutGetApi';
import { createLayoutRotationApi } from './layoutRotationApi/createLayoutRotationApi';
import { createLayoutFixedPositionApi } from './layoutFixedPositionApi';
import { createLayoutDockApi } from './layoutFixedPositionApi/createLayoutDockApi';
import { createLayoutPinApi } from './layoutFixedPositionApi/createLayoutPinApi';
import { createLayoutMeshApi } from './layoutMeshApi';
import { createLayoutFullWidthApi } from './layoutFullWidthApi';
import { createLayoutMoveApi } from './layoutMoveApi';
import { createLayoutResizeApi } from './layoutResizaApi';
import { createStageLayoutApi } from './stageLayoutApi';

import { createLayoutApi_structure } from './createLayoutApi_structure';
import { createHistoryApi } from './createHistoryApi';

import type { Shell } from '#packages/apilib';

export function createLayoutApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const historyApi = createHistoryApi({ editorAPI });
  const layoutRotationApi = createLayoutRotationApi({ editorAPI });
  const layoutGetApi = createLayoutGetApi({ editorAPI });
  const layoutContentAreaApi = createLayoutContentAreaApi({
    editorAPI,
    layoutGetApi,
  });
  const layoutFixedPositionApi = createLayoutFixedPositionApi({
    editorAPI,
    layoutGetApi,
  });
  const layoutMeshApi = createLayoutMeshApi({
    editorAPI,
    layoutGetApi,
    historyApi,
  });
  const layoutFullWidthApi = createLayoutFullWidthApi({
    editorAPI,
    layoutGetApi,
    layoutMeshApi,
  });
  const layoutDockApi = createLayoutDockApi({
    editorAPI,
  });
  const layoutPinApi = createLayoutPinApi({
    editorAPI,
    layoutGetApi,
    layoutMeshApi,
    layoutDockApi,
    layoutFixedPositionApi,
  });
  const layoutContentAreaOutOfGridApi = createLayoutContentAreaOutOfGridApi({
    editorAPI,
    layoutContentAreaApi,
    layoutGetApi,
    layoutRotationApi,
    layoutPinApi,
  });
  const layoutApi_structure = createLayoutApi_structure({
    editorAPI,
    layoutGetApi,
    layoutDockApi,
    layoutMeshApi,
  });
  const layoutMoveApi = createLayoutMoveApi({
    editorAPI,
    layoutGetApi,
    layoutApi_structure,
    layoutMeshApi,
  });
  const layoutResizeApi = createLayoutResizeApi({
    editorAPI,
    layoutGetApi,
    layoutMeshApi,
    layoutApi_structure,
  });
  const layoutStageApi = createStageLayoutApi({
    layoutGetApi,
  });

  return {
    __mesh: layoutMeshApi,
    stage: layoutStageApi,
    ...layoutApi_structure,
    //layout fns
    setLayoutReadFromDOM: layoutGetApi.setLayoutReadFromDOM,
    clearLayoutCache: layoutGetApi.clearCache,
    get_position: layoutGetApi.get_position,
    get_size: layoutGetApi.get_size,
    get_rect: layoutGetApi.get_rect,
    get_rotationInDegrees: layoutGetApi.get_rotationInDegrees,
    get_fixedPosition: layoutGetApi.get_fixedPosition,
    get_scale: layoutGetApi.get_scale,
    get: layoutGetApi.get,
    getRelativeToStructure_rect: layoutGetApi.getRelativeToStructure_rect,
    getRelativeToStructure: layoutGetApi.getRelativeToStructure,
    getRelativeToScreen_rect: layoutGetApi.getRelativeToScreen_rect,
    getRelativeToScreen: layoutGetApi.getRelativeToScreen,
    getRelativeToScreenConsideringScroll:
      layoutGetApi.getRelativeToScreenConsideringScroll,
    getMeasuredLayoutRelativeToScreen:
      layoutGetApi.getMeasuredLayoutRelativeToScreen,
    getConstrainedLayout: layoutApi_structure.getConstrainedLayout,
    //layout fns
    getCompMargin: layoutContentAreaApi.getCompMargin,
    isCompExceedingContainerMargin:
      layoutContentAreaApi.isCompExceedingContainerMargin,
    shouldShowOutOfPageGridlinesWarning:
      layoutContentAreaOutOfGridApi.shouldShowOutOfPageGridlinesWarning,
    shouldShowOutOfGridlinesIndication:
      layoutContentAreaOutOfGridApi.shouldShowOutOfGridlinesIndication,
    openCompOutOfGridPanelIfNeeded:
      layoutContentAreaOutOfGridApi.openCompOutOfGridPanelIfNeeded,
    isRotated: layoutRotationApi.isRotated,
    moveTo: layoutMoveApi.moveTo,
    moveToMany: layoutMoveApi.moveToMany,
    moveBy: layoutMoveApi.moveBy,
    canMoveBy: layoutMoveApi.canMoveBy,
    resizeTo: layoutResizeApi.resizeTo,
    resizeToAndPush: layoutResizeApi.resizeToAndPush,
    getFullWidth: layoutFullWidthApi.getFullWidth,
    isFullWidth: layoutFullWidthApi.isFullWidth,
    isHorizontallyStretchedToScreen:
      layoutFullWidthApi.isHorizontallyStretchedToScreen,
    stretchComponent: layoutFullWidthApi.stretchComponent,
    unStretchComponent: layoutFullWidthApi.unStretchComponent,
    getDock: layoutDockApi.getDock,
    isDocked: layoutDockApi.isDocked,
    isDockedToScreen: layoutDockApi.isDockedToScreen,
    setDock: layoutDockApi.setDock,
    updateDock: layoutDockApi.updateDock,
    unDock: layoutDockApi.unDock,
    pinToDirection: layoutPinApi.pinToDirection,
    unPin: layoutPinApi.unPin,
    isPinned: layoutPinApi.isPinned,
    isPinnedByLayout_deprecated: layoutPinApi.isPinnedByLayout_deprecated,
    getPinDockingDirection: layoutPinApi.getPinDockingDirection,
    getPinDockingOffsets: layoutPinApi.getPinDockingOffsets,
    isShowOnFixedPosition: layoutFixedPositionApi.isShowOnFixedPosition,
    canBeFixedPosition: layoutFixedPositionApi.canBeFixedPosition,
    setFixed: layoutFixedPositionApi.setFixed,
    toggleFixedPosition: layoutFixedPositionApi.toggleFixedPosition,
  };
}
