import { isMeshLayoutEnabled, type LayoutMeshApi } from './layoutMeshApi';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { LayoutGetApi } from './layoutGetApi';
import type { LayoutApiByStructure } from './createLayoutApi_structure';
import type {
  LayoutResizeToOptions,
  LayoutResizeToAndPushOptions,
} from './type';
import { updateLayoutUtil } from '#packages/layoutUtils';

export function createLayoutResizeApi({
  editorAPI,
  layoutGetApi,
  layoutMeshApi,
  layoutApi_structure,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutMeshApi: LayoutMeshApi;
  layoutApi_structure: LayoutApiByStructure;
}) {
  const historyAdd = (
    label: string,
    { dontAddToUndoRedoStack }: { dontAddToUndoRedoStack?: boolean },
  ) => {
    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd(label);
    }
  };

  function resizeTo(
    compRef: CompRef,
    size: { width?: number; height?: number },
    options: LayoutResizeToOptions = {},
  ) {
    const sizeAfterConstrains = updateLayoutUtil.getLayoutAfterConstraints(
      editorAPI,
      compRef,
      size,
      layoutGetApi.get(compRef),
    );

    if (isMeshLayoutEnabled()) {
      layoutMeshApi.resizeTo(compRef, sizeAfterConstrains, options);

      return;
    }

    editorAPI.dsActions.components.layout.update(compRef, sizeAfterConstrains);

    historyAdd('component - update layout size', options);
  }

  /**
   * @deprecated
   */
  function resizeToAndPush(
    compRef: CompRef,
    size: { width?: number; height?: number },
    options: LayoutResizeToAndPushOptions = {},
  ) {
    if (isMeshLayoutEnabled()) {
      if (editorAPI.sections.isSectionLike(compRef)) {
        // NOTE: section hard anchors with distanse `0`, should push next section down
        // So we don't need to shift components below manually via `dsActions.components.layout.updateAndPushUpdate`
        // TODO: check if next sections are pushed down
        layoutMeshApi.resizeTo(compRef, size, options);
        return;
      }

      // eslint-disable-next-line no-console
      console.warn(
        'LayoutResizeApi.resizeToAndPush() for mesh is not implemented yet',
      );
      return;
    }

    layoutApi_structure.updateAndAdjustLayout(
      compRef,
      size,
      options.dontAddToUndoRedoStack,
    );
  }

  return {
    resizeTo,
    resizeToAndPush,
  };
}

export type LayoutResizeApi = ReturnType<typeof createLayoutResizeApi>;
