// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import setPushingStatus from './setPushingStatus';
import isSavePublishOrPushAllowed from './isSavePublishOrPushAllowed';

function localPushCreator({ editorAPI }) {
  //eslint-disable-line
  function wasPushedInThisSession() {
    // console.log("wasPushedInThisSession",pushedInThisSession )
    return window.pushedInThisSession;
  }

  function push(isFullSave, origin, options) {
    return new Promise(function (resolve, reject) {
      const state = editorAPI.store.getState();
      editorAPI.store.dispatch(
        setPushingStatus(constants.PROGRESS_STATUS.IN_PROGRESS),
      );
      if (editorAPI.dsRead.generalInfo.isFirstSave()) {
        reject('Local mode push should not work on first save!');
      } // Todo: Implement this correctly

      if (!isSavePublishOrPushAllowed(state)) {
        // log.error('Site is being saved, published, or pushed!');
        resolve();
      }

      function onSaveSuccess() {
        window.pushedInThisSession = true;
        // console.log("pushedInThisSession", window.pushedInThisSession)
        resolve();
        editorAPI.store.dispatch(
          setPushingStatus(constants.PROGRESS_STATUS.DONE_SUCCESS),
        );
        window.setTimeout(function () {
          editorAPI.store.dispatch(
            setPushingStatus(constants.PROGRESS_STATUS.IDLE_SAVED),
          );
        }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);
      }

      function onSaveFail(error) {
        editorAPI.store.dispatch(
          setPushingStatus(constants.PROGRESS_STATUS.DONE_FAILED),
        );
        window.setTimeout(function () {
          editorAPI.updateState({
            savingStatus: constants.PROGRESS_STATUS.IDLE,
          });
        }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);
        reject(error);
      }

      _.defer(function () {
        editorAPI.dsActions.localMode.push(
          onSaveSuccess,
          onSaveFail,
          isFullSave,
          options,
        );
      });
    });
  }

  function openSuccessPushPanel() {
    editorAPI.panelManager.openPanel(
      'localMode.panels.pushSuccessPanel',
      {},
      true,
    );
  }

  function performFullPush(onSuccess, onError, origin, options) {
    push(true, origin, options)
      .then(function () {
        // if (result.preventSiteWasPushedPanel) {
        //     return;
        // }
        return openSuccessPushPanel();
      })
      .then(onSuccess)
      .catch(onError);
  }

  function openForcePushPanel(origin, options) {
    editorAPI.panelManager.openPanel(
      'localMode.panels.forcePushPanel',
      {
        onForcePush: options.performFullPushBound,
      },
      true,
    );
  }

  function openLocalVersionOutdatedPanel(origin, options) {
    return new Promise(function (resolve, reject) {
      const performFullPushBound = _.partial(
        performFullPush,
        resolve,
        reject,
        origin,
        options,
      );

      editorAPI.panelManager.openPanel(
        'localMode.panels.localVersionOutdatedPushPanel',
        {
          onForcePush: () =>
            openForcePushPanel(origin, { performFullPushBound }),
        },
        true,
      );
    });
  }

  function extractDocumentErrorType(error) {
    return error?.document?.errorType || error?.documentServices?.errorType;
  }

  function callDocumentServicesPush(isFullSave, origin, options) {
    options = options || {};
    if (editorAPI.savePublish.isSaveInProgress()) {
      return Promise.reject();
    }

    // editorAPI.savePublish.setSaveProgress(true);
    //
    // function unlockSave() {
    //     editorAPI.savePublish.setSaveProgress(false);
    //     editorAPI.bi.tryFlushingConditionedEvents();
    // }

    // window.clearInterval(failedPanelDueToValidationErrorIntervalId);

    return push(isFullSave, origin, options)
      .then(function (result) {
        // unlockSave();
        editorAPI.wasLocalPushedInThisSession = true;
        openSuccessPushPanel();
        return result || {};
      })
      .catch(function (error) {
        const errorType = extractDocumentErrorType(error);
        if (errorType === editorAPI.dsRead.errors.save.CONCURRENT_SAVE) {
          return openLocalVersionOutdatedPanel(origin, options).then(
            function () {
              return { preventSiteWasPushedPanel: true };
            },
          );
        }
        // TODO: Handle other kinds of errors
        // displayFailurePanelMatchingAnErrorType(error, errorType);
        editorAPI.panelManager.openPanel(
          'localMode.panels.failedPushGenericPanel',
          {},
          true,
        );
        throw error;
      });
  }

  function localPush(options) {
    options = options || {};
    // const origin = options.origin || '';
    const state = editorAPI.store.getState();

    if (!isSavePublishOrPushAllowed(state)) {
      // log.error('Site is being saved or published!');
      return Promise.resolve();
    }

    return callDocumentServicesPush(false, options.origin, options);
  }
  return {
    localPush,
    wasPushedInThisSession,
  };
}

export default localPushCreator;
