import { EditorRestrictionsApiKey } from './publicApiKey';
import { EditorPermissionsApiKey, EditorAPIKey } from '#packages/apis';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorRestrictionsApi, resetStore } from './editorRestrictionsApi';
import { EditorRestrictionsVersionStore } from './editorRestrictionsVersionStore';

export class EditorRestrictionsScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  restrictionsVersionStore = this.declareStore(EditorRestrictionsVersionStore);
}

export const EditorRestrictionsEntryPoint = createEntryPoint({
  name: 'EditorRestrictions',
  Scope: EditorRestrictionsScope,
  publicApi({ contributeApi }) {
    contributeApi(EditorRestrictionsApiKey, EditorRestrictionsApi);
  },
  initialize() {
    resetStore();
  },
});
