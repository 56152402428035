import _ from 'lodash';
import { combineReducers } from 'redux';
import packageAndPublishReducer from './packageAndPublish/packageAndPublishReducer';
import appLinkReducer from './appLink/appLinkReducer';
import devCenterSettingsReducer from './devCenterSettings/devCenterSettingsReducer';
import actionTypes from './applicationStudioActionTypes';
import constants from '#packages/constants';

export const applicationStudioInitialState = {
  packageAndPublish: {
    appVersion: undefined as AnyFixMe,
    currBuildVersion: constants.APP_STUDIO.BUILD_VERSION_TYPES.MINOR,
    currTask: null as AnyFixMe,
    errorMessage: '',
    isFirstAppBuild: undefined as AnyFixMe,
    isPackaging: false,
    numOfTasksDone: 0,
    packageStep: constants.APP_STUDIO.PACKAGE_STEPS.FIRST_PUBLISH,
    totalNumOfPackageTasks: 0,
    blocksVersion: '0.0.1',
  },
  appLink: {
    link: undefined as AnyFixMe,
    fail: false,
    creating: false,
  },
  appInfo: {
    appDefId: undefined as AnyFixMe,
    appName: undefined as AnyFixMe,
    namespace: undefined as AnyFixMe,
  },
  widgetDesignPanelSplitterHeight: '400px',
  controllerWidgetMap: {},
  devCenterSettings: {
    widgetsData: undefined as AnyFixMe,
    siteHeaderUrl: undefined as AnyFixMe,
  },
  widgetApiTestProperties: [] as AnyFixMe,
  isStrictColorPicker: false,
};

const appInfoReducer = (
  appInfo = applicationStudioInitialState.appInfo,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_APP_DEF_ID:
      return _.defaults({ appDefId: action.appDefId }, appInfo);
    case actionTypes.SET_APP_NAME:
      return _.defaults({ appName: action.appName }, appInfo);
    case actionTypes.SET_APP_NAMESPACE:
      return _.defaults({ namespace: action.namespace }, appInfo);
    default:
      return appInfo;
  }
};

const widgetDesignPanelSplitterHeightReducer = (
  applicationStudio = applicationStudioInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_WIDGET_DESIGN_PANEL_SPLITTER_HEIGHT:
      const widgetDesignPanelSplitterHeight =
        action?.newHeight ?? applicationStudio.widgetDesignPanelSplitterHeight;
      return _.defaultsDeep(
        { widgetDesignPanelSplitterHeight },
        applicationStudio,
      );
    default:
      return applicationStudio;
  }
};

const controllerWidgetMapReducer = (
  controllerWidgetMap = applicationStudioInitialState.controllerWidgetMap,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.UPDATE_WIDGETS_MAP:
      return _.defaultsDeep(action.map, controllerWidgetMap);
    default:
      return controllerWidgetMap;
  }
};

const widgetApiTestPropertiesReducer = (
  testProperties = applicationStudioInitialState.widgetApiTestProperties,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_WIDGET_API_TEST_PROPERTIES:
      return action.widgetApiTestProperties;
    default:
      return testProperties;
  }
};

const strictColorPickerReducer = (
  isStrictColorPicker = false,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_STRICT_COLOR_PICKER_MODE:
      return action.isStrictColorPicker;
    default:
      return isStrictColorPicker;
  }
};

export default combineReducers({
  packageAndPublish: packageAndPublishReducer,
  appLink: appLinkReducer,
  appInfo: appInfoReducer,
  widgetDesignPanelSplitterHeight: widgetDesignPanelSplitterHeightReducer,
  controllerWidgetMap: controllerWidgetMapReducer,
  widgetApiTestProperties: widgetApiTestPropertiesReducer,
  devCenterSettings: devCenterSettingsReducer,
  isStrictColorPicker: strictColorPickerReducer,
});
