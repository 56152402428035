import { EditorAPIKey } from '#packages/apis';

import { createLayoutApi } from './createLayoutApi';
import { LayoutApiKey } from './publicApiKey';

import type { EntryPoint } from '#packages/apilib';

export const LayoutEntrypoint: EntryPoint = {
  name: 'LayoutApi',
  declareAPIs: () => [LayoutApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(LayoutApiKey, () => createLayoutApi(shell));
  },
};

export * from './$layoutY';
export { isMeshLayoutEnabled } from './layoutMeshApi';

export type {
  LayoutMoveByParams,
  LayoutMoveByInterceptor,
} from './layoutMoveApi';
export type {
  AttachCandidateStatus,
  AttachCandidateResponse,
  LayoutFullWidthValue,
  LayoutFullWidthSiteMargin,
} from './type';
