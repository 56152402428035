import { getSnugLayoutFromLayoutsArray } from '#packages/layoutUtils';
import { calculateComponentLayoutWidthAndHeight } from './calculateComponentLayoutWidthAndHeight';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutMeshApi } from './createLayoutMeshApi';

export function createLayoutMeshGroupApi({
  editorAPI,
  layoutGetApi,
  layoutMeshCoreApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
}) {
  /**
   * Group components to a group and update group's container grid
   */
  function groupTransaction(compRefs: CompRef[]) {
    return editorAPI.transactions.run(async () => {
      const groupContainerRef = editorAPI.components.getContainer(compRefs);
      const compRects = compRefs.map((compRef) => layoutGetApi.get(compRef));

      const groupSnagLayout = getSnugLayoutFromLayoutsArray(compRects);
      const groupRect = {
        x: groupSnagLayout.x,
        y: groupSnagLayout.y,
        width: groupSnagLayout.width,
        height: groupSnagLayout.height,
      };
      const groupRef = editorAPI.dsActions.components.groupComponents(compRefs);

      // reposition components inside the groupRect
      layoutMeshCoreApi.updateContainerGrid(groupRef, {
        compRectsMap: new Map(
          compRects
            .map((compRect, index) => [
              compRefs[index].id,
              {
                x: compRect.x - groupRect.x,
                y: compRect.y - groupRect.y,
                width: compRect.width,
                height: compRect.height,
              },
            ])
            // Adding group rect to the map of rects:
            // group is not rendered yet, so group rect can't be received from the DOM
            .concat([[groupRef.id, groupRect]]) as [string, Rect][],
        ),
      });

      {
        // update group componentLayout width and height
        const groupLayouts = layoutMeshCoreApi.get(groupRef);
        const { width, height, minHeight } =
          calculateComponentLayoutWidthAndHeight(
            groupLayouts,
            groupRect.width,
            groupRect.height,
          );

        layoutMeshCoreApi.update(groupRef, {
          componentLayout: {
            ...groupLayouts.componentLayout,
            width,
            height,
            minHeight,
          },
        });
      }

      layoutMeshCoreApi.updateContainerGrid(groupContainerRef, {
        compRectsMap: new Map([[groupRef.id, groupRect]]),
      });

      return groupRef;
    });
  }

  /**
   * Ungroup components from a group and update group's container grid
   */
  function ungroupTransaction(groupRef: CompRef) {
    return editorAPI.transactions.run(async () => {
      const groupRect = layoutGetApi.get(groupRef);

      const containerRef = editorAPI.components.getContainer(groupRef);
      const containerChildrenRectsMap = new Map<string, Rect>();

      editorAPI.components.getChildren(containerRef).forEach((compRef) => {
        // NOTE: group siblings
        containerChildrenRectsMap.set(
          compRef.id,
          layoutGetApi.get_rect(compRef),
        );
      });
      editorAPI.components.getChildren(groupRef).forEach((compRef) => {
        const compRect = layoutGetApi.get_rect(compRef);
        containerChildrenRectsMap.set(compRef.id, {
          ...compRect,
          x: compRect.x + groupRect.x,
          y: compRect.y + groupRect.y,
        });
      });

      editorAPI.dsActions.components.ungroup(groupRef);

      layoutMeshCoreApi.updateContainerGrid(containerRef, {
        compRectsMap: containerChildrenRectsMap,
      });
    });
  }

  return {
    groupTransaction,
    ungroupTransaction,
  };
}
