import { translate } from '#packages/i18n';
import type { BeforePlugin } from 'types/editorPlugins';

const openSource = 'add_panel_documents';

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
) => {
  if (compDef.data.link?.docId) {
    return null;
  }
  const { mediaServices } = editorAPI;
  const compRef = originalAddComponentFunc(containerRef, compDef, optionalId);
  mediaServices.mediaManager.open(
    mediaServices.mediaManager.categories.DOCUMENT,
    openSource,
    {
      callback(items: AnyFixMe) {
        const mediaItem = items[0];
        if (!mediaItem) {
          return;
        }
        editorAPI.components.data.update(compRef, {
          title: mediaItem.title,
          link: {
            docId: mediaItem.fileUrl,
            name: mediaItem.title,
            type: 'DocumentLink',
          },
        });
        mediaServices.setFocusToEditor();
      },
      multiSelect: false,
      translation: {
        submitButton: translate('MMGR_submitbutton_addpanel_mydocs_add'),
      },
    },
  );

  return { compRef };
};

export default plugin;
