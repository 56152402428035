import constants from '#packages/constants';

export const leftBarOverrides = {
  desktop: [
    { key: constants.ROOT_COMPS.LEFTBAR.ADD_ID },
    { key: constants.ROOT_COMPS.LEFTBAR.ADD_SECTION },
    { key: constants.ROOT_COMPS.LEFTBAR.PAGES_ID },
    { key: constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_ID },
    { key: constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_ID },
    { key: constants.ROOT_COMPS.LEFTBAR.WIX_DATA_PANEL_ID },
  ],
};
