import { mathUtils } from '#packages/util';
import { FixedStageApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';

export function createLayoutContentAreaApi({
  editorAPI,
  layoutGetApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
}) {
  function getCompMargin(compRef: CompRef) {
    const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
    if (fixedStageAPI.isStageFixedInDesktop()) {
      return { left: 0, right: 0 };
    }

    const containerRelativeToScreen =
      layoutGetApi.getRelativeToScreen_rect(compRef);
    const containerRelativeToStructure =
      layoutGetApi.getRelativeToStructure_rect(compRef);

    const totalMargins =
      containerRelativeToScreen.width - containerRelativeToStructure.width;
    const siteX = editorAPI.site.getSiteX();
    const leftMarginWidth =
      containerRelativeToStructure.x + siteX - containerRelativeToScreen.x;
    const rightMarginWidth = totalMargins - leftMarginWidth;

    return {
      left: leftMarginWidth,
      right: rightMarginWidth,
    };
  }

  function getRectsIntersectionRatio(rect1: Rect, rect2: Rect) {
    const rectsIntersection = mathUtils.getSquaresIntersection(rect1, rect2);
    return (
      rectsIntersection &&
      mathUtils.rect.getAreaRatioPercentage(rectsIntersection, rect2)
    );
  }

  function isCompExceedingContainerMargin(
    compRefOrRefs: CompRef | CompRef[],
    containerRef: CompRef,
    percentOfCompAllowedOut: number,
  ) {
    const ancestorWithMarginIndicator = editorAPI.components.findAncestor(
      compRefOrRefs,
      editorAPI.components.is.showMarginsIndicator,
      { includeScopeOwner: true },
    );
    const ancestorWithMarginIndicatorOverride =
      editorAPI.components.is.getGridLinesContainerRef(
        ancestorWithMarginIndicator,
      );
    if (ancestorWithMarginIndicatorOverride) {
      containerRef = ancestorWithMarginIndicatorOverride;
    }

    const containerRelativeToScreen =
      layoutGetApi.getRelativeToScreen_rect(containerRef);
    const containerMargin = getCompMargin(containerRef);
    const compRelativeToScreen =
      layoutGetApi.getRelativeToScreen_rect(compRefOrRefs);

    const containerLayout = {
      ...containerRelativeToScreen,
      ...{
        y: 0,
        x: containerRelativeToScreen.x + containerMargin.left,
        width:
          containerRelativeToScreen.width -
          containerMargin.left -
          containerMargin.right,
        height: Number.MAX_SAFE_INTEGER,
      },
    };
    return (
      getRectsIntersectionRatio(containerLayout, compRelativeToScreen) <
      100 - percentOfCompAllowedOut
    );
  }

  return {
    getCompMargin,
    isCompExceedingContainerMargin,
  };
}

export type LayoutContentAreaApi = ReturnType<
  typeof createLayoutContentAreaApi
>;
