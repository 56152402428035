import { components } from '#packages/stateManagement';

import type { LayoutContstraintBoundaries, LayoutConstraint } from '../types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const THRESHOLD = 20;

function getBoundaries(
  editorAPI: EditorAPI,
  compPointer: CompRef,
): LayoutContstraintBoundaries {
  const repeaterItem =
    editorAPI.components.getAncestorRepeaterItem(compPointer);
  const compLayout =
    editorAPI.components.layout.getRelativeToScreen(compPointer);
  const repeaterItemLayout =
    editorAPI.components.layout.getRelativeToScreen(repeaterItem);

  return {
    left: {
      compLeftX: repeaterItemLayout.x - compLayout.width + THRESHOLD,
    },
    right: {
      compRightX:
        repeaterItemLayout.x +
        repeaterItemLayout.width +
        compLayout.width -
        THRESHOLD,
    },
    top: {
      compTopY: repeaterItemLayout.y,
    },
    bottom: {
      compBottomY: repeaterItemLayout.y + repeaterItemLayout.height,
    },
  };
}

const constraint: LayoutConstraint = {
  shouldConstrain(editorAPI, compPtr) {
    return (
      editorAPI.isMobileEditor() &&
      editorAPI.components.is.descendantOfRepeaterItem(compPtr) &&
      !components.selectors.isRepeaterItem(compPtr, editorAPI.dsRead)
    );
  },
  getBoundaries,
};

export default constraint;
