import type { ThunkAction } from 'types/redux';
import constants from '#packages/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { EditorAPI } from '#packages/editorAPI';
import * as util from '#packages/util';
import { SET_BRANCHES } from './branchesActionTypes';
import type { Branch } from './types';

const SITE_BRANCHES_ENDPOINT = `/_api/site-branches-registry/v1/site-branches`;

const setBranches = ({
  branchesList,
  currentBranchName,
  wasBranchesListFetched,
  hasBranches,
}: {
  branchesList: Branch[];
  currentBranchName: string;
  wasBranchesListFetched: boolean;
  hasBranches: boolean;
}) => ({
  type: SET_BRANCHES,
  branchesList,
  currentBranchName,
  wasBranchesListFetched,
  hasBranches,
});

const fetchBranchesList = async (editorAPI: EditorAPI): Promise<Branch[]> => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;
  try {
    const { branches } = await util.http.fetchJson(SITE_BRANCHES_ENDPOINT, {
      credentials: 'include',
      headers: new Headers({ Authorization: metaSiteInstance }),
    });
    return branches;
  } catch (e) {
    ErrorReporter.captureException(e);
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

const fetchAndAssignBranchesData =
  (currentBranchId: string): ThunkAction =>
  async (dispatch, getState, { editorAPI }) => {
    const branchesList = await fetchBranchesList(editorAPI);

    const currentBranch = branchesList.find(
      (branch) => branch.id === currentBranchId,
    );
    const currentBranchName = currentBranch?.name;
    const hasBranches = branchesList.length > 1;

    dispatch(
      setBranches({
        branchesList,
        currentBranchName,
        wasBranchesListFetched: true,
        hasBranches,
      }),
    );
    return {
      hasBranches,
    };
  };

export { fetchAndAssignBranchesData };
