import _ from 'lodash';
import constants from '#packages/constants';
import { array } from '#packages/util';

import type { FixedPositionPlugin } from './fixedPositionPlugins';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const fixedPositionRequestChange: FixedPositionPlugin = (
  editorAPI,
  compRef,
  newVal,
) => {
  const [ref] = array.asArray(compRef);
  const oldLayout = editorAPI.components.layout.getRelativeToStructure(ref);
  const newLayout = { fixedPosition: newVal } as any;

  const limit = constants.SIZE_LIMITS.MOBILE_MENU_MAX_Y;

  if (oldLayout.y > limit) {
    setContainerToHeaderIfNeeded(editorAPI, ref);
    _.merge(
      newLayout,
      editorAPI.mobile.mobileOnlyComponents.getTinyMenuDefaultPosition(),
    );
  }
  editorAPI.components.layout.update(ref, newLayout);
};

function setContainerToHeaderIfNeeded(editorAPI: EditorAPI, compRef: CompRef) {
  const currContainer = editorAPI.components.getContainer(compRef);
  const header = editorAPI.dsRead.siteSegments.getHeader();
  if (!_.isEqual(currContainer, header)) {
    editorAPI.components.setContainer(compRef, header);
  }
}

export default fixedPositionRequestChange;
