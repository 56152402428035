import { biEvents } from '#packages/coreBi';
import { arrayUtils } from '#packages/util';
import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import {
  getPinDockingDirection as getPinDockingDirection_inner,
  getPinDockingOuterOffsets as getPinDockingOuterOffsets_inner,
  calcPinDockingOffsetsDefaults,
  createFixedLayoutItemByPinDockingDirection,
  createLayoutDockingByPinDockingDirection,
  createPopupContainerPropertiesByPinDockingDirection,
  type PinDockingOptions,
  type PinDockingDirection,
  type PinDockingOuterOffsets,
} from '#packages/pinModeUtils';
import { isPopupContainer } from '#packages/documentServices';
import { hasFixedItemLayout } from '#packages/layoutUtils';
import { calulateXYInContainer_byXYRelativeToScreen } from '../layoutMeshApi/layoutMeshReparentApi/calculatePositionInContainer';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompLayout,
  CompRef,
  Docking,
  FixedItemLayout,
  PopupContainerProperties,
} from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutMeshApi } from '../layoutMeshApi';
import type { LayoutFixedPositionApi } from './createLayoutFixedPositionApi';
import type { LayoutDockApi } from './createLayoutDockApi';

export function createLayoutPinApi({
  editorAPI,
  layoutGetApi,
  layoutMeshApi,
  layoutDockApi,
  layoutFixedPositionApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutMeshApi: LayoutMeshApi;
  layoutDockApi: LayoutDockApi;
  layoutFixedPositionApi: LayoutFixedPositionApi;
}) {
  function reparentPinedComponentFromPage(compRef: CompRef) {
    const compSize = layoutGetApi.get_size(compRef);
    const compRectFromDOM =
      editorAPI.components.layout.measure.getBoundingClientRect(compRef);
    const compXYRelativeToScreen = {
      x: compRectFromDOM.absoluteLeft,
      y: compRectFromDOM.absoluteTop,
    };

    const containerRef = editorAPI.sections.getSectionLikeAtY(
      compXYRelativeToScreen.y + compSize.height / 2,
    );
    const compXYRelativeToContainer =
      calulateXYInContainer_byXYRelativeToScreen(editorAPI, {
        compXYRelativeToScreen,
        containerRef,
      });

    const compRefUpdated = arrayUtils.asArray(
      editorAPI.components.setContainer(compRef, containerRef),
    )[0];

    return {
      compRef: compRefUpdated,
      compRect: {
        ...compXYRelativeToContainer,
        ...compSize,
      },
      containerRef,
    };
  }

  async function pinInner_mesh(compRef: CompRef, itemLayout: FixedItemLayout) {
    await editorAPI.transactions.run(async () => {
      const containerRefInitial = editorAPI.components.getContainer(compRef);

      const responsiveLayoutPin = !isPinned(compRef)
        ? editorAPI.dsActions.components.responsiveLayout.pin
        : editorAPI.dsActions.components.responsiveLayout.update;

      responsiveLayoutPin(compRef, {
        itemLayout,
      });

      if (
        !editorAPI.utils.isSameRef(
          containerRefInitial,
          editorAPI.components.getContainer(compRef),
        )
      ) {
        // component was reparented from the initial container, so need to update container grid
        layoutMeshApi.updateContainerGrid(containerRefInitial);
      }
    });
  }

  async function pinInner_defaultDocking(compRef: CompRef, docking: Docking) {
    if (!isPinned(compRef)) {
      layoutFixedPositionApi.setFixed(compRef, true);
    }

    layoutDockApi.setDock(compRef, docking);
  }

  async function pinToDirectionInner_mesh(
    compRef: CompRef,
    { dockingDirection, dockingOffsets }: PinDockingOptions,
  ) {
    const layouts = layoutMeshApi.get(compRef);
    const itemLayout = createFixedLayoutItemByPinDockingDirection(layouts, {
      dockingDirection,
      dockingOffsets,
    });

    await pinInner_mesh(compRef, itemLayout);
  }

  async function pinToDirectionInner_defaultPopupProperties(
    compRef: CompRef,
    { dockingDirection, dockingOffsets }: PinDockingOptions,
  ) {
    const properties =
      editorAPI.dsRead.components.properties.get<PopupContainerProperties>(
        compRef,
      );

    const propertiesUpdated =
      createPopupContainerPropertiesByPinDockingDirection(
        properties.alignmentType,
        {
          dockingDirection,
          dockingOffsets,
        },
      );
    editorAPI.components.properties.update(compRef, propertiesUpdated);
    // TODO: do we really need to update the mobile component additionally?
    // https://github.com/wix-private/santa-editor/blob/f8594982c4d585e2316802b665c5c958e97a7ad3/santa-editor/packages/compPanels/dynamicPanels/popupGridLayoutPanel/popupGridLayoutPanel.tsx#L136
    // source: https://github.com/wix-private/santa-editor/commit/4c836d3557c9275beced14c37a8cce81e959923a#diff-a9f1991640f6d268b3615517f8827f8c287b00b10eada3977aca58fa8eee4f3dR119
    const compRefMobile = editorAPI.components.get.byId(
      compRef.id,
      editorAPI.components.getPage(compRef).id,
      editorAPI.viewMode.VIEW_MODES.MOBILE,
    );
    editorAPI.components.properties.update(compRefMobile, propertiesUpdated);
  }

  async function pinToDirectionInner_defaultDocking(
    compRef: CompRef,
    { dockingDirection, dockingOffsets }: PinDockingOptions,
  ) {
    const compLayout = layoutGetApi.get(compRef);
    const docking = createLayoutDockingByPinDockingDirection(compLayout, {
      dockingDirection,
      dockingOffsets,
    });

    pinInner_defaultDocking(compRef, docking);
  }

  async function pinToDirection(
    compRef: CompRef,
    {
      dockingDirection,
      dockingOffsets,
      biOrigin,
    }: PinDockingOptions & {
      biOrigin?: string;
    },
  ) {
    const isPinnedCurrent = isPinned(compRef);

    dockingOffsets =
      // if component
      // 1. is already pinned, and direction is not changed,
      // use current offsets (+ `dockingOffsets` if provided)
      // 2. is not pinned, or direction is changed,
      // use default offsets (+ `dockingOffsets` if provided)
      isPinnedCurrent && getPinDockingDirection(compRef) === dockingDirection
        ? {
            ...getPinDockingOffsets(compRef),
            ...dockingOffsets,
          }
        : {
            ...calcPinDockingOffsetsDefaults(dockingDirection, 0),
            ...dockingOffsets,
          };

    if (!isPinnedCurrent) {
      editorAPI.bi.event(biEvents.pinToScreen.FIRST_PIN_TO_SCREEN, {
        component_id: compRef.id,
        component_type: editorAPI.components.getType(compRef),
        selected_area: dockingDirection,
        origin: biOrigin,
      });
    }

    if (isMeshLayoutEnabled()) {
      await pinToDirectionInner_mesh(compRef, {
        dockingDirection,
        dockingOffsets,
      });
      return;
    }

    if (isPopupContainer(editorAPI.dsRead, compRef)) {
      await pinToDirectionInner_defaultPopupProperties(compRef, {
        dockingDirection,
        dockingOffsets,
      });
      return;
    }

    await pinToDirectionInner_defaultDocking(compRef, {
      dockingDirection,
      dockingOffsets,
    });

    // TODO: move history.add from the pinnedDockPanel and popupGridLayoutPanel to here
  }

  function unPinHeaderOrFooter_mesh(compRef: CompRef) {
    const isHeader = editorAPI.utils.isSameRef(
      compRef,
      editorAPI.dsRead.siteSegments.getHeader(),
    );
    const isFooter = editorAPI.utils.isSameRef(
      compRef,
      editorAPI.dsRead.siteSegments.getFooter(),
    );

    if (!isHeader && !isFooter) {
      return;
    }

    editorAPI.components.responsiveLayout.update(compRef, {
      itemLayout: {
        type: 'MeshItemLayout',
        gridArea: isHeader
          ? { rowStart: 1, rowEnd: 2 }
          : { rowStart: 2, rowEnd: 3 },
        left: { type: 'px', value: 0 },
        right: { type: 'px', value: 0 },
      },
    });
  }

  async function unPinInner_mesh(compRef: CompRef) {
    if (editorAPI.components.is.siteSegment(compRef)) {
      unPinHeaderOrFooter_mesh(compRef);
      return;
    }

    await editorAPI.transactions.run(async () => {
      const {
        compRef: compRefAfterReparent,
        compRect,
        containerRef,
      } = reparentPinedComponentFromPage(compRef);

      layoutMeshApi.updateContainerGrid(containerRef, {
        compRectsMap: new Map([[compRefAfterReparent.id, compRect]]),
        convertChildrenToMeshItemLayout: true,
      });

      // TODO: remove this after https://wix.slack.com/archives/C04EX7N2BCP/p1696500962578659
      editorAPI.dsActions.components.layout.update(compRefAfterReparent, {
        fixedPosition: false,
      });
    });
  }

  function couldBeUnPinned(compRef: CompRef) {
    if (isPopupContainer(editorAPI.dsRead, compRef)) {
      return false;
    }

    return true;
  }

  async function unPin(
    compRef: CompRef,
    {
      biOrigin,
    }: {
      biOrigin?: string;
    } = {},
  ) {
    if (!isPinned(compRef)) {
      return;
    }

    if (!couldBeUnPinned(compRef)) {
      throw new Error(`Component ${compRef.id} can't be unpinned.`);
    }

    if (isMeshLayoutEnabled()) {
      await unPinInner_mesh(compRef);
    } else {
      layoutDockApi.unDock(compRef);
      layoutFixedPositionApi.setFixed(compRef, false);
    }

    editorAPI.bi.event(biEvents.pinToScreen.UNPIN, {
      origin: biOrigin,
      component_type: editorAPI.components.getType(compRef),
      component_id: compRef.id,
    });

    // TODO: move history.add from the pinnedDockPanel to here
  }

  /**
   * @deprecated Use isPinned instead
   * https://wix.slack.com/archives/C04EX7N2BCP/p1695403836550189
   */
  function isPinnedByLayout_deprecated(serializedLayout: Partial<CompLayout>) {
    if (isMeshLayoutEnabled()) {
      throw new Error(
        'isPinnedByLayout is not supported for mesh layout.\nUse isPinned',
      );
    }

    // - streatched
    // left/rigt
    // top/bottom
    // - fixed
    return (
      typeof serializedLayout.docked !== 'undefined' &&
      serializedLayout.fixedPosition
    );
  }

  function isPinned(compRef: CompRef): boolean {
    if (isMeshLayoutEnabled()) {
      const layouts = layoutMeshApi.get(compRef);

      return hasFixedItemLayout(layouts);
    }

    if (isPopupContainer(editorAPI.dsRead, compRef)) {
      return true;
    }

    const serializedLayout = editorAPI.dsRead.components.layout.get(compRef);
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    const docked = editorAPI.dsRead.components.layout.getDock(compRef);

    return isPinnedByLayout_deprecated({
      ...serializedLayout,
      docked,
    });
  }

  /**
   * Editor UI expects that `isPinned` returns `true` only for the cases when old `layout.docked` was defined
   * ```
   * isPinned: layout.fixedPosition === true && layout.docked !== undefined
   * ```
   * With the new layout structure `FixedItemLayout`, we need to preserve this behaviour.
   */
  function isPinned_backwarCompatible(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    return compRefs.every((compRef) => {
      if (editorAPI.components.is.fixedNonPinned(compRefs)) {
        return false;
      }

      if (
        isMeshLayoutEnabled() &&
        editorAPI.components.is.showLegacyFixedPosition(compRef)
      ) {
        // NOTE: We have different behaviour for old layout and new layout:
        // `!isPinned`: layout.fixedPosition === true && layout.docked === undefined
        // `isPinned`: layout.fixedPosition === true && layout.docked !== undefined
        // https://wix.slack.com/archives/C04EX7N2BCP/p1695403836550189
        return false;
      }

      if (isPopupContainer(editorAPI.dsRead, compRef)) {
        return false;
      }

      return isPinned(compRef);
    });
  }

  function getPinDockingDirection(compRef: CompRef): PinDockingDirection {
    return getPinDockingDirection_inner(editorAPI, compRef);
  }

  function getPinDockingOffsets(compRef: CompRef): PinDockingOuterOffsets {
    return getPinDockingOuterOffsets_inner(editorAPI, compRef);
  }

  return {
    pinToDirection,
    unPin,
    isPinned: isPinned_backwarCompatible,
    isPinnedByLayout_deprecated,
    getPinDockingDirection,
    getPinDockingOffsets,
  };
}

export type LayoutPinApi = ReturnType<typeof createLayoutPinApi>;
