import constants from '#packages/constants';
import type { EditorState } from '#packages/stateManagement';
import {
  COLOR_VAR_CATEGORY_USER,
  StylableEditor,
} from '@wix/stylable-panel-drivers';
import type { Dispatch } from 'types/redux';
import * as services from '../services/services';
import * as userPreferences from '../userPreferences/userPreferences';

const STYLABLE_EDITOR_SERVICE_NAME = 'stylableEditor';
const { SITE_PATH, INITIAL_PALETTE_COLORS } = constants.STYLABLE.EDITOR;
const USER_COLORS_KEY = constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED;

const USER_GRADIENTS_KEY =
  constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED_GRADIENTS;
const USER_SITE_COLORS_KEY =
  constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_SITE_ADDED;
const ALWAYS_SHOWN_COLORS: string[] = Object.values(INITIAL_PALETTE_COLORS);

const servicesActions = services.actions;
const userPreferencesSelectors = userPreferences.selectors;

/**
 * stateManagement.stylableEditor.actions.load
 * Loads a StylableEditor object to services and performs required initializations
 * @param {StylableEditor} stylableEditor - StylableEditor object to load as a service
 */
const loadStylableEditor =
  (stylableEditor: StylableEditor) => (dispatch: Dispatch) => {
    dispatch(
      servicesActions.loadService(STYLABLE_EDITOR_SERVICE_NAME, stylableEditor),
    );
  };

/**
 * stateManagement.stylableEditor.actions.unload
 * Unloads the StylableEditor object from services
 */
const unloadStylableEditor = () => (dispatch: Dispatch) =>
  dispatch(servicesActions.loadService(STYLABLE_EDITOR_SERVICE_NAME, null));

/**
 * stateManagement.stylableEditor.actions.setUserColors
 * Extracts the editor user colors/gradients and sets them in the site stylesheet
 */
const editorSetUserColorsAndGradients =
  () =>
  (
    _dispatch: Dispatch,
    getState: () => EditorState,
    { stylableEditor }: { stylableEditor: StylableEditor },
  ) => {
    const state = getState();
    const siteVarsDriver =
      stylableEditor.stylableDriver.getSiteVarsDriver(SITE_PATH);

    const userSessionColors =
      (userPreferencesSelectors.getSessionUserPreferences(USER_COLORS_KEY)(
        state,
      ) as string[]) || [];

    const userSiteColors =
      (userPreferencesSelectors.getSiteUserPreferences(USER_SITE_COLORS_KEY)(
        state,
      ) as string[]) || [];

    const allUserColors = new Set([
      ...ALWAYS_SHOWN_COLORS,
      ...userSessionColors,
      ...userSiteColors,
    ]);
    const uniqueUserColors = Array.from(allUserColors);

    siteVarsDriver.clearCategory(COLOR_VAR_CATEGORY_USER);
    uniqueUserColors.forEach((color) => siteVarsDriver.addUserColor(color));

    const userGradients =
      (userPreferencesSelectors.getSiteUserPreferences(USER_GRADIENTS_KEY)(
        state,
      ) as string[]) || [];

    siteVarsDriver.addCategory(
      'gradient',
      (index = '(\\d+)') => `gradient_${index}`,
    );
    siteVarsDriver.clearCategory('gradient');

    userGradients.forEach((gradient) =>
      siteVarsDriver.addValue('gradient', gradient),
    );
  };

export {
  loadStylableEditor as load,
  unloadStylableEditor as unload,
  editorSetUserColorsAndGradients as setUserColors,
};
