import { isNumber } from '../utils';
import { calculateComponentLayoutHeight } from '../calculateComponentLayoutWidthAndHeight';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { HistoryApi, HistoryAddOptions } from '../../createHistoryApi';
import type { LayoutMeshApi } from '../createLayoutMeshApi';

export function resizeToAndPushTransition(
  {
    editorAPI,
    historyApi,
    layoutMeshCoreApi,
  }: {
    editorAPI: EditorAPI;
    historyApi: HistoryApi;
    layoutMeshCoreApi: LayoutMeshApi['__core'];
  },
  compRefRaw: CompRef,
) {
  const compRef = layoutMeshCoreApi.resolveCompRefWithVariant(compRefRaw);
  const compLayouInitial = layoutMeshCoreApi.get(compRef);

  const update = (yOrHeight: { y?: number; height?: number }) => {
    if (!isNumber(yOrHeight.height)) {
      if (isNumber(yOrHeight.y)) {
        throw new Error('resizeToAndPush with `y` is not supported yet');
      }

      throw new Error('resizeToAndPush `height` must be a number');
    }

    const { height: heightUpdated } =
      editorAPI.components.layout.getConstrainedLayout(compRef, {
        height: yOrHeight.height,
      });

    const { height, minHeight } = calculateComponentLayoutHeight(
      compLayouInitial,
      heightUpdated,
    );

    layoutMeshCoreApi.update(compRef, {
      componentLayout: {
        ...compLayouInitial.componentLayout,
        minHeight,
        height,
      },
    });
  };

  const end = async (options: HistoryAddOptions & {} = {}) => {
    await editorAPI.transactions.run(async () => {
      layoutMeshCoreApi.updateContainerGrid(
        editorAPI.components.getContainer(compRef),
      );
    });

    historyApi.add('component - resize and push', options);
  };

  return {
    update,
    end,
  };
}
