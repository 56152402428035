// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as helpIds from '#packages/helpIds';
import { getRefComponentRootChild } from '#packages/documentServices';
import * as formPlugin from './formPlugin';
import * as componentPastePlugins from './componentPastePlugins';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

function runFormPlugin(compTypes, editorAPI) {
  const formRefs =
    editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(compTypes);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(formRefs, function (compRef) {
    const data = editorAPI.components.data.get(compRef);
    const clonedData = _.clone(data);
    formPlugin.updateDataWithDefaults(data, editorAPI);
    if (!_.isEqual(data, clonedData)) {
      editorAPI.components.data.update(compRef, data);
    }
  });
}

function registerComponentPastePlugins(editorAPI) {
  editorAPI.registerPasteComponentPlugin(
    '*',
    componentPastePlugins.adjustMobileTextScaling,
  );
  editorAPI.registerPasteComponentPlugin(
    'platform.components.AppController',
    componentPastePlugins.removeConnectedComponentsIfAlreadyExist,
  );
  editorAPI.registerPasteComponentPlugin(
    '*',
    componentPastePlugins.removeSerializedDataId,
  );
  editorAPI.registerAddComponentPlugin(
    '*',
    componentPastePlugins.stretchAppWidgetBottomOnConnectedComponentPaste,
  );
}

function registerCannotRemovePlugin(editorAPI: EditorAPI) {
  editorAPI.registerCannotRemovePlugin('*', (compRef: CompRef) => {
    const { isRefComponent, isReferredComponent } =
      stateManagement.components.selectors;

    if (!isReferredComponent(compRef)) {
      return;
    }

    const connectedComponent = isRefComponent(compRef, editorAPI.dsRead)
      ? getRefComponentRootChild(editorAPI.dsRead, compRef)
      : compRef;
    const stageData =
      editorAPI.dsRead.platform.controllers.getConnectedComponentStageData(
        connectedComponent,
      );

    const isEssential = stageData?.behavior?.essential;
    const preventHide = isEssential || stageData?.behavior?.preventHide;

    if (preventHide) {
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'PLATFORM_Notification_open_close_cannot_delete_element',
          title: 'Delete by ref inner comp',
          type: 'error',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick: () => {
              editorAPI.panelManager.openHelpCenter(
                helpIds.NOTIFICATIONS.CANNOT_DELETE_REF_COMPONENT_INNER_COMP,
              );
            },
          },
        }),
      );
    }
  });
}

export default [
  runFormPlugin.bind(null, [
    'wysiwyg.viewer.components.ContactForm',
    'wysiwyg.viewer.components.DynamicContactForm',
    'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
  ]),
  registerCannotRemovePlugin,
  registerComponentPastePlugins,
];
