import _ from 'lodash';
import * as util from '#packages/util';
const DOMAIN_CONNECTED_OK_CODE = 0;
const HTTP_PREFIX = 'http://';
let PREMIUM_STATE_URL_FOR_SITE = util.serviceTopology.premiumStateApiUrl;
PREMIUM_STATE_URL_FOR_SITE = PREMIUM_STATE_URL_FOR_SITE
  ? PREMIUM_STATE_URL_FOR_SITE.replace(
      /wix\/getTpaPremiumState/,
      'premium-management/external/getDocPremiumState?siteGuid=',
    )
  : '//editor.wix.com/_api/premium-management/external/getDocPremiumState?siteGuid=';

function isDomainConnectedInResponse(response: AnyFixMe) {
  return (
    _.has(response, 'payload.domain.domainName') &&
    _.has(response, 'payload.domain.checkStatus') &&
    response.payload.domain.checkStatus === DOMAIN_CONNECTED_OK_CODE
  );
}

function getPublishedSiteDomain(response: AnyFixMe) {
  let domainName = response?.payload?.domain?.domainName ?? '';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (!_.isEmpty(domainName) && !_.includes(domainName, HTTP_PREFIX)) {
    domainName = HTTP_PREFIX + domainName;
  }
  return !_.isEmpty(domainName) ? domainName : null;
}

function isSitePremiumInResponse(response: AnyFixMe) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
  return !_.isNil(response?.payload?.plan?.cycle);
}

export interface SiteDomainInfo {
  isDomainConnected: boolean;
  isSitePremium: boolean;
  sitePublishedDomain: string;
}

function getSiteDomainInfo(
  siteId: string,
  onSuccess: (info: SiteDomainInfo) => void,
  onFail: (error?: unknown) => void,
) {
  const fetchInit = {
    contentType: 'application/json',
    credentials: 'same-origin',
    method: 'GET',
  };
  util.http
    .fetchJson(PREMIUM_STATE_URL_FOR_SITE + siteId, fetchInit)
    .then((response: AnyFixMe) => {
      const isDomainConnected = isDomainConnectedInResponse(response);
      const sitePublishedDomain = getPublishedSiteDomain(response);
      const isSitePremium = isSitePremiumInResponse(response);
      onSuccess({
        isDomainConnected,
        sitePublishedDomain,
        isSitePremium,
      });
    })
    .catch(onFail);
}

export { getSiteDomainInfo };
