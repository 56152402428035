import type { CompRef, DSRead } from 'types/documentServices';

export function layoutGetRotationInDegrees(dsRead: DSRead, compRef: CompRef) {
  const rotationInDegrees =
    dsRead.components.transformations.get(compRef)?.rotate || 0;

  return rotationInDegrees;
}

export function layoutIsRotated(dsRead: DSRead, compRef: CompRef) {
  const rotationInDegrees = layoutGetRotationInDegrees(dsRead, compRef);

  return typeof rotationInDegrees === 'number' && rotationInDegrees !== 0;
}
