import * as permanentBorderActions from '../permanentBorder/permanentBorderActions';
import _ from 'lodash';
import applicationStudioPresets from './applicationStudioPresets.json';
import * as selection from '../selection/selection';
import * as coreBi from '#packages/coreBi';
import { waitForAddedCompRef } from '#packages/componentsAddUtils';
import constants from '#packages/constants';
import * as bi from '../bi/bi';
import * as selectors from './applicationStudioSelectors';
import { layoutUtils } from '#packages/layoutUtils';
import * as copyPaste from '../copyPaste/copyPaste';
import actionTypes from './applicationStudioActionTypes';
import type { ThunkAction } from 'types/redux';
import type { CompRef } from 'types/documentServices';
const copyPasteSelectors = copyPaste.selectors;

const appBuilderBI = coreBi.events.AppBuilder;
const appStudioConstants = constants.APP_STUDIO;
const selectionActions = selection.actions;

const updateControllerWidgetMap = (map: AnyFixMe) => ({
  type: actionTypes.UPDATE_WIDGETS_MAP,
  map,
});

const updateControllerWidgetMapByWidgetPointer =
  (widgetPointer: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsRead }: AnyFixMe) => {
    const appWidget = selectors.getAppWidgetByWidgetPointer(
      dsRead,
      widgetPointer,
    );
    dispatch(updateControllerWidgetMap({ [appWidget.id]: widgetPointer }));
  };

const getNameByPointer = (dsRead: AnyFixMe, pointer: AnyFixMe) =>
  dsRead.appStudio.widgets.name.get(pointer);

const displayWidget =
  (widgetId: AnyFixMe, callback?: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsActions }: AnyFixMe) => {
    dispatch(permanentBorderActions.setComponent(null));
    dispatch(selectionActions.clearSelectedComponents());

    dsActions.appStudio.widgets.display(widgetId, callback);
  };

const getWidgetStructure = (
  widgetRootType = 'wysiwyg.viewer.components.MediaBox',
) => {
  const structure =
    applicationStudioPresets[
      widgetRootType as keyof typeof applicationStudioPresets
    ];
  return structure;
};

const createWidget =
  (widgetStructure: AnyFixMe, widgetRootType?: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    if (!widgetStructure) {
      widgetStructure = getWidgetStructure(widgetRootType);
    }

    const widgetDataId = dsActions.appStudio.widgets.create(
      { initialWidgetRootStructure: widgetStructure },
      (widgetPointer: AnyFixMe) => {
        dispatch(
          displayWidget(widgetPointer, () => {
            const currentPageId = dsRead.pages.getCurrentPageId();
            const fileId =
              editorAPI.wixCode.fileSystem.getFileIdFromPageId(currentPageId);
            const fileDescriptor =
              editorAPI.wixCode.fileSystem.getVirtualDescriptor(fileId, false);

            return editorAPI.wixCode.fileSystem.writeFile(
              fileDescriptor,
              appStudioConstants.CODE.INITIAL_CONTENT,
            );
          }),
        );
        dispatch(updateControllerWidgetMapByWidgetPointer(widgetPointer));
        dispatch(
          sendBi(appBuilderBI.add_widget, {
            widget_id: widgetPointer.id,
            widget_name: getNameByPointer(dsRead, widgetPointer),
            origin: 'leftBar',
          }),
        );
      },
    );
    editorAPI.history.add('created widget', { isAddingComponent: true });
    return widgetDataId;
  };

const renameWidget =
  (pointer: AnyFixMe, newName: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, editorAPI }: AnyFixMe,
  ) => {
    dsActions.appStudio.widgets.name.set(pointer, newName);
    editorAPI.history.add('renamed widget');
    dispatch(
      sendBi(appBuilderBI.rename_widget, {
        widget_name: newName,
        widget_id: pointer.id,
      }),
    );
  };

const duplicateWidget =
  (
    pointer: AnyFixMe,
    options = {
      widgetName: '',
      shouldNavigate: true,
      shouldReportBi: true,
      shouldAddToHistory: true,
    },
    callback = _.noop,
  ) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    const { widgetName, shouldNavigate, shouldReportBi, shouldAddToHistory } =
      options;
    const onWidgetDuplicated = (widgetPointer: AnyFixMe) => {
      dispatch(updateControllerWidgetMapByWidgetPointer(widgetPointer));
      if (shouldReportBi) {
        dispatch(
          sendBi(appBuilderBI.duplicate_widget, {
            origin_id: pointer.id,
            widget_id: widgetPointer.id,
            widget_name: getNameByPointer(dsRead, widgetPointer),
            origin: 'leftBar',
          }),
        );
      }
      if (shouldAddToHistory) {
        editorAPI.history.add('duplicated widget', { isAddingComponent: true });
      }
      callback(widgetPointer);
    };
    dsActions.appStudio.widgets.duplicate(
      pointer,
      (widgetPointer: AnyFixMe) => {
        if (shouldNavigate) {
          dispatch(
            displayWidget(widgetPointer, () => {
              onWidgetDuplicated(widgetPointer);
            }),
          );
        } else {
          onWidgetDuplicated(widgetPointer);
        }
      },
      { widgetName },
    );
  };

const deleteWidget =
  (
    pointer: AnyFixMe,
    options = { shouldAddToHistory: true, shouldReportBi: true },
  ) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    const { shouldAddToHistory, shouldReportBi } = options;
    const rootCompId = dsRead.pages.getCurrentPageId();
    const selectedWidgetId = _.get(
      dsRead.appStudio.widgets.getByRootCompId(rootCompId),
      'id',
    );
    const allWidgets = dsRead.appStudio.widgets.getAll();

    if (allWidgets.length === 1) {
      return;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const widgetIndex = _.findIndex(allWidgets, { pointer });
    const newWidgetIndexToDisplay = widgetIndex ? 0 : 1;

    const removeWidget = () => {
      dsActions.appStudio.widgets.remove(pointer);
      if (shouldAddToHistory) {
        editorAPI.history.add('deleted widget');
      }
    };

    if (selectedWidgetId === pointer.id) {
      const nextWidgetToOpen = allWidgets[newWidgetIndexToDisplay];
      dispatch(
        displayWidget(nextWidgetToOpen.pointer, () => {
          removeWidget();
        }),
      );
    } else {
      removeWidget();
    }
    if (shouldReportBi) {
      dispatch(
        sendBi(appBuilderBI.delete_widget, {
          widget_name: getNameByPointer(dsRead, pointer),
          widget_id: pointer.id,
        }),
      );
    }
  };

const sendBi =
  (eventType: AnyFixMe, eventParams: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    dispatch(
      bi.actions.event(
        eventType,
        _.defaults({ app_id: selectors.getAppDefId(getState()) }, eventParams),
      ),
    );
  };

const createVariation =
  (
    widgetPointer: AnyFixMe,
    initialRootStructure: AnyFixMe,
    shouldNavigate = true,
    variationName: AnyFixMe,
    callback?: AnyFixMe,
  ) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    const widgetPageId = dsRead.appStudio.widgets.getRootCompId(widgetPointer);
    let variationPointer: AnyFixMe;
    if (initialRootStructure) {
      variationPointer = dsActions.appStudio.widgets.variations.create(
        widgetPointer,
        initialRootStructure,
        null,
        variationName,
      );
    } else {
      variationPointer = dsActions.appStudio.widgets.variations.create(
        widgetPointer,
        null,
        widgetPageId,
        variationName,
      );
    }
    editorAPI.dsActions.waitForChangesApplied(() => {
      dispatch(updateControllerWidgetMapByWidgetPointer(variationPointer));
      if (shouldNavigate) {
        dispatch(
          displayWidget(variationPointer, () => {
            dispatch(
              sendBi(appBuilderBI.add_variation, {
                widget_id: widgetPointer.id,
              }),
            );
          }),
        );
      }
      if (callback) {
        callback(variationPointer);
      }
    });
    editorAPI.history.add('created variation');
    return variationPointer;
  };

const renameVariation =
  (widgetPointer: AnyFixMe, variationPointer: AnyFixMe, newName: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, editorAPI }: AnyFixMe,
  ) => {
    dsActions.appStudio.widgets.variations.name.set(
      variationPointer,
      widgetPointer,
      newName,
    );
    editorAPI.history.add('renamed variation');
  };

const duplicateVariation =
  (widgetPointer: AnyFixMe, variationPointer: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    const duplicatedPageId =
      dsRead.appStudio.widgets.getRootCompId(variationPointer);
    const newVariationPointer = dsActions.appStudio.widgets.variations.create(
      widgetPointer,
      null,
      duplicatedPageId,
    );
    editorAPI.dsActions.waitForChangesApplied(() => {
      dispatch(updateControllerWidgetMapByWidgetPointer(newVariationPointer));
      dispatch(displayWidget(newVariationPointer));
    });
    editorAPI.history.add('duplicated variation');
    return newVariationPointer;
  };

const actualDeleteVariation = (
  editorAPI: AnyFixMe,
  dsActions: AnyFixMe,
  variationPointer: AnyFixMe,
  widgetPointer: AnyFixMe,
) => {
  dsActions.appStudio.widgets.variations.remove(
    variationPointer,
    widgetPointer,
  );
  editorAPI.history.add('deleted variation');
};

const deleteVariation =
  (widgetPointer: AnyFixMe, variationPointer: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { dsActions, dsRead, editorAPI }: AnyFixMe,
  ) => {
    const currentPageId = dsRead.pages.getCurrentPageId();
    const currentVariationPointer =
      dsRead.appStudio.widgets.variations.getByRootCompId(currentPageId) || {};
    const isCurrentVariation =
      variationPointer.id === currentVariationPointer.id;
    if (isCurrentVariation) {
      const allWidgetVariations =
        dsRead.appStudio.widgets.variations.getWidgetVariations(widgetPointer);
      if (allWidgetVariations.length === 1) {
        return;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
      const variationIndex = _.findIndex(allWidgetVariations, {
        pointer: variationPointer,
      });
      const newVariationToDisplayIndex = variationIndex ? 0 : 1;
      const newVariationToDisplay =
        allWidgetVariations[newVariationToDisplayIndex];
      dispatch(
        displayWidget(newVariationToDisplay.pointer, () => {
          actualDeleteVariation(
            editorAPI,
            dsActions,
            variationPointer,
            widgetPointer,
          );
        }),
      );
    } else {
      actualDeleteVariation(
        editorAPI,
        dsActions,
        variationPointer,
        widgetPointer,
      );
    }
  };

const addCompToVariation =
  (originalCompRef: CompRef, containerRef: CompRef): ThunkAction =>
  async (dispatch, getState, { dsActions, editorAPI }) => {
    const compLayout = editorAPI.components.layout.get(originalCompRef);
    const containerLayout = editorAPI.components.layout.get_size(containerRef);
    const isCompOutOfContainer =
      compLayout.x + compLayout.width > containerLayout.width ||
      compLayout.y + compLayout.height > containerLayout.height;

    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const originalStructure = editorAPI.components.serialize(originalCompRef);
    const duplicateCompRef = await waitForAddedCompRef(
      editorAPI.components.add(containerRef, originalStructure),
    );

    const containerToFitContent = copyPasteSelectors.getContainerToFitContent(
      editorAPI,
      containerRef,
    );
    const containerCurrentLayout = editorAPI.components.layout.get(
      containerToFitContent,
    );
    const containerLayoutFitToContent =
      layoutUtils.getContainerLayoutToFitContent(
        containerCurrentLayout,
        compLayout,
      );
    const pastePosition = copyPasteSelectors.getCompPastePositionToFitContainer(
      editorAPI.store.getState(),
      containerCurrentLayout,
      compLayout,
    );

    if (isCompOutOfContainer) {
      dsActions.components.layout.update(
        containerToFitContent,
        containerLayoutFitToContent,
      );
      dsActions.components.layout.update(duplicateCompRef, pastePosition);
    }

    editorAPI.history.add('add comp to variation');
    return duplicateCompRef;
  };

export {
  updateControllerWidgetMap,
  displayWidget,
  createWidget,
  duplicateWidget,
  renameWidget,
  deleteWidget,
  sendBi,
  createVariation,
  renameVariation,
  duplicateVariation,
  deleteVariation,
  addCompToVariation,
};
