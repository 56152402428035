// @ts-nocheck
import _ from 'lodash';
import { layoutTransitionsUtil } from '#packages/layoutUtils';
import sectionsReorganizeUtil from '../sectionsReorganizeUtil';
import constants from '#packages/constants';

const DRAG_DELAY = 0;

let editorAPI;
let sections;
let draggedSection;
let lastDragY;
let isInitialDrag;
let dragTransition;
let onEndCallback;
let onStartCallback;
let allowDrag;
let minY;

function getDragY(evt, scale) {
  return (
    (evt.clientY + editorAPI.ui.scroll.getScroll().scrollTop) / (scale || 1)
  );
}

function getSectionLayout(dragY) {
  const mouse = { x: 0, y: dragY };
  const layout = dragTransition.getLayout(mouse);
  layout.y = Math.max(layout.y, minY);

  return layout;
}

function updateDraggedSectionComponentLayouts(sectionLayout) {
  const individualCompLayouts =
    dragTransition.getBoundComponentLayouts(sectionLayout);
  for (let i = 0; i < draggedSection.comps.length; i++) {
    editorAPI.components.layout.updateRelativeToScreen(
      draggedSection.comps[i],
      _.pick(individualCompLayouts[i], 'y'),
      true,
    );
  }
}

function onInitialDrag() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const draggedCompIds = _.map(draggedSection.comps, 'id');
  if (!_.isEmpty(draggedCompIds)) {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(draggedCompIds);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity(
      draggedCompIds,
      0.5,
    );
  }

  if (_.isFunction(onStartCallback)) {
    onStartCallback();
  }

  window.setTimeout(function () {
    clearSectionsOverlaps();
    allowDrag = true;
  }, DRAG_DELAY);
}

function getDragDirection(dragY) {
  return dragY > lastDragY ? 1 : -1;
}

function switchSectionsIfAtSwitchPoint(dragY, draggedSectionLayout) {
  const dragDirection = getDragDirection(dragY);
  lastDragY = dragY;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const draggedSectionIndex = _.findIndex(sections, draggedSection);
  const possibleSwitchedSectionIndex = draggedSectionIndex + dragDirection;
  if (
    possibleSwitchedSectionIndex >= sections.length ||
    possibleSwitchedSectionIndex < 0
  ) {
    return false;
  }

  const possibleSwitchedSection = sections[possibleSwitchedSectionIndex];
  if (
    possibleSwitchedSection &&
    sectionsReorganizeUtil.isAtSectionsSwitchPoint(
      draggedSectionLayout,
      possibleSwitchedSection,
      dragDirection,
    )
  ) {
    sectionsReorganizeUtil.switchSectionsByDrag(
      editorAPI,
      sections,
      draggedSection,
      possibleSwitchedSection,
    );
    _.defer(clearSectionsOverlaps);
    return true;
  }

  return false;
}

function snapSectionToPosition(dragY) {
  const sectionLayout = getSectionLayout({
    y: dragY,
    x: 0,
  });
  sectionLayout.y = draggedSection.position.top;
  updateDraggedSectionComponentLayouts(sectionLayout);
}

function areSectionsOverlapping(topSection, bottomSection) {
  return bottomSection.position.top < topSection.position.bottom;
}

function clearSectionsOverlaps() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const draggedSectionIdx = _.findIndex(sections, draggedSection);
  const previousSection = sections[draggedSectionIdx - 1];
  const nextSection = sections[draggedSectionIdx + 1];
  let draggedSectionShiftOffset = 0;
  let bottomSectionsShiftOffset = 0;

  if (nextSection && areSectionsOverlapping(draggedSection, nextSection)) {
    bottomSectionsShiftOffset =
      draggedSection.position.bottom - nextSection.position.top;
  }

  if (
    previousSection &&
    areSectionsOverlapping(previousSection, draggedSection)
  ) {
    draggedSectionShiftOffset =
      previousSection.position.bottom - draggedSection.position.top;
    bottomSectionsShiftOffset += draggedSectionShiftOffset;
  }

  if (draggedSectionShiftOffset) {
    sectionsReorganizeUtil.shiftSection(
      editorAPI,
      draggedSection,
      draggedSectionShiftOffset,
    );
  }

  if (bottomSectionsShiftOffset) {
    for (let i = draggedSectionIdx + 1; i < sections.length; i++) {
      sectionsReorganizeUtil.shiftSection(
        editorAPI,
        sections[i],
        bottomSectionsShiftOffset,
      );
    }
  }
}

function getInitAbsLayout() {
  return {
    y: draggedSection.position.top,
    height: draggedSection.position.bottom - draggedSection.position.top,
  };
}

function startDrag(_editorAPI, params) {
  allowDrag = false;
  isInitialDrag = true;

  editorAPI = _editorAPI;
  draggedSection = params.draggedSection;
  if (!draggedSection) {
    return;
  }
  const initialDragPosition = {
    x: 0,
    y: getDragY(params.evt, editorAPI.getSiteScale()),
  };
  lastDragY = initialDragPosition.y;
  sections = _.sortBy(params.sections, 'position.top');
  onEndCallback = params.onDragEnd;
  onStartCallback = params.onDragStart;
  minY = params.minY || 0;

  const initAbsLayout = getInitAbsLayout();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const boundCompLayoutsRelativeToScreen = _.map(
    draggedSection.comps,
    function (comp) {
      return editorAPI.components.layout.getRelativeToScreen(comp);
    },
  );
  dragTransition = new layoutTransitionsUtil.Drag(
    initAbsLayout,
    initialDragPosition,
    boundCompLayoutsRelativeToScreen,
  );
}

function onDrag(evt) {
  if (!draggedSection) {
    return;
  }

  if (isInitialDrag) {
    isInitialDrag = false;

    onInitialDrag();
  } else if (allowDrag) {
    const dragY = getDragY(evt, editorAPI.getSiteScale());
    const sectionLayout = getSectionLayout(dragY);
    updateDraggedSectionComponentLayouts(sectionLayout);

    switchSectionsIfAtSwitchPoint(dragY, sectionLayout);
  }
}

function endDrag(evt) {
  const dragY = getDragY(evt);

  if (draggedSection && !isInitialDrag) {
    snapSectionToPosition(dragY);

    editorAPI.history.add('Dragged Components Section');
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity([]);
  }

  if (_.isFunction(onEndCallback)) {
    onEndCallback(draggedSection);
  }
}

export default {
  start: startDrag,
  on: onDrag,
  end: endDrag,
  type: constants.MOUSE_ACTION_TYPES.SECTION_DRAG,
};
