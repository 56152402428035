// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import { workspace as workspaceUtil } from '#packages/util';
import { layoutUtils } from '#packages/layoutUtils';
import type React from 'react';
import type { Point, Position, Dimensions, Box } from 'types/core';
import type { CompLayout } from 'types/documentServices';
import type { Viewport } from '#packages/stateManagement';

let GFPP_MARGINS;
const PANEL_MARGINS = constants.UI.compPanelMargins;

interface Positions {
  gfpp: Position;
  compPanel: Position;
}

interface VerticalAlignmentDef {
  gfpp: Alignment;
  compPanel: Alignment;
}

export interface KnobMargins {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
interface CustomPosBoundingRect {
  y: number;
  x: number;
  width: number;
  height: number;
  bottomY: number;
  rightX: number;
}

type Alignment =
  | 'compTop'
  | 'compBottom'
  | 'compLeft'
  | 'compRight'
  | 'viewPortTop'
  | 'viewPortBottom'
  | 'viewPortLeft'
  | 'viewPortRight'
  | 'gfppLeft'
  | 'gfppRight';

const ALIGNMENTS = {
  COMP_TOP: 'compTop',
  COMP_BOTTOM: 'compBottom',
  COMP_LEFT: 'compLeft',
  COMP_RIGHT: 'compRight',
  VIEWPORT_TOP: 'viewPortTop',
  VIEWPORT_BOTTOM: 'viewPortBottom',
  VIEWPORT_LEFT: 'viewPortLeft',
  VIEWPORT_RIGHT: 'viewPortRight',
  GFPP_LEFT: 'gfppLeft',
  GFPP_RIGHT: 'gfppRight',
};

function getCustomPosBoundingRect(customPos: Point) {
  const customPosX =
    customPos.x + constants.UI.CUSTOM_GFPP_POS.X_OFFSET_FROM_CLICK;
  const customPosY = customPos.y;
  return {
    y: customPosY,
    x: customPosX,
    width: 0,
    height: 0,
    bottomY: customPosY,
    rightX: customPosX,
  };
}

function getCompBoundingRect(compLayout: CompLayout) {
  return {
    y: compLayout.y,
    x: compLayout.x,
    width: compLayout.width,
    height: compLayout.height,
    bottomY: compLayout.y + compLayout.height,
    rightX: compLayout.x + compLayout.width,
  };
}

function getGfppVerticalAlignment(compBoundingRect, sizesAndConstraints) {
  const viewPort =
    sizesAndConstraints.viewPortForChildOfFocused ||
    sizesAndConstraints.viewPort;

  const hasNavControls = Boolean(sizesAndConstraints.navControlsSize?.height);
  const verticalGfppMargins = hasNavControls
    ? constants.UI.VIEWPORT_VERTICAL_MARGINS_NAV_CONTROLS
    : constants.UI.VIEWPORT_VERTICAL_MARGINS_GFPP;

  if (
    compBoundingRect.y >=
    viewPort.y - viewPort.marginTop + verticalGfppMargins
  ) {
    return ALIGNMENTS.COMP_TOP;
  } else if (
    compBoundingRect.bottomY <=
    viewPort.bottomY + viewPort.marginBottom - verticalGfppMargins
  ) {
    return ALIGNMENTS.COMP_BOTTOM;
  }

  return ALIGNMENTS.VIEWPORT_TOP;
}

function getCompPanelVerticalAlignment(
  compBoundingRect,
  sizesAndConstraints,
  compPanelElm,
) {
  const { viewPort } = sizesAndConstraints;
  const compTopToViewPortBottom = viewPort.bottomY - compBoundingRect.y;
  const compBottomToViewPortTop = compBoundingRect.bottomY - viewPort.y;

  const panelHeight = compPanelElm
    ? compPanelElm.getBoundingClientRect().height
    : 0;

  const compExceedsViewPortTop = compBoundingRect.y < viewPort.y;
  const panelCanFitBetweenCompTopAndViewPortBottom =
    panelHeight <= compTopToViewPortBottom;
  const compExceedsViewPortBottom = compBoundingRect.bottomY > viewPort.bottomY;
  const panelCanFitBetweenCompBottomAndViewPortTop =
    panelHeight <= compBottomToViewPortTop;
  const panelCanFitWithinViewport =
    panelHeight <= viewPort.bottomY - viewPort.y;

  if (compExceedsViewPortTop || !panelCanFitWithinViewport) {
    return ALIGNMENTS.VIEWPORT_TOP;
  } else if (panelCanFitBetweenCompTopAndViewPortBottom) {
    return ALIGNMENTS.COMP_TOP;
  } else if (compExceedsViewPortBottom) {
    return ALIGNMENTS.VIEWPORT_BOTTOM;
  } else if (panelCanFitBetweenCompBottomAndViewPortTop) {
    return ALIGNMENTS.COMP_BOTTOM;
  }

  return ALIGNMENTS.VIEWPORT_TOP;
}

function getVerticalAlignment(
  compBoundingRect,
  sizesAndConstraints,
  compPanelElm,
) {
  return {
    gfpp: getGfppVerticalAlignment(compBoundingRect, sizesAndConstraints),
    compPanel: getCompPanelVerticalAlignment(
      compBoundingRect,
      sizesAndConstraints,
      compPanelElm,
    ),
  };
}

function calculateGfppVerticalPosition(
  alignment,
  compBoundingRect,
  viewPort,
  gfppHeight,
  rotateKnobMargins,
) {
  const offsetTopFromComp =
    Math.max(GFPP_MARGINS, rotateKnobMargins?.top || 0) + gfppHeight;
  const offsetTopFromViewPort = gfppHeight + GFPP_MARGINS;
  const gfppBottomOffset = Math.max(
    GFPP_MARGINS,
    rotateKnobMargins?.bottom || 0,
  );

  switch (alignment) {
    case ALIGNMENTS.COMP_TOP:
      return Math.max(
        compBoundingRect.y - offsetTopFromComp,
        viewPort.y - offsetTopFromViewPort,
      );
    case ALIGNMENTS.COMP_BOTTOM:
      return compBoundingRect.bottomY + gfppBottomOffset;
    case ALIGNMENTS.VIEWPORT_TOP:
      return viewPort.y;
  }
}

function calculateCompPanelVerticalPosition(
  alignment,
  compBoundingRect,
  viewPort,
  compPanelHeight,
) {
  switch (alignment) {
    case ALIGNMENTS.COMP_BOTTOM:
      return compBoundingRect.bottomY - compPanelHeight;
    case ALIGNMENTS.COMP_TOP:
      return compBoundingRect.y;
    case ALIGNMENTS.VIEWPORT_BOTTOM:
      return viewPort.bottomY - compPanelHeight;
  }

  return viewPort.y; // fallback to viewport top
}

function calculateGfppHorizontalPosition(
  alignment,
  compBoundingRect,
  viewPort,
  gfppWidth,
  pageXBoundary,
) {
  if (isFullWidthComp(compBoundingRect, viewPort.width)) {
    const leftBoundary = Math.max(pageXBoundary, viewPort.x);
    return leftBoundary;
  }

  switch (alignment) {
    case ALIGNMENTS.COMP_LEFT:
      return compBoundingRect.x;
    case ALIGNMENTS.VIEWPORT_LEFT:
      return viewPort.x;
    case ALIGNMENTS.VIEWPORT_RIGHT:
      return viewPort.rightX - gfppWidth;
  }
}

function calculateCompPanelHorizontalPosition(
  alignment,
  compBoundingRect,
  viewPort,
  compPanelWidth,
  gfppWidth,
  gfppX,
  rotateKnobMargins,
) {
  const compWidth = compBoundingRect.rightX - compBoundingRect.x;
  switch (alignment) {
    case ALIGNMENTS.COMP_LEFT:
      return (
        compBoundingRect.x -
        compPanelWidth -
        Math.max(PANEL_MARGINS, rotateKnobMargins?.left || 0)
      );
    case ALIGNMENTS.COMP_RIGHT:
      return (
        compBoundingRect.x +
        compWidth +
        Math.max(PANEL_MARGINS, rotateKnobMargins?.right || 0)
      );
    case ALIGNMENTS.VIEWPORT_LEFT:
      return viewPort.x;
    case ALIGNMENTS.VIEWPORT_RIGHT:
      return viewPort.rightX - compPanelWidth - PANEL_MARGINS;
    case ALIGNMENTS.GFPP_LEFT:
      return gfppX - compPanelWidth - PANEL_MARGINS;
    case ALIGNMENTS.GFPP_RIGHT:
      return gfppX + gfppWidth + PANEL_MARGINS;
  }
}

function getDimensions(element) {
  return element ? element.getBoundingClientRect() : { width: 0, height: 0 };
}

function calculatePositionByAlignment(
  verticalAlignment,
  horizontalAlignment,
  compBoundingRect,
  compPanelElm,
  sizesAndConstraints,
) {
  const { gfppSize } = sizesAndConstraints;
  const { viewPort } = sizesAndConstraints;
  const { rotateKnobMargins } = sizesAndConstraints;
  const { pageXBoundary } = sizesAndConstraints;
  const { alignToPageBoundaries } = sizesAndConstraints;

  const positions: Positions = {
    gfpp: {
      top: 0,
      left: 0,
    },
    compPanel: {
      top: 0,
      left: 0,
    },
  };

  const gfppHeight = gfppSize.height;
  const gfppWidth = gfppSize.width;

  const { width, height } = getDimensions(compPanelElm);
  const compPanelHeight = height;
  const compPanelWidth = width;

  positions.gfpp.top = calculateGfppVerticalPosition(
    verticalAlignment.gfpp,
    compBoundingRect,
    viewPort,
    gfppHeight,
    rotateKnobMargins,
  );
  positions.gfpp.left = calculateGfppHorizontalPosition(
    horizontalAlignment.gfpp,
    compBoundingRect,
    viewPort,
    gfppWidth,
    pageXBoundary,
  );

  if (
    alignToPageBoundaries ||
    isFullWidthComp(compBoundingRect, viewPort.width)
  ) {
    const leftBoundary = Math.max(pageXBoundary, viewPort.x);
    const panelPositions = getFullWidthCompPanelPosition(
      compBoundingRect,
      viewPort,
      compPanelElm,
      gfppSize.height,
      leftBoundary,
      verticalAlignment.gfpp,
      positions.gfpp.top,
    );
    positions.compPanel = panelPositions;
  } else {
    positions.compPanel.top = calculateCompPanelVerticalPosition(
      verticalAlignment.compPanel,
      compBoundingRect,
      viewPort,
      compPanelHeight,
    );
    positions.compPanel.left = calculateCompPanelHorizontalPosition(
      horizontalAlignment.compPanel,
      compBoundingRect,
      viewPort,
      compPanelWidth,
      gfppWidth,
      positions.gfpp.left,
      rotateKnobMargins,
    );
  }

  if (sizesAndConstraints.draggableSlots?.length > 0) {
    positions.gfpp = recalculateGfppPositionAccordingToDraggableSlots(
      positions,
      gfppSize,
      sizesAndConstraints.draggableSlots,
      verticalAlignment,
      compBoundingRect,
      viewPort,
      rotateKnobMargins,
    );
  }

  return positions;
}

function recalculateGfppPositionAccordingToDraggableSlots(
  positions: Positions,
  gfppSize: Dimensions,
  draggableSlots: Box[],
  verticalAlignment: VerticalAlignmentDef,
  compBoundingRect: CustomPosBoundingRect,
  viewPort: Viewport,
  rotateKnobMargins: KnobMargins,
): Positions {
  let draggableOverlapIndex = getDraggableOverlapIndex(
    draggableSlots,
    positions,
    gfppSize,
  );

  // In case we dont have the height of the draggableSlot that overlaps,
  // we will use a default height.
  const defaultExtraHeight = 50;

  if (draggableOverlapIndex >= 0) {
    if (verticalAlignment.gfpp === ALIGNMENTS.COMP_TOP) {
      positions.gfpp.top = calculateGfppVerticalPosition(
        ALIGNMENTS.COMP_BOTTOM,
        compBoundingRect,
        viewPort,
        gfppSize.height,
        rotateKnobMargins,
      );
      draggableSlots.splice(draggableOverlapIndex, 1);
      draggableOverlapIndex = getDraggableOverlapIndex(
        draggableSlots,
        positions,
        gfppSize,
      );
      if (draggableOverlapIndex >= 0 || positions.gfpp.top > viewPort.bottomY) {
        const extraHeight =
          draggableSlots[draggableOverlapIndex]?.height || defaultExtraHeight;
        positions.gfpp.top =
          calculateGfppVerticalPosition(
            ALIGNMENTS.COMP_TOP,
            compBoundingRect,
            viewPort,
            gfppSize.height,
            rotateKnobMargins,
          ) +
          gfppSize.height +
          extraHeight;
      }
    } else if (verticalAlignment.gfpp === ALIGNMENTS.COMP_BOTTOM) {
      positions.gfpp.top = calculateGfppVerticalPosition(
        ALIGNMENTS.VIEWPORT_TOP,
        compBoundingRect,
        viewPort,
        gfppSize.height,
        rotateKnobMargins,
      );

      draggableSlots.splice(draggableOverlapIndex, 1);
      draggableOverlapIndex = getDraggableOverlapIndex(
        draggableSlots,
        positions,
        gfppSize,
      );
      if (draggableOverlapIndex >= 0) {
        positions.gfpp.top +=
          gfppSize.height + draggableSlots[draggableOverlapIndex].height;
      }
    } else if (verticalAlignment.gfpp === ALIGNMENTS.VIEWPORT_TOP) {
      positions.gfpp.top +=
        gfppSize.height + draggableSlots[draggableOverlapIndex].height;
    }
  }
  return positions.gfpp;
}

function getDraggableOverlapIndex(
  draggableSlots: Box[],
  positions: Positions,
  gfppSize: Dimensions,
): number {
  return draggableSlots.findIndex((draggableSlot) =>
    layoutUtils.doBoxesOverlap(draggableSlot, {
      y: positions.gfpp.top,
      x: positions.gfpp.left,
      width: gfppSize.width,
      height: gfppSize.height,
    }),
  );
}

function getGfppHorizontalAlignment(compBoundingRect, viewPort, gfppWidth) {
  let result;
  if (compBoundingRect.x >= viewPort.x) {
    if (gfppWidth + compBoundingRect.x <= viewPort.rightX) {
      result = ALIGNMENTS.COMP_LEFT;
    } else {
      result = ALIGNMENTS.VIEWPORT_RIGHT;
    }
  } else {
    result = ALIGNMENTS.VIEWPORT_LEFT;
  }
  return result;
}

function getNavControlsHorizontalAlignment(
  compBoundingRect,
  viewPort,
  navControlsWidth,
) {
  let result;
  if (compBoundingRect.x >= viewPort.x) {
    if (
      navControlsWidth +
        compBoundingRect.x +
        constants.UI.NAVIGATION_CONTROLS_POS.LEFT <=
      viewPort.rightX
    ) {
      result = ALIGNMENTS.COMP_LEFT;
    } else {
      result = ALIGNMENTS.VIEWPORT_RIGHT;
    }
  } else {
    result = ALIGNMENTS.VIEWPORT_LEFT;
  }

  return result;
}

function getCustomGfppPos(
  customGfppPos,
  viewPort,
  gfppSize,
  navControlsSize,
): React.CSSProperties {
  const result: React.CSSProperties = {};
  const compBoundingRect = getCustomPosBoundingRect(customGfppPos);
  const customGfppVerticalAlignment = getVerticalAlignment(compBoundingRect, {
    viewPort,
    navControlsSize,
  }).gfpp;
  const customGfppHorizontalAlignment = getGfppHorizontalAlignment(
    compBoundingRect,
    viewPort,
    gfppSize.width,
  );

  result.top = calculateGfppVerticalPosition(
    customGfppVerticalAlignment,
    compBoundingRect,
    viewPort,
    gfppSize.height,
  );
  result.left = calculateGfppHorizontalPosition(
    customGfppHorizontalAlignment,
    compBoundingRect,
    viewPort,
    gfppSize.width,
    0,
  );

  return result;
}

function getNavControlsPos(
  verticalAlignment,
  horizontalAlignment,
  compBoundingRect,
  sizesAndConstraints,
) {
  const { navControlsSize } = sizesAndConstraints;
  const { viewPort } = sizesAndConstraints;
  const { pageXBoundary } = sizesAndConstraints;

  const style = {};

  if (isFullWidthComp(compBoundingRect, viewPort.width)) {
    const leftBoundary = Math.max(pageXBoundary, viewPort.x);
    style.left =
      leftBoundary -
      compBoundingRect.x +
      constants.UI.NAVIGATION_CONTROLS_POS.LEFT;
  } else {
    const isNewWorkspace = workspaceUtil.isNewWorkspaceEnabled();
    switch (horizontalAlignment.navControls) {
      case ALIGNMENTS.VIEWPORT_LEFT:
        if (isNewWorkspace) {
          style.left = constants.UI.NAVIGATION_CONTROLS_POS.LEFT;
        } else {
          style.left =
            viewPort.x -
            compBoundingRect.x +
            constants.UI.NAVIGATION_CONTROLS_POS.LEFT;
        }
        break;
      case ALIGNMENTS.VIEWPORT_RIGHT:
        style.left =
          viewPort.rightX -
          (compBoundingRect.x +
            navControlsSize.width +
            constants.UI.NAVIGATION_CONTROLS_POS.LEFT);
        break;
      case ALIGNMENTS.COMP_LEFT:
        style.left = constants.UI.NAVIGATION_CONTROLS_POS.LEFT;
    }
  }

  switch (verticalAlignment.gfpp) {
    case ALIGNMENTS.COMP_BOTTOM:
      style.top = constants.UI.NAVIGATION_CONTROLS_POS.BOTTOM;
      break;
    case ALIGNMENTS.COMP_TOP:
    case ALIGNMENTS.VIEWPORT_TOP:
      style.top = constants.UI.NAVIGATION_CONTROLS_POS.TOP;
  }
  return style;
}

function getHorizontalAlignment(
  compBoundingRect,
  verticalAlignment,
  compPanelElm,
  sizesAndConstraints,
) {
  const { gfppSize } = sizesAndConstraints;
  const { viewPort } = sizesAndConstraints;
  const { navControlsSize } = sizesAndConstraints;

  const gfppWidth = gfppSize.width;
  const compPanelWidth = compPanelElm
    ? compPanelElm.getBoundingClientRect().width
    : 0;
  const compWidth = compBoundingRect.rightX - compBoundingRect.x;

  const alignment = {};
  let panelCanFitToTheRightOfComp, panelCanFitToTheLeftOfComp;

  alignment.gfpp = getGfppHorizontalAlignment(
    compBoundingRect,
    viewPort,
    gfppWidth,
  );
  alignment.navControls = getNavControlsHorizontalAlignment(
    compBoundingRect,
    viewPort,
    navControlsSize.width,
  );

  // panel position
  if (verticalAlignment.gfpp === verticalAlignment.compPanel) {
    panelCanFitToTheRightOfComp =
      compBoundingRect.rightX + compPanelWidth + PANEL_MARGINS <=
      viewPort.rightX;
    panelCanFitToTheLeftOfComp =
      compBoundingRect.x - compPanelWidth - PANEL_MARGINS >= viewPort.x;

    if (compBoundingRect.rightX < viewPort.x) {
      alignment.compPanel = ALIGNMENTS.VIEWPORT_LEFT;
    } else if (panelCanFitToTheRightOfComp) {
      alignment.compPanel = ALIGNMENTS.COMP_RIGHT;
    } else if (panelCanFitToTheLeftOfComp) {
      alignment.compPanel = ALIGNMENTS.COMP_LEFT;
    } else {
      alignment.compPanel = ALIGNMENTS.VIEWPORT_RIGHT;
    }
  } else if (alignment.gfpp === ALIGNMENTS.COMP_LEFT) {
    if (gfppWidth <= compWidth) {
      panelCanFitToTheRightOfComp =
        compBoundingRect.x + compWidth + PANEL_MARGINS + compPanelWidth <
        viewPort.rightX;
      panelCanFitToTheLeftOfComp =
        compBoundingRect.x - PANEL_MARGINS - compPanelWidth >= viewPort.x;

      if (panelCanFitToTheRightOfComp) {
        alignment.compPanel = ALIGNMENTS.COMP_RIGHT;
      } else if (panelCanFitToTheLeftOfComp) {
        alignment.compPanel = ALIGNMENTS.COMP_LEFT;
      } else {
        //no room on the left side
        alignment.compPanel = ALIGNMENTS.VIEWPORT_RIGHT;
      }
    } else {
      panelCanFitToTheLeftOfComp =
        compBoundingRect.x - PANEL_MARGINS - compPanelWidth >= viewPort.x;

      if (panelCanFitToTheLeftOfComp) {
        alignment.compPanel = ALIGNMENTS.COMP_LEFT;
      } else {
        alignment.compPanel = ALIGNMENTS.GFPP_RIGHT;
      }
    }
  } else if (alignment.gfpp === ALIGNMENTS.VIEWPORT_RIGHT) {
    alignment.compPanel = ALIGNMENTS.GFPP_LEFT;
  } else if (alignment.gfpp === ALIGNMENTS.VIEWPORT_LEFT) {
    panelCanFitToTheRightOfComp =
      Math.max(viewPort.x + gfppWidth, compBoundingRect.x + compWidth) +
        compPanelWidth <=
      viewPort.rightX;

    if (panelCanFitToTheRightOfComp) {
      if (viewPort.x + gfppWidth > compBoundingRect.x + compWidth) {
        alignment.compPanel = ALIGNMENTS.GFPP_RIGHT;
      } else {
        alignment.compPanel = ALIGNMENTS.COMP_RIGHT;
      }
    } else {
      alignment.compPanel = ALIGNMENTS.VIEWPORT_RIGHT;
    }
  }

  return alignment;
}

function getViewPortForChildOfFocusedComponent(
  viewPort,
  focusedBoundingRect,
  selectedBoundingRect,
) {
  if (
    focusedBoundingRect.height <
    selectedBoundingRect.height +
      constants.UI.VIEWPORT_VERTICAL_MARGINS_GFPP * 2
  ) {
    return viewPort;
  }

  const result = {
    y: Math.max(viewPort.y, focusedBoundingRect.y + viewPort.marginTop),
    height: Math.min(
      viewPort.height,
      focusedBoundingRect.height - viewPort.marginTop - viewPort.marginBottom,
    ),
    bottomY: Math.min(
      viewPort.bottomY,
      focusedBoundingRect.bottomY - viewPort.marginBottom,
    ),
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, viewPort, result);
}

function getEditBoxLabelsOffset(navControlsStyle, sizesAndConstrains) {
  const navControlsWidth = sizesAndConstrains.navControlsSize.width;

  if (navControlsWidth) {
    return (
      navControlsStyle.left +
      sizesAndConstrains.navControlsSize.width +
      constants.UI.MARGIN_BETWEEN_FOCUS_TAB_AND_COMP_LABELS
    );
  }

  return 0;
}

function calculateCompPositions(
  compBoundingRect,
  compPanelElm,
  sizesAndConstraints,
) {
  const verticalAlignment = getVerticalAlignment(
    compBoundingRect,
    sizesAndConstraints,
    compPanelElm,
  );
  const horizontalAlignment = getHorizontalAlignment(
    compBoundingRect,
    verticalAlignment,
    compPanelElm,
    sizesAndConstraints,
  );
  const positions = calculatePositionByAlignment(
    verticalAlignment,
    horizontalAlignment,
    compBoundingRect,
    compPanelElm,
    sizesAndConstraints,
  );
  const gfppStyle: React.CSSProperties = positions.gfpp;
  const compPanelStyle: React.CSSProperties = positions.compPanel;
  const navControlsStyle: React.CSSProperties = getNavControlsPos(
    verticalAlignment,
    horizontalAlignment,
    compBoundingRect,
    sizesAndConstraints,
  );
  const editBoxLabelsOffset: number = getEditBoxLabelsOffset(
    navControlsStyle,
    sizesAndConstraints,
  );

  return {
    gfppStyle,
    compPanelStyle,
    navControlsStyle,
    editBoxLabelsOffset,
  };
}

function isFullWidthComp(compLayout, viewPortWidth) {
  return compLayout.x < 1 && compLayout.width >= viewPortWidth;
}

function getFullWidthCompPanelPosition(
  compBoundingRect,
  viewPort,
  compPanelElm,
  gfppHeight,
  leftBoundary,
  gfppVerticalAlignment,
  gfppTop,
) {
  const position = {
    left: 0,
    top: 0,
  };

  const { width, height } = getDimensions(compPanelElm);

  const compPanelHeight = height;
  const compPanelWidth = width;

  if (compPanelWidth === 0 || compPanelHeight === 0) {
    return {
      left: 0,
      top: 0,
    };
  }

  const compPanelNormalVerticalAlignment = getCompPanelVerticalAlignment(
    compBoundingRect,
    { viewPort },
    compPanelElm,
  );
  const compPanelNormalTop = calculateCompPanelVerticalPosition(
    compPanelNormalVerticalAlignment,
    compBoundingRect,
    viewPort,
    compPanelHeight,
  );

  const panelCanFitBetweenViewPortLeftAndPageXBoundary =
    compPanelWidth <= leftBoundary - viewPort.x;
  const panelCanFitBetweenGfppTopAndViewPortBottom =
    compPanelHeight <= viewPort.bottomY - gfppTop;
  const panelCanFitBetweenGfppTopAndViewPortTop =
    gfppVerticalAlignment === ALIGNMENTS.COMP_TOP &&
    compPanelHeight <= gfppTop - viewPort.y - PANEL_MARGINS;
  const panelCanFitBetweenGfppBottomAndViewPortBottom =
    gfppVerticalAlignment === ALIGNMENTS.COMP_BOTTOM &&
    compPanelHeight <=
      viewPort.bottomY - (gfppTop + gfppHeight) - PANEL_MARGINS;

  if (panelCanFitBetweenViewPortLeftAndPageXBoundary) {
    position.left = leftBoundary - (compPanelWidth + PANEL_MARGINS / 2);
    position.top = panelCanFitBetweenGfppTopAndViewPortBottom
      ? gfppTop
      : viewPort.bottomY - compPanelHeight;
  } else if (panelCanFitBetweenGfppTopAndViewPortTop) {
    position.left = leftBoundary;
    position.top = gfppTop - (compPanelHeight + GFPP_MARGINS);
  } else if (panelCanFitBetweenGfppBottomAndViewPortBottom) {
    position.left = leftBoundary;
    position.top = gfppTop + gfppHeight + GFPP_MARGINS;
  } else {
    position.left = viewPort.rightX - compPanelWidth;
    position.top = compPanelNormalTop;
  }

  return position;
}

export default {
  /**
   *
   * @param {Object} focusedCompParams - layout and id for the focused component (with nav controls on it)
   * @param {string} focusedCompParams.id - the id of the focused component
   * @param {Object} focusedCompParams.layout - the layout of the focused component
   * @param compPanelElm
   * @param {Object} selectedCompParams - layout and id for the selected component
   * @param {string} selectedCompParams.id - the id of the selected component
   * @param {Object} selectedCompParams.layout - the layout of the selected component
   * @param {Object} sizesAndConstraints
   * @param {Object} sizesAndConstraints.viewPort - viewPort layout
   * @param {Object} sizesAndConstraints.gfppSize
   * @param {Object} sizesAndConstraints.navControlsSize
   * @param {Object} sizesAndConstraints.pageXBoundary - the x of the pagesContinaer, relative to screen
   * @param {Object} sizesAndConstraints.rotateKnobMargins - margins for the rotate knob when exists
   * @param gfppClickPosition
   * @returns {*}
   */
  getCompControlsPositions(
    focusedCompParams,
    compPanelElm,
    selectedCompParams,
    initialSizesAndConstraints,
    gfppClickPosition?,
    gfppMarginsOverride?,
    appContainer?,
    isInteractionsCompControls?: boolean,
    shouldSubtractStageLeftMargin?: boolean,
  ) {
    const DEFAULTS = {
      gfppSize: { width: 0, height: 0 },
      navControlsSize: { width: 0, height: 0 },
      pageXBoundary: 0,
      rotateKnobMargins: {},
      alignToPageBoundaries: false,
    };

    let sizesAndConstraints = _.cloneDeep(initialSizesAndConstraints);

    if (shouldSubtractStageLeftMargin) {
      const { viewPort } = sizesAndConstraints;
      const stageLeftMargin =
        viewPort.stageLayout.left - viewPort.stageLayout.x;
      viewPort.rightX -= stageLeftMargin;
      viewPort.x -= stageLeftMargin;
    }

    sizesAndConstraints = _(sizesAndConstraints)
      .pickBy()
      .defaults(DEFAULTS)
      .value();

    let result, focusedBoundingRect;
    const selectedBoundingRect = getCompBoundingRect(selectedCompParams.layout);
    const selectedCompIsChildOfFocused =
      focusedCompParams && focusedCompParams.id !== selectedCompParams.id;

    GFPP_MARGINS = constants.UI.gfppMargins;
    if (gfppMarginsOverride) {
      GFPP_MARGINS = gfppMarginsOverride;
    } else if (
      isInteractionsCompControls ||
      (focusedCompParams && !selectedCompIsChildOfFocused)
    ) {
      GFPP_MARGINS = constants.UI.navControlsGfppMargins;
    } else if (selectedCompParams.hasLabels) {
      GFPP_MARGINS = constants.UI.componentLabelsGfppMargins;
    }

    if (focusedCompParams) {
      focusedBoundingRect = getCompBoundingRect(focusedCompParams.layout);
      result = calculateCompPositions(
        focusedBoundingRect,
        compPanelElm,
        sizesAndConstraints,
      );
    } else {
      result = calculateCompPositions(
        selectedBoundingRect,
        compPanelElm,
        sizesAndConstraints,
      );

      if (appContainer) {
        result.editBoxLabelsOffset = 0;
      }
    }

    if (focusedCompParams && selectedCompIsChildOfFocused) {
      const adjustedViewPort = getViewPortForChildOfFocusedComponent(
        sizesAndConstraints.viewPort,
        focusedBoundingRect,
        selectedBoundingRect,
      );
      const selectedCompPositions = calculateCompPositions(
        selectedBoundingRect,
        compPanelElm,
        _.defaults(
          { viewPortForChildOfFocused: adjustedViewPort },
          sizesAndConstraints,
        ),
      );
      result.gfppStyle = selectedCompPositions.gfppStyle;
      result.compPanelStyle = selectedCompPositions.compPanelStyle;

      //if component is child of focused no need to shift labels in editBox for it
      result.editBoxLabelsOffset = 0;
    }

    if (gfppClickPosition) {
      result.gfppStyle = getCustomGfppPos(
        gfppClickPosition,
        sizesAndConstraints.viewPort,
        sizesAndConstraints.gfppSize,
        sizesAndConstraints.navControlsSize,
      );
    }

    return result;
  },

  getGfppPosition(gfppSize, compLayout, viewPort) {
    GFPP_MARGINS = constants.UI.gfppMargins;

    const compBoundingRect = getCompBoundingRect(compLayout);

    const gfppVerticalAlignment = getGfppVerticalAlignment(compBoundingRect, {
      viewPort,
    });
    const gfppHorizontalAlignment = getGfppHorizontalAlignment(
      compBoundingRect,
      viewPort,
      gfppSize.width,
    );

    const top = calculateGfppVerticalPosition(
      gfppVerticalAlignment,
      compBoundingRect,
      viewPort,
      gfppSize.height,
    );
    const left = calculateGfppHorizontalPosition(
      gfppHorizontalAlignment,
      compBoundingRect,
      viewPort,
      gfppSize.width,
      0,
    );

    return {
      top,
      left,
    };
  },
};
