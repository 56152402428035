// @ts-nocheck
import { userPreferences } from '#packages/stateManagement';

function getIdByDataType(structure) {
  const designStructure = structure?.design ?? {};
  let data = structure?.data ?? designStructure;
  switch (data.type) {
    case 'VectorImage':
      return data.svgId;
    case 'Image':
      return data.uri;
    case 'WixVideo':
      data = structure?.design ?? {};
      return data.videoId || '';
    case 'BackgroundMedia':
      data = structure?.design ?? {};
      return getIdByDataType(data.mediaRef) || '';
    case 'MediaPlayerDesignData':
      return structure?.design?.background?.mediaRef?.videoId ?? '';
    default:
      return '';
  }
}

const storePathWithDispatch = (
  dispatch,
  structurePreset,
  mediaManagerPreset = {},
) => {
  const { path, maskPath } = mediaManagerPreset;

  if (path) {
    dispatch(
      userPreferences.actions.setSessionUserPreferences(
        `last_media_path_${getIdByDataType(structurePreset)}`,
        path,
      ),
    );
  }

  if (maskPath) {
    dispatch(
      userPreferences.actions.setSessionUserPreferences(
        `last_svg_mask_path_${getIdByDataType(structurePreset)}`,
        maskPath,
      ),
    );
  }
};

/**
 * Save media manager path for current component to site prefs
 * Expects a single comp
 * @param editorAPI
 * @param mediaManagerPreset
 * @param structure
 */
function storePath(editorAPI, structurePreset, mediaManagerPreset = {}) {
  storePathWithDispatch(
    editorAPI.store.dispatch,
    structurePreset,
    mediaManagerPreset,
  );
}

export { getIdByDataType, storePath, storePathWithDispatch };
