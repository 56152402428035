import * as a11y from './a11y/a11y';
import * as addPanel from './addPanel/addPanel';
import * as ai from './ai/ai';
import * as applicationStudio from './applicationStudio/applicationStudio';
import * as applyModeFromClipboard from './applyModeFromClipboard/applyModeFromClipboard';
import * as appsStore from './appsStore/appsStore';
import ascend from './ascend/ascend';
import * as attachCandidate from './attachCandidate/attachCandidate';
import * as background from './background/background';
import { hoc as biHoc, actions as biActions } from './bi/bi';
import * as blog from './blog/blog';
import * as boxSlideShow from './boxSlideShow/boxSlideShow';
import * as branches from './branches/branches';
import * as businessManager from './businessManager/businessManager';
import * as componentLocator from './componentLocator/componentLocator';
import * as componentMetaData from './componentMetaData/componentMetaData';
import * as components from './components/components';
import * as componentsLoadService from './componentsLoadService/componentsLoadService';
import * as componentsStore from './componentsStore/componentsStore';
import * as concurrentUsers from './concurrentUsers/concurrentUsers';
import config from './config/config';
import * as contextMenu from './contextMenu/contextMenu';
import * as copyPaste from './copyPaste/copyPaste';
import * as dealer from './dealer/dealer';
import * as designPanel from './designPanel/designPanel';
import * as developerToolBar from './developerToolBar/developerToolBar';
import * as domain from './domain/domain';
import * as domainSuggestions from './domainSuggestions/domainSuggestions';
import * as domMeasurements from './domMeasurements/domMeasurements';
import * as draggableSlots from './draggable/draggableSlots/draggableSlots';
import * as dynamicPages from './dynamicPages/dynamicPages';
import * as tpaDynamicPages from './tpaDynamicPages/tpaDynamicPages';
import { editorSearch } from './editorSearch/editorSearch';
import * as externalLinks from './externalLinks/externalLinks';
import * as featuresOverridden from './featuresOverridden/featuresOverridden';
import * as googleMap from './googleMap/googleMap';
import * as gridLines from './gridLines/gridLines';
import * as helpHome from './helpHome/helpHome';
import * as helpPanel from './helpPanel/helpPanel';
import * as highlights from './highlights/highlights';
import * as history from './history/history';
import * as hoverBox from './hoverBox/hoverBox';
import * as inlinePopup from './inlinePopup/inlinePopup';
import { interactions } from './interactions/interactions';
import * as introFunnel from './introFunnel/introFunnel';
import * as layers from './layers/layers';
import * as layersPanel from './layersPanel/layersPanel';
import * as leavePopup from './leavePopup/leavePopup';
import * as leftBar from './leftBar/leftBar';
import * as leftTree from './leftTree/leftTree';
import * as linkPanel from './linkPanel/linkPanel';
import * as localMode from './localMode/localMode';
import * as media from './media/media';
import * as mobile from './mobile/mobile';
import * as mouseActions from './mouseActions/mouseActions';
import * as multilingual from './multilingual/multilingual';
import * as newAddPanel from './newAddPanel/newAddPanel';
import * as newReleases from './newReleases/newReleases';
import * as notifications from './notifications/notifications';
import * as pages from './pages/pages';
import * as panels from './panels/panels';
import * as permanentBorder from './permanentBorder/permanentBorder';
import {
  selectors as pinModeSelectors,
  actions as pinModeActions,
} from './pinMode/pinMode';
import * as platform from './platform/platform';
import * as preloadings from './preloadings/preloadings';
import * as preview from './preview/preview';
import * as publishingStatus from './publishingStatus/publishingStatus';
import * as testSiteStatus from './testSiteStatus/testSiteStatus';
import * as relatedApps from './relatedApps/relatedApps';
import * as rendererPanelsWorkerService from './rendererPanelsWorkerService/rendererPanelsWorkerService';
import * as rightClickMenu from './rightClickMenu/rightClickMenu';
import * as rulers from './rulers/rulers';
import * as savedComponents from './savedComponents/savedComponents';
import * as savePublish from './savePublish/savePublish';
import * as schoolMode from './schoolMode/schoolMode';
import * as secondaryLeftArea from './secondaryLeftArea/secondaryLeftArea';
import * as sectionedPanel from './sectionedPanel/sectionedPanel';
import { sections } from './sections/sections';
import { sectionsOnStage } from './sectionsOnStage/sectionsOnStage';
import * as selection from './selection/selection';
import * as services from './services/services';
import * as siteCreation from './siteCreation/siteCreation';
import * as siteDisplayName from './siteDisplayName/siteDisplayName';
import * as siteSegments from './siteSegments/siteSegments';
import { smartWriter } from './smartWriter/smartWriter';
import * as stageLoader from './stageLoader/stageLoader';
import * as stateBox from './stateBox/stateBox';
import * as stateMapperArgsSelectors from './stateMapperArgsSelectors/stateMapperArgsSelectors';
import * as strip from './strip/strip';
import * as stylableEditor from './stylableEditor/stylableEditor';
import focalPoint from './focalPoint/focalPoint';
import * as text from './text/text';
import * as topBar from './topBar/topBar';
import * as topBarTooltip from './topBarTooltip/topBarTooltip';
import * as tpa from './tpa/tpa';
import * as unifiedChat from './unifiedChat/unifiedChat';
import * as uploadedFonts from './uploadedFonts/uploadedFonts';
import * as userPreferences from './userPreferences/userPreferences';
import * as userProfile from './userProfile/userProfile';
import virtualNumbers from './virtualNumbers/virtualNumbers';
import * as welcomeScreen from './welcomeScreen/welcomeScreen';
import * as window from './window/window';
import * as wixAd from './wixAd/wixAd';
import * as wixBlocksConsumer from './wixBlocksConsumer/wixBlocksConsumer';
import * as wixCodeIDE from './wixCodeIDE/wixCodeIDE';
export * from './store/editorState';

export type Interactions = typeof interactions;
export const componentSelectors = components.selectors;
export const componentActions = components.actions;
export const userPreferencesSelectors = userPreferences.selectors;
export const userPreferencesActions = userPreferences.actions;
export const hoverBoxSelectors = hoverBox.selectors;
export const hoverBoxActions = hoverBox.actions;
export const selectionSelectors = selection.selectors;
export const selectionActions = selection.actions;
export const interactionsSelectors = interactions.selectors;
export const interactionsActions = interactions.actions;
export const multilingualSelectors = multilingual.selectors;
export const multilingualActions = multilingual.actions;

/**
 * @deprecated use `biActions`, and `biHoc` instead
 */
export const bi = {
  actions: biActions,
  hoc: biHoc,
};

export { biActions, biHoc };
/**
 * @deprecated use `pinModeSelectors`, and `pinModeActions` instead
 */
export const pinMode = {
  selectors: pinModeSelectors,
  actions: pinModeActions,
};
export { pinModeSelectors, pinModeActions };
export {
  a11y,
  addPanel,
  ai,
  applicationStudio,
  applyModeFromClipboard,
  appsStore,
  ascend,
  attachCandidate,
  background,
  blog,
  boxSlideShow,
  branches,
  businessManager,
  componentLocator,
  componentMetaData,
  components,
  componentsLoadService,
  componentsStore,
  concurrentUsers,
  config,
  contextMenu,
  copyPaste,
  dealer,
  designPanel,
  developerToolBar,
  domMeasurements,
  domain,
  domainSuggestions,
  draggableSlots,
  dynamicPages,
  tpaDynamicPages,
  editorSearch,
  externalLinks,
  featuresOverridden,
  googleMap,
  gridLines,
  helpHome,
  helpPanel,
  highlights,
  history,
  hoverBox,
  inlinePopup,
  interactions,
  introFunnel,
  layers,
  layersPanel,
  leavePopup,
  leftBar,
  leftTree,
  linkPanel,
  localMode,
  media,
  mobile,
  mouseActions,
  multilingual,
  newAddPanel,
  newReleases,
  notifications,
  pages,
  panels,
  permanentBorder,
  platform,
  preloadings,
  preview,
  publishingStatus,
  testSiteStatus,
  relatedApps,
  rendererPanelsWorkerService,
  rightClickMenu,
  rulers,
  savePublish,
  savedComponents,
  schoolMode,
  secondaryLeftArea,
  sections,
  sectionedPanel,
  sectionsOnStage,
  selection,
  stateMapperArgsSelectors,
  services,
  siteCreation,
  siteDisplayName,
  siteSegments,
  smartWriter,
  stageLoader,
  stateBox,
  strip,
  stylableEditor,
  focalPoint,
  text,
  topBar,
  topBarTooltip,
  tpa,
  unifiedChat,
  uploadedFonts,
  userPreferences,
  userProfile,
  virtualNumbers,
  welcomeScreen,
  window,
  wixAd,
  wixBlocksConsumer,
  wixCodeIDE,
};
