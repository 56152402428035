import _ from 'lodash';
import * as boundariesConstraintsUtil from './boundariesConstraintsUtil';

import type { LayoutConstraint } from './types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import desktopConstraints from './layoutConstraints/desktopBoundariesConstraints';

function isValidConstraint({
  applyConstraint,
  getBoundaries,
}: LayoutConstraint) {
  return (
    typeof applyConstraint === 'function' || typeof getBoundaries === 'function'
  );
}

function getConstraintsToApply(
  editorAPI: EditorAPI,
  compPointer: CompRef,
  constraintsDefinitions: LayoutConstraint[],
) {
  return constraintsDefinitions
    .filter((constraintDef) => {
      return (
        isValidConstraint(constraintDef) &&
        (!constraintDef.shouldConstrain ||
          constraintDef.shouldConstrain(editorAPI, compPointer))
      );
    })
    .map(function (constraintDef) {
      const newConstraintDef = _.clone(constraintDef);

      newConstraintDef.applyConstraint =
        newConstraintDef.applyConstraint ||
        boundariesConstraintsUtil.applyBoundariesConstraintOnLayout.bind(
          null,
          newConstraintDef.getBoundaries(editorAPI, compPointer),
        );

      return newConstraintDef;
    });
}

let additionalLayoutConstraints: LayoutConstraint[] = [];
class LayoutConstraintsUtil {
  private constraintsToApply: LayoutConstraint[];

  static registerConstraint(constraint: LayoutConstraint) {
    additionalLayoutConstraints.push(constraint);
  }

  static removeConstraint(constraint: LayoutConstraint) {
    additionalLayoutConstraints = additionalLayoutConstraints.filter(
      (c) => c !== constraint,
    );
  }

  constructor(
    editorAPI: EditorAPI,
    compRef: CompRef,
    constraints: LayoutConstraint[],
  ) {
    this.constraintsToApply = getConstraintsToApply(editorAPI, compRef, [
      ...constraints,
      ...additionalLayoutConstraints,
    ]);
  }

  applyConstraints(
    editorAPI: EditorAPI,
    compRef: CompRef,
    relativeToScreenUpdated: Partial<Rect>,
    relativeToScreenInitial: Rect,
  ) {
    relativeToScreenUpdated = relativeToScreenUpdated || {};
    relativeToScreenInitial = (relativeToScreenInitial || {}) as Rect;

    const relativeToScreenUpdatedAfterConstrains: Rect = {
      ...relativeToScreenInitial,
      ...relativeToScreenUpdated,
    };

    this.constraintsToApply.forEach((constraint) => {
      constraint.applyConstraint(
        editorAPI,
        compRef,
        relativeToScreenUpdatedAfterConstrains,
        relativeToScreenInitial,
      );
    });

    Object.assign(
      relativeToScreenUpdated,
      _.pick(
        relativeToScreenUpdatedAfterConstrains,
        Object.keys(relativeToScreenUpdated),
      ),
    );
  }

  /**
   * Note: this function does not currently return an intersection of areas, since there is no use case for it. It only returns the smallest area.
   * If you need it to return the intersection, you can extend it..
   */
  getConstraintArea(editorAPI: EditorAPI, compRef: CompRef) {
    const constraintsAreas = this.constraintsToApply
      .filter(
        (constraint) => typeof constraint.getConstraintArea === 'function',
      )
      .map((constraint) => constraint.getConstraintArea(editorAPI, compRef))
      .flat()
      .filter(Boolean);

    return constraintsAreas.length ? constraintsAreas : null;
  }

  hasConstraints() {
    return this.constraintsToApply.length > 0;
  }

  getDesktopBoundaries(editorAPI: EditorAPI, compRef: CompRef) {
    return desktopConstraints.getBoundaries(editorAPI, compRef);
  }
}

export default LayoutConstraintsUtil;
