import actionTypes from './sectionedPanelActionTypes';
import * as bi from '../bi/bi';
import * as userPreferences from '../userPreferences/userPreferences';
import * as sectionedPanelSelectors from './sectionedPanelSelectors';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import _ from 'lodash';
import type { ThunkAction } from 'types/redux';

const { event } = bi.actions;
const { setSiteUserPreferences } = userPreferences.actions;
const dockedSectionsKeys = constants.DOCKED_PANEL_SECTIONS;

const sectionNameToUserPreferenceKey = {
  [dockedSectionsKeys.PROPERTIES]:
    constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_TOOLBAR_ENABLED,
  [dockedSectionsKeys.WIDGET_PUBLIC_API]:
    constants.USER_PREFS.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API_ENABLED,
};

const sectionNameToBiEvent = {
  [dockedSectionsKeys.PROPERTIES]: coreBi.events.PROPERTIES_PANEL_TOGGLE,
  [dockedSectionsKeys.WIDGET_PUBLIC_API]: coreBi.events.WIDGET_PUBLIC_API,
};

const toggleSection = (sectionName: AnyFixMe) => ({
  type: actionTypes.TOGGLE_SECTION,
  sectionName,
});
const openSection = (sectionName: AnyFixMe) => ({
  type: actionTypes.OPEN_SECTION,
  sectionName,
});
const closeSection = (sectionName: AnyFixMe) => ({
  type: actionTypes.CLOSE_SECTION,
  sectionName,
});

const displayPanel = (shouldShow: AnyFixMe) => ({
  type: actionTypes.DISPLAY_PANEL,
  shouldShow,
});

const reportBi = (
  sectionName: AnyFixMe,
  shouldOpenSection: AnyFixMe,
  siteId: AnyFixMe,
  dispatch: AnyFixMe,
) => {
  const state = shouldOpenSection ? 'open' : 'close';

  dispatch(
    event(
      sectionNameToBiEvent[sectionName as keyof typeof sectionNameToBiEvent],
      {
        site_id: siteId,
        state,
      },
    ),
  );
};

const saveToPreferences = (
  sectionName: AnyFixMe,
  shouldOpenSection: AnyFixMe,
  dispatch: AnyFixMe,
) => {
  dispatch(
    setSiteUserPreferences(
      sectionNameToUserPreferenceKey[
        sectionName as keyof typeof sectionNameToUserPreferenceKey
      ],
      shouldOpenSection,
    ),
  );
};

const sendBiAndSaveToPreferences = (
  shouldOpenSection: AnyFixMe,
  sectionName: AnyFixMe,
  siteId: AnyFixMe,
  dispatch: AnyFixMe,
  shouldUpdatePreferences = true,
) => {
  if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    _.isUndefined(
      sectionNameToBiEvent[sectionName as keyof typeof sectionNameToBiEvent],
    ) ||
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    _.isUndefined(
      sectionNameToUserPreferenceKey[
        sectionName as keyof typeof sectionNameToUserPreferenceKey
      ],
    )
  ) {
    throw new Error(
      `Docked section ${sectionName} should have BiEvent and Preferences definition`,
    );
  }

  reportBi(sectionName, shouldOpenSection, siteId, dispatch);
  if (shouldUpdatePreferences) {
    saveToPreferences(sectionName, shouldOpenSection, dispatch);
  }
};

const toggleSectionThunk =
  (sectionName: string): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const shouldOpenSection = !_.includes(
      sectionedPanelSelectors.openDockedSections(getState()),
      sectionName,
    );
    sendBiAndSaveToPreferences(
      shouldOpenSection,
      sectionName,
      dsRead.generalInfo.getSiteId(),
      dispatch,
    );

    dispatch(toggleSection(sectionName));
  };

const openSectionThunk =
  (sectionName: AnyFixMe, shouldUpdatePreferences = true): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    const shouldOpenSection = true;
    sendBiAndSaveToPreferences(
      shouldOpenSection,
      sectionName,
      dsRead.generalInfo.getSiteId(),
      dispatch,
      shouldUpdatePreferences,
    );

    dispatch(openSection(sectionName));
  };

const closeSectionThunk =
  (sectionName: AnyFixMe, shouldUpdatePreferences = true): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    const shouldOpenSection = false;
    sendBiAndSaveToPreferences(
      shouldOpenSection,
      sectionName,
      dsRead.generalInfo.getSiteId(),
      dispatch,
      shouldUpdatePreferences,
    );

    dispatch(closeSection(sectionName));
  };

export {
  toggleSectionThunk as toggleSection,
  openSectionThunk as openSection,
  displayPanel,
  closeSectionThunk as closeSection,
};
