import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import {
  createSource,
  events,
  PrivateSite,
  Uploader,
} from '@wix/media-manager-sdk';
import _ from 'lodash';
import * as bi from '../../bi/bi';
import fontsActionTypes from '../uploadedFontsActionTypes';
import * as uploadedFontsSelectors from '../uploadedFontsSelectors';
import * as fontsUploader from './fontsUploader';
import * as uploadedFontsUtils from './uploadedFontsLogicUtils';

const {
  SET_LIST,
  SET_QUEUE,
  SET_FLAG_TO_QUEUE_ITEM,
  SET_UPLOADED_ITEMS,
  ADD_TIME_OUT_TOKEN,
  CLEAR_TIME_OUT_TOKENS,
} = fontsActionTypes;

const { getQueue, getUploadedItems, getTimeOutTokens } = uploadedFontsSelectors;
const { constants } = util.fontsManagerUtils;

const setList = (list: AnyFixMe) => ({
  type: SET_LIST,
  list,
});

const setQueue = (queue: AnyFixMe) => ({
  type: SET_QUEUE,
  queue,
});

const setFlagToQueueItem = (queueItemIndex: number, flag: AnyFixMe) => ({
  type: SET_FLAG_TO_QUEUE_ITEM,
  queueItemIndex,
  flag,
});

const setUploadedItems = (uploadedItems: AnyFixMe) => ({
  type: SET_UPLOADED_ITEMS,
  uploadedItems,
});

const addTimeOutToken = (timeOutToken: AnyFixMe) => ({
  type: ADD_TIME_OUT_TOKEN,
  timeOutToken,
});

const clearTimeOutTokens = () => ({
  type: CLEAR_TIME_OUT_TOKENS,
});

const loadMediaManagerSdk =
  (mediaToken: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, services: AnyFixMe) => {
    const mediaManagerSdkSource = createSource(PrivateSite, {
      asyncFontUpload: true,
      siteMediaToken: mediaToken,
    });

    services.mediaManagerSdkFonts = services.mediaManagerSdkFonts || {};
    services.mediaManagerSdkFonts.mediaManagerSdkSource =
      services.mediaManagerSdkFonts.mediaManagerSdkSource ||
      mediaManagerSdkSource;

    return services.mediaManagerSdkFonts;
  };

const init =
  () =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { mediaManagerSdkFonts }: AnyFixMe,
  ) => {
    fetchFontsList(dispatch, mediaManagerSdkFonts).catch((e: AnyFixMe) =>
      console.error(e),
    );
    dispatch(prepareUploadedFontsData());
    registerToFileStatusChanged(dispatch, getState, mediaManagerSdkFonts);
    registerToQueueChange(dispatch, getState, mediaManagerSdkFonts);
    registerToItemsRemove(dispatch, mediaManagerSdkFonts);
    registerToUploadFinish();
  };

const fetchFontsList = (dispatch: AnyFixMe, mediaManagerSdkFonts: AnyFixMe) => {
  const options = {
    mediaType: constants.FONTS_MEDIA_TYPE,
    paging: { cursor: null as AnyFixMe, size: 500 },
  };
  return mediaManagerSdkFonts.mediaManagerSdkSource.items
    .list(null, options)
    .then(function (res: AnyFixMe) {
      const fontsList = _.cloneDeep(res.data);
      dispatch(setList(uploadedFontsUtils.getFontObject(fontsList)));
    });
};

const cancelUpload =
  (currentItemIndex: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { mediaManagerSdkFonts }: AnyFixMe,
  ) => {
    if (currentItemIndex !== -1) {
      if (
        fontsUploader.cancelUpload(
          mediaManagerSdkFonts.uploadQueue[currentItemIndex],
        )
      ) {
        dispatch(assignFlagToQueueItem(currentItemIndex, { canceled: true }));
      }
    }
  };

const assignFlagToQueueItem =
  (queueItemIndex: AnyFixMe, flag: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { mediaManagerSdkFonts }: AnyFixMe,
  ) => {
    // Due to historic reasons we need to assign an id to the actual queueItem
    if (flag.id) {
      Object.assign(mediaManagerSdkFonts.uploadQueue[queueItemIndex], {
        id: flag.id,
      });
    }

    dispatch(setFlagToQueueItem(queueItemIndex, flag));
  };

const prepareUploadedFontsData =
  () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const queue = uploadedFontsUtils.getInitialQueueWithData(
      fontsUploader
        .getUploadQueue()
        .map(uploadedFontsUtils.buildQueueItemForStore),
    );
    const uploadedItems = uploadedFontsUtils.buildUploadedItems(
      getUploadedItems(getState()),
    );
    dispatch(setQueue(queue));
    dispatch(setUploadedItems(uploadedItems));
    clearTimeouts(dispatch, getState);
  };

const clearTimeouts = (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const fontsTimeOut = getTimeOutTokens(getState());

  fontsTimeOut.forEach((timeout: AnyFixMe) => {
    window.clearTimeout(timeout);
  });

  dispatch(clearTimeOutTokens());
};

const registerToItemsRemove = (
  dispatch: AnyFixMe,
  mediaManagerSdkFonts: AnyFixMe,
) => {
  events.on(events.ITEMS.REMOVE, function (promises: AnyFixMe) {
    const promisesArray = Array.isArray(promises) ? promises : [promises];

    promisesArray.forEach(function (promise) {
      promise
        .then(() => fetchFontsList(dispatch, mediaManagerSdkFonts))
        .catch((err: AnyFixMe) => {
          console.error(err);
        });
    });
  });
};

const registerToFileStatusChanged = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  mediaManagerSdkFonts: AnyFixMe,
) => {
  Uploader.on('fileStatusChanged', function (fileData: AnyFixMe) {
    if (
      fileData.currentFileProgress.status === constants.FONT_STATUS.SUCCEEDED
    ) {
      fetchFontsList(dispatch, mediaManagerSdkFonts).catch((e: AnyFixMe) =>
        console.error(e),
      );
    }
    const queue = getQueue(getState());

    const flags = { status: fileData.currentFileProgress.status };
    const fileIndexInQueue = fileData.currentFileQueueIdx;

    switch (flags.status) {
      case util.fontsManagerUtils.constants.FONT_STATUS.PROCESSING:
        handleFileStatusProcessing(dispatch, queue, fileIndexInQueue);
        break;
      case util.fontsManagerUtils.constants.FONT_STATUS.FAILED:
        const failFlags = handleFileStatusFailed(
          fileData,
          queue,
          fileIndexInQueue,
        );
        Object.assign(flags, failFlags);
        break;
      case util.fontsManagerUtils.constants.FONT_STATUS.SUCCEEDED:
        const successFlags = handleFileStatusSucceeded(
          dispatch,
          getState,
          fileData,
          queue,
          fileIndexInQueue,
        );
        Object.assign(flags, successFlags);
        break;
    }

    dispatch(assignFlagToQueueItem(fileIndexInQueue, flags));
  });
};

const handleFileStatusProcessing = (
  dispatch: AnyFixMe,
  queue: AnyFixMe,
  fileIndexInQueue: AnyFixMe,
) => {
  dispatch(
    bi.actions.event(coreBi.events.fontsUploadPanel.Start_Font_Upload_Process, {
      name: queue[fileIndexInQueue].name,
    }),
  );

  const timeOutToken = window.setTimeout(function () {
    dispatch(assignFlagToQueueItem(fileIndexInQueue, { stillUploading: true }));
  }, 30000);
  dispatch(addTimeOutToken(timeOutToken));
};

const handleFileStatusFailed = (
  fileData: AnyFixMe,
  queue: AnyFixMe,
  fileIndexInQueue: AnyFixMe,
) => {
  const failFontData =
    fileData?.currentFileProgress?.file?.serverResponse?.data;
  const error_code = failFontData?.error_code || 'unknown';
  if (!queue[fileIndexInQueue].canceled) {
    const cloneForBi = _.cloneDeep(queue[fileIndexInQueue]);
    cloneForBi.error_code = error_code;
    sendUploadFailedBi(error_code, cloneForBi);
  }

  return { error_code };
};

const handleFileStatusSucceeded = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  fileData: AnyFixMe,
  queue: AnyFixMe,
  fileIndexInQueue: AnyFixMe,
) => {
  const fontObj = fileData?.currentFileProgress?.file?.serverResponse?.data;
  const finishedItem = {
    [fontObj.id]: true,
  };
  const newUploadedItems = Object.assign(
    { [fontObj.id]: { finished: false } },
    getUploadedItems(getState()),
    finishedItem,
  );
  dispatch(setUploadedItems(newUploadedItems));

  bi.actions.event(coreBi.events.fontsUploadPanel.Fonts_Uploaded, {
    name: queue[fileIndexInQueue].name,
  });

  return util.fontsManagerUtils.getUploadedFontData(fontObj);
};

const registerToQueueChange = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  mediaManagerSdkFonts: AnyFixMe,
) => {
  Uploader.on('queueChanged', function (data: AnyFixMe) {
    mediaManagerSdkFonts.uploadQueue = data;
    const stateQueue = getQueue(getState());
    const serverQueue = uploadedFontsUtils.getNewQueueData(
      data.map(uploadedFontsUtils.buildQueueItemForStore),
    );
    const queueToSet = serverQueue.map((queueItem, idx) =>
      Object.assign({}, queueItem, stateQueue[idx]),
    );

    dispatch(setQueue(queueToSet));
  });
};

const registerToUploadFinish = () => {
  Uploader.on('uploadQueueFinished', () => {
    fontsUploader.clearUploadQueue();
  });
};

const sendUploadFailedBi = (errorCode: AnyFixMe, file: AnyFixMe) => {
  const message = util.fontsManagerUtils.getErrorMessage(errorCode).bi_msg;
  bi.actions.event(coreBi.events.fontsUploadPanel.Upload_Failed, {
    message,
    name: file.name,
  });
};

const removeFonts =
  (itemsToRemove: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { mediaManagerSdkFonts }: AnyFixMe,
  ) => {
    mediaManagerSdkFonts.mediaManagerSdkSource.items.remove(itemsToRemove);
  };

const uploadItems =
  (fileList: AnyFixMe) =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { mediaManagerSdkFonts }: AnyFixMe,
  ) => {
    fontsUploader.upload(mediaManagerSdkFonts.mediaManagerSdkSource, fileList);
  };

const clearUploadQueue = () => () => {
  fontsUploader.clearUploadQueue();
};

export {
  loadMediaManagerSdk,
  init,
  removeFonts,
  assignFlagToQueueItem,
  cancelUpload,
  prepareUploadedFontsData,
  uploadItems,
  clearUploadQueue,
  setUploadedItems,
  setQueue,
  addTimeOutToken,
  setList,
  setFlagToQueueItem,
  clearTimeOutTokens,
};
