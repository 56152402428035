import { getPinDockingDirection } from './getPinDockingDirection';
import { hasLayoutDockingSizeRestrictionsIssues } from './dockingRestrictions';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export function hasPinDockingSizeRestrictionsIssues(
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean {
  const dockingDirection = getPinDockingDirection(editorAPI, compRef);
  if (!dockingDirection) {
    return false;
  }

  const layout = editorAPI.components.layout.get_size(compRef);
  return hasLayoutDockingSizeRestrictionsIssues(layout, dockingDirection);
}
