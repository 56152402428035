// @ts-nocheck
import * as util from '#packages/util';

const TITLE_LENGTH_LIMIT = 100;
const DESCRIPTION_LENGTH_LIMIT = 1200;

export default function (data) {
  if (data.title) {
    data.title = util.media.fixMediaTitleLength(data.title, TITLE_LENGTH_LIMIT);
  }
  if (data.description) {
    data.description = util.media.fixMediaTitleLength(
      data.description,
      DESCRIPTION_LENGTH_LIMIT,
    );
  }
  return data;
}
