import { isFixedItemLayout, isMeshItemLayout } from '#packages/layoutUtils';
import { createFixedItemLayoutFromDocking } from '../../dockingMigrations';
import { getFullWidthValueFromDocking } from '../../fullWidth/fullWidthDocking';
import {
  responsiveLayoutGet,
  responsiveLayoutUpdate,
} from '../responsiveLayout';
import { layoutGetDocked } from './layoutGetDocked';
import type { CompRef, Docking } from 'types/documentServices';
import type { DSContext } from '../types';

export function layoutSetDock(
  dsContext: DSContext,
  compRef: CompRef,
  docking: Docking,
) {
  console.error('layout.setDock/updateDock/unDock is depricated.');

  const layouts = responsiveLayoutGet(dsContext.dsRead, compRef);
  const fullWidthValue = getFullWidthValueFromDocking(docking);

  if (fullWidthValue.isFullWidth && isMeshItemLayout(layouts.itemLayout)) {
    responsiveLayoutUpdate(dsContext, compRef, {
      componentLayout: {
        width: undefined,
      },
      itemLayout: {
        left: fullWidthValue.siteMargin,
        right: fullWidthValue.siteMargin,
      },
    });

    return;
  }

  if (isFixedItemLayout(layouts.itemLayout)) {
    responsiveLayoutUpdate(dsContext, compRef, {
      itemLayout: createFixedItemLayoutFromDocking(docking),
    });

    return;
  }

  throw new Error('Not implemented');
}

export function layoutUpdateDock(
  dsContext: DSContext,
  compRef: CompRef,
  docking: Docking,
) {
  const dockingCurrent = layoutGetDocked(dsContext.dsRead, compRef);
  const dockingUpdated = {
    ...dockingCurrent,
    ...docking,
  };

  return layoutSetDock(dsContext, compRef, dockingUpdated);
}
