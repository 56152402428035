// @ts-nocheck
import * as util from '#packages/util';
import { translate } from '#packages/i18n';

const MAX_TITLE_LENGTH = 40;

function generateDuplicatedTitle(title) {
  const regExp = new RegExp(/copy\s(\d+\s|)of\s/i);
  let count;

  if (regExp.test(title)) {
    if (RegExp.$1) {
      count = parseInt(RegExp.$1, 10) + 1;
    } else {
      count = 2;
    }
    return title.replace(regExp, `Copy ${count} of `);
  }

  title = `${translate('Pages_Actions_Duplicate_CopyOf')} ${title}`;

  if (title.length > MAX_TITLE_LENGTH) {
    title = `${title.substr(0, MAX_TITLE_LENGTH - 3)}...`;
  }

  return title;
}

function convertPageNameToUrl(title) {
  const dash = '-';
  const pageUrlWithoutSpaces = title.replace(/\s/g, dash);
  const validator = util.validate.byInvalidCharacters(
    util.validationExpressions.invalidUrlCharacters,
  );

  return validator(pageUrlWithoutSpaces)
    ? pageUrlWithoutSpaces.toLowerCase()
    : 'blank';
}

export { generateDuplicatedTitle, convertPageNameToUrl };
