import _ from 'lodash';
import constants from '#packages/constants';
import actionTypes from './publishingStatusActionTypes';

const { PROGRESS_STATUS } = constants;

const isValidStatus = (status: AnyFixMe) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values, you-dont-need-lodash-underscore/includes
  _.includes(_.values(PROGRESS_STATUS), status);

export const publishingStatusInitialState = constants.PROGRESS_STATUS.IDLE;

const reducer = (
  publishingStatus = publishingStatusInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_PUBLISHING_STATUS:
      return isValidStatus(action.publishingStatus)
        ? action.publishingStatus
        : publishingStatus;
    default:
      return publishingStatus;
  }
};

export default reducer;
