import { isPopupContainer } from '#packages/documentServices';
import { createPopupPropertiesFromLayouts } from '../../popupMigrations';
import type { CompRef, DSRead } from 'types/documentServices';

export function propertiesGet(dsRead: DSRead, compRef: CompRef) {
  const properties = dsRead.components.properties.get(compRef);

  if (isPopupContainer(dsRead, compRef)) {
    const layouts = dsRead.components.responsiveLayout.get(compRef);

    return {
      ...properties,
      ...createPopupPropertiesFromLayouts(layouts),
    };
  }

  return properties;
}
