import localModeActionTypes from './localModeActionTypes';
import constants from '#packages/constants';

const { ENABLE_LOCAL_MODE, SET_PUSHING_STATUS, SET_IS_RECONNECTING } =
  localModeActionTypes;

export const localModeInitialState = {
  enabled: false,
  pushingStatus: constants.PROGRESS_STATUS.IDLE,
  isLocalPushInProgress: false,
  isReconnecting: false,
};

const reducer = (localMode = localModeInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case ENABLE_LOCAL_MODE:
      return Object.assign({}, localMode, { enabled: true });
    case SET_PUSHING_STATUS:
      return Object.assign({}, localMode, {
        pushingStatus: action.pushingStatus,
        isLocalPushInProgress:
          action.pushingStatus === constants.PROGRESS_STATUS.IN_PROGRESS,
      });
    case SET_IS_RECONNECTING:
      return Object.assign({}, localMode, {
        isReconnecting: action.isReconnecting,
      });
    default:
      return localMode;
  }
};

export default reducer;
