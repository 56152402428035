import type { ProgressStatus } from '#packages/constants';
import type {
  AnimateScrollPosition,
  EditorConfig,
  MobileFramePosition,
  ScrollPosition,
} from 'types/core';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { AddPanelState } from '../addPanel/addPanelReducer';
import type { AscendState } from '../ascend/ascendReducer';
import type { AttachCandidateState } from '../attachCandidate/attachCandidateReducer';
import type { BranchesState } from '../branches/types';
import type { ComponentsStoreState } from '../componentsStore/componentsStoreReducer';
import type { ConcurrentUsersState } from '../concurrentUsers/concurrentUsersReducers';
import type { ContextMenuState } from '../contextMenu/contextMenuReducer';
import type { DesignPanelState } from '../designPanel/designPanelReducer';
import type { DomMeasurementsState } from '../domMeasurements/domMeasurementsReducer';
import type { DomainSuggestionsState } from '../domainSuggestions/domainSuggestionsReducer';
import type { DraggableSlotsState } from '../draggable/draggableSlots/draggableSlotsReducer';
import type { DynamicPagesState } from '../dynamicPages/dynamicPagesReducer';
import type { EditorSearchState } from '../editorSearch/editorSearchReducer';
import type { FeaturesOverriddenState } from '../featuresOverridden/featuresOverriddenReducer';
import type { GridLinesState } from '../gridLines/gridLinesReducer';
import type { HelpHomeState } from '../helpHome/helpHomeReducer';
import type { HiddenItemData } from '#packages/mobileEditor';
import type { HoverBoxState } from '../hoverBox/hoverBoxReducer';
import type { InteractionsState } from '../interactions/interactionsReducer';
import type { IntroFunnelState } from '../introFunnel/introFunnelTypes';
import type { LayersPanelState } from '../layersPanel/layersPanelReducer';
import type { LeftBarState } from '../leftBar/leftBarReducer';
import type { SchoolModeState } from '../schoolMode/schoolModeReducer';
import type { LinkPanelState } from '../linkPanel/linkPanelReducer';
import type { MultilingualState } from '../multilingual/multilingualReducer';
import type { NewAddPanelState } from '../newAddPanel/newAddPanelReducer';
import type { NewReleasesState } from '../newReleases/newReleasesReducer';
import type { NotificationsState } from '../notifications/notificationsTypes';
import type { PagesState } from '../pages/pagesReducer';
import type { PanelDescriptor, PanelsState } from '../panels/panelsReducer';
import type { PinModeState } from '../pinMode/pinModeReducer';
import type { RelatedAppsState } from '../relatedApps/relatedAppsActionTypes';
import type { SavePublishState } from '../savePublish/savePublishReducer';
import type { SectionsState } from '../sections/sections.reducer';
import type { SectionsOnStageState } from '../sectionsOnStage/sectionsOnStage.reducer';
import type { SelectionState } from '../selection/selectionReducer';
import type { SmartWriterState } from '../smartWriter/smartWriterReducer';
import type { TextState } from '../text/textReducer';
import type { UserPreferencesState } from '../userPreferences/userPreferencesReducer';
import type { VirtualNumbersState } from '../virtualNumbers/types';
import type { SiteCreationState } from '../siteCreation/siteCreationReducer';
import type { MobileKeyboardState } from '../mobile/mobileKeyboard/mobileKeyboardReducer';
import type { MouseActionsState } from '../mouseActions/mouseActionsReducer';
import type { RulersState } from '../rulers/rulersReducer';
import type { TpaDynamicPagesState } from '../tpaDynamicPages/tpaDynamicPagesReducer';
import type { PreviewState } from '../preview/previewReducer';
import type { StageLoaderState } from '../stageLoader/stageLoaderReducer';

export interface LassoCandidate {
  layout: CompLayout & { bounding: { className?: string } };
  component: CompRef;
}

export interface ImageCropState {
  isOn: boolean;
  cropArea: null | {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  cropOriginArea: null | {};
  isEntering: boolean;
}

export interface SitePremiumState {
  isDomainConnected?: boolean;
  isSitePremium?: boolean;
  domain?: string;
}

export interface MobileState {
  reAddedHiddenElement: {
    compRef: CompRef;
    addingPhase: string;
  } | null;
  isHiddenItemsCounterVisible?: boolean;
  mobileKeyboard: MobileKeyboardState;
  hiddenItems: {
    isAddingHiddenItemInProgress: boolean;
    hoveredComponent: CompRef;
    masterPageHiddenComponents: HiddenItemData[];
    currentPageHiddenComponents: HiddenItemData[];
    isAddingInteractionsHiddenItemInProgress: boolean;
    interactionsHiddenComponents: HiddenItemData[];
  };
  didVisitMobileEditorInCurrentSession: boolean;
}

export interface EditorState {
  mobile: MobileState;
  addPanel: AddPanelState;
  newAddPanel: NewAddPanelState;
  ascend: AscendState;
  attachCandidate: AttachCandidateState;
  designPanel: DesignPanelState;
  domMeasurements: DomMeasurementsState;
  domainSuggestions: DomainSuggestionsState;
  featuresOverridden: FeaturesOverriddenState;
  linkPanel: LinkPanelState;
  snapComponent: {
    verticalEqualDistance: boolean;
    horizontalEqualDistance: boolean;
  };
  componentsStore: ComponentsStoreState;
  concurrentUsers: ConcurrentUsersState;
  schoolMode: SchoolModeState;
  gridLines: GridLinesState;
  panelData: PanelsState;
  savePublish: SavePublishState;
  sectionedPanel: {
    openDockedSections: PanelDescriptor[];
    shouldShow: boolean;
  };
  config: EditorConfig;
  editorIsInit: boolean;
  previewReady: boolean;
  previewMode: boolean;
  preview: PreviewState;
  userPreferences?: UserPreferencesState;
  userPreferencesLoaded?: boolean;
  selectedComponents?: CompRef[];
  viewTools: any;
  hideTools: boolean;
  hideToolsAndMaintainStagePosition: boolean;
  text: TextState;
  isMobileEditor: boolean;
  isOpenTextEditor: boolean;
  editBoxRotateKnobRect?: any;
  editingAreaPosition: any;
  mobileFramePosition?: MobileFramePosition;
  scrollTo: Partial<ScrollPosition>;
  animateScrollTo: {
    position: AnimateScrollPosition;
    duration: number;
    onComplete(): void;
  };
  leftBar: LeftBarState;
  topBar: {
    openedDropPanel: any;
    dropPanelDisabled: boolean;
    shouldDropPanelRemainOpen: boolean;
    isMac: boolean;
  };
  branches: BranchesState;
  eventCounters: {
    forceOpenPagesQuickNavigation: any;
    forceOpenPagesPanel: any;
    focus: number;
    clearTextSelection: number;
  };
  hoverBox: HoverBoxState;
  selection: SelectionState;
  imageCrop: ImageCropState;
  contextMenu: ContextMenuState;
  portalComps: any;
  lassoCandidates?: LassoCandidate[];
  lassoLayout: any;
  layersPanel: LayersPanelState;
  constraintArea: any;
  pages: PagesState;
  draggableSlots: DraggableSlotsState;
  pageSections: any;
  siteScale: number;
  siteCreation: SiteCreationState;
  halfOpacityTools: boolean;
  shouldHideToolsBtnBlink: boolean;
  shouldOverlayUpdate: boolean;
  snapData: any;
  topBarPagesFilter: any;
  editorSearch: EditorSearchState;
  interactions: InteractionsState;
  pinMode: PinModeState;
  dynamicPages: DynamicPagesState;
  tpaDynamicPages: TpaDynamicPagesState;
  smartWriter: SmartWriterState;
  newReleases: NewReleasesState;
  helpHome: HelpHomeState;
  introFunnel: IntroFunnelState;
  wixCodeLoaded: boolean;
  mouseActions: MouseActionsState;
  savingStatus: ProgressStatus;
  previewingStatus: ProgressStatus;
  exitPreviewStatus: ProgressStatus;
  publishingStatus: ProgressStatus;
  testSiteStatus: ProgressStatus;
  devModeContext: any;
  floatingBubble: any;
  clipboard: any;
  __renderCounter: number;
  helpProps: object;
  rulers: RulersState;
  tabIndicationState: any;
  notifications: NotificationsState;
  sitePremiumState?: SitePremiumState;
  unreadComments?: number;
  relatedApps: RelatedAppsState;
  sectionsOnStage: SectionsOnStageState;
  sections: SectionsState;
  virtualNumbers: VirtualNumbersState;
  multilingual: MultilingualState;
  stageLoader: StageLoaderState;
  managedColumnPointer?: CompRef;
  pageIdBeforeEnteringPreview: string;
}
