import _ from 'lodash';
import { fedopsLogger, url as urlUtil } from '#packages/util';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';

import * as biState from '../bi/bi';
import * as history from '../history/history';
import * as notifications from '../notifications/notifications';
import * as panels from '../panels/panels';

import * as selectors from './savedComponentsSelectors';
import * as actionTypes from './savedComponentsActionTypes';
import {
  getMetaSiteInstance,
  mergeCollections,
  createBiComponentType,
  createBiComponents,
  createBiErrorMassage,
  createNotificationErrorMassage,
} from './utils';
import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const setCollectionAction = (items: AnyFixMe) => ({
  type: actionTypes.SET_COLLECTION_ACTION,
  items,
});

const addOptimisticItemAction = (item: AnyFixMe) => ({
  type: actionTypes.ADD_OPTIMISTIC_ITEM_ACTION,
  item,
});

const cleanOptimisticItemAction = (id: AnyFixMe) => ({
  type: actionTypes.CLEAN_OPTIMISTIC_ITEM_ACTION,
  id,
});

const deleteItemAction = (id: AnyFixMe) => ({
  type: actionTypes.DELETE_ITEM_ACTION,
  id,
});

const updateItemAction = (id: string, updatedProps: AnyFixMe) => ({
  type: actionTypes.UPDATE_ITEM_ACTION,
  id,
  updatedProps,
});

const showErrorNotification = (e: AnyFixMe) => (dispatch: AnyFixMe) => {
  const NOTIFICATION_PANEL_TIMEOUT = 3000;
  const SAVE_PANEL_NAME = 'SaveToMyElementsPanel';

  const { message, helpId } = createNotificationErrorMassage(e);
  const shouldShowNotificationLink = Boolean(helpId);
  const onNotificationLinkClick = () =>
    dispatch(
      panels.actions.openHelpCenter(helpId, null, {
        panel_name: SAVE_PANEL_NAME,
        origin: constants.BI.HELP.ORIGIN.PANEL,
        learn_more: true,
      }),
    );

  dispatch(notifications.actions.closeAllNotifications());
  dispatch(
    notifications.actions.showUserActionNotification({
      type: constants.NOTIFICATIONS.TYPES.ERROR,
      title: message,
      message,
      timeout: NOTIFICATION_PANEL_TIMEOUT,
      ...(shouldShowNotificationLink
        ? {
            link: {
              caption: 'Notifications_Learn_More_Link',
              onNotificationLinkClick,
            },
          }
        : null),
    }),
  );
};

const renameItem: (id: string, name: string) => ThunkAction =
  (id, name) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_RENAME,
    );

    const metaSiteInstance = getMetaSiteInstance(dsRead);

    return editorAPI.savedComponents.httpService
      .renameItem(metaSiteInstance, id, name)
      .then(() => {
        dispatch(updateItemAction(id, { name }));

        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_RENAME,
        );
      });
  };

const deleteItem: (id: string) => ThunkAction =
  (id) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_DELETE,
    );

    const metaSiteInstance = getMetaSiteInstance(dsRead);

    return editorAPI.savedComponents.httpService
      .deleteItem(metaSiteInstance, id)
      .then(() => {
        dispatch(deleteItemAction(id));
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_DELETE,
        );
      })
      .catch((e: AnyFixMe) => {
        dispatch(
          biState.actions.event(
            coreBi.events.savedComponents.confirm_deletion_from_library,
            {
              element_id: id,
              success: false,
              error_msg: createBiErrorMassage(e),
            },
          ),
        );
        throw e;
      });
  };
/***
 * @param {Array} selectedComponents componentRef for save
 * @param {string} name New name for component at saved components
 * @param {Object} biOptions
 * @param {string} biOptions.funnelId Id use only to track bi flow generated when save is started
 */
const saveItem: (
  selectedComponents: CompRef[],
  name: string,
  type: typeof constants.COMP_TYPES.SECTION | 'my-design',
  biOptions: {
    funnelId: string;
    fullSaved: boolean;
    origin: string;
  },
) => ThunkAction =
  (selectedComponents, name, type, biOptions) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_SAVE,
    );
    const metaSiteInstance = getMetaSiteInstance(dsRead);
    const elementSource = dsRead.generalInfo.isFirstSave()
      ? 'unsaved site'
      : 'saved site';

    const {
      metaData,
      serializedData,
      previewDimension,
      previewTemplate,
      previewData,
    } = editorAPI.savedComponents.prepareComponentsForSave(selectedComponents);

    const source = {
      metaData,
      serializedData,
    };

    const tempId = _.uniqueId('saved-component-');

    dispatch(
      addOptimisticItemAction({
        id: tempId,
        name,
        source,
        previewDimension,
        previewHTML: previewData.body,
        isOptimistic: true,
      }),
    );

    const biParams = {
      funnel_id: biOptions.funnelId,
      full_saved: biOptions.fullSaved,
      origin: biOptions.origin,
      element_name: name,
      element_source: elementSource,
      content_type: createBiComponentType(serializedData.components),
      components: createBiComponents(serializedData.components),
    };

    return editorAPI.savedComponents.httpService
      .postItem(metaSiteInstance, name, {
        source,
        previewDimension,
        previewTemplate,
        previewData,
        type,
      })
      .then((id: AnyFixMe) => {
        dispatch(updateItemAction(tempId, { id, isOptimistic: false }));

        dispatch(
          biState.actions.event(coreBi.events.savedComponents.save_elements, {
            ...biParams,
            success: true,
            element_id: id,
          }),
        );
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_SAVE,
        );
      })
      .catch((e: AnyFixMe) => {
        dispatch(deleteItemAction(tempId));
        dispatch(showErrorNotification(e));

        dispatch(
          biState.actions.event(coreBi.events.savedComponents.save_elements, {
            ...biParams,
            success: false,
            error_msg: createBiErrorMassage(e),
          }),
        );

        throw e;
      })
      .finally(() => {
        dispatch(cleanOptimisticItemAction(tempId));
      });
  };

const fetchCollection: () => ThunkAction =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const state = getState();

    const isEmpty = selectors.getIsEmpty(state);
    const hasOptimisticItems = selectors.hasOptimisticItems(state);
    const disableReloading = urlUtil.isQA() && !isEmpty;

    // disable fetching when unresolved optimistic items and when sled QA
    if (hasOptimisticItems || disableReloading) {
      return;
    }

    const metaSiteInstance = getMetaSiteInstance(dsRead);

    return editorAPI.savedComponents.httpService
      .fetchCollection(metaSiteInstance)
      .then((newItems) => {
        const currentItems = selectors.getSavedComponents(getState());
        const items = mergeCollections(currentItems, newItems);

        dispatch(setCollectionAction(items));
      });
  };

const fetchItemPreview: (id: string) => ThunkAction =
  (id) =>
  (dispatch, getState, { editorAPI }) => {
    const { previewUrl } = selectors.getSavedComponentsItem(getState(), id);

    return editorAPI.savedComponents.httpService
      .fetchItemPreview(previewUrl)
      .then((previewHTML: AnyFixMe) => {
        dispatch(updateItemAction(id, { previewHTML }));
      });
  };

const fetchItemSource = (editorAPI: EditorAPI, item: AnyFixMe) => {
  const { source, sourceUrl } = item;
  return source
    ? Promise.resolve(_.cloneDeep(source))
    : editorAPI.savedComponents.httpService.fetchItemSource(sourceUrl);
};

const biFields = { category: 'my_designs' };

const pasteItem =
  (id: AnyFixMe, options: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_PASTE,
    );

    const item = selectors.getSavedComponentsItem(getState(), id);

    const biEventFields = {
      success: true,
      adding_method: options.addingMethod,
      theme: options.shouldApplyTargetTheme ? 'target' : 'origin',
      element_id: id,
      content_type: null as string,
      error_msg: null as string,
    };
    const setBiComponentType = (source: AnyFixMe) => {
      const components = source?.serializedData?.components;
      biEventFields.content_type =
        components && createBiComponentType(components);
    };

    return fetchItemSource(editorAPI, item)
      .then((source: AnyFixMe) => {
        setBiComponentType(source);
        return source;
      })
      .then(({ serializedData, metaData }: AnyFixMe) =>
        editorAPI.savedComponents.pasteComponents(
          serializedData,
          metaData,
          options,
          biFields,
        ),
      )
      .then(() => {
        biEventFields.success = true;
        dispatch(
          biState.actions.event(
            coreBi.events.savedComponents.add_element_to_stage,
            biEventFields,
          ),
        );
        dispatch(history.actions.add('savedComponents:past'));
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SAVED_COMPONENTS_PASTE,
        );
      })
      .catch((e: AnyFixMe) => {
        biEventFields.success = false;
        biEventFields.error_msg = createBiErrorMassage(e);
        dispatch(
          biState.actions.event(
            coreBi.events.savedComponents.add_element_to_stage,
            biEventFields,
          ),
        );
      });
  };

export {
  setCollectionAction,
  renameItem,
  deleteItem,
  saveItem,
  fetchCollection,
  fetchItemPreview,
  pasteItem,
};
