import { array } from '#packages/util';
import {
  dsOneDockAdapter,
  isMeshLayoutEnabled,
} from '#packages/layoutOneDockMigration';

import { createLayoutGetApi_Bolt } from './createLayoutGetApi_Bolt';
import { createLayoutGetApi_FromDOM } from './createLayoutGetApi_FromDOM';
import * as domLayout from './domLayout';
import {
  createLayoutTypeDefine,
  DefineLayoutType as LayoutType,
} from '../lib/createLayoutTypeDefine';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, CompRef } from 'types/documentServices';

export function createLayoutGetApi({ editorAPI }: { editorAPI: EditorAPI }) {
  // NOTE:
  // Tom Halpern (13.02.2023)
  // > Looks like we still have 14 users excluded. Meaning they are getting Bolt.
  const layoutGetApi_Bolt = createLayoutGetApi_Bolt({ editorAPI });
  const layoutGetApi_FromDOM = createLayoutGetApi_FromDOM({
    editorAPI,
  });
  const { define } = createLayoutTypeDefine();

  function pickPosition(layout: CompLayout): Pick<CompLayout, 'x' | 'y'> {
    if (!layout) {
      return layout;
    }

    const { x, y } = layout;

    return {
      x,
      y,
    };
  }

  function pickSize(layout: CompLayout): Pick<CompLayout, 'width' | 'height'> {
    if (!layout) {
      return layout;
    }

    const { width, height } = layout;

    return {
      width,
      height,
    };
  }

  function pickRect(
    layout: CompLayout,
  ): Pick<CompLayout, 'x' | 'y' | 'width' | 'height'> {
    if (!layout) {
      return layout;
    }

    const { x, y, width, height } = layout;

    return {
      x,
      y,
      width,
      height,
    };
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.rotationInDegrees`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_rotationInDegrees(
    compRefOrRefs: CompRef | CompRef[],
  ): CompLayout['rotationInDegrees'] {
    if (array.isMultiselect(compRefOrRefs)) {
      return 0;
    }

    const compRef = array.asSingle(compRefOrRefs);
    if (!compRef) {
      return;
    }

    const dsRead = editorAPI.dsRead;
    return isMeshLayoutEnabled()
      ? dsOneDockAdapter(dsRead).layout.get_rotationInDegrees(compRef)
      : dsRead.components.layout.get(compRef)?.rotationInDegrees;
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.fixedPosition`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_fixedPosition(
    compRefOrRefs: CompRef | CompRef[],
  ): CompLayout['fixedPosition'] {
    const compRef = array.asSingle(compRefOrRefs);
    if (!compRef || array.isMultiselect(compRefOrRefs)) {
      return undefined;
    }

    if (isMeshLayoutEnabled()) {
      return dsOneDockAdapter(editorAPI.dsRead).layout.get_fixedPosition(
        compRef,
      );
    }

    return editorAPI.dsRead.components.layout.get(compRef)?.fixedPosition;
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.scale`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_scale(compRefOrRefs: CompRef | CompRef[]): CompLayout['scale'] {
    const compRef = array.asSingle(compRefOrRefs);
    if (!compRef || array.isMultiselect(compRefOrRefs)) {
      return undefined;
    }

    if (isMeshLayoutEnabled()) {
      return dsOneDockAdapter(editorAPI.dsRead).layout.get_scale(compRef);
    }

    return editorAPI.dsRead.components.layout.get(compRef)?.scale;
  }

  /**
   * @deprecated Use get_position, get_size, get_rect, etc. instead. Details https://github.com/wix-private/santa-editor/issues/51158
   */
  function get(compRefOrRefs: CompRef | CompRef[]): CompLayout {
    if (isMeshLayoutEnabled()) {
      return layoutGetApi_FromDOM.get(compRefOrRefs);
    }

    return layoutGetApi_Bolt.get(compRefOrRefs);
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.x|y`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_position(
    compRefOrRefs: CompRef | CompRef[],
  ): Pick<CompLayout, 'x' | 'y'> {
    return pickPosition(get(compRefOrRefs));
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.width|height`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_size(
    compRefOrRefs: CompRef | CompRef[],
  ): Pick<CompLayout, 'width' | 'height'> {
    if (isMeshLayoutEnabled() && !array.isMultiselect(compRefOrRefs)) {
      // TODO: onedoc size without measure if multiple?
      return dsOneDockAdapter(editorAPI.dsRead).layout.get_size(
        array.asSingle(compRefOrRefs),
      );
    }

    return pickSize(get(compRefOrRefs));
  }

  /**
   * Adapter for the `dsRead.components.layout.get(compRef)?.x|y|width|height`
   * Details: https://github.com/wix-private/santa-editor/issues/51158
   */
  function get_rect(
    compRefOrRefs: CompRef | CompRef[],
  ): Pick<CompLayout, 'x' | 'y' | 'width' | 'height'> {
    return pickRect(get(compRefOrRefs));
  }
  /**
   * @deprecated Use getRelativeToStructure_rect instead. Details https://github.com/wix-private/santa-editor/issues/51158
   */
  function getRelativeToStructure(compRefOrRefs: CompRef | CompRef[]) {
    if (isMeshLayoutEnabled()) {
      return layoutGetApi_FromDOM.getRelativeToStructure(compRefOrRefs);
    }

    return layoutGetApi_Bolt.getRelativeToStructure(compRefOrRefs);
  }

  function getRelativeToStructure_rect(
    compRefOrRefs: CompRef | CompRef[],
  ): Pick<CompLayout, 'x' | 'y' | 'width' | 'height'> {
    const dsRead = editorAPI.dsRead;

    if (isMeshLayoutEnabled() && !array.isMultiselect(compRefOrRefs)) {
      return dsOneDockAdapter(dsRead).layout.getRelativeToStructure_rect(
        array.asSingle(compRefOrRefs),
        {
          siteScale: editorAPI.getSiteScale(),
        },
      );
    }

    return pickRect(getRelativeToStructure(compRefOrRefs));
  }

  const getRelativeToScreenTB = domLayout.getDomLayoutFn(
    editorAPI,
    domLayout.getRelativeToScreen,
  );

  /**
   * @deprecated Use getRelativeToScreen_rect instead. Details https://github.com/wix-private/santa-editor/issues/51158
   */
  function getRelativeToScreen(compRefOrRefs: CompRef | CompRef[]) {
    if (isMeshLayoutEnabled()) {
      return layoutGetApi_FromDOM.getRelativeToScreen(compRefOrRefs);
    }

    return getRelativeToScreenTB(compRefOrRefs);
  }

  function getRelativeToScreen_rect(
    compRefOrRefs: CompRef | CompRef[],
  ): Pick<CompLayout, 'x' | 'y' | 'width' | 'height'> {
    const dsRead = editorAPI.dsRead;

    if (isMeshLayoutEnabled() && !array.isMultiselect(compRefOrRefs)) {
      return dsOneDockAdapter(dsRead).layout.getRelativeToScreen_rect(
        array.asSingle(compRefOrRefs),
        {
          siteScale: editorAPI.getSiteScale(),
        },
      );
    }

    return pickRect(getRelativeToScreen(compRefOrRefs));
  }

  const getRelativeToScreenConsideringScroll = domLayout.getDomLayoutFn(
    editorAPI,
    domLayout.getRelativeToScreenConsideringScroll,
  );

  const getMeasuredLayoutRelativeToScreen = (compRef: CompRef) =>
    domLayout.getRelativeToScreen(
      domLayout.getLayoutContext(editorAPI),
      compRef,
    );

  return {
    setLayoutReadFromDOM: layoutGetApi_Bolt.cache.setLayoutReadFromDOM,
    clearCache: define({
      [LayoutType.Default]: layoutGetApi_Bolt.cache.clearLayoutCache,
      [LayoutType.Mesh2]: layoutGetApi_FromDOM.clearCache,
    }),
    get_rotationInDegrees,
    get_fixedPosition,
    get_scale,
    get_position,
    get_size,
    get_rect,
    get,
    getRelativeToStructure_rect,
    getRelativeToStructure,
    getRelativeToScreen_rect,
    getRelativeToScreen,
    getRelativeToScreenConsideringScroll: define({
      [LayoutType.Default]: getRelativeToScreenConsideringScroll,
      [LayoutType.Mesh2]:
        layoutGetApi_FromDOM.getRelativeToScreenConsideringScroll,
    }),
    getMeasuredLayoutRelativeToScreen,
  };
}

export type LayoutGetApi = ReturnType<typeof createLayoutGetApi>;
