import _ from 'lodash';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { sections } from '#packages/util';
import * as multiStateBox from '#packages/multiStateBox';
import * as tabsComp from '#packages/tabs';

import * as componentsSelectors from '../components/componentsSelectors';
import * as boxSlideShow from '../boxSlideShow/boxSlideShow';
import * as stateBox from '../stateBox/stateBox';
import * as strip from '../strip/strip';

import { getRefComponentRootChild } from '#packages/documentServices';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, DSRead, Features } from 'types/documentServices';

const { getCurrentSlideIndex, getTotalSlidesLength } = boxSlideShow.selectors;
const { isStateBox, getPlatformDisplayName } = componentsSelectors;
const { getStateTitle } = stateBox.selectors;
const { getCurrentColumnIndex } = strip.selectors;
const {
  getSelectedStateId,
  getStateTitle: getMSBStateTitle,
  getStates,
} = multiStateBox.selectors;
const { getCurrentTabId, getTabs } = tabsComp.selectors;

interface TabsDefinition {
  type: typeof constants.UI.NAVIGATION_TYPES.TABS;
  tabs: {
    id: string;
    label: string;
    active: boolean;
  }[];
  translate: boolean;
}

interface PaginationDefinition {
  type: typeof constants.UI.NAVIGATION_TYPES.PAGINATION;
  label: string;
  translate: boolean;
}

interface DropdownDefinition {
  type: typeof constants.UI.NAVIGATION_TYPES.DROPDOWN;
  label: string;
  options: {
    id: number;
    label: string;
    active: boolean;
    key: string;
  }[];
}

type FocusBoxDefinitionsGetter = (
  compRef: CompRef,
  dsRead: DSRead,
  {
    editorAPI,
    getDisplayName,
    getComponentFeature,
    overrideTitle,
  }?: {
    editorAPI?: EditorAPI;
    getDisplayName: (compRef: CompRef) => string;
    getComponentFeature?: Features['get'];
    overrideTitle?: string;
  },
) => TabsDefinition | PaginationDefinition | DropdownDefinition;

const getRefComponentTabsDefinition: FocusBoxDefinitionsGetter = (
  refComponent,
  dsRead,
  { getDisplayName, getComponentFeature, editorAPI },
) => {
  const rootChildRef = getRefComponentRootChild(dsRead, refComponent);
  const rootChildTypeSuffix =
    rootChildRef && componentsSelectors.getCompTypeSuffix(rootChildRef, dsRead);
  const childFocusBoxDefinition =
    containerTypeToFocusBoxTabs[
      rootChildTypeSuffix as keyof typeof containerTypeToFocusBoxTabs
    ];

  return childFocusBoxDefinition?.(rootChildRef, dsRead, {
    getDisplayName,
    getComponentFeature,
    editorAPI,
  });
};

const getAppWidgetTabsDefinition: FocusBoxDefinitionsGetter = (
  appWidget,
  dsRead,
  { getDisplayName, getComponentFeature, editorAPI },
) => {
  const firstChild = _.head(dsRead.components.getChildren(appWidget));

  if (firstChild && isStateBox(firstChild, dsRead)) {
    return getStateBoxDefinition(firstChild, dsRead, {
      overrideTitle: getDisplayName(appWidget),
      getDisplayName,
      getComponentFeature,
      editorAPI,
    });
  }

  const tabs = [];
  const displayName = getDisplayName(appWidget);

  if (displayName) {
    tabs.push({
      id: 'appWidget',
      label: displayName,
      active: true,
    });
  }

  return {
    tabs,
    translate: false,
    type: constants.UI.NAVIGATION_TYPES.TABS,
  };
};

const getStripSlideShowTabsDefinition: FocusBoxDefinitionsGetter = (
  boxSlideShowRef,
  dsRead,
) => {
  const currentSlide = getCurrentSlideIndex(dsRead, boxSlideShowRef) + 1;
  const totalSlides = getTotalSlidesLength(dsRead, boxSlideShowRef);

  const defaultLabel = translate('gfpp_action_navigation_label', {
    curr_slide: currentSlide,
    total_slides: totalSlides,
  });

  return {
    label: getPlatformDisplayName(boxSlideShowRef, dsRead) || defaultLabel,
    type: constants.UI.NAVIGATION_TYPES.PAGINATION,
    translate: false,
  };
};

const getStateBoxDefinition: FocusBoxDefinitionsGetter = (
  stateBoxRef,
  dsRead,
  { overrideTitle, getDisplayName } = { getDisplayName: () => '' },
) => {
  const states = dsRead.components.getChildren(stateBoxRef);
  const currentIndex = getCurrentSlideIndex(dsRead, stateBoxRef);

  return {
    label: overrideTitle || getDisplayName(stateBoxRef),
    options: states.map((stateRef, index) => {
      const label = getStateTitle(dsRead, stateBoxRef, stateRef);
      return {
        id: index,
        label,
        active: index === currentIndex,
        key: `${label} ${index}`,
      };
    }),
    type: constants.UI.NAVIGATION_TYPES.DROPDOWN,
  };
};

const getMultiStateBoxDefinition: FocusBoxDefinitionsGetter = (
  multiStateBoxRef,
  dsRead,
  { overrideTitle, getDisplayName, getComponentFeature },
) => {
  const states = getStates(multiStateBoxRef, dsRead);
  const selectedStateId = getSelectedStateId(multiStateBoxRef, dsRead);

  return {
    label: overrideTitle || getDisplayName(multiStateBoxRef),
    options: states.map((stateRef, index) => {
      const { id } = stateRef;
      const label = getMSBStateTitle(dsRead, stateRef, getComponentFeature);
      return {
        id: index,
        label,
        active: id === selectedStateId,
        key: id,
      };
    }),
    type: constants.UI.NAVIGATION_TYPES.DROPDOWN,
  };
};

const getTabsDefinition: FocusBoxDefinitionsGetter = (
  tabsRef,
  dsRead,
  { overrideTitle, getDisplayName },
) => {
  const tabs = getTabs(tabsRef, dsRead);
  const currentTabId = getCurrentTabId(tabsRef, dsRead);

  return {
    label: overrideTitle || getDisplayName(tabsRef),
    options: tabs.map((tabRef, index) => {
      const { label, tabId } = tabRef;
      return {
        id: index,
        label,
        active: tabId === currentTabId,
        key: tabId,
      };
    }),
    type: constants.UI.NAVIGATION_TYPES.DROPDOWN,
  };
};

const getRepeaterTabsDefinition: FocusBoxDefinitionsGetter = (
  repeaterRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: [
    {
      id: 'repeater',
      label: getDisplayName(repeaterRef),
      active: true,
    },
  ],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const getMenuContainerTabsDefinition: FocusBoxDefinitionsGetter = (
  menuContainerRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: [
    {
      id: 'menu',
      label: getDisplayName(menuContainerRef),
      active: true,
    },
  ],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const getColumnTabsDefinition: FocusBoxDefinitionsGetter = (
  columnRef,
  dsRead,
) => {
  const index = getCurrentColumnIndex(dsRead, columnRef);

  const defaultLabel = translate('gfpp_column_navigation_slide_number', {
    curr_column: index + 1,
  });

  return {
    tabs: [
      {
        id: `item_${index}`,
        label: getPlatformDisplayName(columnRef, dsRead) || defaultLabel,
        active: true,
      },
    ],
    type: constants.UI.NAVIGATION_TYPES.TABS,
    translate: false,
  };
};

const getPopupContainerTabsDefinition: FocusBoxDefinitionsGetter = (
  popupContainerRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: [
    {
      id: 'item',
      label: getDisplayName(popupContainerRef),
      active: true,
    },
  ],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const getSiteRegionTabsDefinition: FocusBoxDefinitionsGetter = (
  siteRegionRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: [
    {
      id: 'siteRegion',
      label: getDisplayName(siteRegionRef),
      active: true,
    },
  ],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const getStripColumnsContainerTabsDefinition: FocusBoxDefinitionsGetter = (
  stripColumnsContainerRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: [
    {
      id: 'item_component_label_stripColumnsContainer',
      label: getDisplayName(stripColumnsContainerRef),
      active: true,
    },
  ],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const getModefulContainerTabsDef = () => ({
  type: constants.UI.NAVIGATION_TYPES.TABS,
});

const getSectionTabsDefinition: FocusBoxDefinitionsGetter = (
  compRef,
  dsRead,
  { getDisplayName },
) => ({
  tabs: sections.isSectionsEnabled()
    ? []
    : [{ id: 'section', label: getDisplayName(compRef), active: true }],
  type: constants.UI.NAVIGATION_TYPES.TABS,
  translate: false,
});

const containerTypeToFocusBoxTabs: Record<
  string,
  FocusBoxDefinitionsGetter | undefined
> = {
  AppWidget: getAppWidgetTabsDefinition,
  RefComponent: getRefComponentTabsDefinition,
  StripContainerSlideShow: getStripSlideShowTabsDefinition,
  BoxSlideShow: getStripSlideShowTabsDefinition,
  StateBox: getStateBoxDefinition,
  StateStrip: getStateBoxDefinition,
  Repeater: getRepeaterTabsDefinition,
  MenuContainer: getMenuContainerTabsDefinition,
  Column: getColumnTabsDefinition,
  PopupContainer: getPopupContainerTabsDefinition,
  SiteRegionContainer: getSiteRegionTabsDefinition,
  StripColumnsContainer: getStripColumnsContainerTabsDefinition,
  ClassicSection: getSectionTabsDefinition,
  MultiStateBox: getMultiStateBoxDefinition,
  Tabs: getTabsDefinition,
};

export { containerTypeToFocusBoxTabs, getModefulContainerTabsDef };
