import { areComponentsSiblings } from '#packages/documentServices';
import type { CompRef, DSRead } from 'types/documentServices';

export function ensureComponentsAreSiblings(
  dsRead: DSRead,
  compRefs: CompRef[],
) {
  if (!areComponentsSiblings(dsRead, compRefs)) {
    throw new Error('Components are not siblings');
  }
}
