import type { ComponentsAddResult } from '#packages/components';
import type { CompRef } from 'types/documentServices';

// export function createComponentAddResult({
//   waitForAddedCompRef,
//   waitForChangesApplied,
// }: {
//   waitForAddedCompRef: Promise<CompRef>;
//   waitForChangesApplied: Promise<CompRef>;
// }) {
//   return {
//     hooks: {
//       waitForAddedCompRef,
//       waitForChangesApplied,
//     },
//   };
// }

export async function waitForAddedCompRef(
  compRefOrAddResult: CompRef | null | undefined | ComponentsAddResult,
) {
  return compRefOrAddResult && 'hooks' in compRefOrAddResult
    ? await compRefOrAddResult.hooks.waitForCompRef
    : (compRefOrAddResult as CompRef | null | undefined);
}
