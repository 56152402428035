import _ from 'lodash';
import * as actionTypes from './domMeasurementsActionTypes';
import * as util from '#packages/util';
import type { Reducer } from 'types/redux';
import type {
  PreviewPosition,
  StageLayout,
  StageRect,
} from './domMeasurements.types';

const {
  SET_MEASURED_DOM,
  CLEAR_MEASURED_DOM,
  SET_STAGE_LAYOUT,
  SET_MEASURED_SCROLL,
  SET_STAGE_RECT,
  SET_PREVIEW_POS,
  SET_EDITOR_CONTENT_LAYOUT,
} = actionTypes;

interface Layout {
  top: number;
  left: number;
  bottom: number;
  width: number;
  x: number;
  y: number;
  right: number;
  height: number;
}

export interface DomMeasurementsState {
  stageLayout?: StageLayout;
  editorContentLayout?: Layout;
  scroll?: {
    scrollHeight: number;
    scrollLeft: number;
    scrollTop: number;
    scrollWidth: number;
  };
  previewPosition?: PreviewPosition;
  stageRect?: StageRect;
}

export const domMeasurementsInitialState: DomMeasurementsState = {
  stageLayout: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },
  editorContentLayout: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },
  scroll: {
    scrollLeft: 0,
    scrollTop: 0,
    scrollHeight: 0,
    scrollWidth: 0,
  },
  stageRect: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  previewPosition: {
    top: util.topBar.getHeightConst(),
    left: 0,
    height: 0,
    width: 0,
  },
};

const reducer: Reducer<DomMeasurementsState> = (
  state = domMeasurementsInitialState,
  action,
) => {
  switch (action.type) {
    case SET_MEASURED_DOM:
      return _.defaults({ [action.measureKey]: action.measuredRes }, state);
    case CLEAR_MEASURED_DOM:
      return _.omit(state, [action.measureKey]);
    case SET_STAGE_LAYOUT:
      return _.defaults({ stageLayout: action.stageLayout }, state);
    case SET_MEASURED_SCROLL:
      return _.defaults({ scroll: action.scroll }, state);
    case SET_STAGE_RECT:
      return _.defaults({ stageRect: action.stageRect }, state);
    case SET_PREVIEW_POS:
      return _.defaults({ previewPosition: action.previewPos }, state);
    case SET_EDITOR_CONTENT_LAYOUT:
      return _.defaults(
        { editorContentLayout: action.editorContentLayout },
        state,
      );
    default:
      return state;
  }
};

export default reducer;
