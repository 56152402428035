import type { Selector } from './selector';
import * as reselect from 'reselect';
import type { StateMapperArgs } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { DSRead } from 'types/documentServices';
import type { EditorState } from '../store/editorState';

const { defaultMemoize } = reselect;

export const editorAPISel: Selector<StateMapperArgs, EditorAPI> =
  defaultMemoize(
    ({ editorAPI }) => Object.assign({}, editorAPI),
    (prev: any, next: any) => {
      if (next.state.mouseActions?.dragInProgress) {
        return true;
      }
      return (
        next.state.viewerRendersCounter === prev.state.viewerRendersCounter &&
        next.state === prev.state
      );
    },
  );

export const editorAPIMouseSel: Selector<StateMapperArgs, EditorAPI> =
  defaultMemoize(
    ({ editorAPI }) => Object.assign({}, editorAPI),
    (prev: any, next: any) => {
      return (
        next.state.viewerRendersCounter === prev.state.viewerRendersCounter &&
        next.state === prev.state
      );
    },
  );

export const editorStateSel: Selector<StateMapperArgs, EditorState> =
  defaultMemoize(
    ({ state }) => state,
    (prev: any, next: any) => {
      if (next.state.mouseActions?.dragInProgress) {
        return true;
      }
      return prev.state === next.state;
    },
  );

export const dsReadSel: Selector<StateMapperArgs, DSRead> = defaultMemoize(
  ({ dsRead }) => Object.assign({}, dsRead),
  (prev: any, next: any) => {
    if (next.state.mouseActions?.dragInProgress) {
      return true;
    }
    return next.state.viewerRendersCounter === prev.state.viewerRendersCounter;
  },
);

export const editorStateMouseOpsSel: Selector<StateMapperArgs, EditorState> =
  defaultMemoize(
    ({ state }) => state,
    (prev: any, next: any) => {
      return prev.state === next.state;
    },
  );

export const getStateMapperArgsFromEditorAPI = (
  editorAPI: EditorAPI,
): StateMapperArgs => ({
  editorAPI,
  host: editorAPI.host,
  dsRead: editorAPI.dsRead,
  state: editorAPI.store.getState(),
});
