import experiment from 'experiment';
import * as util from '#packages/util';
import constants from '#packages/constants';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

interface ComponentPanelContext {
  componentType: string;
  componentPanelType: string;
  isTpa: boolean;
  isMobileComponent: boolean;
  isMobileOnlyComponent: boolean;
}

/**
 * @deprecated use panelLoader + dynamic import or editor-elements-registry
 * ```
 * panelLoader: () => componentModel.loadComponentPanel(componentType, componentPanelType),
 * panelLoader: () => import('compPanels').then(compPanels => compPanels.panels[componentType][componentPanelType]),
 * ```
 */
export const getComponentPanelPathByType_DEPRECATED = async ({
  componentType,
  componentPanelType,
  isTpa,
  isMobileComponent,
  isMobileOnlyComponent,
}: ComponentPanelContext): Promise<string> => {
  const componentTypeSuffix = util.path.suffix(componentType);

  if (componentTypeSuffix === 'AppPart') {
    return `wixApps.AppPart.${componentPanelType}`;
  }

  return import('@/compPanels').then((compPanels) => {
    if (
      isMobileComponent &&
      !isMobileOnlyComponent &&
      (
        compPanels.panels[
          componentTypeSuffix as keyof typeof compPanels.panels
        ] as AnyFixMe
      )?.mobile?.[componentPanelType] !== undefined
    ) {
      return `compPanels.panels.${componentTypeSuffix}.mobile.${componentPanelType}`;
    }

    let path = `compPanels.panels.${componentTypeSuffix}.${componentPanelType}`;

    if (
      compPanels.panels[componentTypeSuffix]?.[componentPanelType] === undefined
    ) {
      if (isTpa && componentPanelType === constants.componentPanels.settings) {
        // looks like this method is not called by tpa GFPP. `openPanel` is called directly with tpa settings panel path as name
        path = 'tpa.compPanels.tpaSettings';
      } else if (componentPanelType === constants.componentPanels.animation) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.animation}`;
      } else if (componentPanelType === constants.componentPanels.stretching) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.stretching}`;
      } else if (componentPanelType === constants.componentPanels.filters) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.filters}`;
      } else if (componentPanelType === constants.componentPanels.effects) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.effects}`;
      } else if (componentPanelType === constants.componentPanels.apply) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.apply}`;
      } else if (componentPanelType === constants.componentPanels.design) {
        path = `designPanel.${constants.componentPanels.design}`;
      } else if (
        componentPanelType === constants.componentPanels.customizeDesign
      ) {
        path = `advancedStylePanel.${constants.componentPanels.customizeDesign}`;
      } else if (
        componentPanelType ===
        constants.componentPanels.mobileBackgroundSettings
      ) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.mobileBackgroundSettings}`;
      } else if (componentPanelType === constants.componentPanels.stylable) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.stylable}`;
      } else if (componentPanelType === constants.componentPanels.smartWriter) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.smartWriter}`;
      } else if (componentPanelType === constants.componentPanels.aiTools) {
        path = `compPanels.dynamicPanels.${constants.componentPanels.aiTools}`;
      } else if (
        componentPanelType === constants.componentPanels.aiTextGenerator
      ) {
        const openAiTextCreatorV2 = isResponsiveEditor()
          ? experiment.isOpen('se_aiTextCreatorV2ForEditorX')
          : experiment.isOpen('se_aiTextCreatorV2');

        path = openAiTextCreatorV2
          ? `compPanels.dynamicPanels.${constants.componentPanels.aiTextCreatorV2}`
          : `compPanels.dynamicPanels.${constants.componentPanels.aiTextGenerator}`;
      }
    }

    return path;
  });
};
