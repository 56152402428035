import type { EditorState } from '#packages/stateManagement';
import * as actionTypes from './savePublishActionTypes';
import _ from 'lodash';

const getWasLatestRCPublished = (state: EditorState) =>
  state.savePublish.wasLatestRCPublished;
const getOverridingSiteWasSavedPanel = (state: EditorState) =>
  state.savePublish.overridden.siteWasSavedPanel;
const getOverridingDomainPanel = (state: EditorState) =>
  state.savePublish.overridden.domainPanel;
const getOverridingSaveAndPublish = (state: EditorState) =>
  state.savePublish.overridden.saveAndPublish;
const getOverridingSaveReminderPanel = (state: EditorState) =>
  state.savePublish.overridden.saveReminderPanel;
const getOverridingSiteDefaultName = (state: EditorState) =>
  state.savePublish.overridden.siteDefaultName;
const getOverridingShouldCheckForConcurrentUsers = (state: EditorState) =>
  state.savePublish.overridden.shouldCheckForConcurrentUsers;
const getIsDiffSaveInProgress = (state: EditorState): boolean =>
  state.savePublish.isDiffSaveInProgress;
const getIsBuildInProgress = (state: EditorState): boolean =>
  state.savePublish.isBuildInProgress;
const getIsPublishInProgress = (state: EditorState): boolean =>
  state.savePublish.isPublishInProgress;
const getIsTestSiteInProgress = (state: EditorState): boolean =>
  state.savePublish.isTestSiteInProgress;
const getIsSaveInProgress = (state: EditorState): boolean =>
  state.savePublish.isSaveInProgress;
const getIsManualSave = (state: EditorState): boolean =>
  state.savePublish.isManualSave;
const getIsAfterManualSave = (state: EditorState): boolean =>
  state.savePublish.isAfterManualSave;
const getIsLocked = (state: EditorState): boolean => state.savePublish.isLocked;
const getIsForceSaveInBackground = (state: EditorState): boolean =>
  state.savePublish.isForceSaveInBackground;
const getPublishPanelPromotionIndex = (state: EditorState): number =>
  state.savePublish.publishPanelPromotionIndex;
const getIsDealerPostpublishLoaded = (state: EditorState): boolean =>
  state.savePublish.isDealerPostpublishLoaded;
const getIsDealerPostsaveLoaded = (state: EditorState): boolean =>
  state.savePublish.isDealerPostsaveLoaded;
const getWaitingForSaveDoneCallbacks = (
  state: EditorState,
): ((saveSuccess: boolean, e?: any) => void)[] =>
  _.cloneDeep(state.savePublish.saveProgressCallbacks);
const getIsWaitingForSaveDoneEmpty = (state: EditorState): boolean =>
  state.savePublish.saveProgressCallbacks.length === 0;
const getQrCodeUrl = (state: EditorState): string =>
  state.savePublish.qrCodeUrl;
const getSessionLoadedAsDraft = (state: EditorState): boolean =>
  state.savePublish.sessionLoadedAsDraft;
const getIsPublishedInCurrentSession = (state: EditorState): boolean =>
  state.savePublish.isPublishedInCurrentSession;
const getIsIgnorePublishErrorForCurrentSession = (
  state: EditorState,
): boolean =>
  Boolean(
    state.userPreferences.session?.[actionTypes.USER_IGNORE_PUBLISH_ERROR],
  );

export {
  getWasLatestRCPublished,
  getOverridingSiteWasSavedPanel,
  getOverridingDomainPanel,
  getOverridingSaveAndPublish,
  getOverridingSaveReminderPanel,
  getOverridingSiteDefaultName,
  getOverridingShouldCheckForConcurrentUsers,
  getIsDiffSaveInProgress,
  getIsBuildInProgress,
  getIsPublishInProgress,
  getIsTestSiteInProgress,
  getIsSaveInProgress,
  getIsManualSave,
  getIsAfterManualSave,
  getIsLocked,
  getIsForceSaveInBackground,
  getPublishPanelPromotionIndex,
  getIsDealerPostpublishLoaded,
  getIsDealerPostsaveLoaded,
  getWaitingForSaveDoneCallbacks,
  getIsWaitingForSaveDoneEmpty,
  getQrCodeUrl,
  getSessionLoadedAsDraft,
  getIsPublishedInCurrentSession,
  getIsIgnorePublishErrorForCurrentSession,
};
