// @ts-nocheck
import $ from 'zepto';
import _ from 'lodash';
import constants from '#packages/constants';
import * as panelsActionTypes from './welcomeScreenActionTypes';
import * as domainSelectors from '../domain/domainSelectors';
import * as notificationsActions from '../notifications/notificationsActions';

const baseUrl =
  'editor.wix.com/_api/wix-html-live-site-data-webapp/html-site-properties';

const setLocalConfig = (config, isDirty) => ({
  type: panelsActionTypes.SET_CONFIG,
  welcomeScreen: { config, isDirty },
});

const ajaxAction =
  (getAjaxParams) =>
  (dispatch, getState, { dsRead }) => {
    const siteProtocol = domainSelectors.getSiteProtocol(getState(), dsRead);
    const siteId = dsRead.generalInfo.getSiteId();
    const { instance } = dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    );
    const headers = {
      Accept: 'application/json',
      Authorization: instance,
    };
    $.ajax(
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        { headers },
        getAjaxParams({ dispatch, getState, siteId, siteProtocol }),
      ),
    );
  };

const getRemoteConfig = () =>
  ajaxAction(({ dispatch, siteId, siteProtocol }) => ({
    type: 'GET',
    url: `${siteProtocol}://${baseUrl}/${siteId}?property_types=welcomeScreen`,
    dataType: 'json',
    success: (response) => {
      const config = response?.properties?.[0]?.content ?? null;
      if (config) {
        dispatch(setLocalConfig(JSON.parse(config), true));
      }
    },
    error: () => {
      dispatch(setLocalConfig({ error: true }));
    },
  }));

const setRemoteConfig = (config) =>
  ajaxAction(({ dispatch, siteId, siteProtocol }) => ({
    type: 'PUT',
    url: `${siteProtocol}://${baseUrl}/save/${siteId}`,
    contentType: 'application/json',
    data: JSON.stringify({
      property: {
        propertyType: 'welcomeScreen',
        content: JSON.stringify(config),
      },
    }),
    error: () => {
      dispatch(
        notificationsActions.showUserActionNotification({
          message: 'Notifications_Error_Welcome_Screen_Not_Saved',
          type: 'error',
        }),
      );
    },
  }));

const deleteRemoteConfig = (onSuccess) =>
  ajaxAction(({ dispatch, siteId, siteProtocol }) => ({
    type: 'DELETE',
    url: `${siteProtocol}://${baseUrl}/delete/${siteId}?property_types=welcomeScreen`,
    success: () => {
      onSuccess();
    },
    error: () => {
      dispatch(
        notificationsActions.showUserActionNotification({
          message: 'Notifications_Error_Welcome_Screen_Unavailable',
          type: 'error',
        }),
      );
    },
  }));

const publishRemoteConfig = () =>
  ajaxAction(({ siteId, siteProtocol }) => ({
    type: 'POST',
    url: `${siteProtocol}://${baseUrl}/publish/welcome-screen/${siteId}`,
  }));

const setOverrideItem = (overrides) => ({
  type: panelsActionTypes.SET_OVERRIDE,
  overrides,
});

export {
  setLocalConfig as setLocalWelcomeScreenConfig,
  getRemoteConfig as getRemoteWelcomeScreenConfig,
  setRemoteConfig as setRemoteWelcomeScreenConfig,
  deleteRemoteConfig as deleteRemoteWelcomeScreenConfig,
  publishRemoteConfig as publishRemoteWelcomeScreenConfig,
  setOverrideItem,
};
