import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import { hasFixedItemLayout } from '#packages/layoutUtils';
import { isPopupContainer } from '#packages/documentServices';

import { getFixedLayoutItemDockingOuterOffsets } from './private/fixedItemLayoutByDirection';
import { getPopupContainerPropertiesDockingOffsets } from './private/popupContainerPropertiesByDirection';
import { getDockingDockingOuterOffsets } from './private/dockingByDirection';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, PopupContainerProperties } from 'types/documentServices';
import type { PinDockingOuterOffsets } from './types';

export function getPinDockingOuterOffsets(
  editorAPI: EditorAPI,
  compRef: CompRef,
): PinDockingOuterOffsets | null {
  if (isMeshLayoutEnabled()) {
    const layouts = editorAPI.components.layout.__mesh.get(compRef);

    if (!hasFixedItemLayout(layouts)) {
      return null;
    }

    return getFixedLayoutItemDockingOuterOffsets(layouts);
  }

  if (isPopupContainer(editorAPI.dsRead, compRef)) {
    const properties = editorAPI.components.properties.get(
      compRef,
    ) as PopupContainerProperties;
    return getPopupContainerPropertiesDockingOffsets(properties);
  }

  const compLayout = editorAPI.components.layout.get(compRef);
  // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
  const compDocking = editorAPI.components.layout.getDock(compRef);
  return compLayout && compDocking
    ? getDockingDockingOuterOffsets({
        compLayout,
        compDocking,
      })
    : null;
}
