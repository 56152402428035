// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import { overridable } from '@wix/santa-editor-utils';
import * as highlightsSelectors from './highlightsSelectors';
import * as selectionSelectors from '../selection/selectionSelectors';
import * as mouseActions from '../mouseActions/mouseActions';
import highlightsActionTypes from './highlightsActionTypes';
import { isRepeater } from '#packages/documentServices';
import type { CompRef } from 'types/documentServices';

function getItemHighlight(editorAPI, compRef, withOpacity) {
  return {
    compRef,
    layout: editorAPI.components.layout.getRelativeToScreen(compRef),
    type: withOpacity
      ? constants.UI.HIGHLIGHTS.TYPES.THICK_WITH_OPACITY
      : constants.UI.HIGHLIGHTS.TYPES.THICK,
  };
}

const getHighlightStructure = (compRef, layout, type) => ({
  compRef,
  layout,
  type,
});

const {
  ADD_TO_HIGHLIGHTS,
  REMOVE_COMPS_FROM_HIGHLIGHTS,
  SET_HIGHLIGHTS,
  CLEAR_HIGHLIGHTS,
  REMOVE_FROM_HIGHLIGHTS_BY_HIGHLIGHT_TYPE,
} = highlightsActionTypes;

const addToHighlights = (highlightsDefs) => ({
  type: ADD_TO_HIGHLIGHTS,
  highlightsDefs,
});

const addCompsToHighlights =
  (compRefs: CompRef[], highlightType: string) =>
  (dispatch, getState, { editorAPI }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const highlightsDefs = _.map(compRefs, (compRef) => ({
      compRef,
      layout: editorAPI.components.layout.getRelativeToScreen(compRef),
      type: highlightType,
    }));

    dispatch(addToHighlights(highlightsDefs));
  };

const addExternalHighlight =
  (compRef) =>
  (dispatch, getState, { dsRead }) =>
    dispatch(
      addToHighlights([
        {
          compRef,
          layout: dsRead.components.layout.getRelativeToScreen(compRef),
          type: constants.UI.HIGHLIGHTS.TYPES.EXTERNAL,
        },
      ]),
    );

const removeCompsFromHighlights = overridable((compRefs) => ({
  type: REMOVE_COMPS_FROM_HIGHLIGHTS,
  compRefs,
}));

const clearExternalHighlights = () => (dispatch) =>
  dispatch(
    removeFromHighlightsByHighlightType([
      constants.UI.HIGHLIGHTS.TYPES.EXTERNAL,
    ]),
  );

const clearOverlayHighlights = () => (dispatch) =>
  dispatch(
    removeFromHighlightsByHighlightType([
      constants.UI.HIGHLIGHTS.TYPES.OVERLAY,
    ]),
  );

const setHighlights = (highlightsDefs) => ({
  type: SET_HIGHLIGHTS,
  highlightsDefs,
});

const clearHighlights = () => ({
  type: CLEAR_HIGHLIGHTS,
});

const removeFromHighlightsByHighlightType = (highlightTypes: string[]) => ({
  type: REMOVE_FROM_HIGHLIGHTS_BY_HIGHLIGHT_TYPE,
  highlightTypes,
});

const { isPerformingMouseMoveAction } = mouseActions.selectors;

const getPlatformConnectionToHighlight = (dsRead, compRef) => {
  const connections = dsRead.platform.controllers.connections.get(compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  if (_.isArray(connections)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const compConnection = _.find(connections, (connection) => {
      const { controllerRef, role } = connection;
      const stageDataByRole = dsRead.platform.controllers.getStageDataByRole(
        controllerRef,
        role,
      );
      return stageDataByRole?.relatedToRole;
    });
    if (compConnection) {
      const { controllerRef, role } = compConnection;
      const connectedComponents =
        dsRead.platform.controllers.connections.getConnectedComponents(
          controllerRef,
        );
      const roleConnection = dsRead.platform.controllers.getStageDataByRole(
        controllerRef,
        role,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      return _.filter(
        connectedComponents,
        (connectedRef) =>
          _.head(dsRead.platform.controllers.connections.get(connectedRef))
            .role === roleConnection.relatedToRole &&
          dsRead.components.is.rendered(connectedRef) &&
          dsRead.components.is.visible(connectedRef),
      );
    }
  }
};

const handleSelectionHighlights = overridable(
  () =>
    (dispatch, getState, { dsRead, editorAPI }) => {
      const highlightsDefs = [];
      const selectedComponents =
        selectionSelectors.getSelectedCompsRefs(getState());
      const selectedComponent = _.head(selectedComponents);

      dispatch(
        removeFromHighlightsByHighlightType([
          constants.UI.HIGHLIGHTS.TYPES.THICK_WITH_OPACITY,
          constants.UI.HIGHLIGHTS.TYPES.THICK,
        ]),
      );

      if (!selectedComponent) {
        return;
      }

      const platformRelatedCompRefs = getPlatformConnectionToHighlight(
        dsRead,
        selectedComponent,
      );
      if (!_.isEmpty(platformRelatedCompRefs)) {
        platformRelatedCompRefs.forEach((compRef) =>
          highlightsDefs.push(getItemHighlight(editorAPI, compRef, false)),
        );
        dispatch(addToHighlights(highlightsDefs));
        return;
      }
      const isRepeaterItem =
        dsRead.components.getType(
          dsRead.deprecatedOldBadPerformanceApis.components.getContainer(
            selectedComponent,
          ),
        ) === 'wysiwyg.viewer.components.Repeater';

      if (isRepeaterItem) {
        highlightsDefs.push(
          getItemHighlight(editorAPI, selectedComponent, false),
        );
        dispatch(addToHighlights(highlightsDefs));
        return;
      }

      const repeaterItemRef = editorAPI.components.findAncestor(
        selectedComponent,
        (ancestor) =>
          isRepeater(
            dsRead,
            dsRead.deprecatedOldBadPerformanceApis.components.getContainer(
              ancestor,
            ),
          ),
        { includeScopeOwner: true },
      );

      if (repeaterItemRef) {
        highlightsDefs.push(getItemHighlight(editorAPI, repeaterItemRef, true));

        dispatch(addToHighlights(highlightsDefs));
      }
    },
);

const handleComponentHighlight =
  (compRef, layout, isHover: boolean, type?: string) => (dispatch) => {
    const highlightsDefs = [];
    const defaultHighlightType = isHover
      ? constants.UI.HIGHLIGHTS.TYPES.OVERLAY_WITH_OPACITY
      : constants.UI.HIGHLIGHTS.TYPES.OVERLAY;
    const highlightType = type ? type : defaultHighlightType;

    dispatch(removeFromHighlightsByHighlightType([highlightType]));
    highlightsDefs.push(getHighlightStructure(compRef, layout, highlightType));
    dispatch(addToHighlights(highlightsDefs));
  };

const handleMultiComponentHighlight =
  (compRefsWithLayout, isHover) => (dispatch) => {
    const highlightsDefs = [];
    const highlightType = isHover
      ? constants.UI.HIGHLIGHTS.TYPES.OVERLAY_WITH_OPACITY
      : constants.UI.HIGHLIGHTS.TYPES.OVERLAY;

    dispatch(removeFromHighlightsByHighlightType([highlightType]));
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compRefsWithLayout, ({ compRef, layout }) => {
      highlightsDefs.push(
        getHighlightStructure(compRef, layout, highlightType),
      );
    });

    dispatch(addToHighlights(highlightsDefs));
  };

const updateHighlights =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const state = getState();
    const highlights = highlightsSelectors.getHighlights(state);

    if (_.isEmpty(highlights) || isPerformingMouseMoveAction(state)) {
      return;
    }

    const isHighlightCompRefExist = ({ compRef }) =>
      dsRead.components.is.exist(compRef);
    const highlightDefWithLayout = (highlightDef) =>
      _.defaults(
        {
          layout: getComponentLayout(highlightDef.compRef, editorAPI),
        },
        highlightDef,
      );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const updatedHighlights = _(highlights)
      .filter(isHighlightCompRefExist)
      .map(highlightDefWithLayout)
      .value();

    dispatch(setHighlights(updatedHighlights));
  };

const getComponentLayout = (compRef, editorAPI) => {
  if (!editorAPI.zoomMode.isStageZoomMode()) {
    return editorAPI.components.layout.getRelativeToScreen(compRef);
  }

  const boundingRect =
    editorAPI.components.layout.measure.getBoundingClientRect(compRef);
  return {
    ...boundingRect,
    y: boundingRect.absoluteTop,
    x: boundingRect.absoluteLeft,
  };
};

export {
  addToHighlights,
  addCompsToHighlights,
  addExternalHighlight,
  clearExternalHighlights,
  removeCompsFromHighlights,
  removeFromHighlightsByHighlightType,
  setHighlights,
  clearHighlights,
  clearOverlayHighlights,
  handleSelectionHighlights,
  handleComponentHighlight,
  updateHighlights,
  handleMultiComponentHighlight,
  getComponentLayout,
};
