import { arrayUtils } from '#packages/util';
import {
  isMeshLayoutEnabled,
  dsOneDockAdapter,
} from '#packages/layoutOneDockMigration';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export function createLayoutRotationApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  function isRotated(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    return compRefs.every((compRef) =>
      isMeshLayoutEnabled()
        ? dsOneDockAdapter(editorAPI.dsRead).layout.isRotated(compRef)
        : editorAPI.dsRead.components.layout.isRotated(compRef),
    );
  }

  return {
    isRotated,
  };
}

export type LayoutRotationApi = ReturnType<typeof createLayoutRotationApi>;
