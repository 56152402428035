import * as util from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';
import type { LayoutRelativeToScreenPluginDef } from '../layoutRelativeToScreenPlugins';

const ORIGINAL_TO_NEW_WIDTH_OFFSET = 1;

export default function (
  editorAPI: EditorAPI,
  compRef: CompRef,
  layout: CompLayout,
  keepProportions: boolean,
): LayoutRelativeToScreenPluginDef {
  const imageProperties = editorAPI.components.properties.get(compRef);
  const {
    crop,
    width: originalWidth,
    height: originalHeight,
  } = editorAPI.components.data.get(compRef);
  const originalProportions = originalHeight / originalWidth;
  const width = layout.height / originalProportions;
  if (
    Math.abs(width - layout.width) < ORIGINAL_TO_NEW_WIDTH_OFFSET ||
    imageProperties.autoFill ||
    imageProperties.displayMode !==
      util.imageTransform.fittingTypes.SCALE_TO_FILL ||
    !!crop
  ) {
    return {
      updatedLayout: layout,
      keepProportions,
    };
  }
  return {
    updatedLayout: {
      ...layout,
      width: Math.round(width),
      fixedPosition: !!layout.fixedPosition,
    },
    keepProportions: false,
  };
}
