import _ from 'lodash';
import constants from '#packages/constants';
import { someComponentAncestorMatchesCondition } from '#packages/documentServices';
import { getSiteUserPreferences } from '../userPreferences/userPreferencesSelectors';
import { isReferredComponent } from '../components/componentsSelectors';

import type { DSRead } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '../store/editorState';
import type {
  InteractionType,
  CompRef,
  CompVariantPointer,
  VariantPointer,
} from 'types/documentServices';

const ENTER_INTERACTION_HISTORY_LABEL = 'ENTER_INTERACTION';
const INTERACTIONS_FIRST_TIME_USER_PREF_KEY = 'INTERACTIONS_FIRST_TIME';
const DEFAULT_Z_LAYER_VALUE = 'BELOW_PINNED';

export interface InteractionDef {
  name: InteractionType;
  label: string;
  displayNameKey: string;
}

export interface InteractionVariantDef {
  [interactionName: string]: VariantPointer[];
}

const interactionForbiddenDragComponents = new Set([
  constants.COMP_TYPES.COLUMN,
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
]);

const ALLOWED_INTERACTIONS_TYPES = {
  [constants.COMP_TYPES.CONTAINER]: true,
  [constants.COMP_TYPES.MEDIA_CONTAINER]: true,
};

const getInteractionName = (state: EditorState): InteractionType =>
  state.interactions.interactionName;

const isInInteractionMode = (state: EditorState): boolean =>
  state.interactions.isInteractionMode;

const isInteractionPlaying = (state: EditorState): boolean =>
  state.interactions.isPlaying;

const showInteractionModeControls = (state: EditorState): boolean =>
  state.interactions.showInteractionModeControls;

const doesInteractionVariantHaveOverrides = (
  editorAPI: AnyFixMe,
  state: EditorState,
): boolean => {
  const ref = getInteractionTriggerRef(state);
  const variantPointers = getVariantPointers(state);
  const triggerVariantPointer = editorAPI.components.variants.getPointer(
    ref,
    variantPointers,
  );
  return editorAPI.variants.hasOverrides(triggerVariantPointer);
};

const interactionDuration = (state: EditorState): number =>
  state.interactions.interactionDuration;

const getVariantId = (state: EditorState): string =>
  state.interactions.compVariantPointer?.variants[0]?.id;

const getInteractionTriggerRef = (state: EditorState): CompRef =>
  state.interactions.triggerRef;

const getTriggerVariantPointer = (state: EditorState): CompVariantPointer => ({
  ...state.interactions.triggerRef,
  variants: state.interactions?.compVariantPointer?.variants,
});

const getCompVariantPointer = (state: EditorState): CompVariantPointer =>
  state.interactions.compVariantPointer;

const getVariantPointers = (state: EditorState): VariantPointer[] =>
  state.interactions?.compVariantPointer?.variants;

function getFirstAncestorWithInteraction(
  editorAPI: EditorAPI,
  compRef: CompRef,
): CompRef | null {
  if (!isInteractionModeAvailable(editorAPI.dsRead)) {
    return null;
  }

  return (
    editorAPI.components.findAncestor(compRef, (ancestor) =>
      componentHasInteraction(editorAPI, ancestor),
    ) || null
  );
}

function isInteractionAllowedForComp(
  editorAPI: AnyFixMe,
  compRef: CompRef,
): boolean {
  return (
    !isReferredComponent(compRef) &&
    !!ALLOWED_INTERACTIONS_TYPES[
      editorAPI.components.getType(
        compRef,
      ) as keyof typeof ALLOWED_INTERACTIONS_TYPES
    ]
  );
}

function isInteractionsSupported(editorAPI: AnyFixMe, compRefs: CompRef[]) {
  const currentEditorState = editorAPI.store.getState();
  const inInteractionMode = isInInteractionMode(currentEditorState);

  if (
    inInteractionMode ||
    compRefs.length !== 1 ||
    !isInteractionModeAvailable(editorAPI.dsRead)
  ) {
    return false;
  }

  const isMobileEditor = editorAPI.isMobileEditor();
  if (isMobileEditor) {
    return getCompInteractionsCount(editorAPI, compRefs[0]) > 0;
  }
  return isInteractionAllowedForComp(editorAPI, compRefs[0]);
}

function getComponentInteractionsVariantsDefIfExist(
  editorAPI: AnyFixMe,
  compRef: CompRef,
): InteractionVariantDef {
  const availableInteractionTypes = getEditorAvailableInteractions(editorAPI);
  return availableInteractionTypes.reduce((acc: AnyFixMe, interactionDef) => {
    const variantPointers = editorAPI.components.variants.get(
      compRef,
      interactionDef.name,
    );
    const compVariantPointer = editorAPI.components.variants.getPointer(
      compRef,
      variantPointers,
    );
    if (editorAPI.variants.hasOverrides(compVariantPointer)) {
      acc[interactionDef.name] = variantPointers;
    }
    return acc;
  }, {});
}

const componentHasInteraction = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
): boolean => {
  if (!isInteractionsSupported(editorAPI, [compRef])) {
    return false;
  }
  return getCompInteractionsCount(editorAPI, compRef) > 0;
};

function getComponentInteractionsDefIfExist(
  editorAPI: AnyFixMe,
  compRef: CompRef,
): InteractionDef[] {
  const availableInteractionTypes = getEditorAvailableInteractions(editorAPI);
  return availableInteractionTypes.reduce((acc, interactionDef) => {
    const variantPointers = editorAPI.components.variants.get(
      compRef,
      interactionDef.name,
    );
    const compVariantPointer = editorAPI.components.variants.getPointer(
      compRef,
      variantPointers,
    );
    if (editorAPI.variants.hasOverrides(compVariantPointer)) {
      acc.push(interactionDef);
    }
    return acc;
  }, []);
}

const getCompInteractionsCount = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
): number => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/size
  return _.size(getComponentInteractionsVariantsDefIfExist(editorAPI, compRef));
};

const isOneOfAncestorsShownOnlyOnVariant = (
  dsRead: DSRead,
  compRef: CompRef,
): boolean => {
  if (!isInteractionModeAvailable(dsRead)) {
    return false;
  }

  return someComponentAncestorMatchesCondition(
    dsRead,
    compRef,
    (ancestorRef) =>
      !!dsRead.components.transformations.get(ancestorRef)?.hidden,
  );
};

export const isShownOnlyOnVariant = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean => {
  const compTransformations = editorAPI.components.transformations.get(compRef);
  return (
    (compTransformations && !!compTransformations.hidden) ||
    isOneOfAncestorsShownOnlyOnVariant(editorAPI.dsRead, compRef)
  );
};

function getComponentInteractionIfExists(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
): InteractionDef | null {
  if (
    compRefs.length !== 1 ||
    !isInteractionModeAvailable(editorAPI.dsRead) ||
    !editorAPI.components.is.exist(compRefs[0])
  ) {
    return null;
  }
  const compRef = editorAPI.columns.isStrip(compRefs[0])
    ? editorAPI.columns.getColumnIfStripIsSingleColumn(compRefs[0])
    : compRefs[0];
  const compInteractionsDef = getComponentInteractionsDefIfExist(
    editorAPI,
    compRef,
  );
  return compInteractionsDef[0] || null;
}

const isShownOnlyOnSpecificVariant = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
  specificVariantPointer: VariantPointer,
): boolean => {
  if (!isShownOnlyOnVariant(editorAPI, compRef)) {
    return false;
  }
  const compVariantPointer = editorAPI.components.variants.getPointer(
    compRef,
    specificVariantPointer,
  );
  if (!compVariantPointer) {
    return false;
  }
  const compVariantTransformations =
    editorAPI.components.transformations.get(compVariantPointer);
  // We must check if equal to 'false' because we must know if 'hidden' property exists in order to understand it's shown on the specific variant
  return compVariantTransformations?.hidden === false;
};

const isInteractionModeAvailable = (_dsRead: DSRead): boolean => {
  return true;
};

const getEditorAvailableInteractions = (
  editorAPI: AnyFixMe,
): InteractionDef[] => [
  {
    name: editorAPI.variants.getHoverType(),
    label: 'interactions_main_interaction_type_hover',
    displayNameKey: 'interactions_element_hover_trigger_label',
  },
];

const isLastUndoEnterInteractionMode = (editorAPI: AnyFixMe): boolean => {
  return (
    editorAPI.documentServices.history.getUndoLastSnapshotLabel() ===
    ENTER_INTERACTION_HISTORY_LABEL
  );
};

const inInteractionForbiddenDragComponent = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
) => {
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state)) {
    return interactionForbiddenDragComponents.has(
      editorAPI.components.getType(compRef),
    );
  }
  return false;
};

function getInteractionContainerToAddTo(
  editorAPI: AnyFixMe,
  interactionTriggerRef: CompRef,
  suggestedContainer?: CompRef,
) {
  const triggerType = editorAPI.components.getType(interactionTriggerRef);
  switch (triggerType) {
    case constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER:
      if (suggestedContainer) {
        const possibleParents = editorAPI.components
          .getAncestors_DEPRECATED_BAD_PERFORMANCE(suggestedContainer)
          .concat(suggestedContainer);
        const columnToAddTo = possibleParents.find(
          (parent: AnyFixMe) =>
            editorAPI.components.getType(parent) ===
            constants.COMP_TYPES.COLUMN,
        );
        if (columnToAddTo) {
          return columnToAddTo;
        }
      }
      return editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
        interactionTriggerRef,
      )[0];
    default:
      return interactionTriggerRef;
  }
}

const isComponentVisibleInInteractionMode = (
  dsRead: DSRead,
  compRef: CompRef,
  currentEditorState: EditorState,
): boolean => {
  if (isInteractionModeAvailable(dsRead)) {
    const isInteractionMode = isInInteractionMode(currentEditorState);

    const isVisibleOnRegularMode =
      !dsRead.components.transformations.get(compRef)?.hidden;
    if (!isInteractionMode) {
      return isVisibleOnRegularMode;
    }
    const compVariantPointer = dsRead.components.variants.getPointer(
      compRef,
      getVariantPointers(currentEditorState),
    );
    const scopedTransformations =
      dsRead.components.transformations.get(compVariantPointer);
    if (!scopedTransformations) {
      return isVisibleOnRegularMode;
    }
    return !scopedTransformations.hidden;
  }
  return true;
};

function isInteractionsFirstTimeExperience(editorAPI: AnyFixMe): boolean {
  const userExperiencedInteractionsBefore = getSiteUserPreferences(
    INTERACTIONS_FIRST_TIME_USER_PREF_KEY,
  )(editorAPI.store.getState());
  return !userExperiencedInteractionsBefore;
}

function getComponentCompVariantPointersIfExists(
  editorAPI: EditorAPI,
  compRef: CompRef,
): CompVariantPointer[] {
  const ancestors =
    editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(compRef);
  const compsInteractions = [compRef, ...ancestors]
    .map((comp) => getComponentInteractionsVariantsDefIfExist(editorAPI, comp))
    .filter((interaction) => !!Object.values(interaction).length);
  return compsInteractions.reduce((acc, interactionDef) => {
    const variantsPointers = Object.values(interactionDef);
    return [
      ...acc,
      ...variantsPointers.map((variantPointer) =>
        editorAPI.components.variants.getPointer(compRef, variantPointer),
      ),
    ];
  }, []);
}

function getComponentCompVariantPointersCascade(
  editorAPI: EditorAPI,
  compRef: CompRef,
): CompRef[] {
  const cascadeArray = [compRef];
  if (isInInteractionMode(editorAPI.store.getState())) {
    cascadeArray.push(
      ...getComponentCompVariantPointersIfExists(editorAPI, compRef),
    );
  }
  return cascadeArray.reverse();
}

export const interactionsSelectors = {
  DEFAULT_Z_LAYER_VALUE,
  ENTER_INTERACTION_HISTORY_LABEL,
  INTERACTIONS_FIRST_TIME_USER_PREF_KEY,
  getVariantId,
  getInteractionName,
  isInInteractionMode,
  showInteractionModeControls,
  getInteractionTriggerRef,
  getTriggerVariantPointer,
  getCompVariantPointer,
  isShownOnlyOnVariant,
  isShownOnlyOnSpecificVariant,
  getFirstAncestorWithInteraction,
  isInteractionsFirstTimeExperience,
  isOneOfAncestorsShownOnlyOnVariant,
  isComponentVisibleInInteractionMode,
  getComponentInteractionsVariantsDefIfExist,
  componentHasInteraction,
  isInteractionsSupported,
  getVariantPointers,
  getComponentInteractionIfExists,
  isInteractionModeAvailable,
  getEditorAvailableInteractions,
  getInteractionContainerToAddTo,
  isLastUndoEnterInteractionMode,
  inInteractionForbiddenDragComponent,
  getComponentCompVariantPointersIfExists,
  getComponentCompVariantPointersCascade,
  isInteractionPlaying,
  doesInteractionVariantHaveOverrides,
  interactionDuration,
};
