import editorAPIMixin from './mixins/editorAPIMixin';
import styleManager from './app/styleManager';
import * as designManager from './app/designManager';
import * as premiumServicesAPI from './app/premiumServicesAPI';

const mixins = {
  editorAPIMixin,
};

export type { SystemStyleDef } from './app/styleManager';
export type { KnobMargins } from './utils/compControlsMeasurer';
// @deprecated - use types from @/layout instead
export type {
  AttachCandidateStatus,
  AttachCandidateResponse,
} from '#packages/layout';
export type {
  SnapDirections,
  ClosestLayoutDistance,
} from './utils/SnapToHandler';
export { addUtil as addUtils } from './utils';
export * as utils from './utils';

export { mixins, styleManager, designManager, premiumServicesAPI };
