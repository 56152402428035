import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import { getStates } from './multiStateBoxSelectors';

function changeState(
  editorAPI: EditorAPI,
  multiStateBoxRef: CompRef,
  index: number,
) {
  const states = getStates(multiStateBoxRef, editorAPI.dsRead);
  const newSelectedStateId = states[index].id;
  editorAPI.components.data.update(multiStateBoxRef, {
    selectedStateId: newSelectedStateId,
  });
}

export { changeState };
