// @ts-nocheck
import * as util from '#packages/util';

const url = '/_api/business-manager/prefetch';

const prefetchBizMgr = () => () =>
  util.http.fetch(url, {
    credentials: 'same-origin',
    method: 'GET',
  });

export { prefetchBizMgr };
