// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as topBar from '../topBar/topBar';
import * as externalLinks from '../externalLinks/externalLinks';
import * as leftBar from '../leftBar/leftBar';
import * as leftTree from '../leftTree/leftTree';
import * as linkPanel from '../linkPanel/linkPanel';
import * as addPanel from '../addPanel/addPanel';
import * as designPanel from '../designPanel/designPanel';
import * as componentsStore from '../componentsStore/componentsStore';
import * as gridLines from '../gridLines/gridLines';
import * as selection from '../selection/selection';
import * as pages from '../pages/pages';
import * as mobile from '../mobile/mobile';
import * as overrideHelpers from './applicationStudioOverrideHelpers';
import * as packageAndPublishActions from './packageAndPublish/packageAndPublishActions';
import * as exportImportActions from './exportImportActions';
import * as widgetActions from './widgetActions';
import devCenterSettingsActions from './devCenterSettings/devCenterSettingsActions';
import * as savePublishActions from '../savePublish/savePublishActions';
import * as stateBoxActions from '../stateBox/stateBoxActions';
import * as appLinkActions from './appLink/appLinkActions';
import * as wixAdActions from '../wixAd/wixAdActions';
import * as rightClickMenuActions from '../rightClickMenu/rightClickMenuActions';
import * as sectionedPanel from '../sectionedPanel/sectionedPanel';
import * as userPreferences from '../userPreferences/userPreferences';
import * as wixCodeIDEActions from '../wixCodeIDE/wixCodeIDEActions';
import * as featuresOverriddenActions from '../featuresOverridden/featuresOverriddenActions';
import * as secondaryLeftAreaActions from '../secondaryLeftArea/secondaryLeftAreaActions';
import * as panels from '../panels/panels';
import experiment from 'experiment';
import actionTypes from './applicationStudioActionTypes';
import * as selectors from './applicationStudioSelectors';
import * as welcomeScreenActions from '../welcomeScreen/welcomeScreenActions';
import * as permanentBorderActions from '../permanentBorder/permanentBorderActions';
import * as a11yActions from '../a11y/a11yActions';
import jsonSchemaUtils from '@wix/wix-json-schema-utils';

const topBarActions = topBar.actions;
const externalLinksActions = externalLinks.actions;
const leftBarActions = leftBar.actions;
const leftTreeActions = leftTree.actions;
const addPanelActions = addPanel.actions;
const designPanelActions = designPanel.actions;
const componentsStoreActions = componentsStore.actions;
const gridLinesActions = gridLines.actions;
const selectionActions = selection.actions;
const mobileActions = mobile.actions;
const sectionedPanelActions = sectionedPanel.actions;
const userPreferencesSelector = userPreferences.selectors;
const linkPanelActions = linkPanel.actions;
const panelsActions = panels.actions;

const appStudioConstants = constants.APP_STUDIO;
const { META_DATA_OVERRIDES } = constants.COMPONENTS_STORE_KEYS;

const START_FROM_VERSION = '1.0.0';

const calcAndSetControllerWidgetMap =
  () =>
  (dispatch, getState, { dsRead }) => {
    const map = {};
    const widgets = dsRead.appStudio.widgets.getAll();
    const variations = _.flatMap(widgets, (widget) =>
      dsRead.appStudio.widgets.variations.getWidgetVariations(widget.pointer),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/concat
    const widgetsAndVariations = _.concat(widgets, variations);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(widgetsAndVariations, (widget) => {
      const appWidget = selectors.getAppWidgetByWidgetPointer(
        dsRead,
        widget.pointer,
      );
      map[appWidget.id] = widget.pointer;
    });
    dispatch(widgetActions.updateControllerWidgetMap(map));
  };

function getCurrentWidgetRootRef(dsRead, getState) {
  const currentPage = pages.selectors.getFocusedPage(getState());
  return (
    currentPage && dsRead.appStudio.widgets.getRootWidgetByPage(currentPage)
  );
}

function duplicateTemplateWidgetsToGenerateNewCompIds(dsRead, dispatch) {
  if (dsRead.generalInfo.isFirstSave()) {
    const widgets = dsRead.appStudio.widgets.getAll();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(widgets, ({ pointer, name }, index) => {
      const isFirstWidget = index === 0;
      const options = {
        widgetName: name,
        shouldNavigate: isFirstWidget,
        shouldReportBi: false,
        shouldAddToHistory: false,
      };
      dispatch(
        widgetActions.duplicateWidget(pointer, options, () => {
          dispatch(
            widgetActions.deleteWidget(pointer, {
              shouldReportBi: false,
              shouldAddToHistory: false,
            }),
          );
        }),
      );
    });
  }
}

const openApplicationStudio =
  () =>
  (dispatch, getState, { editorAPI, dsRead }) =>
    new Promise((resolve) => {
      const state = getState();
      duplicateTemplateWidgetsToGenerateNewCompIds(dsRead, dispatch);
      const appDefId = util.appStudioUtils.getAppDefIdIfExists();
      const widgetRootCompRef = getCurrentWidgetRootRef(dsRead, getState);

      dispatch(packageAndPublishActions.setBlocksVersion(START_FROM_VERSION));

      dispatch(
        topBarActions.overrideItems(
          overrideHelpers.getOverriddenTopBarItems(state, dispatch, editorAPI),
        ),
      );
      dispatch(
        externalLinksActions.overrideExternalLinks(
          overrideHelpers.getOverrideExternalLinks(state),
        ),
      );
      dispatch(
        leftBarActions.overrideItems(
          overrideHelpers.getOverriddenLeftBarButtons(
            selectors.isWidgetContainer(dsRead, widgetRootCompRef),
          ),
        ),
      );
      dispatch(
        leftTreeActions.overrideItems(
          overrideHelpers.getOverriddenLeftTreeItems(),
        ),
      );
      dispatch(
        addPanelActions.overrideItems(
          overrideHelpers.getOverriddenAddPanelItems(state),
        ),
      );
      dispatch(
        designPanelActions.overrideSections(
          overrideHelpers.getOverriddenDesignPanelSections(state),
        ),
      );
      dispatch(mobileActions.intros.disable(state));
      dispatch(
        savePublishActions.overrideSaveAndPublish(() =>
          dispatch(packageAndPublishActions.saveAndPackage()),
        ),
      );
      dispatch(
        packageAndPublishActions.setIsFirstAppBuild(
          !util.appStudioUtils.isAppPublishedOnLoad(),
        ),
      );
      dispatch(
        savePublishActions.overrideDomainPanel(
          overrideHelpers.getOverriddenDomainPenelData(),
        ),
      );
      dispatch(
        savePublishActions.overrideSiteWasSavedPanel({ disabled: true }),
      );
      dispatch(
        savePublishActions.overrideSaveReminderPanel({ disabled: true }),
      );
      dispatch(
        savePublishActions.overrideSiteDefaultName(
          overrideHelpers.getOverriddenSiteDefaultName(),
        ),
      );
      if (experiment.isOpen('se_epBuilderRepeaterAsState')) {
        dispatch(stateBoxActions.setIsRepeaterStateAllowed(true));
      }

      dispatch(a11yActions.toggleAdvancedA11yEnabled());
      dispatch(
        userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.ACCESSIBILITY.ADVANCED_SETTINGS,
          true,
        ),
      );

      const appName = util.appStudioUtils.getApplicationNameOnLoad();
      if (appName) {
        //existing app
        dispatch(savePublishActions.setIsForceSaveInBackground(true));
        dispatch(setAppName(appName));
      }
      dispatch(
        packageAndPublishActions.setAppVersion(
          util.appStudioUtils.getAppVersionOnLoad(),
        ),
      );
      dispatch(wixAdActions.overrideWixAdsBanner({ alwaysHidden: true }));
      dispatch(
        rightClickMenuActions.overrideRightClickMenu({
          addToSavedComponents: { isHidden: true },
          copyDesign: { isHidden: true },
        }),
      );
      dispatch(
        linkPanelActions.setOverridden(
          overrideHelpers.getOverriddenLinkPanelSections(),
        ),
      );
      dispatch(featuresOverriddenActions.disableConnectToData());
      dispatch(
        secondaryLeftAreaActions.setContentModule(
          'appStudio.panels.widgetDesignPanel',
        ),
      );

      dispatch(
        welcomeScreenActions.setOverrideItem({
          panels: [
            {
              shouldShow: shouldShowAppBuilderWelcome,
              show: showAppBuilderWelcome,
            },
          ],
        }),
      );

      dispatch(calcAndSetControllerWidgetMap());
      editorAPI.preview.registerToBeforeGoToPreview(() => {
        dispatch(setWidgetApiTestProperties([]));
      });

      if (appDefId) {
        dispatch(appLinkActions.fetchAppLink(appDefId));
        dispatch(setAppDefID(appDefId));
        dispatch(setNamespace(util.appStudioUtils.getNamespace()));
      }

      editorAPI.wixCode.registerToCodeEditorLoaded(() => {
        dispatch(updateWixCodeDefinition());
      });

      setPagesTransition(editorAPI.dsActions);

      const devModeOpenedPromise = openDevModeIfNeeded(editorAPI);
      const widgetPublicAPIOpenedPromise =
        openWidgetPublicAPIIfNeeded(editorAPI);

      Promise.all([devModeOpenedPromise, widgetPublicAPIOpenedPromise]).then(
        resolve,
      );
    });

const setPagesTransition = (dsActions) => {
  dsActions.pages.transitions.set('none');
};

const openWidgetPublicApiByUserPreferences = () => (dispatch, getState) => {
  const widgetPublicAPISectionEnabled =
    userPreferencesSelector.getSiteUserPreferences(
      constants.USER_PREFS.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API_ENABLED,
    )(getState());

  if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    _.isUndefined(widgetPublicAPISectionEnabled) ||
    widgetPublicAPISectionEnabled
  ) {
    dispatch(
      sectionedPanelActions.openSection(
        constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
      ),
    );
  }
};

const closeWidgetPublicApiByUserPreferences = () => (dispatch, getState) => {
  const widgetPublicAPISectionEnabled =
    userPreferencesSelector.getSiteUserPreferences(
      constants.USER_PREFS.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API_ENABLED,
    )(getState());

  if (widgetPublicAPISectionEnabled === false) {
    dispatch(
      sectionedPanelActions.closeSection(
        constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
      ),
    );
  }
};

const openPropertiesPanelByUserPreferences = () => (dispatch, getState) => {
  const sectionEnabled = userPreferencesSelector.getSiteUserPreferences(
    constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_TOOLBAR_ENABLED,
  )(getState());

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(sectionEnabled) || sectionEnabled) {
    dispatch(
      sectionedPanelActions.openSection(
        constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
      ),
    );
  }
};

const openToolBarPanelByUserPreferences = (getState, editorAPI) => {
  const toolBarEnabled = userPreferencesSelector.getSiteUserPreferences(
    constants.USER_PREFS.VIEW_TOOLS.TOOLBAR_ENABLED,
  )(getState());

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(toolBarEnabled) || toolBarEnabled) {
    editorAPI.updateEditorViewTools('toolbarEnabled', true);
  }
};

const openWidgetPublicAPIIfNeeded = (editorAPI) =>
  new Promise((resolve) => {
    const { dispatch, getState } = editorAPI.store;
    editorAPI.registerInitUserPrefsCallback(() => {
      openWidgetPublicApiByUserPreferences()(dispatch, getState);

      resolve();
    });
  });

const openDevModeIfNeeded = (editorAPI) =>
  new Promise((resolve) => {
    const toggleDevMode = () => {
      if (!editorAPI.developerMode.isEnabled()) {
        editorAPI.developerMode.toggle({
          notifyApps: false,
        });
      }
      editorAPI.developerMode.ui.idePane.minimize();
    };

    const USER_PREFERENCES_LOADED_TIMEOUT = 5000;
    const timer = window.setTimeout(() => {
      toggleDevMode();
      resolve();
    }, USER_PREFERENCES_LOADED_TIMEOUT);

    editorAPI.registerInitUserPrefsCallback(() => {
      toggleDevMode();

      window.clearTimeout(timer);

      resolve();
    });
  });

const removeExports = (definition: string) => {
  return definition.replace(/export interface/g, 'interface');
};

const convertPropsSchemaToTypeScript = async (editorAPI, properties) => {
  const customDefinitions = Object.assign(
    {},
    ...editorAPI.dsRead.appStudio.definitions.getAllSerialized(),
  );
  const resolver = jsonSchemaUtils.createResolver(
    jsonSchemaUtils.baseDefinitions,
    customDefinitions,
  );
  const { propsSchemaToTypeScript } = await import(
    '@wix/blocks-props-schema-to-typescript'
  );
  const propertiesSchema =
    properties?.length > 0
      ? _.mapValues(Object.assign({}, ...properties), (prop) =>
          resolver.resolve(prop),
        )
      : {};
  const propsDts = await propsSchemaToTypeScript(
    'WidgetProps',
    propertiesSchema,
    jsonSchemaUtils.baseDefinitions,
    customDefinitions,
  );
  return removeExports(propsDts);
};

const updateWixCodeTypeScript = async (editorAPI, properties) => {
  const propertiesAsDts = await convertPropsSchemaToTypeScript(
    editorAPI,
    properties,
  );
  editorAPI.wixCode.definitions.remove({ fileName: 'widget-props.dts' });
  editorAPI.wixCode.definitions.add({
    fileName: 'widget-props.dts',
    content: propertiesAsDts,
  });
  editorAPI.wixCode.definitions.remove({ fileName: 'app-studio.dts' });
  editorAPI.wixCode.definitions.add({
    fileName: 'app-studio.dts',
    content: appStudioConstants.DEFINITIONS.$widget,
  });
};

const updateWixCodePropertiesDefinition =
  (properties) =>
  async (dispatch, getState, { editorAPI }) => {
    if (editorAPI.wixCode.isLoaded()) {
      updateWixCodeTypeScript(editorAPI, properties);
    } else {
      editorAPI.wixCode.registerToWixCodeLoaded(() =>
        updateWixCodeTypeScript(editorAPI, properties),
      );
    }
  };

const updateWixCodeDefinition =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const widgetPointer = getDisplayedWidgetPointer(editorAPI);
    if (widgetPointer) {
      dispatch(
        updateWixCodePropertiesDefinition(
          dsRead.appStudio.widgets.propertiesSchemas.get(widgetPointer),
        ),
      );
    }
  };

const setAppDefID = (appDefId) => ({
  type: actionTypes.SET_APP_DEF_ID,
  appDefId,
});

const setWidgetApiTestProperties = (widgetApiTestProperties) => ({
  type: actionTypes.SET_WIDGET_API_TEST_PROPERTIES,
  widgetApiTestProperties,
});

const setAppName = (appName) => ({
  type: actionTypes.SET_APP_NAME,
  appName,
});

const setNamespace = (namespace) => ({
  type: actionTypes.SET_APP_NAMESPACE,
  namespace,
});

const getDisplayedWidgetPointer = (editorAPI) => {
  const rootCompId = editorAPI.dsRead.pages.getFocusedPageId();
  return editorAPI.dsRead.appStudio.widgets.getByRootCompId(rootCompId);
};

const updateAppListingInfo = (appDefId, data) => {
  util.http.fetchJson(
    `${util.serviceTopology.editorRootUrl}_api/app-service/v2/apps/${appDefId}/listing-info?languageCode=en`,
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(data),
    },
  );
};

const renameAppInDevCenter = (appName, appDefId) => () => {
  const marketListingObject = {
    marketListing: {
      trackingAndAnalytics: null,
      installationRequirement: null,
      basicInfo: {
        appId: appDefId,
        name: appName,
      },

      languageCode: 'en',
      assetsMap: {},
    },
    languageCode: 'en',
    appId: appDefId,
  };

  updateAppListingInfo(appDefId, marketListingObject);
};

const showAppBuilderWelcome = (editorAPI) => {
  editorAPI.store
    .dispatch(
      userPreferences.actions.setGlobalUserPreferences(
        'didPassAppBuilderVideoCheck',
        true,
      ),
    )
    .catch(_.noop);

  editorAPI.panelManager.openPanel('appStudio.panels.appBuilderWelcomePanel');
};

const shouldShowAppBuilderWelcome = (editorAPI) => {
  const didPassNewUserVideoCheck =
    !userPreferences.selectors.getGlobalUserPreferences(
      'didPassAppBuilderVideoCheck',
    )(editorAPI.store.getState());

  return didPassNewUserVideoCheck;
};

const getWidgetRootBehaviors = (dsRead) => ({
  movable: false,
  verticallyMovable: false,
  horizontallyMovable: false,
  resizableWithAnchors: false,
  selectedBeforeDescendants: false,
  allowLassoOnContainer: true,
  focusable: true,
  duplicatable: false,
  pinnable: false,
  rotatable: false,
  groupable: false,
  alignable: false,
  draggable: false,
  multiselectable: false,
  proportionallyResizable: false,
  removable: false,
  disabledKnobs: [dsRead.components.layout.RESIZE_SIDES.TOP],
  propertiesEditable: false,
});

const getCompDescendants = (dsRead, comp) => {
  const children =
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(comp);

  return _(children)
    .flatMap((c) => getCompDescendants(dsRead, c))
    .union(children)
    .value();
};

const applyWidgetRootBehaviors =
  (widgetRoot) =>
  (dispatch, getState, { dsRead }) => {
    const currentPage = pages.selectors.getFocusedPage(getState());
    const isVariationPage =
      !!currentPage &&
      dsRead.appStudio.widgets.variations.isVariationPage(currentPage.id);

    if (widgetRoot) {
      dispatch(
        componentsStoreActions.updateComponentStore(
          widgetRoot,
          META_DATA_OVERRIDES,
          getWidgetRootBehaviors(dsRead),
        ),
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(getCompDescendants(dsRead, widgetRoot), (comp) => {
        dispatch(
          componentsStoreActions.updateComponentStore(
            comp,
            META_DATA_OVERRIDES,
            {
              pinnable: false,
              stretchable: false,
              groupable: false,
              duplicatable: !isVariationPage,
              canReparent: !isVariationPage,
            },
          ),
        );
      });

      const directChild = _.head(
        dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
          widgetRoot,
        ),
      );
      dispatch(
        componentsStoreActions.updateComponentStore(
          directChild,
          META_DATA_OVERRIDES,
          {
            removable: false,
          },
        ),
      );
    }
  };

const applyWidgetPageBehaviors = () => (dispatch) => {
  dispatch(
    gridLinesActions.setGridLinesOverrides(constants.GRID_LINES_TYPES.PAGE, []),
  );
};

const revertWidgetPageBehaviors = () => (dispatch) => {
  dispatch(
    gridLinesActions.setGridLinesOverrides(
      constants.GRID_LINES_TYPES.PAGE,
      null,
    ),
  );
};

const applyApplicationStudioBehaviors =
  (hasPageChanged) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const currentPage = pages.selectors.getFocusedPage(getState());
    const isWidgetPage =
      !!currentPage && dsRead.pages.isWidgetPage(currentPage.id);

    if (isWidgetPage) {
      const widgetRoot =
        dsRead.appStudio.widgets.getRootWidgetByPage(currentPage);
      dispatch(applyWidgetRootBehaviors(widgetRoot));
      dispatch(applyWidgetPageBehaviors());
      dispatch(permanentBorderActions.setComponent(widgetRoot));
      if (hasPageChanged) {
        dispatch(panelsActions.closeOpenedPanels());
        const isVariationPage =
          !!currentPage &&
          dsRead.appStudio.widgets.variations.isVariationPage(currentPage.id);
        const shouldShowIDE = !isVariationPage;
        if (isVariationPage) {
          dispatch(
            leftBarActions.overrideItems(
              overrideHelpers.getVariationOverriddenLeftBarButtons(),
            ),
          );
          dispatch(
            sectionedPanelActions.closeSection(
              constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
              false,
            ),
          );
          dispatch(
            sectionedPanelActions.closeSection(
              constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
              false,
            ),
          );
          dispatch(featuresOverriddenActions.disablePaste());
          dispatch(
            rightClickMenuActions.overrideRightClickMenu({
              viewProperties: { isHidden: true },
            }),
          );
        } else {
          dispatch(
            leftBarActions.overrideItems(
              overrideHelpers.getOverriddenLeftBarButtons(
                selectors.isWidgetContainer(dsRead, widgetRoot),
              ),
            ),
          );
          openWidgetPublicApiByUserPreferences()(dispatch, getState);
          openPropertiesPanelByUserPreferences()(dispatch, getState);
          dispatch(featuresOverriddenActions.enablePaste());
          dispatch(
            rightClickMenuActions.overrideRightClickMenu({
              viewProperties: { isHidden: false },
            }),
          );
        }

        dispatch(
          wixCodeIDEActions.overrideWixCodeIDE({ shouldShow: shouldShowIDE }),
        );
        editorAPI.selection.selectComponentByCompRef(widgetRoot);
        dispatch(updateWixCodeDefinition());
      }
    } else {
      dispatch(revertWidgetPageBehaviors());
    }
  };

const setProperties =
  (pointer, properties) =>
  async (dispatch, getState, { dsActions, editorAPI }) => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_CUSTOM_TYPE,
    );
    dsActions.appStudio.widgets.propertiesSchemas.set(pointer, properties);
    editorAPI.history.add('set widget property', {
      postAction: () => dispatch(updateWixCodeDefinition()),
    });
    await dispatch(updateWixCodePropertiesDefinition(properties));

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_CUSTOM_TYPE,
    );
  };

const setDraftAppName =
  (appName) =>
  (dispatch, getState, { dsActions }) => {
    dsActions.appStudio.appName.set(appName);
  };

const setDefinition =
  (pointer, definition, callback) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_CUSTOM_TYPE,
    );
    dsActions.appStudio.definitions.set(pointer, definition, callback);
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_CUSTOM_TYPE,
    );
    editorAPI.history.add('set app definition');
  };

const removeDefinition =
  (pointer) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    dsActions.appStudio.definitions.remove(pointer);
    editorAPI.history.add('removed app definition');
  };

const setEvents =
  (pointer, events) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    dsActions.appStudio.widgets.events.set(pointer, events);
    editorAPI.history.add('set widget event');
  };

const setFunctions =
  (pointer, functions) =>
  (dispatch, getState, { dsActions }) => {
    // Don't add to history, as we want the source of truth of functions to be the code
    dsActions.appStudio.widgets.functions.set(pointer, functions);
  };

const notifyPropertiesUpdateToWorker =
  (widgetPointer, oldProps, newProps) =>
  (dispatch, getState, { dsActions }) => {
    dsActions.appStudio.widgets.notifyPropertiesUpdateToWorker(
      widgetPointer,
      oldProps,
      newProps,
    );
  };

const addFunctionTemplate =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const pagesFolder = editorAPI.wixCode.fileSystem.getRoots().pages;
    let currentFile;
    editorAPI.wixCode.fileSystem
      .getChildren(pagesFolder)
      .then((_pages) => {
        const currentPageId = dsRead.pages.getCurrentPageId();
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        currentFile = _.find(_pages, ['name', `${currentPageId}.js`]);
        return editorAPI.wixCode.fileSystem.readFile(currentFile);
      })
      .then((fileContent) => {
        const functionTemplate = appStudioConstants.CODE.EXPORTED_FUNCTION;
        fileContent += functionTemplate;
        const fileDescriptor =
          editorAPI.wixCode.fileSystem.getVirtualDescriptor(
            currentFile.location,
            false,
          );
        return editorAPI.wixCode.fileSystem.writeFile(
          fileDescriptor,
          fileContent,
        );
      })
      .then(() => {
        editorAPI.developerMode.ui.idePane.unMinimize();
        // TODO: add 'select line' to newly created function when wix code will expose this API
      })
      .catch((e) => {
        ErrorReporter.captureException(e, {
          tags: {
            pagesFolder,
            description: 'failed getting adding function template',
          },
        });
      });
  };

const setWidgetDesignPanelSplitterHeight = (newHeight) => ({
  type: actionTypes.SET_WIDGET_DESIGN_PANEL_SPLITTER_HEIGHT,
  newHeight,
});

const openFullStagePanel =
  (panelName, panelProps) =>
  (dispatch, getState, { editorAPI }) => {
    dispatch(panelsActions.updateOrOpenPanel(panelName, panelProps, false));
    dispatch(
      leftBarActions.overrideItems(
        overrideHelpers.getVariationOverriddenLeftBarButtons(),
      ),
    );
    dispatch(
      sectionedPanelActions.closeSection(
        constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
        false,
      ),
    );
    dispatch(
      sectionedPanelActions.closeSection(
        constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
        false,
      ),
    );
    editorAPI.updateEditorViewTools('toolbarEnabled', false);
    dispatch(wixCodeIDEActions.overrideWixCodeIDE({ shouldShow: false }));
    dispatch(selectionActions.clearSelectedComponents());
    dispatch(
      topBarActions.overrideItems(
        overrideHelpers.getOverriddenTopBarItems(
          getState(),
          dispatch,
          editorAPI,
          true,
        ),
      ),
    );
  };

const closeFullStagePanel =
  (panelName) =>
  (dispatch, getState, { editorAPI, dsRead }) => {
    const widgetRootCompRef = getCurrentWidgetRootRef(dsRead, getState);
    dispatch(panelsActions.closePanelByName(panelName));
    dispatch(
      leftBarActions.overrideItems(
        overrideHelpers.getOverriddenLeftBarButtons(
          selectors.isWidgetContainer(dsRead, widgetRootCompRef),
        ),
      ),
    );
    openWidgetPublicApiByUserPreferences()(dispatch, getState);
    openPropertiesPanelByUserPreferences()(dispatch, getState);
    openToolBarPanelByUserPreferences(getState, editorAPI);
    dispatch(wixCodeIDEActions.overrideWixCodeIDE({ shouldShow: true }));
    dispatch(
      topBarActions.overrideItems(
        overrideHelpers.getOverriddenTopBarItems(
          getState(),
          dispatch,
          editorAPI,
          false,
        ),
      ),
    );
  };

const setStrictColorPickerMode = (isStrictColorPicker: boolean) => ({
  type: actionTypes.SET_STRICT_COLOR_PICKER_MODE,
  isStrictColorPicker,
});

const shouldShowWixCodeIDE =
  ({
    shouldShow,
    biOrigin,
    openWithMaxHeight = true,
    openInMaximizeMode,
  }: {
    shouldShow: boolean;
    biOrigin?: string;
    openWithMaxHeight?: boolean;
    openInMaximizeMode?: boolean;
  }) =>
  (dispatch) => {
    dispatch(
      wixCodeIDEActions.overrideWixCodeIDE({
        shouldShow,
        biOrigin,
        openWithMaxHeight,
        openInMaximizeMode,
      }),
    );
  };

const applicationStudioActions = {
  applyApplicationStudioBehaviors,
  notifyPropertiesUpdateToWorker,
  openApplicationStudio,
  openFullStagePanel,
  closeFullStagePanel,
  setProperties,
  setDefinition,
  removeDefinition,
  setEvents,
  setFunctions,
  addFunctionTemplate,
  closeWidgetPublicApiByUserPreferences,
  openPropertiesPanelByUserPreferences,
  setAppDefID,
  setNamespace,
  setAppName,
  setWidgetApiTestProperties,
  setWidgetDesignPanelSplitterHeight,
  renameAppInDevCenter,
  setDraftAppName,
  setStrictColorPickerMode,
  updateWixCodeDefinition,
  shouldShowWixCodeIDE,
};

// eslint-disable-next-line you-dont-need-lodash-underscore/assign
export default _.assign(
  applicationStudioActions,
  packageAndPublishActions,
  appLinkActions,
  devCenterSettingsActions,
  widgetActions,
  exportImportActions,
);
