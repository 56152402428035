import _ from 'lodash';
import { overridable } from '@wix/santa-editor-utils';
import * as platformSelectors from './platformSelectors';
import actionTypes, {
  type SilentInstallAction,
  type SilentInstallUsedOnSiteAction,
} from './platformActionTypes';
import * as componentsSelectors from '../components/componentsSelectors';
import * as addElementsActions from './addElements/addElementsActions';
import * as installActions from './install/installActions';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import type { Dispatch, DispatchMapperArgs } from 'types/redux';
import type {
  Component,
  GetAppsInfoResponse,
} from '@wix/ambassador-app-service-webapp/types';
import { WixBiProfileWebapp } from '@wix/ambassador-wix-bi-profile-webapp/http';

import type {
  CompRef,
  DSAction,
  DSRead,
  AppData,
  PlatformAppDescription,
  VariantOverrides,
} from 'types/documentServices';

import { devCenterFacade } from '#packages/serverFacade';
import experiment from 'experiment';
import type { AppData as AppDataBlocks } from '#packages/privateAppsPanel';
import { isResponsiveBlocksVersion } from '#packages/util';

interface DevCenterApp
  extends Omit<
    AppDataBlocks,
    | 'displayVersion'
    | 'installStatus'
    | 'instance'
    | 'instanceId'
    | 'components'
  > {
  components: Component[];
}

type PlatformAppDescriptionWithVersion = PlatformAppDescription & {
  version?: string;
};

const fetchLatestApps = devCenterFacade.appV2Query;
const profileDataServiceBaseUrl = '/_api/wix-bi-profile-webapp/';

const { getFreshAppsData, getLatestVersions } = platformSelectors;

const setAvailableApps = (apps: DevCenterApp[]) => ({
  type: actionTypes.SET_AVAILABLE_APPS,
  apps,
});

const setSilentInstallRunning = (isRunning: boolean): SilentInstallAction => ({
  type: actionTypes.SET_SILENT_INSTALL_FLAG,
  isRunning,
});

const setSilentInstallHappenedOnSite = (
  usedSilentInstallBefore: boolean,
): SilentInstallUsedOnSiteAction => ({
  type: actionTypes.SET_USED_SILENT_INSTALL_ON_SITE,
  usedOnSite: usedSilentInstallBefore,
});

const setIsLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_LOADING,
  isLoading,
});

const setIsAppInstallationInProgress = (installationInProgress: boolean) => ({
  type: actionTypes.SET_IS_APP_INSTALLATION_IN_PROGRESS,
  installationInProgress,
});

const setFreshAppsData = (appsData: GetAppsInfoResponse['apps']) => ({
  type: actionTypes.SET_FRESH_APPS_DATA,
  appsData,
});

const setLatestVersions = (latestVersions: { [appDefId: string]: string }) => ({
  type: actionTypes.SET_LATEST_VERSIONS,
  latestVersions,
});

const setIsImportingApp = (isImportingApp: boolean) => ({
  type: actionTypes.SET_IS_IMPORTING_APP,
  isImportingApp,
});

const createVersionsMapFromAppsInfo = (apps: GetAppsInfoResponse['apps']) => {
  const pairs =
    apps?.map((app) => {
      return [app.appId, app.latestVersion];
    }) ?? [];
  return Object.fromEntries(pairs);
};

const fetchAvailableAppsFromDevCenter = (userId: string, accountId: string) => {
  if (experiment.isOpen('se_getDeveloperAppsNewAPI')) {
    return devCenterFacade.getDeveloperApps(userId, accountId);
  }

  return devCenterFacade.getDeveloperAppsOld(true);
};

const fetchMultipleAppsFromDevCenter = (
  appDefinitionIds: string[],
): Promise<GetAppsInfoResponse> => devCenterFacade.getAppInfo(appDefinitionIds);

const isEmptyBlocksApp = (app: DevCenterApp) => {
  return (
    Boolean(!app.blocksVersion) &&
    app.components?.length === 1 &&
    app.components?.[0]?.compType === 'STUDIO'
  );
};

const filterAlphaApps = (apps: DevCenterApp[]) =>
  experiment.isOpen('se_appBuilderSunset_P2')
    ? apps.filter(
        (app) =>
          isResponsiveBlocksVersion(app.blocksVersion) || isEmptyBlocksApp(app),
      )
    : apps;

const fetchAvailableApps =
  () =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsRead }: DispatchMapperArgs,
  ) => {
    const userInfo = dsRead?.generalInfo?.getUserInfo();
    if (!userInfo) {
      return;
    }
    dispatch(setIsLoadingAppsError(false));
    dispatch(setIsLoading(true));
    fetchAvailableAppsFromDevCenter(userInfo.userId, userInfo.accountId)
      .then((apps: DevCenterApp[]) => {
        dispatch(setAvailableApps(filterAlphaApps(apps)));
        dispatch(setIsLoading(false));
        const appsWithNullLatestVersion = apps.filter(
          (app) => !app.latestVersion,
        );
        if (appsWithNullLatestVersion.length > 0) {
          ErrorReporter.captureMessage('Blocks apps with null version', {
            extra: {
              appsIds: appsWithNullLatestVersion.map(
                ({ appDefinitionId }: DevCenterApp) => appDefinitionId,
              ),
            },
          });
        }
      })
      .catch((e: Error) => {
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingAppsError(true));
        dispatch(setAvailableApps([]));
        ErrorReporter.captureException(e, { tags: { appImportPanel: true } });
      });
  };

const getFreshAppDataAndLatestVersions = async (
  installedAppsIds: string[],
  dispatch: Dispatch,
  getState: () => EditorState,
) => {
  const appMarketData =
    await devCenterFacade.fetchAppMarketData(installedAppsIds);

  const freshAppData = appMarketData.map((appData) => {
    return {
      appId: appData.appId,
      isPublished: appData.versionStatus === 'PUBLISHED',
    };
  });

  if (!_.isEqual(freshAppData, getFreshAppsData(getState()))) {
    dispatch(setFreshAppsData(freshAppData));
  }

  const latestVersions =
    await devCenterFacade.fetchListLatestProductionVersions(installedAppsIds);

  const appIdToVersionMap = Object.fromEntries(
    latestVersions.map((appData) => [appData.id, appData.fullVersion]),
  );
  if (!_.isEqual(appIdToVersionMap, getLatestVersions(getState()))) {
    dispatch(setLatestVersions(appIdToVersionMap));
  }
};

const getFreshAppDataAndLatestVersionsOldAPIs = async (
  installedAppsIds: string[],
  dispatch: Dispatch,
  getState: () => EditorState,
) => {
  const { apps } = await fetchMultipleAppsFromDevCenter(installedAppsIds);

  if (!_.isEqual(apps, getFreshAppsData(getState()))) {
    dispatch(setFreshAppsData(apps));
  }

  const latestVersions = createVersionsMapFromAppsInfo(apps);
  if (!_.isEqual(latestVersions, getLatestVersions(getState()))) {
    dispatch(setLatestVersions(latestVersions));
  }
};

const fetchFreshAppsData =
  () =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsRead }: DispatchMapperArgs,
  ) =>
    dsRead?.platform?.getInstalledEditorApps
      ? new Promise<void>((resolve, reject) => {
          const installedAppsIds: string[] = dsRead.platform
            .getInstalledEditorApps()
            .filter(({ version }: PlatformAppDescriptionWithVersion) =>
              Boolean(version),
            )
            .map(({ appDefinitionId }) => appDefinitionId);

          if (_.isEmpty(installedAppsIds)) {
            resolve();
            return;
          }

          Promise.all([
            experiment.isOpen('se_getFreshAppDataNewDecCenterAPI')
              ? getFreshAppDataAndLatestVersions(
                  installedAppsIds,
                  dispatch,
                  getState,
                )
              : getFreshAppDataAndLatestVersionsOldAPIs(
                  installedAppsIds,
                  dispatch,
                  getState,
                ),
          ])
            .then(() => {
              resolve();
            })
            .catch((e) => {
              ErrorReporter.captureException(e);
              reject(e);
            });
        })
      : null;

const setIsLoadingAppsError = (isLoadingAppsError: boolean) => ({
  type: actionTypes.SET_IS_LOADING_APPS_ERROR,
  isLoadingAppsError,
});

const togglePackagesViewRoot = () => ({
  type: actionTypes.TOGGLE_PACKAGES_VIEW_ROOT,
});

const notifyAddWidget =
  (applicationId: number, widgetType: string, appExtraData = {}) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsActions }: DispatchMapperArgs,
  ) =>
    dsActions.platform.notifyApplication(applicationId, {
      eventType: 'addWidget',
      eventPayload: _.merge({ widgetType }, appExtraData),
    });

const notifyApplication =
  (applicationId: number, eventType: string, eventPayload = {}) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsActions }: DispatchMapperArgs,
  ) =>
    dsActions.platform.notifyApplication(applicationId, {
      eventType,
      eventPayload,
    });

const setAppsToMeasureLoadingTime = (apps: DevCenterApp[]) => ({
  type: actionTypes.SET_APPS_TO_MEASURE_LOADING_TIME,
  apps,
});

const setAppManifestHasBeenLoaded = (app: DevCenterApp) => ({
  type: actionTypes.SET_APP_MANIFEST_HAS_BEEN_LOADED,
  app,
});

const setAllAppManifestsLoadedReported = () => ({
  type: actionTypes.SET_ALL_APP_MANIFESTS_LOADED_REPORTED,
});

const setEditorScriptsLoadedReported = () => ({
  type: actionTypes.SET_EDITOR_SCRIPTS_LOADED_REPORTED,
});

const setStartLoadingPlatformReported = () => ({
  type: actionTypes.SET_START_LOADING_PLATFORM_REPORTED,
});

const setStartLoadingEditorScriptsReported = () => ({
  type: actionTypes.SET_START_LOADING_EDITOR_SCRIPTS_REPORTED,
});

const actualChangeVariation = (
  dsActions: DSAction,
  editorAPI: EditorAPI,
  componentRef: CompRef,
  variationId: string,
  customOverrides: VariantOverrides,
  keepOverrides: boolean,
) => {
  const onSuccess = (newCompRef: CompRef) => {
    if (!_.eq(newCompRef, componentRef)) {
      editorAPI.selection.selectComponentByCompRef(newCompRef);
    }
    editorAPI.history.add('change variation');
  };
  const onError = _.noop;
  dsActions.appStudioWidgets.changeVariation(
    componentRef,
    variationId,
    onSuccess,
    onError,
    { customOverrides, keepOverrides },
  );
};

const isClosable = (dsRead: DSRead, componentRef: CompRef) =>
  dsRead.platform.controllers.getStageData(componentRef)?.behavior?.closable;

const changeVariation =
  (
    componentRef: CompRef,
    variationId: string,
    customOverrides: VariantOverrides,
    keepOverrides: boolean,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsRead, dsActions, editorAPI }: DispatchMapperArgs,
  ) => {
    const isRefHost = componentsSelectors.isRefComponent(componentRef, dsRead);
    if (isRefHost || isClosable(dsRead, componentRef) === false) {
      actualChangeVariation(
        dsActions,
        editorAPI,
        componentRef,
        variationId,
        customOverrides,
        keepOverrides,
      );
      return;
    }

    editorAPI.panelManager.openPanel(
      'panels.focusPanels.confirmResetAndClose',
      {
        onConfirm: () => {
          editorAPI.selection.deselectComponents(componentRef);
          actualChangeVariation(
            dsActions,
            editorAPI,
            componentRef,
            variationId,
            customOverrides,
            keepOverrides,
          );
        },
      },
    );
  };

interface PresetData {
  stylePresetId: string;
  layoutPresetId: string;
}

const changePresetByViewMode =
  (
    compRef: CompRef,
    { layoutPresetId, stylePresetId }: PresetData,
    viewMode?: string,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    if (viewMode === editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE) {
      const mobileVariant = editorAPI.mobile.getMobileVariant();
      const compVariantRef = editorAPI.components.variants.getPointer(compRef, [
        mobileVariant,
      ]);
      editorAPI.dsActions.appStudioWidgets.presets.change(
        compVariantRef,
        stylePresetId,
        layoutPresetId,
      );
    } else {
      editorAPI.dsActions.appStudioWidgets.presets.change(
        compRef,
        stylePresetId,
        layoutPresetId,
      );
    }
    editorAPI.history.add('component - preset changed');
  };

const changePresetInCurrentContext = overridable(
  (compRef: CompRef, { layoutPresetId, stylePresetId }: PresetData) =>
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      { editorAPI }: DispatchMapperArgs,
    ) =>
      dispatch(
        changePresetByViewMode(
          compRef,
          { layoutPresetId, stylePresetId },
          editorAPI.dsRead.viewMode.get(),
        ),
      ),
);

const removeUnusedOverrides =
  (refCompPtr: CompRef) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsActions }: DispatchMapperArgs,
  ) =>
    dsActions.components.refComponents.removeUnusedOverrides(refCompPtr);

const loadSilentInstallBiProfile =
  () =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const profileDataService = WixBiProfileWebapp(
      profileDataServiceBaseUrl,
    ).ProfileDataService()();

    profileDataService
      .get({
        fields: ['silent_install_per_site'],
        metasiteGuid: editorAPI.dsRead.generalInfo.getMetaSiteId(),
        userGuid: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
      })
      .then(({ fields }) => {
        dispatch(
          setSilentInstallHappenedOnSite(
            Boolean(fields?.silent_install_per_site),
          ),
        );
      })
      .catch((e) => {
        ErrorReporter.captureException(e, {
          tags: { failedToLoadSiteBiProfile: true },
        });
      });
  };

interface UninstallAppOptions {
  origin?: string;
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
}

const uninstallApp =
  (
    appData: AppData,
    shouldShowModal = true,
    options: UninstallAppOptions = {},
  ) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    return await editorAPI.platform.applications.uninstall(
      appData,
      shouldShowModal,
      options,
    );
  };

export default {
  uninstallApp,
  changeVariation,
  changePresetInCurrentContext,
  changePresetByViewMode,
  notifyAddWidget,
  notifyApplication,
  fetchAvailableApps,
  fetchAvailableAppsFromDevCenter,
  fetchMultipleAppsFromDevCenter,
  fetchFreshAppsData,
  removeUnusedOverrides,
  setAvailableApps,
  setIsImportingApp,
  setIsLoading,
  setIsAppInstallationInProgress,
  setIsLoadingAppsError,
  togglePackagesViewRoot,
  setAppsToMeasureLoadingTime,
  setAppManifestHasBeenLoaded,
  setAllAppManifestsLoadedReported,
  setEditorScriptsLoadedReported,
  setStartLoadingPlatformReported,
  setStartLoadingEditorScriptsReported,
  setSilentInstallRunning,
  loadSilentInstallBiProfile,
  ...addElementsActions,
  ...installActions,
  fetchLatestApps,
};
