// @ts-nocheck
import constants from '#packages/constants';

const overriddenConstants = constants.OVERRIDDEN;

const getOverriddenButtons = (state) => state.topBar.overridden.buttons;
const getOverriddenMenus = (state) => state.topBar.overridden.menus;
const getOverriddenMenuItems = (state) => state.topBar.overridden.menuItems;
const getOverriddenQuickNavigation = (state) =>
  state.topBar.overridden[overriddenConstants.TOP_BAR.QUICK_NAVIGATION.KEY];
const getBackToAdiTooltipVisibility = (state) =>
  state.topBar.adiTooltipVisibility;
const getOverrideGetters = (state) => state.topBar.overridden.overrideGetters;
const getBannerTitle = (state) => state.topBar.bannerTitle;
const getBannerVisibility = (state) => !!getBannerTitle(state);
const getDropPanelActiveItem = (state) => state.topBar.dropPanelActiveItem;

export {
  getOverriddenButtons,
  getOverriddenMenus,
  getOverriddenMenuItems,
  getOverriddenQuickNavigation,
  getBackToAdiTooltipVisibility,
  getOverrideGetters,
  getBannerTitle,
  getBannerVisibility,
  getDropPanelActiveItem,
};
