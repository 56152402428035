import experiment from 'experiment';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompStructure } from 'types/documentServices';

function removeConnectionPropertyRecursively(
  compStructure: CompStructure,
): void {
  delete compStructure.connections;
  if (compStructure.components?.length) {
    compStructure.components?.forEach((component: AnyFixMe) => {
      removeConnectionPropertyRecursively(component);
    });
  }
}

/**
 * Removes connection property when adding component on mobile (to be mobile only)
 * @param editorAPI
 * @param compStructure - serialized structure to be added
 */
export default function mobileEnhancerPlugin(
  editorAPI: EditorAPI,
  compStructure: CompStructure,
): void {
  if (
    editorAPI.isMobileEditor() &&
    !experiment.isOpen('se_mobileOnlyDataBinding')
  ) {
    removeConnectionPropertyRecursively(compStructure);
  }
}
