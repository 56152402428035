import _ from 'lodash';
import tpaTypeUtils from './tpaTypeUtils';
import { tpaUtils } from '#packages/util';

/*var WIX_STORE_PAGES = {
    PRODUCT_PAGE: '13a94f09-2766-3c40-4a32-8edb5acdd8bc',
    SHOPPING_CART_PAGE: '1380bbab-4da3-36b0-efb4-2e0599971d14',
    THANK_YOU_PAGE: '1380bbb4-8df0-fd38-a235-88821cf3f8a4'
};*/

// TODO: page-types. think about why special pages type recognition. How to use WIX_STORE_PAGES guids?
const WIX_STORE_ALIASES = {
  PRODUCT_PAGE: 'product_page',
  SHOPPING_CART_PAGE: 'shopping_cart',
  THANK_YOU_PAGE: 'thank_you_page',
};

const WIX_STORE_APP_DEF_ID = tpaUtils.getEcomAppDefId();

function getAppDefId(editorAPI: AnyFixMe, pageData: AnyFixMe) {
  if (tpaTypeUtils.isTPA(pageData)) {
    const tpaPageData = editorAPI.dsRead.tpa.section.getPageData(
      pageData.id,
    ).appData;
    return tpaPageData?.appDefinitionId;
  }
  return undefined;
}

function isOfAppDefId(
  appDefId: AnyFixMe,
  editorAPI: AnyFixMe,
  pageData: AnyFixMe,
) {
  return getAppDefId(editorAPI, pageData) === appDefId;
}

function isOfTPAPageId(tpaPageId: AnyFixMe, pageData: AnyFixMe) {
  return pageData.tpaPageId === tpaPageId;
}

const isWixStore = _.partial(isOfAppDefId, WIX_STORE_APP_DEF_ID);

const isWixStoreCartPage = _.partial(
  isOfTPAPageId,
  WIX_STORE_ALIASES.SHOPPING_CART_PAGE,
);
const isWixStoreThankYouPage = _.partial(
  isOfTPAPageId,
  WIX_STORE_ALIASES.THANK_YOU_PAGE,
);
const isWixStoreProductPage = _.partial(
  isOfTPAPageId,
  WIX_STORE_ALIASES.PRODUCT_PAGE,
);

export {
  isWixStore,
  isWixStoreCartPage,
  isWixStoreThankYouPage,
  isWixStoreProductPage,
};
