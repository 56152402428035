const OVERRIDE_SITE_WAS_SAVED_PANEL = 'OVERRIDE_SITE_WAS_SAVED_PANEL';
const OVERRIDE_SAVE_REMINDER_PANEL = 'OVERRIDE_SAVE_REMINDER_PANEL';
const OVERRIDE_DOMAIN_PANEL = 'OVERRIDE_DOMAIN_PANEL';
const OVERRIDE_SAVE_AND_PUBLISH = 'OVERRIDE_SAVE_AND_PUBLISH';
const OVERRIDE_SITE_DEFAULT_NAME = 'OVERRIDE_SITE_DEFAULT_NAME';
const OVERRIDE_SHOULD_CHECK_FOR_CONCURRENT_USERS =
  'OVERRIDE_SHOULD_CHECK_FOR_CONCURRENT_USERS';
const SET_IS_FORCE_SAVE_IN_BACKGROUND = 'SET_IS_FORCE_SAVE_IN_BACKGROUND';
const SET_IS_DIFF_SAVE_IN_PROGRESS = 'SET_IS_DIFF_SAVE_IN_PROGRESS';
const SET_IS_BUILD_IN_PROGRESS = 'SET_IS_BUILD_IN_PROGRESS';
const SET_IS_PUBLISH_IN_PROGRESS = 'SET_IS_PUBLISH_IN_PROGRESS';
const SET_IS_TEST_SITE_IN_PROGRESS = 'SET_IS_TEST_SITE_IN_PROGRESS';
const SET_IS_SAVE_IN_PROGRESS = 'SET_IS_SAVE_IN_PROGRESS';
const SET_SAVE_IN_PROGRESS_DONE_CB = 'SET_SAVE_IN_PROGRESS_DONE_CB';
const CLEAR_SAVE_IN_PROGRESS_DONE_CB = 'CLEAR_SAVE_IN_PROGRESS_DONE_CB';
const SET_IS_MANUAL_SAVE = 'SET_IS_MANUAL_SAVE';
const SET_IS_LOCKED = 'SET_IS_LOCKED';
const SET_PUBLISH_PANEL_PROMOTION_INDEX = 'SET_PUBLISH_PANEL_PROMOTION_INDEX';
const SET_IS_DEALER_POSTPUBLISH_LOADED = 'SET_IS_DEALER_POSTPUBLISH_LOADED';
const SET_IS_DEALER_POSTSAVE_LOADED = 'SET_IS_DEALER_POSTSAVE_LOADED';
const SET_SITE_QR_CODE_URL = 'SET_SITE_QR_CODE_URL';
const SET_WAS_LATEST_RC_PUBLISHED = 'SET_WAS_LATEST_RC_PUBLISHED';
const SET_SESSION_LOADED_AS_DRAFT = 'SET_SESSION_LOADED_AS_DRAFT';
const SET_PUBLISH_IN_CURRENT_SESSION = 'SET_PUBLISH_IN_CURRENT_SESSION';
const USER_IGNORE_PUBLISH_ERROR = 'userIgnorePublishError';

export {
  OVERRIDE_SITE_WAS_SAVED_PANEL,
  OVERRIDE_DOMAIN_PANEL,
  OVERRIDE_SAVE_AND_PUBLISH,
  OVERRIDE_SAVE_REMINDER_PANEL,
  OVERRIDE_SITE_DEFAULT_NAME,
  OVERRIDE_SHOULD_CHECK_FOR_CONCURRENT_USERS,
  SET_IS_FORCE_SAVE_IN_BACKGROUND,
  SET_IS_DIFF_SAVE_IN_PROGRESS,
  SET_IS_BUILD_IN_PROGRESS,
  SET_IS_PUBLISH_IN_PROGRESS,
  SET_IS_TEST_SITE_IN_PROGRESS,
  SET_IS_SAVE_IN_PROGRESS,
  SET_SAVE_IN_PROGRESS_DONE_CB,
  CLEAR_SAVE_IN_PROGRESS_DONE_CB,
  SET_IS_MANUAL_SAVE,
  SET_IS_LOCKED,
  SET_PUBLISH_PANEL_PROMOTION_INDEX,
  SET_IS_DEALER_POSTPUBLISH_LOADED,
  SET_IS_DEALER_POSTSAVE_LOADED,
  SET_SITE_QR_CODE_URL,
  SET_WAS_LATEST_RC_PUBLISHED,
  SET_SESSION_LOADED_AS_DRAFT,
  SET_PUBLISH_IN_CURRENT_SESSION,
  USER_IGNORE_PUBLISH_ERROR,
};
