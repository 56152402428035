// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import type { BeforePlugin } from 'types/editorPlugins';
import { isMeshLayoutEnabled } from '#packages/layout';

const { fittingTypes } = util.imageTransform;

function getClipArtStructure(compDef) {
  return _.merge(compDef, {
    data: {
      title: '',
    },
    layout: {
      rotationInDegrees: 0,
      scale: 1,
      fixedPosition: false,
      anchors: [],
    },
    props: {
      displayMode: fittingTypes.SCALE_TO_FILL,
      type: 'WPhotoProperties',
    },
    style: compDef.style || 'ca1',
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  if (!compDef.props) {
    compDef = getClipArtStructure(compDef);
  }

  const compRef = originalAddComponentFunc(containerRef, compDef);

  if (isMeshLayoutEnabled()) {
    return {
      compRef,
      onChangesApplied: onCompAddCallback,
    };
  }

  if (onCompAddCallback) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      onCompAddCallback(compRef);
    });
  }

  return { compRef };
};

export default plugin;
