import _ from 'lodash';
import * as actionTypes from './savePublishActionTypes';

export interface SavePublishState {
  isPublishInProgress: boolean;
  isTestSiteInProgress: boolean;
  isManualSave: boolean;
  saveProgressCallbacks: any[];
  isAfterManualSave: boolean;
  publishPanelPromotionIndex: number;
  isLocked: boolean;
  isBuildInProgress: boolean;
  isDiffSaveInProgress: boolean;
  isForceSaveInBackground: boolean;
  overridden: {
    saveReminderPanel: unknown;
    shouldCheckForConcurrentUsers: boolean;
    saveAndPublish?: (origin: string) => void;
    siteWasSavedPanel: unknown;
    domainPanel: unknown;
    siteDefaultName: string;
  };
  isSaveInProgress: boolean;
  isDealerPostpublishLoaded: boolean;
  isDealerPostsaveLoaded: boolean;
  qrCodeUrl: string;
  wasLatestRCPublished: boolean;
  sessionLoadedAsDraft: boolean;
  isPublishedInCurrentSession: boolean;
}

export const savePublishInitialState: SavePublishState = {
  isSaveInProgress: false,
  saveProgressCallbacks: [],
  isManualSave: false,
  isAfterManualSave: false,
  isPublishInProgress: false,
  isTestSiteInProgress: false,
  isBuildInProgress: false,
  isDiffSaveInProgress: false,
  isLocked: false,
  publishPanelPromotionIndex: 0,
  isForceSaveInBackground: false,
  overridden: {
    siteWasSavedPanel: null,
    domainPanel: null,
    saveAndPublish: null,
    saveReminderPanel: null,
    siteDefaultName: null,
    shouldCheckForConcurrentUsers: undefined,
  },
  isDealerPostpublishLoaded: false,
  isDealerPostsaveLoaded: false,
  qrCodeUrl: '',
  wasLatestRCPublished: false,
  sessionLoadedAsDraft: false,
  isPublishedInCurrentSession: false,
};

const reducer = (
  savePublish: SavePublishState = savePublishInitialState,
  action: AnyFixMe,
): SavePublishState => {
  switch (action.type) {
    case actionTypes.OVERRIDE_SITE_WAS_SAVED_PANEL:
      return _.defaultsDeep(
        { overridden: { siteWasSavedPanel: action.overrides } },
        savePublish,
      );
    case actionTypes.OVERRIDE_DOMAIN_PANEL:
      return _.defaultsDeep(
        { overridden: { domainPanel: action.overrides } },
        savePublish,
      );
    case actionTypes.OVERRIDE_SAVE_AND_PUBLISH:
      return _.defaultsDeep(
        { overridden: { saveAndPublish: action.overridingFunc } },
        savePublish,
      );
    case actionTypes.OVERRIDE_SAVE_REMINDER_PANEL:
      return _.defaultsDeep(
        { overridden: { saveReminderPanel: action.overrides } },
        savePublish,
      );
    case actionTypes.OVERRIDE_SITE_DEFAULT_NAME:
      return _.defaultsDeep(
        { overridden: { siteDefaultName: action.overridingDefaultName } },
        savePublish,
      );
    case actionTypes.OVERRIDE_SHOULD_CHECK_FOR_CONCURRENT_USERS:
      return _.defaultsDeep(
        {
          overridden: {
            shouldCheckForConcurrentUsers: action.shouldCheckForConcurrentUsers,
          },
        },
        savePublish,
      );
    case actionTypes.SET_IS_FORCE_SAVE_IN_BACKGROUND:
      return _.defaults(
        { isForceSaveInBackground: action.isForceSaveInBackground },
        savePublish,
      );
    case actionTypes.SET_PUBLISH_PANEL_PROMOTION_INDEX:
      return _.defaults(
        { publishPanelPromotionIndex: action.publishPanelPromotionIndex },
        savePublish,
      );
    case actionTypes.SET_IS_SAVE_IN_PROGRESS:
      return _.defaults(
        {
          isSaveInProgress: action.isSaveInProgress,
          isManualSave: action.isManualSave,
          isAfterManualSave: action.isManualSave,
        },
        savePublish,
      );
    case actionTypes.SET_SAVE_IN_PROGRESS_DONE_CB:
      const saveProgressCallbacksArr = _.cloneDeep(
        savePublish.saveProgressCallbacks,
      );
      saveProgressCallbacksArr.push(action.saveDoneCb);
      return _.defaults(
        {
          saveProgressCallbacks: saveProgressCallbacksArr,
        },
        savePublish,
      );
    case actionTypes.CLEAR_SAVE_IN_PROGRESS_DONE_CB:
      return _.defaults(
        {
          saveProgressCallbacks: [],
        },
        savePublish,
      );
    case actionTypes.SET_IS_MANUAL_SAVE:
      return _.defaults(
        {
          isManualSave: action.isManualSave,
        },
        savePublish,
      );
    case actionTypes.SET_IS_LOCKED:
      return _.defaults({ isLocked: action.isLocked }, savePublish);
    case actionTypes.SET_IS_PUBLISH_IN_PROGRESS:
      return _.defaults(
        { isPublishInProgress: action.isPublishInProgress },
        savePublish,
      );
    case actionTypes.SET_IS_TEST_SITE_IN_PROGRESS:
      return _.defaults(
        { isTestSiteInProgress: action.isTestSiteInProgress },
        savePublish,
      );

    case actionTypes.SET_IS_BUILD_IN_PROGRESS:
      return _.defaults(
        { isBuildInProgress: action.isBuildInProgress },
        savePublish,
      );
    case actionTypes.SET_IS_DIFF_SAVE_IN_PROGRESS:
      return _.defaults(
        {
          isDiffSaveInProgress: action.isDiffSaveInProgress,
          isManualSave: action.isManualSave,
          isAfterManualSave: action.isManualSave,
        },
        savePublish,
      );
    case actionTypes.SET_IS_DEALER_POSTPUBLISH_LOADED:
      return _.defaults(
        { isDealerPostpublishLoaded: action.isDealerPostpublishLoaded },
        savePublish,
      );
    case actionTypes.SET_IS_DEALER_POSTSAVE_LOADED:
      return _.defaults(
        { isDealerPostsaveLoaded: action.isDealerPostsaveLoaded },
        savePublish,
      );
    case actionTypes.SET_SITE_QR_CODE_URL:
      return _.defaults({ qrCodeUrl: action.qrCodeUrl }, savePublish);
    case actionTypes.SET_WAS_LATEST_RC_PUBLISHED:
      return _.defaults(
        { wasLatestRCPublished: action.wasLatestRCPublished },
        savePublish,
      );
    case actionTypes.SET_SESSION_LOADED_AS_DRAFT:
      return _.defaults(
        { sessionLoadedAsDraft: action.sessionLoadedAsDraft },
        savePublish,
      );
    case actionTypes.SET_PUBLISH_IN_CURRENT_SESSION:
      return _.defaults(
        { isPublishedInCurrentSession: action.isPublishedInCurrentSession },
        savePublish,
      );
    default:
      return savePublish;
  }
};

export default reducer;
