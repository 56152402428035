// @ts-nocheck
import * as util from '#packages/util';
import { recompose } from '@wix/santa-editor-utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const BI_NOT_IMPLEMENTED_MESSAGE =
  'There is no BI event for the control [%s], in [%s] panel.\n\tDear developer, Please add the control mapping to core/bi/controlsToEvents and the event to core/bi/events.json .';

const sendSelectedComponentBI =
  (...args) =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.selectedComponent.sendSelectedComponentBI(...args);
  };

const mapDispatchToProps = (dispatch) => ({
  sendSelectedComponentBI: (...args) =>
    dispatch(sendSelectedComponentBI(...args)),
});

const childContextTypes = {
  reportUIChange: PropTypes.func,
};

export default (Component) => {
  const getChildContext = (props) => ({
    reportUIChange(controlName, instanceId, changeEvent) {
      const eventDefinition =
        util.uiControlsBIUtil.getEventDefinition(controlName);
      if (!eventDefinition) {
        console.warn(
          BI_NOT_IMPLEMENTED_MESSAGE,
          controlName,
          Component.displayName,
        );
        return;
      }
      const controlParams = util.uiControlsBIUtil.getParams(
        controlName,
        changeEvent,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const params = _.assign(
        { panel_name: Component.displayName, control_name: instanceId },
        controlParams,
      );
      props.sendSelectedComponentBI(eventDefinition, params);
    },
  });

  return connect(
    EDITOR_API,
    undefined,
    mapDispatchToProps,
  )(recompose.withContext(childContextTypes, getChildContext)(Component));
};
