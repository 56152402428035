import _ from 'lodash';
import * as topBarActionTypes from './topBarActionTypes';
import constants from '#packages/constants';
import { browserUtil } from '#packages/util';

const { OVERRIDE_TOP_BAR, SET_ADI_TOOLTIP_VISIBILITY, SET_BANNER_TITLE } =
  topBarActionTypes;

export const topBarInitialState = {
  openedDropPanel: null as AnyFixMe,
  dropPanelDisabled: false,
  dropPanelActiveItem: null as AnyFixMe,
  shouldDropPanelRemainOpen: false,
  overridden: {
    menuItems: {
      [constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.TOOLS]: {},
    },
    overrideGetters: {},
  },
  adiTooltipVisibility: false,
  isMac: browserUtil.isMacintosh(),
  bannerTitle: undefined as string,
};

const reducer = (topBar = topBarInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case OVERRIDE_TOP_BAR:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const overridden = _.assign({}, topBar.overridden, action?.overridden);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, topBar, { overridden });

    case SET_ADI_TOOLTIP_VISIBILITY:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, topBar, { adiTooltipVisibility: action.visibility });
    case SET_BANNER_TITLE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, topBar, { bannerTitle: action.bannerTitle });
    default:
      return topBar;
  }
};

export default reducer;
