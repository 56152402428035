import { Hooks } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

interface ComponentsToResizeInterceptorData {
  compsToResize: CompRef[];
}

function createResizeAndPushHooks() {
  return {
    getComponentsToResizeInterceptor:
      Hooks.createInterceptorHook<ComponentsToResizeInterceptorData>({
        isUpdateDataAllowed: true,
      }),
  };
}

function createResizeHooks() {
  return {
    onResizeStart: Hooks.createHook<{ compRef: CompRef }>(),
    onResize: Hooks.createHook<{ proportionally: boolean }>(),
    onResizeEnd: Hooks.createHook(),
  };
}

const resizeHooks = createResizeHooks();
const resizeAndPushHooks = createResizeAndPushHooks();

export { resizeHooks, resizeAndPushHooks };
