import _ from 'lodash';
import * as featuresActionTypes from './featuresOverriddenActionTypes';
import type { Reducer } from 'types/redux';
import type { Feature } from '#packages/constants';
import constants from '#packages/constants';

const { DISABLE_FEATURE, ENABLE_FEATURE } = featuresActionTypes;

export type FeaturesOverriddenState = {
  [F in Feature]: { isDisabled: boolean };
};

export const featuresOverriddenInitialState: FeaturesOverriddenState = {
  [constants.FEATURES.CONNECT_TO_DATA]: {
    isDisabled: false,
  },
  [constants.FEATURES.PASTE]: {
    isDisabled: false,
  },
};

const reducer: Reducer<FeaturesOverriddenState> = (features, action) => {
  switch (action.type) {
    case DISABLE_FEATURE:
      return _.defaultsDeep(
        { [action.featureName]: { isDisabled: true } },
        features,
      );
    case ENABLE_FEATURE:
      return _.defaultsDeep(
        { [action.featureName]: { isDisabled: false } },
        features,
      );
    default:
      return features;
  }
};

export default reducer;
