import * as coreBi from '#packages/coreBi';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { CompData } from 'types/documentServices';

const origin = 'editor';

const middles = (editorAPI: EditorAPI, biComponent?: CompData) => {
  const pageId = () => editorAPI.pages.getCurrentPageId();
  const pageName = () => editorAPI.pages.getPageTitle(pageId());
  const compData = () =>
    biComponent || editorAPI.selection.getSelectedComponentData() || {};
  const selectedComponent = () =>
    editorAPI.selection.getSelectedComponents()[0];

  return {
    selectedComponent,
    pageId,
    pageName,
    compData,
  };
};
/**
 * @param  {object} editorAPI
 * @param  {number} stringLength
 * @param  {{id: string, type: string}} biComponent
 */
const googleTranslateSent = (
  editorAPI: EditorAPI,
  stringLength: number,
  biComponent: CompData,
  numberOfWords: number,
  primary_lang: string,
  current_secondary_lng: string,
) => {
  const { compData } = middles(editorAPI, biComponent);
  const { id, type } = compData();
  const state = editorAPI.store.getState();
  const { machineTranslateWordsLimit, machineTranslationWordsUsed } =
    stateManagement.multilingual.selectors.siteTranslatablesProperties(state) ||
    {};

  editorAPI.bi.event(coreBi.events.googleTranslate.sent, {
    component_id: id,
    component_type: type,
    string_length_sent: stringLength,
    origin,
    words_in_credit: machineTranslateWordsLimit - machineTranslationWordsUsed,
    words_translated: numberOfWords,
    words_remain:
      machineTranslateWordsLimit - machineTranslationWordsUsed - numberOfWords,
    primary_lang,
    current_secondary_lng,
  });
};
/**
 * @param  {object} editorAPI
 * @param  {number} stringLength
 * @param  {number} stringLengthReceived
 * @param  {boolean} success
 * @param  {number} duration
 * @param  {{id: string, type: string}} biComponent
 */
const googleTranslateReceived = (
  editorAPI: EditorAPI,
  stringLength: number,
  stringLengthReceived: number,
  numberOfWords: number,
  success: boolean,
  duration: number,
  biComponent: CompData,
  words_in_credit?: number,
  words_remain?: number,
) => {
  const { compData } = middles(editorAPI, biComponent);
  const { id, type } = compData();
  editorAPI.bi.event(coreBi.events.googleTranslate.received, {
    component_id: id,
    component_type: type,
    string_length_sent: stringLength,
    string_length_received: stringLengthReceived,
    success,
    duration: Math.floor(duration),
    origin,
    words_in_credit,
    words_translated: numberOfWords,
    words_remain,
  });
};

const translateGfppExposer = (editorAPI: EditorAPI) => {
  fireBiIfSecondaryLanguage(
    editorAPI,
    coreBi.events.multilingual.translate_gfpp_exposure,
    () => {
      const { pageId, pageName, compData, selectedComponent } =
        middles(editorAPI);
      const component = selectedComponent();
      if (!component || editorAPI.utils.isPage(component)) {
        return;
      }
      const { id, type, appDefinitionId } = compData();
      if (!id || !type) {
        return;
      }

      return {
        app_id: appDefinitionId,
        component_id: id,
        component_type: type,
        fgpp_list: null as any,
        page_id: pageId(),
        page_name: pageName(),
      };
    },
  );
};
/**
 * @param  {object} editorAPI
 * @param  {{id: string, type: string}} biComponent
 */
const smthWentWrongPopupDisplayed = (
  editorAPI: EditorAPI,
  biComponent: CompData,
) => {
  const { compData } = middles(editorAPI, biComponent);
  const { id, type } = compData();
  editorAPI.bi.event(
    coreBi.events.googleTranslate.smthWentWrongPopupDisplayed,
    {
      component_id: id,
      component_type: type,
      origin,
    },
  );
};

/**
 * @param  {object} editorAPI
 * @param  {{id: string, type: string}} biComponent
 */
const smthWentWrongPopupClosed = (
  editorAPI: EditorAPI,
  biComponent: CompData,
) => {
  const { compData } = middles(editorAPI, biComponent);
  const { id, type } = compData();
  editorAPI.bi.event(coreBi.events.googleTranslate.smthWentWrongPopupClosed, {
    component_id: id,
    component_type: type,
    origin,
  });
};

const fireBiIfSecondaryLanguage = (
  editorAPI: EditorAPI,
  eventStructure: BiEventDefinition,
  getEventData: () => BiEventFields<any>,
) => {
  if (editorAPI?.language.isCurrentLanguageSecondary()) {
    const eventData = getEventData();
    if (eventData) {
      editorAPI.bi.event(eventStructure, eventData);
    }
  }
};

export {
  translateGfppExposer,
  googleTranslateSent,
  googleTranslateReceived,
  smthWentWrongPopupDisplayed,
  smthWentWrongPopupClosed,
};
