import _ from 'lodash';
import EditorLayoutConstraintsUtil from './constraints/EditorLayoutConstraintsUtil';
import layoutRelativeToScreenPlugins from './layoutRelativeToScreenPlugins/layoutRelativeToScreenPlugins';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';

function getParentLayoutRelativeToScreen(
  editorAPI: EditorAPI,
  compPointer: CompRef,
) {
  const compParent = editorAPI.components.getContainer(compPointer);
  return editorAPI.components.layout.getRelativeToScreen(compParent);
}

function convertRelativeToParentToRelativeToScreen<TRect extends Partial<Rect>>(
  relativeToParentLayout: TRect,
  parentLayoutRelativeToScreen: Rect,
  isHorizontallyStretchedToScreen: boolean,
  siteX: number,
): TRect {
  if (!relativeToParentLayout) {
    return relativeToParentLayout;
  }

  const relativeToScreenLayout = _.pick(relativeToParentLayout, [
    'width',
    'height',
  ]) as TRect;

  if (_.isNumber(relativeToParentLayout.x)) {
    relativeToScreenLayout.x =
      parentLayoutRelativeToScreen.x + relativeToParentLayout.x;
    if (isHorizontallyStretchedToScreen) {
      relativeToScreenLayout.x += siteX;
    }
  }

  if (_.isNumber(relativeToParentLayout.y)) {
    relativeToScreenLayout.y =
      parentLayoutRelativeToScreen.y + relativeToParentLayout.y;
  }

  return relativeToScreenLayout;
}

function convertRelativeToScreenToRelativeToParent<TRect extends Partial<Rect>>(
  relativeToScreenLayout: TRect,
  parentLayoutRelativeToScreen: Rect,
  isHorizontallyStretchedToScreen: boolean,
  siteX: number,
): TRect {
  if (!relativeToScreenLayout) {
    return relativeToScreenLayout;
  }

  const relativeToParentLayout = _.pick(relativeToScreenLayout, [
    'width',
    'height',
  ]) as TRect;

  if (_.isNumber(relativeToScreenLayout.x)) {
    relativeToParentLayout.x =
      relativeToScreenLayout.x - parentLayoutRelativeToScreen.x;
    if (isHorizontallyStretchedToScreen) {
      relativeToParentLayout.x -= siteX;
    }
  }

  if (_.isNumber(relativeToScreenLayout.y)) {
    relativeToParentLayout.y =
      relativeToScreenLayout.y - parentLayoutRelativeToScreen.y;
  }

  return relativeToParentLayout;
}

function isLayoutChanged(newLayout: Partial<Rect>, oldLayout: Rect) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(newLayout, function (value, key) {
    return !_.isEqual(value, oldLayout[key as keyof Rect]);
  });
}

function getLayoutRelativeToScreenAfterConstraints(
  editorAPI: EditorAPI,
  compPointer: CompRef,
  newLayout: Rect,
  oldLayout: Rect,
): Rect {
  if (
    isLayoutChanged(newLayout, oldLayout) &&
    !editorAPI.utils.isPage(compPointer)
  ) {
    // todo - editorAPI.utils.isPage(compPointer) check in getApplyFunctions of editorBoundariesConstraints.js
    const editorLayoutConstraintsUtil = new EditorLayoutConstraintsUtil(
      editorAPI,
      compPointer,
    );

    if (editorLayoutConstraintsUtil.hasConstraints()) {
      editorLayoutConstraintsUtil.applyConstraints(
        editorAPI,
        compPointer,
        newLayout,
        oldLayout,
      );
    }
  }

  return newLayout;
}

function getLayoutAfterConstraints<TRect extends Partial<Rect>>(
  editorAPI: EditorAPI,
  compPointer: CompRef | CompRef[],
  layoutUpdated: TRect,
  layoutCurrent: Rect,
  forceApply: boolean = false,
): TRect {
  if (
    (forceApply || isLayoutChanged(layoutUpdated, layoutCurrent)) &&
    !editorAPI.utils.isPage(compPointer)
  ) {
    const compRef = Array.isArray(compPointer) ? compPointer[0] : compPointer;
    const editorLayoutConstraintsUtil = new EditorLayoutConstraintsUtil(
      editorAPI,
      compRef,
    );

    if (editorLayoutConstraintsUtil.hasConstraints()) {
      const isHorizontallyStretchedToScreen =
        editorAPI.components.layout.isHorizontallyStretchedToScreen(compRef);
      const siteX = editorAPI.site.getSiteX();
      const parentLayoutRelativeToScreen = getParentLayoutRelativeToScreen(
        editorAPI,
        compRef,
      );
      const newLayoutRelativeToScreen =
        convertRelativeToParentToRelativeToScreen(
          layoutUpdated,
          parentLayoutRelativeToScreen,
          isHorizontallyStretchedToScreen,
          siteX,
        );
      const oldLayoutRelativeToScreen =
        convertRelativeToParentToRelativeToScreen(
          layoutCurrent,
          parentLayoutRelativeToScreen,
          isHorizontallyStretchedToScreen,
          siteX,
        );

      editorLayoutConstraintsUtil.applyConstraints(
        editorAPI,
        compRef,
        newLayoutRelativeToScreen,
        oldLayoutRelativeToScreen,
      );

      const fixedLayout = convertRelativeToScreenToRelativeToParent(
        newLayoutRelativeToScreen,
        parentLayoutRelativeToScreen,
        isHorizontallyStretchedToScreen,
        siteX,
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign, you-dont-need-lodash-underscore/keys
      _.assign(layoutUpdated, _.pick(fixedLayout, _.keys(layoutUpdated)));
    }
  }

  return layoutUpdated;
}

function runLayoutRelativeToScreenPlugin(
  editorAPI: EditorAPI,
  compRef: CompRef,
  layout: Rect,
  keepProportions: boolean,
): { updatedLayout: Rect; keepProportions: boolean } {
  const compType = editorAPI.components.getType(compRef);
  if (layoutRelativeToScreenPlugins[compType]) {
    return layoutRelativeToScreenPlugins[compType](
      editorAPI,
      compRef,
      layout,
      keepProportions,
    );
  }

  return { updatedLayout: layout, keepProportions };
}

export {
  getLayoutAfterConstraints,
  getLayoutRelativeToScreenAfterConstraints,
  runLayoutRelativeToScreenPlugin,
};
