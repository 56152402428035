import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { panels } from '#packages/stateManagement';
import { isMeshLayoutEnabled } from '#packages/layout';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { BeforePlugin } from 'types/editorPlugins';

const ANCHOR_LABEL_KEY = 'component_label_anchor';

function getAnchorNameNumbers(
  editorAPI: EditorAPI,
  anchors: CompRef[],
  anchorLabel: string,
) {
  const anchorNameRegexp = new RegExp(`${anchorLabel}\\s(\\d+)`);
  const anchorNumbers: number[] = [];

  anchors.forEach((anchor) => {
    const data = editorAPI.components.data.get(anchor);
    const { name } = data;

    const labelMatch = name.match(anchorNameRegexp);
    if (labelMatch) {
      anchorNumbers.push(parseInt(labelMatch[1], 10));
    }
  });

  return anchorNumbers;
}

function getNextAvailableAnchorNum(
  editorAPI: EditorAPI,
  anchors: CompRef[],
  anchorLabel: string,
) {
  let i;

  const anchorNumbers = getAnchorNameNumbers(editorAPI, anchors, anchorLabel);
  anchorNumbers.sort(function (a, b) {
    return a - b;
  });

  for (i = 0; i < anchorNumbers.length; ++i) {
    if (!anchorNumbers.includes(i + 1)) {
      return i + 1;
    }
  }

  return i + 1;
}

const getPageRef = (editorAPI: EditorAPI, containerRef: CompRef) => {
  if (editorAPI.pages.isPageExist(containerRef.id)) return containerRef;
  const containerRefPage = editorAPI.components.getPage(containerRef);
  return (
    containerRefPage ||
    editorAPI.dsRead.pages.getReference(
      editorAPI.dsRead.pages.getPrimaryPageId(),
    )
  );
};

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
  origin,
) => {
  const anchorLabel = translate(ANCHOR_LABEL_KEY);

  const pageRef = getPageRef(editorAPI, containerRef);
  const anchors = editorAPI.components.get.byType(
    'wysiwyg.common.components.anchor.viewer.Anchor',
    pageRef,
  );

  const newAnchorNum = getNextAvailableAnchorNum(
    editorAPI,
    anchors,
    anchorLabel,
  );

  compDef.data.name = `${anchorLabel} ${newAnchorNum}`;

  const compRef = originalAddComponentFunc(containerRef, compDef);
  const openAnchorSettingsPanelIfNeeded = () => {
    if (
      !editorAPI.panelManager.isPanelOpened(
        constants.PANELS.ANCHOR.ANCHORS_MENU_MANAGE_PANEL,
      )
    ) {
      editorAPI.store.dispatch(
        panels.actions.openComponentPanel(
          constants.PANELS.ANCHOR.ANCHOR_SETINGS_PANEL,
          {
            origin: origin ?? 'anchor_added_on_stage',
          },
        ),
      );
    }
  };

  if (onCompAddCallback || isMeshLayoutEnabled()) {
    const onChangesApplied = () => {
      onCompAddCallback?.(compRef);
      editorAPI.store.dispatch(
        panels.actions.closePanelByName(
          constants.PANELS.ANCHOR.ANCHORS_MENU_MANAGE_PANEL,
        ),
      );
      editorAPI.selection.selectComponentByCompRef(compRef);
      openAnchorSettingsPanelIfNeeded();
    };

    if (isMeshLayoutEnabled()) {
      return {
        compRef,
        onChangesApplied,
      };
    }

    editorAPI.dsActions.waitForChangesApplied(onChangesApplied);
  }

  return { compRef };
};

export default plugin;
