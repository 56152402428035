import { allowed } from './editorRestrictionsApi';

import type { UIResourceRestriction } from '#packages/editorRestrictions';

// TODO: will have an effect (probably with subscription)
export const useUXResourceRestrictions = (
  uiResourceTypes: UIResourceRestriction[],
): boolean[] => {
  return uiResourceTypes.map(allowed);
};
