import { array } from '#packages/util';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

export default {
  isSelectionContainsMobileOnlyComponent(
    editorAPI: EditorAPI,
    compRef: CompRef | CompRef[],
  ): boolean {
    const components = array.asArray(compRef);

    const maybeComponentWithChildren = components.flatMap(
      (compRef: CompRef) => [
        compRef,
        ...editorAPI.components.getChildren(compRef, true),
      ],
    );

    return maybeComponentWithChildren.every((compRef) => {
      return editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        compRef,
      );
    });
  },

  isContainedByHeaderOrFooter(
    editorAPI: EditorAPI,
    compRefOrRefs: CompRef | CompRef[],
    isHeader: boolean,
  ) {
    const headerOrFooterRef = isHeader
      ? editorAPI.dsRead.siteSegments.getHeader()
      : editorAPI.dsRead.siteSegments.getFooter();

    return array.asArray(compRefOrRefs).some((compRef) => {
      const rootAncestorRef = editorAPI.components.findAncestor(
        compRef,
        (ancestorRef) =>
          ancestorRef.id === 'MENU_AS_CONTAINER' ||
          editorAPI.utils.isSameRef(ancestorRef, headerOrFooterRef),
        { includeScopeOwner: true },
      );

      if (!rootAncestorRef || rootAncestorRef.id === 'MENU_AS_CONTAINER') {
        return false;
      }

      return editorAPI.utils.isSameRef(rootAncestorRef, headerOrFooterRef);
    });
  },

  isShownOnAllPagesComponent(
    editorAPI: EditorAPI,
    componentsPointer: CompRef | CompRef[],
  ) {
    const components = array.asArray(componentsPointer);

    return components.some((component) => {
      return (
        editorAPI.components.isShowOnAllPages(component) &&
        !editorAPI.utils.isSameRef(
          component,
          editorAPI.dsRead.siteSegments.getFooter(),
        )
      );
    });
  },
};
