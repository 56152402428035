// @ts-nocheck
import * as util from '#packages/util';

const urlUtils = util.url;

const SPOTIFY_SEARCH_PANEL_NAME = 'panels.focusPanels.spotifySearchFocusPanel';

const DEFAULT_TABS = ['tracks', 'albums', 'artists'];

const baseUrl = urlUtils.joinURL(
  util.editorModel.editorBase,
  'app/external/SpotifySearch/index.html?id=',
);

function validateFollowUri(spotifyUri) {
  return spotifyUri.toLowerCase().indexOf('spotify:artist:') === 0;
}

function validateSpotifyUri(spotifyUri) {
  return isSpotifyPresent(spotifyUri) && isItemIdPresent(spotifyUri);
}

function getIframeSrc(id, tabsToDisplay, lang) {
  return `${baseUrl + id}&lang=${lang}&tabs=${JSON.stringify(
    tabsToDisplay,
  )}&default_tab=${
    tabsToDisplay[0]
  }&fontFaceUrl=https://static.parastorage.com/services/wix-public/1.160.0/css/Helvetica/fontFace.css`;
}

/**
 * Checks if the song id is present
 * @param spotifyUri
 * @returns {boolean}
 */
function isItemIdPresent(spotifyUri) {
  //spotify:track:LONG_ID_HERE - valid
  //spotify:LONG_ID_HERE - invalid
  const splittedVal = spotifyUri.split(':');
  return (
    splittedVal.length > 2 && splittedVal[splittedVal.length - 1].length > 0
  );
}

/**
 * Checks if the string contains 'spotify:'
 * @param uri
 * @returns {boolean}
 */
function isSpotifyPresent(uri) {
  //spotify:track:LONG_ID_HERE - valid
  //track:LONG_ID_HERE - invalid
  return uri && uri.indexOf('spotify:') === 0;
}

export default {
  openIframe(editorAPI, tabsToDisplay, callbackFn) {
    const lang = util.languages.getLanguageCode();
    const id = `spotifySearch_${Date.now()}`;
    tabsToDisplay = tabsToDisplay || DEFAULT_TABS;
    const selectedComponent = editorAPI.selection.getSelectedComponentType();
    editorAPI.panelManager.openPanel(
      SPOTIFY_SEARCH_PANEL_NAME,
      {
        panelName: 'Spotify Search',
        iframeSrc: getIframeSrc(id, tabsToDisplay, lang),
        id,
        selectedComponentType: selectedComponent,
        callback: callbackFn,
      },
      false,
    );
  },
  validateSpotifyUri,
  validateFollowUri,
};
