// @ts-nocheck
import constants from '#packages/constants';

const { PANEL_NAMES } = constants;

const TEXT_INPUT_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.TEXT_INPUT_SETTINGS];
const TEXT_AREA_INPUT_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.TEXT_AREA_INPUT_SETTINGS];
const RADIO_GROUP_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.RADIO_GROUP_SETTINGS];
const COMBO_BOX_INPUT_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.COMBO_BOX_INPUT_SETTINGS];
const COMBO_BOX_INPUT_MANAGE_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.COMBO_BOX_MANAGE];
const CHECKBOX_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.CHECKBOX_SETTINGS];
const DATE_PICKER_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.DATE_PICKER_SETTINGS];
const FILE_UPLOADER_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.FILE_UPLOADER_SETTINGS];
const TIME_PICKER_SETTINGS_SECTIONS =
  constants.PANEL_SECTIONS[PANEL_NAMES.TIME_PICKER_SETTINGS];
const SITE_BUTTON_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.SITE_BUTTON_SETTINGS];
const WPHOTO_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.WPHOTO_SETTINGS];
const SLIDER_SHOW_GALLERY_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.SLIDER_SHOW_GALLERY_SETTINGS];
const PAGINATED_GRID_GALLERY_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.PAGINATED_GRID_GALLERY_SETTINGS];
const MATRIX_GALLERY_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.MATRIX_GALLERY_SETTINGS];
const SLIDER_GALLERY_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.SLIDER_GALLERY_SETTINGS];
const VIDEO_PLAYER_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.VIDEO_PLAYER_SETTINGS];
const TOGGLE_SWITCH_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.TOGGLE_SWITCH_SETTINGS];
const ADDRESS_INPUT_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.ADDRESS_INPUT_SETTINGS];
const MUSIC_PLAYER_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.MUSIC_PLAYER_SETTINGS];
const VIDEO_BOX_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.VIDEO_BOX_SETTINGS];
const VECTOR_SHAPE_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.VECTOR_SHAPE_SETTINGS];
const POPUP_CONTAINER_SETTINGS_SECTION =
  constants.PANEL_SECTIONS[PANEL_NAMES.POPUP_CONTAINER_SETTINGS];

const ENABLED = 'enabled';
const VISIBLE = 'visible';

export type PanelSectionStatus = typeof ENABLED | typeof VISIBLE;
export interface PanelSectionDefinition {
  [sectionKey: string]: PanelSectionStatus;
}

export default {
  [PANEL_NAMES.TEXT_INPUT_SETTINGS]: {
    [TEXT_INPUT_SETTINGS_SECTIONS.INPUT_TYPE]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.PLACEHOLDER]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.INITIAL_VALUE]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.READ_ONLY]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.MAX_LENGTH]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.MAX_NUMBER]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.MIN_NUMBER]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.PATTERN_VALIDATION]: ENABLED,
    [TEXT_INPUT_SETTINGS_SECTIONS.PHONE_FORMAT]: ENABLED,
  },
  [PANEL_NAMES.TEXT_AREA_INPUT_SETTINGS]: {
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.PLACEHOLDER]: ENABLED,
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.INITIAL_VALUE]: ENABLED,
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.READ_ONLY]: ENABLED,
    [TEXT_AREA_INPUT_SETTINGS_SECTIONS.MAX_LENGTH]: ENABLED,
  },
  [PANEL_NAMES.RADIO_GROUP_SETTINGS]: {
    [RADIO_GROUP_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [RADIO_GROUP_SETTINGS_SECTIONS.INITIAL_VALUE]: ENABLED,
    [RADIO_GROUP_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [RADIO_GROUP_SETTINGS_SECTIONS.OPTIONS]: ENABLED,
  },
  [PANEL_NAMES.COMBO_BOX_INPUT_SETTINGS]: {
    [COMBO_BOX_INPUT_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [COMBO_BOX_INPUT_SETTINGS_SECTIONS.PLACEHOLDER]: ENABLED,
    [COMBO_BOX_INPUT_SETTINGS_SECTIONS.INITIAL_VALUE]: ENABLED,
    [COMBO_BOX_INPUT_SETTINGS_SECTIONS.DROPDOWN_LIST_TYPE]: ENABLED,
    [COMBO_BOX_INPUT_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
  },
  [PANEL_NAMES.COMBO_BOX_MANAGE]: {
    [COMBO_BOX_INPUT_MANAGE_SECTIONS.SETTINGS]: ENABLED,
  },
  [PANEL_NAMES.CHECKBOX_SETTINGS]: {
    [CHECKBOX_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [CHECKBOX_SETTINGS_SECTIONS.VALUE]: ENABLED,
    [CHECKBOX_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [CHECKBOX_SETTINGS_SECTIONS.CHECKED]: ENABLED,
    [CHECKBOX_SETTINGS_SECTIONS.LINK]: ENABLED,
  },
  [PANEL_NAMES.DATE_PICKER_SETTINGS]: {
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_LABEL]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_INITIAL_VALUE]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_PLACEHOLDER]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_REQUIRED]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_READ_ONLY]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.INPUT_FORMAT]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.CALENDAR_DISABLED_DAYS]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.CALENDAR_WEEK_STARTS_ON]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.CALENDAR_DISABLED_DAYS_OF_WEEK]: ENABLED,
    [DATE_PICKER_SETTINGS_SECTIONS.CALENDAR_TIME_SLOTS]: ENABLED,
  },
  [PANEL_NAMES.FILE_UPLOADER_SETTINGS]: {
    [FILE_UPLOADER_SETTINGS_SECTIONS.VIEW_FILES]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.FILE_TYPE]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.BUTTON_LABEL]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.PLACEHOLDER]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [FILE_UPLOADER_SETTINGS_SECTIONS.MULTI_FILES_UPLOAD]: ENABLED,
  },
  [PANEL_NAMES.TIME_PICKER_SETTINGS]: {
    [TIME_PICKER_SETTINGS_SECTIONS.LABEL]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.USE_AM_PM_FORMAT]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INPUT_OPTION]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INITIAL_TIME]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INITIAL_TEXT_NOTHING]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INITIAL_TEXT_PLACEHOLDER]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INITIAL_TEXT_CURRENT_TIME]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.INITIAL_TEXT_SPECIFIC_TIME]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.STEP]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.REQUIRED]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.READ_ONLY]: ENABLED,
    [TIME_PICKER_SETTINGS_SECTIONS.DISABLED_TIMES]: ENABLED,
  },
  [PANEL_NAMES.SITE_BUTTON_SETTINGS]: {
    [SITE_BUTTON_SETTINGS_SECTION.LABEL]: ENABLED,
    [SITE_BUTTON_SETTINGS_SECTION.LINK]: ENABLED,
  },
  [PANEL_NAMES.WPHOTO_SETTINGS]: {
    [WPHOTO_SETTINGS_SECTION.IMAGE_CHANGE_ADJUST]: ENABLED,
    [WPHOTO_SETTINGS_SECTION.IMAGE_BEHAVIORS]: ENABLED,
    [WPHOTO_SETTINGS_SECTION.ALT_TEXT]: ENABLED,
    [WPHOTO_SETTINGS_SECTION.TOOLTIP]: ENABLED,
  },
  [PANEL_NAMES.SLIDER_SHOW_GALLERY_SETTINGS]: {
    [SLIDER_SHOW_GALLERY_SETTINGS_SECTION.CHANGE_IMAGES]: ENABLED,
    [SLIDER_SHOW_GALLERY_SETTINGS_SECTION.LINK]: ENABLED,
  },
  [PANEL_NAMES.PAGINATED_GRID_GALLERY_SETTINGS]: {
    [PAGINATED_GRID_GALLERY_SETTINGS_SECTION.CHANGE_IMAGES]: ENABLED,
    [PAGINATED_GRID_GALLERY_SETTINGS_SECTION.LINK]: ENABLED,
  },
  [PANEL_NAMES.MATRIX_GALLERY_SETTINGS]: {
    [MATRIX_GALLERY_SETTINGS_SECTION.CHANGE_IMAGES]: ENABLED,
    [MATRIX_GALLERY_SETTINGS_SECTION.LINK]: ENABLED,
  },
  [PANEL_NAMES.SLIDER_GALLERY_SETTINGS]: {
    [SLIDER_GALLERY_SETTINGS_SECTION.CHANGE_IMAGES]: ENABLED,
    [SLIDER_GALLERY_SETTINGS_SECTION.LINK]: ENABLED,
  },
  [PANEL_NAMES.VIDEO_PLAYER_SETTINGS]: {
    [VIDEO_PLAYER_SETTINGS_SECTION.CHANGE_VIDEO]: ENABLED,
    [VIDEO_PLAYER_SETTINGS_SECTION.VIDEO_DESCRIPTION]: ENABLED,
    [VIDEO_PLAYER_SETTINGS_SECTION.PLAYBACK]: ENABLED,
    [VIDEO_PLAYER_SETTINGS_SECTION.WIX_VIDEO_APP_MARKET_LINK]: ENABLED,
  },
  [PANEL_NAMES.TOGGLE_SWITCH_SETTINGS]: {
    [TOGGLE_SWITCH_SETTINGS_SECTION.TOGGLE_ON]: ENABLED,
  },
  [PANEL_NAMES.ADDRESS_INPUT_SETTINGS]: {
    [ADDRESS_INPUT_SETTINGS_SECTION.PLACEHOLDER]: ENABLED,
    [ADDRESS_INPUT_SETTINGS_SECTION.READ_ONLY]: ENABLED,
    [ADDRESS_INPUT_SETTINGS_SECTION.REQUIRED]: ENABLED,
    [ADDRESS_INPUT_SETTINGS_SECTION.LABEL]: ENABLED,
  },
  [PANEL_NAMES.MUSIC_PLAYER_SETTINGS]: {
    [MUSIC_PLAYER_SETTINGS_SECTION.UPLOAD_IMAGE]: ENABLED,
    [MUSIC_PLAYER_SETTINGS_SECTION.CHOOSE_TRACK]: ENABLED,
    [MUSIC_PLAYER_SETTINGS_SECTION.TRACK_DETAILS]: ENABLED,
  },
  [PANEL_NAMES.VIDEO_BOX_SETTINGS]: {
    [VIDEO_BOX_SETTINGS_SECTION.CHANGE_VIDEO]: VISIBLE,
    [VIDEO_BOX_SETTINGS_SECTION.RESET_VIDEO]: VISIBLE,
    [VIDEO_BOX_SETTINGS_SECTION.VIDEO_DESCRIPTION]: VISIBLE,
  },
  [PANEL_NAMES.VECTOR_SHAPE_SETTINGS]: {
    [VECTOR_SHAPE_SETTINGS_SECTION.LINK]: VISIBLE,
    [VECTOR_SHAPE_SETTINGS_SECTION.DESCRIPTION]: VISIBLE,
    [VECTOR_SHAPE_SETTINGS_SECTION.RESIZING]: VISIBLE,
  },
  [PANEL_NAMES.POPUP_CONTAINER_SETTINGS]: {
    [POPUP_CONTAINER_SETTINGS_SECTION.NAME]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.DISPLAY]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.PAGES]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.DELAY]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.X_ICON_TOGGLE]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.CLOSE_BUTTON]: VISIBLE,
    [POPUP_CONTAINER_SETTINGS_SECTION.LINKS]: VISIBLE,
  },
} as { [panelName: string]: PanelSectionDefinition };
