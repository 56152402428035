import { Hooks } from '#packages/apilib';
import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import { createLayoutTypeDefine } from '../lib/createLayoutTypeDefine';
import { createLayoutMoveApi_structure } from './createLayoutMoveApi_structure';
import { createLayoutMoveInteractionApi } from './createLayoutMoveInteractionApi';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutMeshApi } from '../layoutMeshApi';
import type { LayoutMoveByOptions } from '../type';
import type { LayoutMoveHooks, LayoutMoveByParams } from './types';
import type { LayoutApiByStructure } from '../createLayoutApi_structure';

export function createLayoutMoveApi({
  editorAPI,
  layoutGetApi,
  layoutApi_structure,
  layoutMeshApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutApi_structure: LayoutApiByStructure;
  layoutMeshApi: LayoutMeshApi;
}) {
  const { define } = createLayoutTypeDefine();
  const layoutMoveHooks: LayoutMoveHooks = {
    moveByInterceptor: Hooks.createInterceptorHook({
      isCancelAllowed: true,
    }),
  };
  const layoutMoveInteractionApi = createLayoutMoveInteractionApi({
    editorAPI,
    layoutGetApi,
  });
  const layoutMoveApi_structure = createLayoutMoveApi_structure({
    layoutApi_structure,
    layoutGetApi,
  });

  function canMoveByX(compRef: CompRef) {
    return editorAPI.components.is.horizontallyMovable(compRef);
  }

  function canMoveByY(compRef: CompRef) {
    return editorAPI.components.is.verticallyMovable(compRef);
  }

  function canMoveBy(compRef: CompRef, { deltaX, deltaY }: LayoutMoveByParams) {
    const hasDeltaX = typeof deltaX === 'number' && deltaX !== 0;
    const hasDeltaY = typeof deltaY === 'number' && deltaY !== 0;

    if (
      (!hasDeltaX && !hasDeltaY) ||
      (hasDeltaX && !canMoveByX(compRef)) ||
      (hasDeltaY && !canMoveByY(compRef))
    ) {
      return false;
    }

    return true;
  }

  layoutMoveHooks.moveByInterceptor.tap(
    layoutMoveInteractionApi.moveByInterceptor,
  );

  async function moveBy(
    compRefOrRefs: CompRef | CompRef[],
    params: LayoutMoveByParams,
    options: LayoutMoveByOptions = {},
  ) {
    const compRefs = Array.isArray(compRefOrRefs)
      ? editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
          compRefOrRefs,
        )
      : [compRefOrRefs];

    if (
      compRefs.length > 0 &&
      compRefs.every((compRef) => canMoveBy(compRef, params))
    ) {
      // TODO: why do we need to wait? To be sure that previous changes are applied?
      await editorAPI.dsActions.waitForChangesAppliedAsync();

      const interceptionResult = layoutMoveHooks.moveByInterceptor.intercept({
        compRefs,
        params,
      });

      if (interceptionResult.isCanceled) {
        return;
      }

      if (isMeshLayoutEnabled()) {
        layoutMeshApi.moveBy(compRefs, params, options);
      } else {
        layoutMoveApi_structure.moveBy(compRefs, params, options);
      }
    }
  }

  return {
    hooks: layoutMoveHooks,
    moveBy,
    moveTo: define({
      [define.Type.Default]: () => {
        throw new Error('Not implemented');
      },
      [define.Type.Mesh2]: layoutMeshApi.moveTo,
    }),
    moveToMany: define({
      [define.Type.Default]: () => {
        throw new Error('Not implemented');
      },
      [define.Type.Mesh2]: layoutMeshApi.moveToMany,
    }),
    canMoveBy,
  };
}

export type LayoutMoveApi = ReturnType<typeof createLayoutMoveApi>;
