import { ensureComponentsAreSiblings } from '../lib/ensureComponentsAreSiblings';
import type { EditorAPI } from '#packages/editorAPI';
import type { LayoutMeshApi } from './createLayoutMeshApi';
import type { CompRef, Point, Rect } from 'types/documentServices';
import type { LayoutMoveToOptions } from '../type';
import type { HistoryApi } from '../createHistoryApi';
import { isNumber } from './utils';
import { ensureItemLayoutIsMeshItemLayout } from '#packages/layoutUtils';

export function createLayoutMeshMoveToApi({
  editorAPI,
  historyApi,
  layoutMeshCoreApi,
}: {
  editorAPI: EditorAPI;
  historyApi: HistoryApi;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
}) {
  async function moveTo(
    compRef: CompRef,
    compPosition: Partial<Point>,
    options: LayoutMoveToOptions = {},
  ): Promise<void> {
    if (!isNumber(compPosition.x) && !isNumber(compPosition.y)) {
      throw new Error('moveTo should be called with at least one of x or y');
    }

    const layouts = layoutMeshCoreApi.get(compRef);

    ensureItemLayoutIsMeshItemLayout(layouts.itemLayout);

    const updateLayout = {
      x: isNumber(compPosition.x)
        ? compPosition.x
        : layouts.itemLayout.meshData.x,
      y: isNumber(compPosition.y)
        ? compPosition.y
        : layouts.itemLayout.meshData.y,
    };

    layoutMeshCoreApi.update(compRef, {
      itemLayout: {
        ...layouts.itemLayout,
        meshData: {
          ...layouts.itemLayout.meshData,
          ...updateLayout,
        },
      },
    });

    historyApi.debouncedAdd('component - update layout position', options);
  }

  async function moveToMany(
    compRefs: CompRef[],
    compPositionsMap: Map<string, Partial<Point>>,
    options: LayoutMoveToOptions = {},
  ): Promise<void> {
    ensureComponentsAreSiblings(editorAPI.dsRead, compRefs);

    await editorAPI.transactions.run(async () => {
      const containerRef = editorAPI.components.getContainer(compRefs[0]);

      layoutMeshCoreApi.updateContainerGrid(containerRef, {
        compRectsMap: new Map(
          compRefs.map<[string, Rect]>((compRef) => [
            compRef.id,
            {
              ...layoutMeshCoreApi.measureRect(compRef),
              ...compPositionsMap.get(compRef.id),
            },
          ]),
        ),
      });
    });

    historyApi.debouncedAdd('component - update layout position', options);
  }

  return {
    moveTo,
    moveToMany,
  };
}

export type LayoutMeshMoveToApi = ReturnType<typeof createLayoutMeshMoveToApi>;
