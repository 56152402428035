// @ts-nocheck
import * as reselect from 'reselect';

import _ from 'lodash';
import { translate } from '#packages/i18n';

const getSavedComponents = (state) => state.savedComponents.items;
const getOptimisticSavedComponents = (state) =>
  state.savedComponents.optimisticItems;
const getSavedComponentsItem = (state, id) =>
  state.savedComponents.items.find((item) => item.id === id);
const getCount = reselect.createSelector(
  getSavedComponents,
  (items) => items.length,
);
const hasOptimisticItems = reselect.createSelector(
  getOptimisticSavedComponents,
  (items) => Boolean(items.length),
);
/**
 * @param {array} items saved component
 * @return {string} default name with next default item index e.g. 'name 1'
 * */
const getNextComponentDefaultNameUtil = (items) => {
  const defaultNameTemplateWithoutNumber = translate(
    'MyDesigns_SaveDesign_Popup_Name_Default',
    {
      number: '__REGEXP_COUNT_PLACEHOLDER__',
    },
  );

  const pattern = new RegExp(
    _.escapeRegExp(defaultNameTemplateWithoutNumber).replace(
      '__REGEXP_COUNT_PLACEHOLDER__',
      '(\\d+)',
    ),
  );
  const indexes = items
    .filter((item) => pattern.test(item.name))
    .map((item) => parseInt(item.name.match(pattern)[1], 10));

  return translate('MyDesigns_SaveDesign_Popup_Name_Default', {
    number: indexes.length === 0 ? 1 : Math.max(...indexes) + 1,
  });
};

const getIsEmpty = reselect.createSelector(getCount, (count) => count === 0);
const getNextComponentDefaultName = reselect.createSelector(
  getSavedComponents,
  getNextComponentDefaultNameUtil,
);

export {
  getSavedComponents,
  getSavedComponentsItem,
  getCount,
  hasOptimisticItems,
  getIsEmpty,
  getNextComponentDefaultName,
};
