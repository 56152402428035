import type { Dock, Docking } from 'types/documentServices';
import type { LayoutFullWidthValue } from '#packages/layout';

export function getDockingFromFullWidthValue({
  isFullWidth,
  siteMargin,
}: LayoutFullWidthValue): Docking {
  if (!isFullWidth) {
    return;
  }

  // NOTE:
  // `vw` should be always defined, because it is used as a marker for full width[1]:
  // ```
  // const isFullWidth = isFinite(docking.left.vw) && isFinite(docking.right.vw)
  // ```
  // [1]: https://github.com/wix-private/santa-core/blob/f1a5a0d8e67d5d606cd1983a47a56794e4f12238/santa-core-utils/src/coreUtils/core/dockUtils.js#L62-L68
  const siteMarginDock: Dock =
    siteMargin.type === 'px'
      ? {
          px: siteMargin.value,
          vw: 0,
        }
      : {
          vw: siteMargin.value,
        };

  return {
    left: siteMarginDock,
    right: siteMarginDock,
  };
}

export function getFullWidthValueFromDocking(docking: Docking | undefined) {
  if (
    !docking ||
    docking.top ||
    docking.bottom ||
    docking.hCenter ||
    docking.vCenter
  ) {
    return {
      isFullWidth: false,
    };
  }

  // see NOTE in `getDockingFromFullWidthValue`, to understand why `vw` is used as a marker for full width
  const isFullWidth =
    typeof docking.left?.vw === 'number' &&
    typeof docking.right?.vw === 'number';

  if (!isFullWidth) {
    return {
      isFullWidth: false,
    };
  }

  if (
    docking.left.vw !== docking.right.vw ||
    docking.left.px !== docking.right.px
  ) {
    console.error(
      'Left and right site margins for a full width component, should be equal.\n' +
        'Right margin will be ignored.',
    );
  }

  // To know if site margin unit is `px` or `vw` we need to check if `docking.left.px` and `docking.right.px` are defined[1]:
  // ```
  // const ixPx = isFinite(docking.left.px) && isFinite(docking.right.px)`
  // const isVw = !ixPx
  // ```
  // [1]: https://github.com/wix-private/santa-editor/blob/bd8199982bd3260a61afcb9c11150e989c114334/santa-editor/packages/compPanels/dynamicPanels/stretchingPanel/stretchingPanelMapper.ts#L65-L73
  const siteMargin: LayoutFullWidthValue['siteMargin'] =
    typeof docking.left.px === 'number'
      ? {
          type: 'px',
          value: docking.left.px,
        }
      : {
          type: 'vw',
          value: docking.left.vw,
        };

  return {
    isFullWidth,
    siteMargin,
  };
}
